<template>
    <div class="slider">
        <button
            class="slider-controll slider-controll-prev"
            :ref="id + '-slider-controll-prev'">
            <img src="@/assets/_redesigne/icons/ic-arrow-previous.svg"/>
        </button>
        <div class="carousel" :ref="id + '-carousel'">
            <div class="swiper-wrapper">
                <div
                    class="swiper-slide"
                    v-for="(item) of items"
                    :key="item.id">
                    <div class="type text-uppercase">
                        {{ $config.fileTypesLabels[item.type] }}
                    </div>
                    <div class="slide">
                        <v-img
                            v-if="item.type !== $config.fileTypes.models && item.type !== $config.fileTypes.others"
                            lazy-src="@/assets/_redesigne/icons/ic-lazy-img.svg"
                            max-height="85%"
                            max-width="90%"
                            :src="item.url"/>
                        <v-img
                            v-if="item.type === $config.fileTypes.others"
                            lazy-src="@/assets/_redesigne/icons/ic-lazy-img.svg"
                            contain
                            width="38"
                            height="38"
                            max-height="85%"
                            max-width="90%"
                            :src="require('../../assets/_redesigne/icons/ic-lazy-img.svg')">
                            <span class="d-flex justify-center align-center w-100 fill-height file-ext grey--text text--darken-2">
                                {{ `.${fileExtension(item.original_name)}` }}
                            </span>
                        </v-img>

                        <div class="model-wrapper " v-if="item.type === $config.fileTypes.models">
                            <model-viewer :disable-loading="true" :width="slideWidth" :file-name="item.original_name" :height="slideHeight"
                                          :src="item.url"></model-viewer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button
            class="slider-controll slider-controll-next"
            :ref="id + '-slider-controll-next'">
            <img src="@/assets/_redesigne/icons/ic-arrow-next.svg"/>
        </button>
    </div>
</template>

<script>
    import Swiper, { EffectFade } from "swiper";
    import { getExtension } from "@/utils/index.utils";

    Swiper.use([EffectFade]);
    export default {
        name: "small-product-slider",
        data() {
            return {
                carousel: null
            }
        },
        components: {
            ModelViewer: () => import("@/components/3d-model/model")
        },
        props: {
            id: {
                type:String,
                default:"swiper"
            },
            items: {
                type: Array,
                default: () => []
            }
        },
        computed: {
            fileExtension() {
                return (filename) => getExtension(filename)
            },
            slideHeight() {
                const typeLabelHeight = 20;
                if (this.carousel && this.carousel.height) {
                    return this.carousel.height - typeLabelHeight;
                } else {
                    return 74; //fallback height (from Figma)
                }
            },
            slideWidth() {
                const paddings = 0;
                if (this.carousel && this.carousel.width) {
                    return this.carousel.width - paddings;
                } else {
                    return 143; //fallback height (from Figma)
                }
            }
        },
        methods: {
            getSnapshot(e, item) {
                // item.modelUrl = e.snapshot()[1]
                this.$nextTick(() => {
                    this.$set(item, "modelUrl", e.snapshot()[1])

                })
            },
            createCarousel() {
                this.carousel = new Swiper(this.$refs[this.id + "-carousel"],
                                           {
                                               speed: 400,
                                               spaceBetween: 12,
                                               slidesPerView: 4,
                                               resizeObserver: true,
                                               watchSlidesVisibility: true,
                                               watchSlidesProgress: true,
                                               followFinger: false,
                                               longSwipes: false,
                                               shortSwipes: false,
                                               allowTouchMove: false,
                                               observer: true,
                                               navigation: {
                                                   nextEl: this.$refs[this.id + "-slider-controll-next"],
                                                   prevEl: this.$refs[this.id + "-slider-controll-prev"]
                                               },
                                               breakpoints: {
                                                   280: {
                                                       slidesPerView: 2
                                                   },
                                                   640: {
                                                       slidesPerView: 4
                                                   }
                                               }
                                           });
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.createCarousel();
            });
        }
    }
</script>

<style scoped lang="scss" src="./style.scss"></style>

