
const createConfig = (handler, id, blurEvent) => ({
  select: "#app-editor"+id,
  extended_valid_elements: "span[class|style],i[class|style],sof_learn_more[class|style]",
  menu: {
    file: { title: "File", items: "newdocument restoredraft | preview | print " },
    edit: { title: "Edit", items: "undo redo | cut copy paste | selectall | searchreplace" },
    view: { title: "View", items: "code | spellchecker | preview fullscreen" },
    insert: { title: "Insert", items: "image link media template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime" },
    format: { title: "Format", items: "bold italic underline strikethrough superscript subscript codeformat | blockformats fontformats fontsizes align lineheight | forecolor backcolor | removeformat" },
    table: { title: "Table", items: "inserttable | cell row column | tableprops deletetable" }
  },
  menubar: "edit view insert format table",
  toolbar1: "formatselect bold italic underline blockquote bullist numlist customEmElementButton customAdsButton customLinkButton",
  plugins: "lists link code image media table paste",
  paste_as_text: true,
  fontsize_formats: "8px 10px 12px 14px 16px 18px 24px 36px 48px",
  images_upload_handler: handler,
  media_alt_source: true,
  branding: false,
  height: 350,
  min_height: 350,
  content_style: `
  .sof_learn_more {
      background-color:#aabbcc;
      border:1px dotted #CCCCCC;
    }
    .sof_adv_block {
      background-color: red;
      border:1px dotted #CCCCCC;
    }
    .sof_link {
      background-color: green;
      border:1px dotted #CCCCCC;
    }
    .sof_text {
      background-color: gray;
      border:1px dotted #CCCCCC;
    }
  `,
  init_instance_callback: function (editor) {
    editor.on("blur", function () {
      blurEvent()
    });
  },
  setup: function(editor) {
    tinymce.PluginManager.add("customem", function(editor, url) {
      editor.ui.registry.addButton("customEmElementButton", {
          text: "Summary block",
          // icon: false,
          onAction: function(e) {
              editor.insertContent("<p class='sof_learn_more'>{SOF_LEARN_MORE_BLOCK}</p><p>text here</p><p class='sof_learn_more'>{EOF_LEARN_MORE_BLOCK}</p><p>");
          }
      });
  });
  tinymce.PluginManager.add("customadv", function(editor, url) {
    editor.ui.registry.addButton("customAdsButton", {
        text: "Grey Separator",
        // icon: false,
        onAction: function(e) {
            editor.insertContent("<p class='sof_adv_block'>{SOF_ADV_BLOCK}</p><p></p>");
        }
    });
  });
  tinymce.PluginManager.add("customlink", function(editor, url) {
    editor.ui.registry.addButton("customLinkButton", {
        text: "Link block",
        // icon: false,
        onAction: function(e) {
          editor.insertContent("<p class='sof_text'>{SOF_BUTTON_TEXT_BLOCK}</p><p>Learn More</p><p class='sof_text'>{EOF_BUTTON_TEXT_BLOCK}</p><p class='sof_link'>{SOF_BUTTON_LINK_BLOCK}</p><p>link here</p><p class='sof_link'>{EOF_BUTTON_LINK_BLOCK}</p><p></p>");
        }
    });
  });
  }
})

export default createConfig