<template>
    <v-card class="pa-0 py-12 px-15 blueish">
        <div class="d-flex justify-space-between align-center">
            <v-card-title class="px-0">
                {{ type === "completed" ? "Completed" : "Ongoing" }} Projects
            </v-card-title>
            <v-card-subtitle class="pa-0 ma-0 blueish d-flex align-center">
                <div>
                    {{ fullName }}
                </div>
                <v-menu
                    v-if="type === 'ongoing'"
                    :close-on-content-click="false"
                    bottom
                    nudge-bottom="1"
                    nudge-top="1"
                    offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <div class="pf-drd-bw ml-5">
                            <div
                                v-bind="attrs"
                                v-on="on"
                                class="pf-drd-btn">
                                Filter by:
                                <svg
                                    fill="none"
                                    height="7"
                                    viewBox="0 0 12 7"
                                    width="12"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        clip-rule="evenodd"
                                        d="M1.70724 0.29275C1.31624 -0.09825 0.684239 -0.09825 0.293239 0.29275C-0.0977611 0.68375 -0.0977611 1.31575 0.293239 1.70675L5.29324 6.70675C5.68424 7.09775 6.31624 7.09775 6.70724 6.70675L11.7072 1.70675C12.0982 1.31575 12.0982 0.68375 11.7072 0.29275C11.3162 -0.0982499 10.6842 -0.0982499 10.2932 0.29275L6.00024 4.58575L1.70724 0.29275Z"
                                        fill="#656565"
                                        fill-rule="evenodd"/>
                                </svg>
                            </div>
                        </div>
                    </template>
                    <div class="pf-drd-cw">
                        <div class="pf-drd-sect">
                            <div class="pf-drd-ttl">
                                Status
                            </div>
                            <div class="pf-statuses">
                                <v-chip
                                    v-for="(status, i) in statuses"
                                    :key="i"
                                    :class="[status.class, { [`${status.activeClass}`]: status.isActive }]"
                                    :style="chipStyle"
                                    class="ma-2"
                                    @click="filterByStatus(i, status.id)">
                                    {{ status.title }}
                                </v-chip>
                            </div>
                        </div>
                    </div>
                </v-menu>
            </v-card-subtitle>
        </div>

        <v-divider/>

        <v-card-text class="px-0 mt-5">
            <v-data-table
                :headers="headers"
                :items="projects"
                :items-per-page="100"
                class="data-table__modelers-completed"
                hide-default-footer>
                <template #[`item.created_at`]="{ item }">
                    {{ $moment(item.created_at).format("MM/DD/YY") }}
                </template>

                <template #[`item.status`]="{ item }">
                    {{ $store.getters["getValueProjectStatus"](item.status) }}
                    <v-menu
                        left
                        nudge-left="10"
                        nudge-top="7"
                        offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                                small>
                                mdi-dots-vertical
                            </v-icon>
                        </template>
                        <v-list class="data-table__modelers-completed--menu-list py-0">
                            <v-list-item @click="showReassignDialog(item.id)">
                                <v-list-item-title
                                    class="menu-list__reassign text-body-2">
                                    Reassign
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        props: {
            type: {
                type: String,
                required: true
            },
            ongoing: {
                type: Object,
                required: false,
                default: () => {
                    return {};
                }
            },
            amount: {
                type: Number,
                required: false,
                default: 0
            },
            id: {
                type: Number,
                required: true
            },
            fullName: {
                type: String,
                required: true
            }
            // items: {
            //   type: Array,
            //   required: true
            // },
        },
        data: () => ({
            statuses: [
                { id: 3, isActive: false, activeClass: "open-for-work__active", class: "open-for-work", title: "Completed" },
                { id: 2, isActive: false, activeClass: "in-progress__active", class: "in-progress", title: "In Progress" },
                { id: 1, isActive: false, activeClass: "input-needed__active", class: "input-needed", title: "Input Needed" },
                { id: 0, isActive: false, activeClass: "pending-approval__active", class: "pending-approval", title: "Pending Approval" }
            ],
            chipStyle: {
                cursor: "pointer",
                borderRadius: "18px"
            }
        }),
        methods: {
            showReassignDialog (id) {
                this.$emit("showReassignView", id);
            },
            filterByStatus (index) {
                this.statuses[index].isActive = !this.statuses[index].isActive;
                let filters = this.statuses.filter(status => status.isActive);
                this.$store.dispatch("manager/fetchModelersProjects", { id: this.id, type: this.type, filters });
            }
        },
        computed: {
            headers () {
                if (this.type === "completed") {
                    return [
                        { text: "Completed", align: "start", value: "finished_at" },
                        { text: "Title", align: "start", value: "title" },
                        { text: "Project ID", align: "start", value: "id" },
                        { text: "Client", align: "start", value: "client" }
                    ];
                }

                return [
                    { text: "Started", align: "start", value: "created_at" },
                    { text: "Client", align: "start", value: "client" },
                    { text: "Title", align: "start", value: "title" },
                    { text: "Project ID", align: "start", value: "id" },
                    { text: "Status", align: "start", value: "status" }
                ];
            },
            maxWidth () {
                if (this.type === "completed") {
                    return 635;
                }
                return 810;
            },
            projects () {
                return this.$store.state.manager.modelersProjects;
            }
        }
    };
</script>

<style lang="scss">
    .data-table__ongoing-reassign {
        position: absolute;
        right: 35px;
        bottom: -2px;
        color: #0046AF !important;
        border-color: #CBD2D0 !important;
        z-index: 5;
        background-color: #fff;
    }
    .data-table__modelers-completed {
        max-height: 520px;
        overflow: auto;
        &--menu-list {
            .v-list-item {
                min-height: 25px !important;
                cursor: pointer;
                padding-top: 5px;
                padding-bottom: 5px;
            }
            .menu-list__reassign {
                color: #0046AF;
            }
        }
        .v-btn--icon {
            color: #0B3C49 !important;
        }
        thead tr th {
            font-size: 14px !important;
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
        tr {
            &:hover {
                background-color: unset !important;
            }
            td {
                font-size: 13px !important;
                color: #0B3C49 !important;
                padding-left: 0 !important;
                &:first-child {
                    font-size: 10px !important;
                    color: #7B7B7B !important;
                }
                &:last-child {
                    position: relative;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
            }
        }
    }
</style>
