<template>
    <v-app>
        <component :is="layout">
            <router-view/>
        </component>
    </v-app>
</template>

<script>
    import Default from "@/layouts/default";
    import Login from "@/layouts/login";
    import Home from "@/layouts/home";
    import { mapGetters } from "vuex";
    import { initProductFruitsAnalytics } from "@/utils/analytics/productFruits";

    export default {
        name: "app",
        components: {
            Default,
            Login,
            Home
        },
        computed: {
            ...mapGetters([
                "isClient",
                "profile"
            ]),
            layout() {
                return this.$route.meta.layout ?? "default";
            }
        },
        watch: {
            $route() {
                document.body.classList.remove("cursor-grater");
            },
            "profile.id"(val) {
                if (val && this.isClient && process.env.VUE_APP_ENVIRONMENT === "production") {
                    initProductFruitsAnalytics(this.profile);
                }
            }
        }
    };
</script>

<style lang="scss">
html {
  overflow-y: auto !important;
}

.placeholder {
  input {
    font-family: "Futura PT", sans-serif;
    font-size: 12px;

    &::placeholder {
      font-family: "Futura PT", sans-serif;
      font-size: 12px;
    }
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      font-family: "Futura PT", sans-serif;
      font-size: 12px;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      font-family: "Futura PT", sans-serif;
      font-size: 12px;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      font-family: "Futura PT", sans-serif;
      font-size: 12px;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      font-family: "Futura PT", sans-serif;
      font-size: 12px;
    }
  }
}

.no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

.productfruits--container,
.productfruits--container-root {
    font-family: Arial, sans-serif;
}
</style>
