<template>
    <v-dialog
        v-model="dialog"
        max-width="620">
        <template v-slot:activator="{ on, attrs }">
            <v-list-item-title
                v-bind="attrs"
                v-on="on"
                class="text-body-2 actions-item__remove">
                Remove Modeler from Unco
            </v-list-item-title>
        </template>

        <div class="dialog-overflow-wrap">
            <v-btn
                class="pci-ad-cl-btn"
                icon>
                <v-img
                    :src="require('@/assets/icons/ic-close-modal.svg')"
                    contain
                    height="36"
                    max-width="36"
                    @click="dialog = false"/>
            </v-btn>

            <v-card class="manager-modelers__header--remove-form pa-0 py-12 px-15 blueish">
                <v-card-title class="px-0">
                    Remove Modeler from Unco?
                </v-card-title>

                <v-card-text class="remove-form__info px-0 pb-9">
                    <span class="font-weight-bold">Warning:</span>
                    Removing a modeler from Unco will permanently bar them from entering the app until you or another manager reinvites them.
                </v-card-text>

                <v-card-text class="px-0 pt-0 pb-4">
                    <span class="blueish">{{ modeler.first_name }} </span>
                    <span class="blueish"> {{ modeler.last_name }}</span>
                </v-card-text>

                <div class="pci-ad-options mt-6">
                    <div class="pci-ad-opt-item">
                        Email:
                        <span class="pci-ad-oi-val ttf-lc">
                            {{ modeler.email }}
                        </span>
                    </div>
                    <div class="pci-ad-opt-item mt-4">
                        Start Date:
                        <span class="pci-ad-oi-val">
                            <!-- {{ this.$moment(onGoingProject.created_at).format('d/MM/YY') }} -->
                            <!-- ({{ onGoingProject.user }}) -->
                            {{ this.$moment(modeler.created_at).format("MM/DD/YY") }} (1 month)
                        </span>
                    </div>
                    <div
                        :class="{'red-bright': ongoingProjectsLength }"
                        class="pci-ad-opt-item mt-4">
                        Ongoing Projects:
                        <span class="pci-ad-oi-val">{{ ongoingProjectsLength > 0 ? ongoingProjectsLength : "None" }}</span>
                        <div
                            v-if="ongoingProjectsLength"
                            class="mt-2 d-flex">
                            <svg
                                fill="none"
                                height="15"
                                viewBox="0 0 15 15"
                                width="15">
                                <path
                                    d="M15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0C11.6421 0 15 3.35786 15 7.5Z"
                                    fill="#D00E0E"/>
                                <path
                                    d="M8.46878 3.11658H6.66277L6.82511 8.93638H8.3105L8.46878 3.11658ZM7.5678 11.5338C8.08728 11.5338 8.53777 11.0995 8.54183 10.5598C8.53777 10.0281 8.08728 9.59385 7.5678 9.59385C7.03209 9.59385 6.58972 10.0281 6.59378 10.5598C6.58972 11.0995 7.03209 11.5338 7.5678 11.5338Z"
                                    fill="white"/>
                            </svg>
                            <p class="pa-0 ma-0 body-2 text-capitalize ml-1">
                                Must reassign projects before removing modeler
                            </p>
                        </div>
                    </div>
                </div>

                <v-card-actions class="pa-0 d-flex justify-space-between align-center  mt-10">
                    <p
                        class="body-2 pa-0 ma-0 bright-blue"
                        style="cursor: pointer"
                        @click="dialog = false">
                        Cancel
                    </p>
                    <v-btn
                        :loading="processing"
                        :disabled="processing"
                        :class="{ 'disabledBtn': ongoingProjectsLength || processing }"
                        class="remove-form__remove px-3"
                        large
                        outlined
                        rounded
                        @click="removeModeler">
                        Remove modeler from Unco
                    </v-btn>
                </v-card-actions>
            </v-card>
            <v-snackbar v-model="errorSnack" color="red" top right>
                <ul v-if="errorSnackMessages.length > 1">
                    <li v-for="(message, idx) in errorSnackMessages" :key="idx">
                        {{ message }}
                    </li>
                </ul>
                <span v-else>{{ errorSnackMessages[0] }}</span>
            </v-snackbar>
        </div>
    </v-dialog>
</template>

<script>
    import http from "@/api/httpClient.api";

    export default {
        name: "remove-modeler-dialog",
        props: {
            modeler: {
                type: Object,
                required: true
            }
        },
        data: () => ({
            dialog: false,
            processing: false,
            errorSnack: false,
            errorSnackMessages: ["validation.without_payouts", "validation.without_projects"]
        }),
        methods: {
            removeModeler () {

                this.processing = true;
                const ID = this.modeler.id;

                http.delete(`/manager/modelers/${ID}`).then(() => {
                    this.$store.dispatch("manager/getModelerList");
                    this.dialog = false;
                    this.processing = false;
                }).catch((e) => {
                    console.error(e);
                    if(e.response  && e.response.data.errors) {
                        this.errorSnack = true
                        this.errorSnackMessages = Object.values(e.response.data.errors).flat()
                    }
                    this.processing = false;
                });
            }
        },
        computed: {
            ongoingProjectsLength () {
                let length = 0;
                let ongoing = this.modeler.projects_ongoing;

                for (var project in ongoing) {
                    length += ongoing[project];
                }
                return length;
            }
        }
    };
</script>

<style lang="scss">
    .blueish {
        color: #0B3C49 !important;
    }
    .grey-lightend {
        color: #4E4E4E !important;
    }
    .manager-modelers__header {
        &--btn {
            color: #0046AF !important;
        }
        &--remove-form {
            .remove-form__remove {
                background-color: #02810F !important;
                color: #fff !important;
            }
            .disabledBtn {
                pointer-events: none !important;
                color: #fff !important;
                background-color: #CBD2D0 !important;
            }
            .remove-form__info {
                color: #4E4E4E !important;
                font-size: 16px !important;
                span {
                    color: #C81C31;
                }
            }
        }
    }
</style>
