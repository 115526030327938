<template>
    <v-footer height="78" class="project-footer" app>
        <slot/>
    </v-footer>
</template>

<script>
    export default {
        name: "project-footer"
    }
</script>
