<template>
    <div class="refund-block">
        <button type="button" class="button-text" @click="issueRefundOpen = !issueRefundOpen">
            Issue refund
        </button>
        <v-fade-transition>
            <div v-if="issueRefundOpen" class="issue-refund">
                <div class="issue-refund--title d-flex justify-space-between align-center mb-6">
                    <div>Price paid by client <span>${{ price }}</span></div>

                    <span v-if="isSuccess" class="success-update d-flex align-center">
                        <svg class="mr-2 flex-shrink-0" width="14" height="14" viewBox="0 0 14 14"
                             fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z" fill="#3F7359"/>
                            <path d="M4.5 7L6.5625 9L10 5" stroke="white" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                        </svg>


                        Refund was successful
                    </span>
                </div>
                <div>
                    <validation-observer tag="div" class="form-group" v-slot="{handleSubmit:handleSubmitRefund, valid}">
                        <form @submit.prevent="handleSubmitRefund(submitRefund)">
                            <span class="label">Partial refund amount</span>

                            <div class="d-flex ">
                                <validation-provider :custom-messages="refundErrorMessages" name="refund" :rules="refundRules" v-slot="{errors}"
                                                     slim>
                                    <v-text-field v-model="refund" type="number" :error-messages="errors"
                                                  placeholder="Partial refund amount"
                                                  class="edit-input edit-input--white mr-6"
                                                  height="36"
                                                  outlined></v-text-field>
                                </validation-provider>

                                <v-radio-group v-model="issueRefundType" row class="mt-1">
                                    <v-radio color="#9FF4A9"
                                             on-icon="ic-on"
                                             off-icon="ic-off"
                                             label="in $"
                                             :ripple="false"
                                             :value="$config.refundType.money" class="custom-radio"></v-radio>
                                    <v-radio color="#9FF4A9"
                                             on-icon="ic-on"
                                             off-icon="ic-off"
                                             label="in %"
                                             :ripple="false"
                                             :value="$config.refundType.percent" class="custom-radio">
                                    </v-radio>
                                </v-radio-group>
                            </div>
                            <div class="d-flex">
                                <v-btn type="submit" :disabled="loading || !valid" :loading="loading" rounded
                                       depressed
                                       color="#9FF4A9"
                                       height="38"
                                       class="green-btn px-8 ml-auto">
                                    Confirm
                                </v-btn>
                            </div>
                        </form>
                    </validation-observer>
                </div>
            </div>
        </v-fade-transition>
    </div>
</template>

<script>
    import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
    import { max_value, min_value, required } from "vee-validate/dist/rules";
    import { projectApi } from "@/api";

    extend("required", required);
    extend("max_value", max_value);
    extend("min_value", min_value);
    export default {
        name: "index",
        components: {
            ValidationObserver,
            ValidationProvider
        },
        props: {
            price: {
                type: [String, Number],
                default: 0
            },
            projectId: {
                type: [String, Number],
                default: null
            }
        },
        data(){
            return {
                issueRefundOpen: false,
                issueRefundType: 1,
                refund: null,
                loading: false,
                isSuccess: false,
                isSuccessTimer: null
            }
        },
        computed: {
            refundErrorMessages() {
                const defaultRulesMsg = {
                    required: "Refund amount is required"
                }
                if(this.issueRefundType === this.$config.refundType.money) {
                    return {
                        ...defaultRulesMsg,
                        min_value: "Must be greater than 0",
                        max_value: "Must be less than the price paid"
                    }
                }  else {
                    return {
                        ...defaultRulesMsg,
                        min_value: "Only 1-100% allowed",
                        max_value: "Only 1-100% allowed"
                    }
                }
            },

            refundRules() {
                if(this.issueRefundType === this.$config.refundType.money) {
                    return `required|min_value:1|max_value:${this.price}`
                }  else {
                    return "required|min_value:1|max_value:100"
                }
            }
        },
        methods: {
            async submitRefund(){
                if(!this.projectId) return
                this.loading = true
                try {
                    const response = await projectApi.paymentRefund(this.projectId, {
                        amount: this.refund,
                        type: this.issueRefundType
                    });
                    this.setSuccessUpdate()
                    this.$emit("successUpdate", response.data.data)
                } catch (e) {
                    console.log(e)
                } finally {
                    this.loading = false
                }
            },
            setSuccessUpdate() {
                clearTimeout(this.isSuccessTimer)
                this.isSuccess = true

                this.isSuccessTimer = setTimeout(()=> {
                    this.isSuccess = false
                }, 1500)
            }
        }
    }
</script>

<style scoped lang="scss">
.button-text{
    font-size: 14px;
    line-height: 18px;
    text-decoration-line: underline;
    color: #3F7359;
}

::v-deep .edit-input{
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
    &--white{
        fieldset {
            background: #ffffff;
        }
    }
    fieldset{
        border: 1px solid #464646 !important;
    }
    textarea{
        line-height: 1.2;
    }
    .v-input__slot{
        min-height: auto;
        font-size: 12px;
    }
    &.v-text-field--enclosed .v-input__append-inner{
        margin-top: auto;
        margin-bottom: auto;
    }
}

.issue-refund{
    background: #F4F4F4;
    margin-top: 16px;
    padding: 20px;
    &--title{
        font-size: 18px;
        color: #464646;
        span{
            font-size: 24px;
            color: #3F7359;
        }

        .success-update{
            font-size: 14px;
        }
    }
}

.custom-radio{
    ::v-deep .v-input--selection-controls__input{
        width: 20px;
        height: 20px;
    }
    ::v-deep .ic-on{
        font-size: 20px;
        background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTAiIGN5PSIxMCIgcj0iOS41IiBmaWxsPSJ3aGl0ZSIgc3Ryb2tlPSIjNDY0NjQ2Ii8+CjxjaXJjbGUgY3g9IjEwIiBjeT0iMTAiIHI9IjUuNSIgZmlsbD0iIzlGRjRBOSIgc3Ryb2tlPSIjNDY0NjQ2Ii8+Cjwvc3ZnPgo=") no-repeat;
    }
    ::v-deep .ic-off{
        font-size: 20px;
        background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTAiIGN5PSIxMCIgcj0iOS41IiBmaWxsPSJ3aGl0ZSIgc3Ryb2tlPSIjNDY0NjQ2Ii8+Cjwvc3ZnPgo=") no-repeat;
    }
}

.refund-block{
    .label{
        font-size: 14px;
        color: #464646;
        margin-bottom: 7px;
        display: inline-block;
    }
}
.green-btn{
    border: 1px solid #464646 !important;
    ::v-deep .v-btn__content{
        font-size: 14px !important;
    }
}
</style>
