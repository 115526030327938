<template>
    <v-menu offset-y>
        <template #activator="{on, attrs}">
            <v-btn
                v-on="on"
                v-bind="attrs"
                :class="btnClass"
                icon
                small>
                <v-img
                    :src="require('@/assets/icons/ic-user-profile.svg')"
                    contain/>
            </v-btn>
        </template>
        <template #default>
            <v-list class="user-menu-list">
                <div class="user-name">{{ userFullName }}</div>
                <v-list-item>
                    <router-link
                        :to="{name: 'user-personal-information'}"
                        class="d-flex flex text--primary">
                        View Your Account
                    </router-link>
                </v-list-item>
                <div class="separation-line mb-1"></div>
                <v-list-item>
                    <img class="icon" src="@/assets/_redesigne/icons/ic-header-profile.svg">
                    <router-link
                        :to="{name: 'user-personal-information'}"
                        class="d-flex flex text--primary">
                        Personal Information
                    </router-link>
                </v-list-item>
                <v-list-item v-if="isClient || isDesigner">
                    <img class="icon" src="@/assets/_redesigne/icons/ic-header-billing.svg">
                    <router-link
                        :to="{ name: 'user-billing-information' }"
                        class="d-flex flex text--primary">
                        Billing Information
                    </router-link>
                </v-list-item>
                <v-list-item v-if="isClient || isDesigner">
                    <img class="icon" src="@/assets/_redesigne/icons/ic-header-orders.svg">
                    <router-link
                        :to="{ name: 'user-order-history' }"
                        class="d-flex flex text--primary">
                        Order History
                    </router-link>
                </v-list-item>
                <v-list-item v-if="isClient">
                    <img class="icon" src="@/assets/_redesigne/icons/ic-header-receipts.svg">
                    <router-link
                        :to="{ name: 'user-receipts' }"
                        class="d-flex flex text--primary">
                        Receipts
                    </router-link>
                </v-list-item>
                <v-list-item v-if="isClient || isDesigner">
                    <img class="icon" src="@/assets/_redesigne/icons/ic-header-orders.svg">
                    <router-link
                        :to="{ name: 'user-notification' }"
                        class="d-flex flex text--primary">
                        Notification Setting
                    </router-link>
                </v-list-item>
                <div class="separation-line mt-1"></div>
                <v-list-item>
                    <a
                        class="d-flex flex text--primary logout-btn"
                        role="button"
                        @click="$store.dispatch('logout')">
                        <div>
                            <img src="@/assets/_redesigne/icons/ic-header-logout.svg">
                        </div>
                        Logout
                    </a>
                </v-list-item>
            </v-list>
        </template>
    </v-menu>
</template>

<script>
    import { mapGetters } from "vuex";

    export default {
        inheritAttrs: false,
        name: "user-dropdown",
        props: {
            btnClass: {
                type: [String, Array, Object],
                default: ""
            }
        },
        computed: {
            ...mapGetters([
                "profile",
                "isClient",
                "isDesigner"
            ]),
            userFullName() {
                const userFirstName = this.profile.firstName ? this.profile.firstName : ""
                const userLastName = this.profile.lastName ? this.profile.lastName : ""

                return `${userFirstName} ${userLastName}`
            }
        }
    }
</script>
