import Vue from "vue";
import { commentApi, projectApi } from "@/api/index";
import httpClientApi from "@/api/httpClient.api";
import config from "../../utils/config";

export default () => ({
    state: () => ({
        users: {},
        canvasLayers: [],
        itemData: null,
        areLinksValid: true,
        projectData: {
            id: null, // if id isn't null. Project exists
            type: 2, //"type":"Earrings",
            title: "",
            sku: "",
            fileInfos: [], // only for step 2
            files: [],
            comments: {},
            text: "",
            unique_stone_count: "",
            overall_stone_count: "",
            description: {
                description: "",
                links: []
            },
            markers: {},
            status: null,
            designer_id: null,
            designer_amount: null,
            designer_name: null,
            client_amount: null,
            manager_id: null,
            user_id: null,
            user: null,
            metals: null,
            stones: null,
            estimated_date: null,
            created_at: null,
            updated_at: null,
            payment_status: null,
            assetsSpecifications: [],
            discount_amount: 0,
            discount_applied: false,
            discount_code: "",
            discount_type: 0,
            discount_value: 0,
            current_round: {},
            rounds: [],
            render_description: "",
            type_id: config.project.type.bracelet,
            user_role: "",
            current_render_round: {},
            render_rounds: [],
            auto_deliver_renders: true,
            extra_charge: null,
            payout: null,
            substatus_id: null
        },
        commentIsEditing: false,
        projectLoading: true,
        currentModel: null,
        allComments: [],
        markerType: null,
        cutStones: [],
        stonesLimits: {}
    }),
    getters: {
        getStonesLimits(state) {
          return state.stonesLimits
        },
        getCutStones(state) {
          return state.cutStones
        },
        areLinksValid(state) {
            return state.areLinksValid;
        },
        designers3D(state) {
            return state.projectData.files.filter(
                file => file.created_by === state.projectData.designer_id
            );
        },
        getItemData(state) {
            return state.itemData;
        },
        getProjectAmount(state) {
            return state.projectData.client_amount
        },
        isProjectPaid(state) {
            return state.projectData.payment_status === 1;
        },
        isAdminProject(state) {
          return state.projectData.user_role === config.user.roles.admin || state.projectData.user_role === config.user.roles.manager
        },
        getProjectData(state) {
            return state.projectData;
        },
        projectIsOnHold(state) {
            return state.projectData.extra_charge && state.projectData.extra_charge.status === config.extraChargeStatuses.awaiting;
        },
        getProjectId: state => state.projectData.id,
        getCommentsByFileLength: state => fileID => {
            if (state.projectData.comments[fileID]) {
                return Object.keys(state.projectData.comments[fileID]).length;
            } else {
                return 0;
            }
        },
        getCommentNumber: state => (file_id, comment_id) => {
            const commentsByFile = state.projectData.comments[file_id];
            //console.log('commentsByFile', state.projectData.comments, file_id);
            if (!commentsByFile) {
                return 1;
            }
            const commentIndex = Object.keys(commentsByFile).sort(
                (a, b) => parseInt(a) - parseInt(b)
            );
            const getNumber = () => {
                let index = 0;
                for (let i = 0; i < commentIndex.length; i++) {
                    const commentID = commentIndex[i];
                    const commentsThread = commentsByFile[commentID];
                    if (
                        !commentsThread ||
                        !commentsThread[0] ||
                        commentsThread[0].type !== 1
                    ) {
                        continue;
                    }
                    index++;
                    if (commentsThread[0].comment_id === comment_id) {
                        return index;
                    }
                }
                return index;
            };
            return getNumber();
        },
        getCommentsByFile: state => fileID => {
            const commentsByFile = state.projectData.comments[fileID];
            if (!state.projectData.comments[fileID]) {
                return [];
            }
            const commentIndex = Object.keys(commentsByFile).sort(
                (a, b) => parseInt(a) - parseInt(b)
            );
            //commentsByFile.length = commentIndex.length;
            const forEach = callback => {
                const result = [];
                //
                for (let i = 0; i < commentIndex.length; i++) {
                    const commentID = commentIndex[i];
                    const commentsThread = commentsByFile[commentID];
                    if (callback(commentsThread, i)) {
                        result.push(commentsThread);
                    }
                }
                return result;
            };

            Object.defineProperty(commentsByFile, "length", {
                value: commentIndex.length,
                writable: true,
                enumerable: false
            });

            Object.defineProperty(commentsByFile, "forEach", {
                value: callback => {
                    for (let i = 0; i < commentIndex.length; i++) {
                        const commentID = commentIndex[i];
                        callback(commentsByFile[commentID], i);
                    }
                },
                writable: true,
                enumerable: false
            });

            Object.defineProperty(commentsByFile, "filter", {
                value: forEach,
                writable: true,
                enumerable: false
            });

            Object.defineProperty(commentsByFile, "forEachComment", {
                value: callback => {
                    forEach(commentThread => {
                        commentThread.forEach(callback);
                    });
                },
                writable: true,
                enumerable: false
            });

            return commentsByFile;
        },
        getProjectFileInfo: state => state.projectData.files,
        getProjectType: state => state.projectData.type,
        getProjectID: state => state.projectData.id,
        getProjectDescription: state => state.projectData.description,
        getProjectLinks: state => state.projectData.links,
        getCommentThread: state => (fileID, commentID) => {
            const fileComments = state.projectData.comments[fileID];
            if (!fileComments) {
                return [];
            }
            const rootComments = fileComments[commentID]

            if(!rootComments) {
                let thread = null

                for (const key in fileComments) {
                    if(fileComments[key].findIndex(comment=> comment.id==commentID)>=0){
                        thread = fileComments[key]
                    }
                }

                return thread || []
            }

            return  rootComments;
        },
        descriptionIsEmpty: state =>
            !state.projectData.description?.length &&
            !state.projectData.links?.length,
        getProjectFiles: state => {
            return state.projectData.files.sort((a, b) => a.id - b.id);
        },
        getMarker: state => id => state.projectData.markers[id],
        getMarkers: state => state.projectData.markers,
        getCommentEditStatus: state => state.commentIsEditing,
        getProjectLoading: state => state.projectLoading,
        isCommentThread: state => (file_id, comment_id) => {
            return (
                state.projectData.comments &&
                state.projectData.comments[file_id] &&
                state.projectData.comments[file_id][comment_id]
            );
        },
        getKeyProjectType: () => type => {
            let types = {
                Necklace: config.project.type.necklace,
                Bracelet: config.project.type.bracelet,
                Ring: config.project.type.ring,
                Earrings: config.project.type.earrings,
                Cuffs: config.project.type.cuffs,
                Band: config.project.type.band,
                Custom: config.project.type.custom
            };
            if (!types.hasOwnProperty(type)) return 0;

            return types[type];
        },
        getKeyProjectStatus: () => statusLabel => {
            const { status, statusLabels } = config.project;
            let statuses = {
                [statusLabels[status.pending_approval]]: status.pending_approval,
                [statusLabels[status.input_needed]]: status.input_needed,
                [statusLabels[status.in_progress]]: status.in_progress,
                [statusLabels[status.completed]]: status.completed,
                [statusLabels[status.draft]]: status.draft,
                [statusLabels[status.ready_for_review]]: status.ready_for_review,
                [statusLabels[status.canceled]]: status.canceled,
                [statusLabels[status.created]]: status.created,
                [statusLabels[status.pricing_needed]]: status.pricing_needed,
                [statusLabels[status.pricing_pending]]: status.pricing_pending,
                [statusLabels[status.archived]]: status.archived,
                [statusLabels[status.in_progress_rendering]]: status.in_progress_rendering,
                [statusLabels[status.ready_for_review_renders]]: status.ready_for_review_renders,
                [statusLabels[status.in_qa]]: status.in_qa,
                [statusLabels[status.in_cqa]]: status.in_cqa,
                [statusLabels[status.final_assets_prep]]: status.final_assets_prep,
                [statusLabels[status.on_hold_action_needed]]: status.on_hold_action_needed,
                [statusLabels[status.on_hold]]: status.on_hold

            };
            if (!statuses.hasOwnProperty(statusLabel)) return false;

            return statuses[statusLabel];
        },
        getValueProjectStatus: () => statusKey => {
            const { status, statusLabels } = config.project;
            let statuses = {
                [status.pending_approval]: statusLabels[status.pending_approval],
                [status.input_needed]: statusLabels[status.input_needed],
                [status.in_progress]: statusLabels[status.in_progress],
                [status.completed]: statusLabels[status.completed],
                [status.draft]: statusLabels[status.draft],
                [status.ready_for_review]: statusLabels[status.ready_for_review],
                [status.canceled]: statusLabels[status.canceled],
                [status.created]: statusLabels[status.created],
                [status.pricing_needed]: statusLabels[status.pricing_needed],
                [status.pricing_pending]: statusLabels[status.pricing_pending],
                [status.archived]: statusLabels[status.archived],
                [status.in_progress_rendering]: statusLabels[status.in_progress_rendering],
                [status.ready_for_review_renders]: statusLabels[status.ready_for_review_renders],
                [status.in_qa]: statusLabels[status.in_qa],
                [status.in_cqa]: statusLabels[status.in_cqa],
                [status.final_assets_prep]: statusLabels[status.final_assets_prep],
                [status.on_hold_action_needed]: statusLabels[status.on_hold_action_needed],
                [status.on_hold]: statusLabels[status.on_hold]
            };
            if (!statuses.hasOwnProperty(statusKey)) return false;

            return statuses[statusKey];
        },
        getClassProjectStatus: () => statusLabel => {
            const { status, statusLabels } = config.project;
            let statuses = {
                [statusLabels[status.pending_approval]]: "project-status-pending-approval",
                [statusLabels[status.input_needed]]: "project-status-input-needed",
                [statusLabels[status.in_progress]]: "project-status-in-progress",
                [statusLabels[status.completed]]: "project-status-completed",
                [statusLabels[status.draft]]: "project-status-draft",
                [statusLabels[status.ready_for_review]]: "project-status-ready-for-review",
                [statusLabels[status.render_specs]]: "project-status-render_specs",
                [statusLabels[status.canceled]]: "project-status-canceled",
                [statusLabels[status.created]]: "project-status-created",
                [statusLabels[status.pricing_needed]]: "project-status-pricing-needed",
                [statusLabels[status.pricing_pending]]: "project-status-pricing-pending",
                [statusLabels[status.archived]]: "project-status-archived",
                [statusLabels[status.in_qa]]: "project-status-qa",
                [statusLabels[status.in_cqa]]: "project-status-qa",
                [statusLabels[status.in_progress_rendering]]: "project-status-in-progress",
                [statusLabels[status.ready_for_review_renders]]: "project-status-ready-for-review",
                [statusLabels[status.final_assets_prep]]: "project-status-completed",
                [statusLabels[status.on_hold_action_needed]]: "project-status-on-hold",
                [statusLabels[status.on_hold]]: "project-status-on-hold",
                "Cancelled": "project-status-canceled"
            };
            if (!statuses.hasOwnProperty(statusLabel)) return false;

            return statuses[statusLabel];
        },
        getAssetsSpecifications: state => {
            return state.projectData.assetsSpecifications;
        },
        getCurrentModelAssetsSpecifications: state => (children = false, allModels = false) => {
            if (state.currentModel && !allModels) {
                let file = state.projectData.assetsSpecifications.find(item => item.id === state.currentModel.id);

                if (file) {
                    if (children) {

                        if(file.has_background?.props) {
                            file.children.forEach(child =>{
                                if(child.type == 7){
                                    child["backgroundColor"] = JSON.parse(file.has_background.props)["backgroundColor"]
                                }
                            })
                        }
                        return file.children;
                    }
                    return file;
                }
                return null;
            } else if (allModels) {
                const assets = [];
                state.projectData.assetsSpecifications.forEach(model => {
                    if(model.has_background?.props) {
                        model.children.forEach(child =>{
                            if(child.type == 7){
                                child["backgroundColor"] = JSON.parse(model.has_background.props)["backgroundColor"]
                            }
                        })
                    }

                    assets.push(...model.children);
                });

                return assets;
            }
            return null;
        },
        getCommentByFileId: state => id => {
            return state.allComments.find(
                item => item.icon_id === id || item.file_id === id
            );
        },

        GET_DESIGNERS_SLIDER_FILES: state => {
            let files = [];

            state.projectData.files.forEach(file=>{
                if(file.props && file.props.length > 0){
                    file.props.forEach(prop=> {
                        if(prop.attachToDesigner){
                            files.push(file)
                        }
                    })
                }
            })

            return files;
        },

        GET_USERS_SLIDER_FILES: state => {
            let files = [];

            state.projectData.files.forEach(file => {
                const fileHasProps = !!file.props && file.props.length > 0;

                if (fileHasProps) {
                    let fileAtachedToDesignersSlides = false;
                    file.props.forEach(prop => {
                        if (prop.attachToDesigner) {
                            fileAtachedToDesignersSlides = true;
                        }
                    });

                    if (!fileAtachedToDesignersSlides) {
                        files.push(file);
                    }
                } else {
                    files.push(file);
                }
            });

            return files;
        },

        GET_ALL_PROJECT_COMMENTS: state =>  state.allComments,
        getMarkerType: state => state.markerType,
        allowedCancel: ()  => {
            const available = [config.project.status.draft, config.project.status.created, config.project.status.pricing_pending]

            return statusId => available.includes(statusId)
        }
    },
    mutations: {
        setStonesLimits(state, payload) {
            state.stonesLimits = payload
        },
        setCutStones(state, payload) {
            state.cutStones = payload
        },
        setLinksStatus(state, payload) {
            state.areLinksValid = payload;
        },
        setCurrentModel(state, payload) {
            state.currentModel = payload;
        },
        setUser(state, userObj) {
            state.users[userObj.id] = userObj;
        },
        setProjectFileInfo(state, files) {
            state.projectData.fileInfos = files;
        },
        swapProjectFiles(state, files){
            state.projectData.files = files
        },
        setProjectFiles(state, file) {
            state.projectData.files = [...state.projectData.files, file];
        },
        addProjectFiles(state, file) {
            let files = state.projectData.files;
            const index = files.findIndex(file => file.id === file.id);
            if (index > -1) {
                state.projectData.files[index] = JSON.parse(
                    JSON.stringify(file)
                );
            }
        },
        setProjectFile(state, files) {
            state.projectData = {
                ...state.projectData,
                files: files
            };
        },
        pushFileToAssetsSpecifications(state, payload) {
            let findIndex = state.projectData.assetsSpecifications.findIndex(
                item => item.id === state.currentModel.id
            );
            if (findIndex !== -1) {
                let array =
                    state.projectData.assetsSpecifications[findIndex].children;
                Vue.set(array, array.length, payload);
            }
        },
        pushFilesToAssetsSpecifications(state, files) {
            state.projectData.assetsSpecifications = [
                ...state.projectData.assetsSpecifications,
                ...files
            ];
        },
        removeFileToAssetsSpecifications(state, payload) {
            let findIndex = state.projectData.assetsSpecifications.findIndex(
                item => item.id === state.currentModel.id
            );
            if (findIndex !== -1) {
                let array =
                    state.projectData.assetsSpecifications[findIndex].children;
                let indexFile = array.findIndex(item => item.id === payload);
                if (indexFile !== -1) {
                    Vue.delete(array, indexFile);
                }
            }
        },
        setProjectId(state, id) {
            state.projectData.id = id;
        },
        updateProjectData(state, payload) {
            state.projectData = { ...state.projectData, ...payload };
            state.projectLoading = false;
        },
        updateProjectLinks(state, payload) {
            state.projectData.links = payload;
        },
        updateCommentEditStatus(state, status = false) {
            state.commentIsEditing = status;
        },
        addMarker(state, markerObj) {
            const index = markerObj.comment_id;
            //state.projectData.markers = { ...state.projectData.markers, index: marker};
            state.projectData.markers[index] = markerObj;
        },
        removeMarker(state, commentID) {
            //state.projectData.markers = { ...state.projectData.markers, index: marker};
            console.log("remove Marker", this);
            delete state.projectData.markers[commentID];
        },
        addComment(state, commentObj) {
            console.log("addComment", commentObj);

            const { file_id, comment_id } = commentObj;

            if (!state.projectData.comments[file_id]) {
                state.projectData.comments = {
                    ...state.projectData.comments,
                    [file_id]: {}
                };
            }

            let commentThread =
                state.projectData.comments[file_id][comment_id] || [];

            //Update Part
            const index = commentThread.findIndex(
                comment => comment.id === commentObj.id
            ); //!commentObj.id ||
            if (index > -1) {
                commentThread = [
                    ...commentThread.slice(0, index),
                    commentObj,
                    ...commentThread.slice(index + 1, commentThread.length)
                ];
            } else {
                commentThread = [...commentThread, commentObj];
            }
            Vue.set(state.projectData.comments, file_id, {
                ...state.projectData.comments[file_id],
                [comment_id]: commentThread
            });
            // state.projectData.comments[file_id] = { ...state.projectData.comments[file_id], [comment_id]: commentThread };
        },
        removeComment(state, commentObj) {
            console.log("removeComment", commentObj);

            const { file_id, comment_id } = commentObj;
            if (!state.projectData.comments[file_id]) {
                return;
            }

            let commentThread =
                state.projectData.comments[file_id][comment_id] || [];
            const index = commentThread.findIndex(
                comment => !commentObj.id || comment.id === commentObj.id
            );
            if (index > -1) {
                state.projectData.comments[file_id] = {
                    ...state.projectData.comments[file_id],
                    [comment_id]: [
                        ...commentThread.slice(0, index),
                        ...commentThread.slice(index + 1, commentThread.length)
                    ]
                };
            }
        },
        removeFile(state, file_id) {
            //state.projectData.files.removed = true;

            console.log("removeFile file_id", file_id);
            const files = state.projectData.files.map(item => {
                //state.projectData.files = [...files.slice(0, index), ...files.slice(index + 1, files.length)]
                const item2 = { ...item };
                if (item2.children) {
                    const index = item2.children.findIndex(
                        file => file.id === file_id
                    ); //!commentObj.id ||
                    if (index > -1) {
                        item2.children = [
                            ...item2.children.slice(0, index),
                            ...item2.children.slice(
                                index + 1,
                                item2.children.length
                            )
                        ];
                        console.log("fuuuck deleted", item2.children);
                    }
                }
                return item2;
            });
            //const files = state.projectData.files;
            const index = files.findIndex(file => file.id === file_id); //!commentObj.id ||
            if (index > -1) {
                state.projectData.files = [
                    ...files.slice(0, index),
                    ...files.slice(index + 1, files.length)
                ];
            } else {
                state.projectData.files = [...files];
            }
            console.log("state.projectData.files", state.projectData.files);
        },
        removeCommentThread(state, commentObj) {
            const { file_id, comment_id } = commentObj;
            console.log(
                "removeCommentThread",
                commentObj,
                state.projectData.comments &&
                    state.projectData.comments[file_id] &&
                    state.projectData.comments[file_id][comment_id]
            );
            if (
                state.projectData.comments &&
                state.projectData.comments[file_id] &&
                state.projectData.comments[file_id][comment_id]
            ) {
                delete state.projectData.comments[file_id][comment_id];
                state.projectData.comments[file_id] = {
                    ...state.projectData.comments[file_id]
                };
            }
        },
        setItemData(state, itemData) {
            return (state.itemData = itemData);
        },
        setAllComments(state, payload) {
            state.allComments = payload;
        },

        ADD_COMMENT_TO_ALL_COMMENTS (state, comment){
            state.allComments.unshift(comment)
        },
        SET_MARKER_TYPE(state, markerType) {
            console.log("markerType", markerType);
            state.markerType = markerType;
        }
    },
    actions: {
        async loadCutStones({ commit }) {
            try{
                const { data } =  await httpClientApi.get(`/stones/shapes`);
                commit("setCutStones", data.data)
            } catch (e) {
                console.log(e)
            }
        },
        clearItemData({ commit, dispatch }) {
            commit("setItemData", null);
            dispatch("drawer/unFreeze", { root: true });
        },
        // updateProjectFiles({state}) {
        //   const fileInfos = state.projectData.fileInfos.filter((item) => !(item instanceof File));
        //   const files = state.projectData.files;
        //   files.splice(0, state.projectData.files.length , ...files, ...fileInfos);
        // },
        async loadProject({ commit, state, dispatch }) {
            try {
                const id = state.projectData.id;
                dispatch("resetProject")
                if (!id) {
                    new Error("id of project isn't found");
                }

                const {
                    links,
                    files,
                    comments,
                    title,
                    sku,
                    type = 2,
                    description,
                    status,
                    status_id,
                    designer_id,
                    designer_amount = null,
                    client_amount = null,
                    designer_name = null,
                    manager_id,
                    user_id,
                    user,
                    metals,
                    stones,
                    estimated_date,
                    created_at,
                    updated_at,
                    text,
                    unique_stone_count,
                    overall_stone_count,
                    extra_designer_amount,
                    overall_designer_amount,
                    payment_status,
                    can_refund,
                    substatus = null,
                    discount_amount,
                    discount_applied,
                    discount_code,
                    discount_type,
                    discount_value,
                    current_round,
                    rounds,
                    render_description = "",
                    type_id = config.project.type.bracelet,
                    user_role,
                    current_render_round,
                    render_rounds,
                    auto_deliver_renders,
                    extra_charge,
                    payout,
                    substatus_id
                } = await projectApi.get(id);

                const projectComments = indexCommentsByFile(
                    comments,
                    indexFileToComment(files)
                );
                //
                const projectData = {
                    id,
                    fileInfos: [],
                    comments: projectComments,
                    files: files,
                    substatus,
                    markers: { ...(state.projectData.markers || {}) }, //!important
                    description: description ?? "",
                    links: links ?? [],
                    type,
                    title,
                    sku,
                    status,
                    status_id,
                    designer_id,
                    manager_id,
                    user_id,
                    user,
                    metals,
                    stones,
                    estimated_date,
                    created_at,
                    updated_at,
                    designer_amount,
                    designer_name,
                    client_amount,
                    payment_status,
                    assetsSpecifications: [],
                    text,
                    unique_stone_count,
                    overall_stone_count,
                    extra_designer_amount,
                    overall_designer_amount,
                    can_refund,
                    discount_amount,
                    discount_applied,
                    discount_code,
                    discount_type,
                    discount_value,
                    current_round,
                    rounds,
                    render_description,
                    type_id,
                    user_role,
                    current_render_round,
                    render_rounds,
                    auto_deliver_renders,
                    extra_charge,
                    payout,
                    substatus_id
                };

                console.warn("loadProject");
                commit("updateProjectData", projectData);
                commit("setAllComments", comments);
            } catch (error) {
                console.error(
                    `Loading project data failed with error: ${error.message}`
                );
            }
        },
        async removeLink({ commit, state }, payload) {
            try {
                const links = state.projectData.links.filter(
                    link => link !== payload
                );
                await httpClientApi.patch(`/projects/${state.projectData.id}`, {
                    links
                });
                commit("updateProjectLinks", links);
            } catch (error) {
                console.error(
                    `Updating project title failed with error: ${error.message}`
                );
            }
        },

        setMarker({ commit }, commentObject) {
            console.log("addMarker", commentObject);
            commit("addMarker", commentObject);
            commit("SET_MARKER_TYPE", commentObject.type)
        },

        removeMarker({ commit, getters }, commentID) {
            const marker = getters.getMarker(commentID);
            console.log("removeMarker", marker);
            if (!marker) {
                return;
            }
            marker.shape.destroy();
            commit("removeMarker", commentID);
        },
        async updateMarker({ commit, getters }, commentObject) {
            if (!commentObject || !commentObject.id) {
                return;
            }

            console.log("updateMarker", commentObject);
            const { id, comment_id } = commentObject;
            //
            const { x, y, lines = [] } = getters.getMarker(comment_id);
            const res = await commentApi.update(id, {
                x: parseInt(x),
                y: parseInt(y),
                canvas_data: lines
            });
            commit("addComment", { ...res, comment_id: comment_id });
        },
        async updateCommentFull({ commit, getters }, commentObject) {
            console.log("updateCommentFull", commentObject);
            const { file_id, comment_id, type = null } = commentObject;
            const comments = getters.getCommentThread(file_id, comment_id);
            let comment;
            if (comments && comments[0]) {
                comment = comments[0];
            } else {
                return;
            }

            const {
                stone_type = null,
                metal_type = null,
                x,
                y,
                lines = [],
                icon_id = null
            } = getters.getMarker(comment_id);
            const options = {
                ...comment,
                metal_id: null,
                stone_id: null,
                canvas_data: comment.parent_id === null ? lines : [],
                stone_type,
                metal_type,
                icon_id,
                type: type ? type : stone_type ? 2 : metal_type ? 3 : 1,
                x: parseInt(x),
                y: parseInt(y)
            };
            // if (icon_id)
            //   options.icon_id  = icon_id;
            console.log("updateCommentFull options", options);
            const res = await commentApi.updateFull(comment.id, options);
            commit("addComment", { ...res, comment_id: comment_id });
            console.log("updateCommentFull success", commentObject);
        },
        async updateComment({ commit, getters }, commentObject) {
            console.log("updateComment", commentObject);
            const { id, comment_id } = commentObject;
            const { x, y, lines = [] } = getters.getMarker(comment_id);

            const res = await commentApi.update(id, {
                ...commentObject,
                id,
                x: parseInt(x),
                y: parseInt(y),
                canvas_data: lines
            });
            commit("addComment", { ...res, comment_id: comment_id });
        },
        async addComment({ commit, getters }, commentObject) {
            console.log("addComment", commentObject);

            const { comment_id } = commentObject;
            const {
                x,
                y,
                type,
                metal_type,
                stone_type,
                lines = [],
                icon_id
            } = getters.getMarker(comment_id);
            const options = {
                ...commentObject,
                x: parseInt(x),
                y: parseInt(y),
                type,
                metal_type,
                stone_type,
                canvas_data: lines
            };
            if (icon_id) {
                options.icon_id = icon_id;
            }

            const res = await commentApi.create(options);

            commit("addComment", { ...res, comment_id: comment_id });
            console.log("res", res);

            return res;
        },
        async deleteComment({ commit, dispatch, getters }, payload) {
            const { file_id, id, comment_id, parent_id } = payload;
            const commentID = comment_id;

            if (getters.isCommentThread(file_id, comment_id) && id) {
                await commentApi.remove(id);
            }

            if (!parent_id) {
                const marker = getters.getMarker(commentID);
                dispatch("removeMarker", commentID);
                if (marker && typeof marker.draw === "function") {
                    marker.draw();
                }
                commit("removeCommentThread", { file_id, comment_id });
                if (typeof payload.callBack === "function") {
                    payload.callBack();
                }
            } else {
                commit("removeComment", { file_id, comment_id, id });
            }
        },
        markersShowAll({ state }) {
            Object.keys(state.projectData.markers).forEach(markerID => {
                const marker = state.projectData.markers[markerID];
                if (marker.shape) {
                    marker.shape.show();
                }
                if (typeof marker.draw === "function") {
                    marker.draw();
                }
            });
        },
        markersHideAll({ state }) {
            Object.keys(state.projectData.markers).forEach(markerID => {
                const marker = state.projectData.markers[markerID];
                console.log("comments shape", marker);
                if (marker.shape) {
                    marker.shape.hide();
                }
                if (typeof marker.draw === "function") {
                    marker.draw();
                }
            });
        },
        resetProject({ state }) {
            state.projectData.id = null;
            state.projectData.type = 2;
            state.projectData.fileInfos = [];
            state.projectData.files = [];
            state.projectData.comments = {};
            state.projectData.description = "";
            state.projectData.markers = {};
            state.projectData.links = [];
            state.projectData.title = "";
            state.projectData.sku = "";
            state.projectData.status = null;
            state.projectData.status_id = null;
            state.projectData.designer_id = null;
            state.projectData.manager_id = null;
            state.projectData.user_id = null;
            state.projectData.user = null;
            state.projectData.metals = null;
            state.projectData.stones = null;
            state.projectData.estimated_date = null;
            state.projectData.created_at = null;
            state.projectData.updated_at = null;
            state.projectData.designer_amount = null;
            state.projectData.designer_name = null;
            state.projectData.client_amount = null;
            state.projectData.payment_status = null;
            state.projectData.text = null;
            state.projectData.unique_stone_count = "";
            state.projectData.overall_stone_count = "";
            state.projectData.discount_amount = 0;
            state.projectData.discount_applied = false;
            state.projectData.discount_code = "";
            state.projectData.discount_type = 0;
            state.projectData.discount_value = 0;
            state.projectData.current_round = {};
            state.projectData.rounds = [];
            state.projectData.current_render_round= {};
            state.projectData.render_rounds = [];
            state.projectData.render_description = "";
            state.projectData.auto_deliver_renders = false;
            state.projectData.type_id = config.project.type.bracelet;
            state.projectData.extra_charge = null;
        },
        updateProjectData({ commit }, payload) {
            commit("updateProjectData", payload);
        },
        cancelProject({}, payload) {
            return httpClientApi.put(`projects/${payload}/cancel`);
        },
        cancelPayedProject({}, payload) {
            console.log("|||||||||||||||||||||||||||||||||||", payload);
            return httpClientApi.put(`manager/${payload.id}/payment-refund`, { amount: payload.amount });
        },
        downloadOneAsset({}, payload) {
            return httpClientApi({
                method: "get",
                url: `/projects/${payload.projectId}/assets/model/${payload.assetId}/download/${payload.viewId}`,
                responseType: "arraybuffer",
                params: payload.params
            });
        },
        downloadAllAssets({}, payload) {
            return httpClientApi({
                method: "get",
                url: `/projects/${payload.projectId}/assets/${payload.assetId}/download`,
                responseType: "arraybuffer"
            });
        },
        downloadAll({}, payload) {
            return httpClientApi({
                method: "get",
                url: `/projects/${payload.projectId}/assets/download`,
                params: {
                    type: payload.type
                },
                responseType: "arraybuffer"
            });
        },
        getAssets({ commit }, payload) {
            return httpClientApi
                .get(`/projects/${payload}/assets`)
                .then(({ data: { data: files } }) => {
                    commit(
                        "pushFilesToAssetsSpecifications",
                        files.map(item => {
                            return {
                                id: item.id,
                                original_name: item.original_name,
                                dummy: item.dummy,
                                url: item.url,
                                type: item.type_id,
                                has_logo: item.has_logo.data,
                                has_background: item.has_background.data,
                                has_engraving: item.has_engraving.data,
                                props: item.props,
                                children: item.children.map(child => {
                                    return {
                                        children: child.children,
                                        id: child.id,
                                        created_at: child.created_at,
                                        dummy: child.dummy,
                                        title: child.title,
                                        type: child.type_id,
                                        url: child.url,
                                        user: child.user_name,
                                        view_id: child.view_id,
                                        shadow: child.props && child.props[0] && child.props[0].attachToDesigner ? child.props : JSON.parse(child.props)
                                    };
                                })
                            };
                        })
                    );
                });
        }
    }
});

const indexFileToComment = files => {
    const comments = {};
    files.forEach(file => {
        if (!comments[file.id]) {
            comments[file.id] = {};
        }
    });
    return comments;
};
const indexCommentsByFile = (commentsApi, comments = {}) => {
    const commentsResult = { ...comments };
    commentsApi.sort((a, b) => a.id - b.id);
    commentsApi.forEach(comment => {
        if (!commentsResult[comment.file_id]) {
            commentsResult[comment.file_id] = {};
        }
        // {
        //   fileID: {
        //      commentsID: [commentObje, commentObj]б
        //   }
        // }
        //Separate comments on the threads
        // commit('setUser', {id: comment.user_id });
        const fileComments = commentsResult[comment.file_id];
        if (
            comment.parent_id === null ||
            comment.parent_id === undefined ||
            fileComments[comment.parent_id] === undefined
        ) {
            fileComments[comment.id] = [{ ...comment, comment_id: comment.id }];
        } else {
            fileComments[comment.parent_id].push({
                ...comment,
                comment_id: comment.parent_id
            });
        }
    });
    return commentsResult;
};
