import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from "vue-meta"

import NotFoundPage from "../views/NotFoundPage";
import store from "@/store";
import Projects from "../views/project/Projects";
import Support from "../views/support/index";
import PersonalInformation from "../views/user/PersonalInformation";
import GoogleAuth from "../views/user/login/GoogleAuth";
import BillingInformation from "../views/user/BillingInformation";
import OrderHistory from "../views/user/OrderHistory";
import ManagerEditProject from "@/views/project/edit-manager";
import NotificationSetting from "@/views/user/NotificationSetting";

import middlewarePipeline from "@/router/middlewarePipeline";
import profileMiddleware from "@/router/middleware/profile";
import designerAgreementMiddleware from "@/router/middleware/designerAgreement";

const PageHowItWorks = () => import(/* webpackChunkName: "PageHowItWorks" */ "../views/Home/HowItWorks/index.vue");
const PageVisuals = () => import(/* webpackChunkName: "PageVisuals" */ "../views/Home/Visuals/index.vue");
const PageConfigurator = () => import(/* webpackChunkName: "PageConfigurator" */ "../views/Home/Configurator/index.vue");
const PageBlog = () => import(/* webpackChunkName: "PageBlog" */ "../views/Home/Blog/index.vue");
const Unsubscribe = () => import (/* webpackChunkName: "Unsubscribe" */ "../views/Home/Unsubscribe/index.vue")
const PageArticle = () => import(/* webpackChunkName: "PageArticle" */ "../views/Home/Blog/Article.vue");
const PageIndustries = () => import(/* webpackChunkName: "PageIndustries" */ "../views/Home/Industries/index.vue");
const PageFAQ = () => import(/* webpackChunkName: "PageFAQ" */ "../views/Home/Faq/index.vue");
const PageContactUs = () => import(/* webpackChunkName: "PageContactUs" */ "../views/Home/ContactUs/index.vue");
const StaticPages = () => import(/* webpackChunkName: "StaticPages" */ "../views/Home/Static/index.vue");

const Project = () =>import(/* webpackChunkName: "project" */ "../views/project/Project");

const SiteMap = () => import(/* webpackChunkName: "SiteMap" */ "../views/Sitemap.vue")

Vue.use(VueRouter);
Vue.use(VueMeta)

const routes = [
    {
        path: "*",
        redirect: "/404"
    },
    {
        path: "/404",
        name: "not-found",
        component: NotFoundPage
    },
    {
        path: "/auth/google",
        name: "google-auth",
        component: GoogleAuth
    },
    {
        path: "/login",
        name: "login",
        component: () =>
            import(
                /* webpackChunkName: "login" */ "../views/user/login/sing-in"
            ),
        meta: {
            layout: "login",
            title: "Login",
            guard: "guest"
        }
    },
    {
        path: "/registration",
        name: "registration",
        component: () =>
            import(
                /* webpackChunkName: "registration" */ "@/views/user/login/registration"
            ),
        meta: {
            layout: "login",
            title: "Registration",
            guard: "guest"
        }
    },
    {
        path: "/forgot-password",
        name: "forgot-password",
        component: () =>
            import(
                /* webpackChunkName: "forgot-password" */ "@/views/user/login/forgot-password"
            ),
        meta: {
            layout: "login",
            title: "Forgot Password",
            guard: "guest"
        }
    },
    {
        path: "/recover-password",
        name: "recover-password",
        component: () =>
            import(
                /* webpackChunkName: "forgot-password" */ "@/views/user/login/recover-password"
            ),
        meta: {
            layout: "login",
            title: "Recover Password",
            guard: "guest"
        }
    },
    {
        path: "/",
        name: "home",
        props: route => ({ query: route.query.q }),
        component: () => import("@/views/Home"),
        meta: {
            layout: "home",
            title: "Jewelry Photography | 3D Jewelry Design & Product images - Unco",
            description: "Elevate your jewelry Photography with our 3D jewelry Design & Product images. Our team of professionals creates exceptional visuals that showcase the beauty and uniqueness of your pieces. ",
            guard: "guest"
        }
    },
    {
        path: "/how-it-works",
        name: "How It Works",
        component: PageHowItWorks,
        meta: {
            layout: "home",
            title: "How It Works - Unco",
            description: "Find out how HelloUnco works and connect with verified professionals for your needs. Our platform streamlines the hiring process and delivers top-notch service.",
            guard: "guest"
        }
    },
    {
        path: "/visuals",
        name: "Visuals",
        component: PageVisuals,
        meta: {
            layout: "home",
            title: "3D Jewelry Rendering Services & Product | Rendering Software Free",
            description: "Create stunning product visuals with our 3D Jewelry & Product Rendering Services and Free Rendering Software. Trust us to enhance your brand image and drive sales with our state-of-the-art solutions.",
            guard: "guest"
        }
    },
    {
        path: "/configurator",
        name: "Configurator",
        component: PageConfigurator,
        meta: {
            layout: "home",
            title: "E-Commerce Product Configurator - Unco",
            description: "Our E-Commerce Product Configurator empowers customers to create custom products with ease. Enhance your sales process and customer satisfaction today.",
            guard: "guest"
        }
    },
    {
        path: "/blog",
        name: "Blog",
        component: PageBlog,
        meta: {
            layout: "home",
            title: "Blog",
            guard: "guest"
        }
    },
    {
        path: "/blog/:slug",
        name: "Article",
        component: PageArticle,
        meta: {
            layout: "home",
            title: "Article",
            guard: "guest"
        }
    },
    {
        path: "/unsubscribe/:id",
        name: "Unsubscribe",
        component: Unsubscribe,
        meta: {
            layout: "home",
            title: "Unsubscribe",
            guard: "guest"
        }
    },
    {
        path: "/industries",
        name: "PageIndustries",
        component: PageIndustries,
        meta: {
            layout: "home",
            title: "Industries",
            guard: "guest"
        }
    },
    {
        path: "/faq",
        name: "FAQ",
        component: PageFAQ,
        meta: {
            layout: "home",
            title: "Faq - Unco",
            description: "Get answers to your questions with our FAQ page at HelloUnco. Find information on our platform, services, and policies to help you make informed decisions.",
            guard: "guest"
        }
    },
    {
        path: "/contact-us",
        name: "ContactUs",
        component: PageContactUs,
        meta: {
            layout: "home",
            title: "Contact Us - Unco",
            description: "Have a question or need assistance? Contact us at HelloUnco! Our team is dedicated to providing excellent customer service and support for all your needs.",
            guard: "guest"
        }
    },

    {
        path: "/privacy-policy",
        name: "PrivacyPolicy",
        component: StaticPages,
        meta: {
            layout: "home",
            title: "Privacy Policy - Unco",
            description: "Read our Privacy Policy to learn how we collect, use, and protect your personal information. Trust us to keep your data secure.",
            guard: "guest"
        }
    },
    {
        path: "/terms-of-use",
        name: "TermsOfUse",
        component: StaticPages,
        meta: {
            layout: "home",
            title: "Terms Of Use - Unco",
            description: "Before using HelloUnco, read our Terms of Use to understand our policies and guidelines. Our platform connects you with trusted professionals, so you can hire with confidence.",
            guard: "guest"
        }
    },

    {
        path: "/cookie-policy",
        name: "CookiePolicy",
        component: StaticPages,
        meta: {
            layout: "home",
            title: "Cookie Policy - Unco",
            description: "At HelloUnco, we use cookies to provide you with a better browsing experience. Read our Cookie Policy to learn how we use cookies and how to manage your preferences.",
            guard: "guest"
        }
    },
    {
        path: "/sitemap",
        name: "SiteMap",
        component: SiteMap,
        meta: {
            layout: "home",
            title: "Sitemap",
            guard: "guest"
        }
    },

    {
        path: "/project/start",
        name: "project-start",
        props: route => {
            console.log("route", route);
        },
        component: Project,
        meta: {
            title: "Start Project"
        }
    },
    {
        path: "/project/start/:id",
        name: "project-start-edit",
        component: Project,
        meta: {
            title: "Start Project"
        }
    },
    {
        path: "/upload-files/:id",
        name: "upload-files",
        component: Project
    },
    {
        path: "/project/:id",
        name: "project",
        component: Project,
        beforeEnter(to, from, next) {
            if (to.params.id === "payment" || to.params.id === "edit") {
                next("/404");
            } else {
                next();
            }
        },
        meta: {
            title: "Project",
            middleware: [
                designerAgreementMiddleware
            ]
        }
    },
    {
        path: "/project/:id/edit",
        name: "ManagerEditProject",
        component: ManagerEditProject,
        meta: {
            title: "Edit Project"
        }
    },
    {
        path: "/project/:id/assets",
        name: "project-assets",
        component: require("@/views/manager/assets-view").default,
        meta: {
            title: "Final Assets"
        }
    },
    {
        path: "/project/edit/:id",
        name: "project-edit",
        component: Project,
        meta: {
            title: "Edit Project"
        }
    },
    {
        path: "/project/configurator/:id",
        name: "render-configurator",
        component: Project,
        meta: {
            title: "Render Specs"
        }
    },
    {
        path: "/project/render-review/:id",
        name: "render-review",
        component: Project,
        meta: {
            title: "Render Review"
        }
    },
    {
        path: "/dashboard",
        name: "dashboard",
        component: Projects,
        meta: {
            title: "Projects",
            middleware: [
                designerAgreementMiddleware
            ]
        }
    },
    {
        path: "/modelers",
        name: "modelers",
        component: require("@/views/manager/modelers-view").default,
        meta: {
            title: "Modelers"
        }
    },
    {
        path: "/clients",
        name: "clients",
        component: require("@/views/manager/clients-view").default,
        meta: {
            title: "Clients"
        }
    },
    {
        path: "/price-config",
        name: "priceConfig",
        component: require("@/views/admin/price-config").default,
        meta: {
            title: "Price Config"
        }
    },
    {
        path: "/configurator-demos",
        name: "configuratorDemosPage",
        component: require("@/views/admin/configurator-demos").default,
        meta: {
            title: "Configurator Demos"
        }
    },
    {
        path: "/blog-cms",
        name: "blogCMS",
        component: require("@/views/admin/blog-cms").default,
        meta: {
            title: "BLOG CMS"
        }
    },
    {
        path: "/blog-cms/create",
        name: "blogCMS Create",
        component: require("@/views/admin/blog-cms/CreateArticle").default,
        meta: {
            title: "BLOG CMS create"
        }
    },
    {
        path: "/blog-cms/edit/:id",
        name: "blogCMS Edit",
        component: require("@/views/admin/blog-cms/EditArticle").default,
        meta: {
            title: "BLOG CMS edit"
        }
    },
    {
        path: "/support",
        name: "support",
        component: Support,
        meta: {
            title: "Support"
        }
    },
    {
        path: "/user/personal-information",
        name: "user-personal-information",
        component: PersonalInformation,
        meta: {
            title: "Personal Information"
        }
    },
    {
        path: "/user/billing-information",
        name: "user-billing-information",
        component: BillingInformation,
        meta: {
            title: "Billing Information"
        }
    },
    {
        path: "/user/order-history",
        name: "user-order-history",
        component: OrderHistory,
        meta: {
            title: "Order History"
        }
    },
    {
        path: "/user/notification",
        name: "user-notification",
        component: NotificationSetting,
        meta: {
            title: "Notifications"
        }
    },
    {
        path: "/user/receipts",
        name: "user-receipts",
        component: () => import(/* webpackChunkName: "userReceipts" */ "@/views/user/Receipts"),
        meta: {
            title: "Receipts"
        }
    },
    {
        path: "/confidential",
        name: "confidential",
        component: () => import("@/views/confidential/Confidential"),
        meta: {
            title: "Confidential"
        }
    },
    {
        path: "/project/:id/changelog",
        name: "project-changelog",
        component: () => import("@/views/changelog/Changelog"),
        meta: {
            title: "Changelog"
        }
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior: (to, from, savedPosition) => {
        if (to.hash) {
            return {
                selector: to.hash
            }
        }
        if (savedPosition) {
            return savedPosition
        }
        return { x: 0, y: 0 }
    },
    routes
});

router.beforeEach((to, from, next) => {
    if (to.name === "google-auth" && !store.getters.isLoggedIn) {
        next();
    } else if (
        (!to.meta.guard || (to.meta.guard && to.meta.guard !== "guest")) &&
        !store.getters.isLoggedIn
    ) {
        next({ name: "home" });
    } else {
        next();
    }
});

router.beforeEach((to, from, next) => {
    const routeMiddleware = to.meta.middleware ? to.meta.middleware : [];
    const middleware = [profileMiddleware,  ...routeMiddleware];

    const context = {
        to,
        from,
        next,
        store
    };

    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
    });
});

router.afterEach(to => {
    document.title =
        to.meta?.breadcrumb?.label ||
        to.meta?.title ||
        to?.name ||
        to?.fullPath?.replace("/", "");

    const descEl = document.querySelector("head meta[name=\"description\"]");

    if(descEl && to.meta.description) {
        descEl.setAttribute("content", to.meta.description)
    }
    window.productFruits?.pageChanged?.();
});

export default router;
