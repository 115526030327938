<template>
    <v-app>
        <the-header/>
        <the-side-bar/>

        <v-main>
            <!--      <v-container class="pt-0 fill-height" fluid>-->
            <!--        <v-row class="fill-height">-->
            <!--          <v-col class="pt-0 pl-1">-->
            <!-- <transition name="fade"> -->
            <router-view/>
            <!-- </transition> -->
            <!--          </v-col>-->
            <!--        </v-row>-->
            <!--      </v-container>-->
        </v-main>
    </v-app>
</template>

<script>
    import TheHeader from "@/components/TheHeader";
    import TheSideBar from "@/components/TheSideBar";
    import { mapGetters } from "vuex";

    export default {
        name: "default",
        data: () => ({}),
        computed: {
            ...mapGetters([
                "isLoggedIn"
            ])
        },
        watch: {
            isLoggedIn(val) {
                if(val) {
                    this.$store.dispatch("loadCutStones");
                }
            }
        },
        created () {
            if(this.isLoggedIn) {
                this.$store.dispatch("loadCutStones");
            }
        },
        components: {
            TheHeader,
            TheSideBar
        }
    };
</script>
<style lang="scss">
    @import "@/assets/scss/app";
    .v-main__wrap {
        display: flex;
    }
</style>
