<template>
    <div class="project-step-dropdown" v-on-clickaway="close">
        <ul class="list" v-if="opened && links">
            <router-link
                tag="li"
                :class="['item', {'is-disabled': link.disabled }]"
                v-for="(link, index) of variants"
                :key="index"
                :to="link.disabled ? '/' : link.to">
                {{ link.title }}
            </router-link>
        </ul>
        <div class="preview-plate" @click="toggleDropdown" :class="{'sharp-top-edge': opened}">
            <div class="plate-content">
                <div class="icon-wrapper">
                    <img
                        v-if="links && links.length > 1"
                        src="@/assets/_redesigne/icons/ic-shevron-in-circle.svg"
                        :class="{'rotated': opened}"/>
                </div>
                <div class="selected" :class="{'bold': opened}" v-if="selected">{{ selected.title }}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mixin as clickaway } from "vue-clickaway";

    export default {
        mixins: [ clickaway ],
        props: {
            links: {
                type: Array,
                default: () => []
            },
            selected: {
                type: Object,
                default: null
            }
        },
        data: () => ({
            opened: false
        }),
        methods: {
            close(){
                this.opened = false
            },
            toggleDropdown() {
                if (this.links && this.links.length > 1) {
                    this.opened = !this.opened;
                }
            }
        },
        computed: {
            variants(){
                return this.links.filter(link=>link.to.name!==this.selected.to.name)
            }
        }
    };
</script>

<style scoped lang="scss" src="./style.scss"></style>
