<template>
    <v-dialog
        v-model="dialog"
        width="620">
        <template v-slot:activator="{ attrs, on }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                :color="(!isProjectCompleted && isManagerOrAdmin) ? '#9FF4A9' : ''"
                :disabled="isClient && !isProjectCompleted"
                height="30"
                min-width="108"
                :class="btnClasses"
                class="zip-upload-btn">
                Upload
            </v-btn>
        </template>

        <div class="dialog-overflow-wrap">
            <v-btn
                class="pci-ad-cl-btn"
                icon>
                <v-img
                    :src="require('@/assets/icons/ic-close-modal.svg')"
                    contain
                    height="36"
                    max-width="36"
                    @click="closeDialog"/>
            </v-btn>

            <v-card class="pa-12">
                <div class="d-flex justify-space-between align-center mb-4">
                    <v-card-title class="px-0 py-0 blueish">
                        Upload Files to a Model
                    </v-card-title>
                    <v-card-subtitle class="pa-0 ma-0 bright-blue">
                        Bulk Upload Files
                    </v-card-subtitle>
                </div>

                <v-card-text class="px-0 pt-0 pb-0 mb-8 body-1">
                    Add groups of images and assign them to a model.
                </v-card-text>

                <v-row class="d-flex align-center">
                    <v-col cols="4">
                        <div class="grey-lightend font-weight-bold">
                            Publish images to:
                        </div>
                    </v-col>
                    <v-col cols="8">
                        <v-select
                            v-model="asset"
                            :items="assets"
                            :menu-props="{ button: true, offsetY: true }"
                            dense
                            hide-details
                            item-text="original_name"
                            outlined
                            return-object/>
                    </v-col>
                </v-row>

                <v-row class="d-flex align-center mt-5">
                    <v-col cols="4">
                        <div class="bright-grey body-1">
                            Adding a group of files will result in one view.
                        </div>
                    </v-col>
                    <v-col cols="8">
                        <v-file-input
                            v-model="files"
                            :clearable="false"
                            :loading="fileLoader"
                            accept=".png, .jpg, .jpeg, .svg, .tiff, .zip, .7z, .rar"
                            class="manager-uploader"
                            hide-details
                            label="Add files (.png, .jpg, .jpeg, .svg, .tiff, .zip, .7z, .rar)"
                            multiple
                            outlined
                            prepend-icon=""
                            show-size
                            @change="uploadZipFiles"/>
                    </v-col>
                </v-row>
            </v-card>
        </div>
    </v-dialog>
</template>

<script>
    import http from "@/api/httpClient.api";
    import { mapGetters } from "vuex";

    export default {
        name: "upload-incomplete-dialog",
        props: {
            assets: {
                type: Array,
                required: true
            },
            item: {
                type: Object,
                required: true
            },
            btnClasses: {
                type: String,
                default: ""
            }
        },
        data: () => ({
            dialog: false,
            files: [],
            asset: {},
            fileLoader: false
        }),
        methods: {
            uploadZipFiles () {

                this.fileLoader = true;

                let formData = new FormData();

                this.files.forEach(file => {
                    formData.append("files[]", file);
                });

                http.post(`/projects/${this.project.id}/assets/${this.asset.id}/upload`, formData, { headers: { "Content-Type": "multipart/form-data" } }).then((res) => {
                    this.$emit("refetchAssets", true);
                    this.fileLoader = false;
                    this.dialog = false;
                    this.files = [];
                }).catch(err => {
                    this.files = [];
                    this.fileLoader = false;
                    console.log(err);
                });
            },
            closeDialog () {
                this.dialog = false;
                this.files = [];
            }
        },
        computed: {
            ...mapGetters([
                "isManagerOrAdmin",
                "isClient"
            ]),
            isProjectCompleted () {
                return this.$store.getters["manager/isProjectCompleted"];
            },
            project () {
                return this.$store.state.manager.project;
            }
        },
        created () {
            this.asset.original_name = this.item.original_name;
            this.asset.id = this.item.id;
        }
    };
</script>

<style lang="scss">

    .disabled-button {
        pointer-events: none !important;
        background-color: #CBD2D0 !important;
    }
    .data-table__uploaded-files {
        thead th {
            font-size: 12px !important;
            color: #A5A5A5 !important;
        }
        tr {
            &:hover {
                background-color: unset !important;
            }
        }
        tr td {
            padding-top: 10px !important;
            padding-bottom: 10px !important;
            font-size: 12px !important;
            color: #656565 !important;
        }
    }

    .zip-upload-btn {
        border: 1px solid #464646 !important;
        box-shadow: none !important;
        border-radius: 50px;
        font-weight: 400 !important;
        font-size: 14px;
        line-height: 18px;
        color: #464646;
    }
</style>

