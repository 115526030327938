<template>
    <div class="page">
        <button v-if="previewData" class="back-btn d-flex" @click="$emit('closePreview', true)"><v-icon class="mr-1">mdi-arrow-left</v-icon>Go back</button>
        <div v-if="content" class="section">
            <div class="header container mb-9 d-flex">
                <div class="header_left d-flex flex-column justify-end">
                    <h1>{{ content.title }}</h1>
                    <div class="d-flex align-center mt-7">
                        <article-badge :type="content.type"/>
                        <div class="date">{{ $moment(content.post_date).format('MMMM DD, YYYY') }}</div>
                    </div>
                </div>
                <div class="header_right">
                    <img class="header_img" :src="content.page_image.url" alt="article image"/>
                </div>
            </div>
            <div class="container">
                <separator/>
            </div>
            <div class="container">
                <div class="first-section d-flex justify-end align-end">
                    <div class="first-section_right">
                        <div class="section-content" v-html="topText"></div>
                    </div>
                </div>
                <div class="d-flex justify-end summary-block">
                    <div class="first-section_left pt-3">
                        <separator/>
                        <div class="first-section_left_text" v-html="learnMoreText"></div>
                        <v-btn
                            v-if="learnMoreBtn && learnMoreLink"
                            class="btn-green first-section_left_btn"
                            outlined
                            rounded
                            :href="learnMoreLink"
                            target="_blank">
                            {{ learnMoreBtn }}
                        </v-btn>
                    </div>
                    <div class="first-section_right">
                        <div class="section-content" v-html="summaryText"></div>
                    </div>
                </div>
                <div v-if="middleText" class="first-section d-flex justify-end align-end">
                    <div class="first-section_right">
                        <div class="section-content" v-html="middleText"></div>
                    </div>
                </div>
                <div v-if="showAds" class="d-flex justify-end flex-wrap" 
                     :class="[content.blog_ads.length < 3 ? 'justify-center' : '']">
                    <div class="card" :class="[content.blog_ads.length === 1 ? 'translate-50' : '']" v-for="item in content.blog_ads" :key="item.id || item.title">
                        <div class="card_img-container mb-6">
                            <img :src="item.icon.url" :alt="item.original_name"/>
                        </div>
                        <h2 class="card_title mb-10">{{ item.title }}</h2>
                        <p class="card_text">{{ item.description }}</p>
                    </div>
                </div>
                <div class="second-section d-flex justify-end align-end">
                    <div class="second-section_left">
                    </div>
                    <div class="second-section_right">
                        <separator class="second-section_separator"/>
                        <div class="section-content" v-html="bottomText"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Separator from "@/components/blog/Separator"
    import ArticleBadge from "@/components/blog/ArticleBadge"
    export default {
        components: {
            Separator,
            ArticleBadge
        },
        props: {
            previewData: {
                type: Boolean,
                default: false
            },
            content: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                // content: null,
                topText: "",
                summaryText: "",
                bottomText: "",
                middleText: "",
                showAds: false,
                learnMoreText: "",
                learnMoreBtn: "",
                learnMoreLink: ""
            }
        },
        watch: {
            content: {
                handler(val) {
                    this.splitDescription(val.description)
                    this.splitLearnMore(val.learn_more_description)
                },
                immediate: true
            }
        },
        methods: {
            splitDescription(text) {
                const splited = text.split(/\<p class="sof_learn_more">{SOF_LEARN_MORE_BLOCK}<\/p>|\<p class="sof_learn_more">{EOF_LEARN_MORE_BLOCK}<\/p>|\<p class="sof_adv_block">{SOF_ADV_BLOCK}<\/p>/);
                this.topText = splited[0].replace(/^\n|\n$/g,"").trim();
                this.summaryText = splited[1] ? splited[1].replace(/^\n|\n$/g,"").trim() : "";
                this.middleText = splited[2]?.replace(/^\n|\n$/g,"").trim() || ""
                this.bottomText = splited[3] ? splited[3].replace(/^\n|\n$/g,"").trim() : splited[2].replace(/^\n|\n$/g,"").trim();
                this.showAds = splited[3] ? true : false
            },
            splitLearnMore(text) {
                const splited = text.split(/\<p class="sof_text">{SOF_BUTTON_TEXT_BLOCK}<\/p>|\<p class="sof_text">{EOF_BUTTON_TEXT_BLOCK}<\/p>|\<p class="sof_link">{SOF_BUTTON_LINK_BLOCK}<\/p>|\<p class="sof_link">{EOF_BUTTON_LINK_BLOCK}<\/p>/);
   
                this.learnMoreText = splited[0].replace(/^\n|\n$/g,"").trim();
                this.learnMoreBtn = splited[1] ? splited[1].replace(/^\n|<p>|<\/p>|\n$/g,"").trim() : "";
                // this.learnMoreLink = splited[3] ? splited[3].replace(/^\n|\n$/g,"").trim() : "";
                this.learnMoreLink = splited[3] ? splited[3].replace(/^\n|<p>|<\/p>|\n$/g,"").trim() : "";
            }
        }
    }
</script>

<style lang="scss" scoped>
.page {
  // padding-top: 78px;
  // padding-bottom: 100px;
}
.section {
  padding: 0 50px;
  width: 100%;
}
.container {
  max-width: 1280px;
  padding: 0;
  height: 100%;
}
h1 {
  font-weight: 450;
  font-size: 72px;
  line-height: 75px;
  max-width: 410px;
}
.header {

  @media screen and (max-width: 600px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  &_left {
    width: 50%;
    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
  &_right {
    width: 50%;
    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
  &_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.first-section {
  padding-top: 38px;
  @media screen and (max-width: 600px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  h2 {
    font-weight: 450;
    font-size: 38px;
    margin-bottom: 38px;
  }
  p {
    font-weight: 300;
    font-size: 18px;
    margin-bottom: 38px;
  }
  &_left {
    width: 50%;
    max-width: calc(355px + 62px);
    // margin-right: 62px;
    padding-right: 62px;
    &_btn {
      margin-bottom: 38px;
    }
    &_text {
      font-weight: 400 !important;
      font-size: 20px;
      line-height: 140%;
      margin-top: 32px;
    }
    @media screen and (max-width: 600px) {
      width: 100%;
      padding-right: 0;
      max-width: 100%;
    }
  }
  &_right {
    width: 50%;
    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
}

.second-section {

  @media screen and (max-width: 600px) {
    flex-wrap: wrap;
  }
  h2 {
    font-weight: 450;
    font-size: 38px;
    margin-bottom: 38px;
  }
  p {
    font-weight: 300;
    font-size: 18px;
    margin-bottom: 26px;
  }
  &_separator {
    margin-bottom: 58px;
  }
  &_img {
    width: 100%;
    margin-bottom: 20px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &_left {
    width: 50%;
    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
  &_right {
    width: 50%;
    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
}

.quote {
  background: linear-gradient(90deg, #EFEFEF 9.71%, #FFFFFF 100%);
  border-radius: 31px;
  transform: rotate(180deg);
  margin-bottom: 72px;
  &_content {
    transform: rotate(180deg);
    padding: 64px 48px 64px 42px;
    position: relative;
    p {
      margin-bottom: 14px;
      &.bold {
        font-weight: 500;
        font-size: 24px;
        line-height: 111%;
        color: #464646;
      }
    }
  }
  &_quotes {
    position: absolute;
    top: 26px;
    left: 0;
    font-weight: 450;
    font-size: 96px;
    line-height: 75px;
    color: #9FF4A9;
  }
  }
  .btn-green {
    padding: 10px 70px !important;
    background: #9FF4A9;
    border: 1px solid #464646;
    border-radius: 50px;
    height: 40px !important;
    &::before {
      opacity: 0;
    }
  }
  .date {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #6B6B6B;
  }
  .card {
  width: 314px;
  padding: 50px 40px 60px;
  box-shadow: 0px 0px 36px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 54px;

  &:not(:first-child) {
    margin-left: 54px;
  }
  &_img-container {
    img {
      width: 43px;
      height: 43px;
    }
  }
  &_title {
    font-weight: 500;
    font-size: 32px;
    line-height: 100%;
  }
  &_text {
    font-weight: 300;
    font-size: 18px;
    line-height: 140%;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    &:not(:first-child) {
      margin-left: 0;
    }
    // padding-right: 0;
    // max-width: 100%;
  }
}
.section-content {
  &::v-deep {
    h2 {
      font-weight: 450 !important;
      font-size: 38px !important;
      margin-bottom: 38px !important;
    }
    p {
      font-weight: 300 !important;
      font-size: 18px !important;
      margin-bottom: 26px !important;
    }
    ol, ul, li {
      font-weight: 300 !important;
      font-size: 18px !important;
    }
    img {
      max-width: 100%;
    }
    blockquote {
      position: relative;
      background: linear-gradient(90deg, #FFF 9.71%, #EFEFEF 100%);
      border-radius: 31px;
      padding: 64px 48px 64px 42px;
      // transform: rotate(180deg);
      margin-bottom: 72px;
      margin-bottom: 30px;
      &::before {
        content: "“";
        position: absolute;
        top: 26px;
        left: 0;
        font-weight: 450;
        font-size: 96px;
        line-height: 75px;
        color: #9FF4A9;
      }
      h3 {
        color: #464646;
        font-size: 24px;
        font-weight: 450;
      }
    }
  }
  
}
.summary-block {
  @media screen and (max-width: 600px) {
   flex-direction: column;
  }
}
.back-btn {
  background: transparent;
  border: none;
}
.translate-50 {
  transform: translateX(50%);
}
</style>