<template>
    <article-form/>
</template>

<script>
    import ArticleForm from "@/components/blog/ArticleForm"
    export default {
        components: {
            ArticleForm
        }
    }
</script>

<style lang="scss" scoped>

</style>