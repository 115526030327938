import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./plugins/vlink";
import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import "tiptap-vuetify/dist/main.css";
import config from "./utils/config";

import { initAnalytic } from "@/utils/analytics";

if(process.env.VUE_APP_ENVIRONMENT === "production") {
    initAnalytic()
}

Vue.use(TiptapVuetifyPlugin, { vuetify,iconsGroup: "mdiSvg" });


Vue.config.productionTip = false;
Vue.prototype.$moment = moment;
Vue.prototype.$config = config;
Vue.prototype.$print = (value, name) => {
    if (name) {
        console.log(name, value);
    } else {
        console.log(value);
    }
};

Vue.filter("formatFile", value => {
    let arr = value.split(".");
    return `${arr[0]}<strong>.${arr[1]}</strong>`;
});

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount("#app");
