<template>
    <div>
        <div v-show="loading" class="page-preloader" style="z-index: 1"></div>

        <div v-if="currentView === VIEW_ADD_PAYMENT" class="no-select">
            <a class="btn--link back-btn" role="button"
               @click="changeView(VIEW_PAYMENT_CARDS)">
                Back to Manage Payment Methods
            </a>
            <h2 class="mt-3">Add Payment Method</h2>
            <p class="mt-4 mb-0">All transactions are secure and encripted.</p>
            <payment-radio v-model="payMethod" :values="values"/>
            <v-btn class="mt-8 mt-md-13 add-card-btn"
                   large
                   rounded
                   width="100%"
                   @click="changeView(VIEW_STRIPE_ADD_CARD)">
                Add Credit Card through Stripe
                <svg class="add-credit-ic"
                     fill="none"
                     height="15"
                     viewBox="0 0 16 15"
                     width="16">
                    <path d="M4 1H3C1.89543 1 1 1.89543 1 3V12C1 13.1046 1.89543 14 3 14H13C14.1046 14 15 13.1046 15 12V11" stroke="#464646"
                          stroke-width="2"/>img
                    <path
                        d="M15.75 1C15.75 0.585787 15.4142 0.25 15 0.250001L8.25 0.25C7.83579 0.25 7.5 0.585786 7.5 1C7.5 1.41421 7.83579 1.75 8.25 1.75H14.25V7.75C14.25 8.16421 14.5858 8.5 15 8.5C15.4142 8.5 15.75 8.16421 15.75 7.75L15.75 1ZM7.53033 9.53033L15.5303 1.53033L14.4697 0.46967L6.46967 8.46967L7.53033 9.53033Z"
                        fill="#464646"/>
                </svg>
            </v-btn>
        </div>

        <div v-else-if="currentView === VIEW_SUCCEEDED">
            <h1 class="sucsesfull-payment"> Payment is successful </h1>
            <v-btn class="mt-8 mt-md-13 sucsesfull-payment-btn"
                   large
                   rounded
                   width="100%"
                   @click="type === 'extraCharge' ? $emit('closeDialog') : $router.push('/dashboard')">
                OK
            </v-btn>
        </div>

        <payment-cards
            v-else-if="currentView === VIEW_PAYMENT_CARDS"
            :amount="amount"
            :billing-view="billingView"
            :cards="cards"
            :type="type"
            :payment-gateway-id="gatewayId"
            @addPayment="changeView(VIEW_ADD_PAYMENT)"
            @succeededPayment="changeView(VIEW_SUCCEEDED)"/>

        <div v-show="currentView === VIEW_STRIPE_ADD_CARD" class="no-select">
            <a class="btn--link" role="button"
               @click="changeView(VIEW_ADD_PAYMENT)">
                Back to Payment Methods
            </a>
            <input v-model="cardName"
                   class="card-name"
                   placeholder="Name on Card"
                   type="text"
                   value=""/>
            <form id="setup-form"
                  :data-secret="secret"
                  class="mt-4">
                <div id="card-element"></div>
                <span v-if="errorMsg" class="msg--error"> {{ errorMsg }}</span>
                <v-btn class="save-btn mt-8 mt-md-13"
                       color="orange"
                       large
                       rounded
                       width="100%"
                       @click="submitCard">
                    Save
                </v-btn>
            </form>
        </div>
    </div>
</template>

<script>
    import PaymentCards from "@/components/ui/payment/PaymentCards";
    import PaymentRadio from "@/components/ui/payment/PaymentRadio";
    import { paymentApi } from "@/api";

    const views = {
        VIEW_ADD_PAYMENT: 0,
        VIEW_PAYMENT_CARDS: 1,
        VIEW_STRIPE_ADD_CARD: 2,
        VIEW_SUCCEEDED: 3
    //  LOADING: -1,
    };

    const STRIPE = "stripe", PAYPAL = "paypal", AMAZON = "amazon";
    const radioValues = {
        [STRIPE]: {
            w: 37,
            h: 32,
            icon: "payment/ic-payment-r-card.svg",
            iconActive: "payment/ic-payment-r-card-active.svg",
            link: null,
            value: STRIPE,
            title: "Credit Card"
        },
        [PAYPAL]: {
            w: 24,
            h: 32,
            icon: "payment/ic-payment-r-paypal.svg",
            iconActive: "payment/ic-payment-r-paypal-active.svg",
            link: null,
            value: PAYPAL,
            title: "Paypal"
        },
        [AMAZON]: {
            w: 41,
            h: 32,
            icon: "payment/ic-payment-r-amazon.svg",
            iconActive: "payment/ic-payment-r-amazon-active.svg",
            link: null,
            value: AMAZON,
            title: "Amazon"
        }
    };

    export default {
        name: "payment",
        components: { PaymentRadio, PaymentCards },
        data: () => ({
            loading: false,
            currentView: 1,
            nextView: 1,
            secret: "",
            gatewayId: 1,
            payMethod: "stripe",
            values: [],
            cards: [],
            cardName: "",
            errorMsg: null,
            ...views
        }),
        props: {
            type: {
                type: String, // projectPayment | extraCharge
                default: "projectPayment"
            },
            billingView: {
                type: Boolean,
                default: false
            },
            defaultValue: {
                type: String,
                default: "stripe"
            },
            amount: {
                type: Number,
                default: null
            }
            // data: {
            //   type: Object,
            //   default: ()=>{}
            // },
        },
        computed: {
            // project() {
            //   return this.$store.getters.getProjectData;
            // }
        },
        async beforeCreate () {
            document.body.classList.add("hide-dialog");
            this.$emit("loading", true);
            // this.payMethod = this.defaultValue;
            // const resData = [STRIPE, PAYPAL, AMAZON];
            // this.values = resData.map((item)=>({
            //   ...radioValues[item],
            // }));
            //this.loading = true;

        },
        async mounted () {

        },
        methods: {
            async getPaymentMethods () {
                return await paymentApi.gateways();
            },
            async getCards () {
                return this.cards = await paymentApi.gateMethods(this.gatewayId);
                //
            },
            async submitCard () {
                let clientSecret = this.secret;
                if (this.cardName.trim() === "") {
                    this.errorMsg = "Please, press your full name";
                    return;
                }
                this.stripe.confirmCardSetup(
                    clientSecret,
                    {
                        payment_method: {
                            card: this.cardElement,
                            billing_details: {
                                name: this.cardName || "CREDIT CART" //cardholderName.value,
                            }
                        }
                    }
                ).then((result) => {
                    if (result.error) {
                        this.errorMsg = result.error.message;
                        console.error(result.error.message);
                        // Display error.message in your UI.
                        this.$root.$emit("onPaymentError", "Failure to add payment. Try again or contact our support team");
                    } else {
                        this.errorMsg = null;
                        this.cardName = "";
                        this.changeView(this.VIEW_PAYMENT_CARDS);
                        // The setup has succeeded. Display a success message.
                        this.$root.$emit("onPaymentSuccess", "Success! Payment Metehod Added.");
                    }
                });

                // this.changeView(this.VIEW_PAYMENT_CARDS);
            },
            changeView (view) {
                this.nextView = view;
                //this.currentView = view;
            }
        },
        watch: {
            "nextView": {
                immediate: true,
                async handler (newView) {

                    this.loading = true;
                    this.errorMsg = null;
                    this.cardName = "";

                    if (newView === this.VIEW_PAYMENT_CARDS) {
                        await this.getCards();
                    }

                    if (newView === this.VIEW_ADD_PAYMENT && this.values.length === 0) {
                        const resData = await this.getPaymentMethods();
                        this.payMethod = this.defaultValue;

                        this.values = resData.map((item) => ({
                            ...radioValues[item.code]
                        }));
                    }

                    if (newView === this.VIEW_STRIPE_ADD_CARD) {
                        this.secret = await paymentApi.createMethod(this.gatewayId);
                        this.stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
                        this.cardElement = this.stripe.elements().create("card");
                        this.cardElement.mount("#card-element");
                    }


                    if (newView === this.VIEW_SUCCEEDED) {
                        this.$emit("paymentSucceeded");
                    }
                    //let cardholderName = document.getElementById('cardholder-name');

                    this.loading = false;
                    this.currentView = newView;
                    document.body.classList.remove("hide-dialog");
                    this.$emit("loading", false);
                }
            }
        }
    };
</script>

<style lang="scss">
    .add-credit-ic {
        margin-left: 5px;
    }
    .card-name {
		font-family: "Futura PT" !important;
		background: #FFFFFF;
		border: 1px solid #464646;
        height: 34px;
		padding: 10px 16px;

        display: block;
        font-family: sans-serif;
        margin: 32px 0 25px 0;
        width: 100%;
        font-size: 0.9rem;
    }
    .card-name:focus {
        outline: none !important;
        box-shadow: none !important;
    }
    .placeholder input {
        font-family: "Futura PT", sans-serif;
        font-size: 12px;
    }
    .placeholder input::placeholder {
        font-family: "Futura PT", sans-serif;
        font-size: 12px;
    }
    .placeholder input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        font-family: "Futura PT", sans-serif;
        font-size: 12px;
    }
    .placeholder input::-moz-placeholder { /* Firefox 19+ */
        font-family: "Futura PT", sans-serif;
        font-size: 12px;
    }
    .placeholder input:-ms-input-placeholder { /* IE 10+ */
        font-family: "Futura PT", sans-serif;
        font-size: 12px;
    }
    .placeholder input:-moz-placeholder { /* Firefox 18- */
        font-family: "Futura PT", sans-serif;
        font-size: 12px;
    }
    .msg--error {
        margin-top: 22px;
        font-family: "Futura PT", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 32px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #9A0000;
    }

</style>

<style scoped lang="scss">
	.btn--link {
		text-decoration: underline;
		color: #3F7359;
	}

	.add-card-btn {
		color: #464646;
		background-color: #9FF4A9 !important;
	}

	.save-btn {
		color: #464646;
		background-color: #9FF4A9 !important;
	}

	.sucsesfull-payment {
		font-size: 24px;
		line-height: 27px;
		color: #464646;
	}

	.sucsesfull-payment-btn {
		background-color: #9FF4A9 !important;
		border: 1px solid #464646;
		box-sizing: border-box;
		border-radius: 50px;
	}
</style>
