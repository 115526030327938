export default () => ({
    namespaced: true,
    state: () => ({
        large: false
    }),
    getters: {
        isLarge (state) {
            return state.large;
        }
    },
    mutations: {
        SET_STATUS_LARGE_VIEW (state, payload) {
            console.log("view/SET_STATUS_LARGE_VIEW", payload);
            state.large = payload;
        }
    }

})
