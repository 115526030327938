<template>
    <footer>
        <div class="footer-content home-row">
            <div class="contacts">
                <div class="logo-wrapper">
                    <img src="@/assets/HomePageImages/logo.svg">
                </div>
                <div class="social-networks-container home-row">
                    <a
                        v-for="(network, i) of networks"
                        :key="i"
                        :href="network.link"
                        target="_blank">
                        <img class="social-network-icon" :src="network.icon">
                    </a>
                </div>
                <div class="copyright">
                    Copyright {{ copyrightYear }}. RIISE OPCO LLC, DBA UNCO. All Rights Reserved
                </div>
            </div>
            <nav class="home-column site-map">
                <router-link
                    v-for="(page, index) of siteMapPages"
                    :key="index"
                    class="page-link"
                    :to="{name: page.name}">
                    {{ page.title }}
                </router-link>
            </nav>
        </div>
    </footer>
</template>

<script>
    // import youtubeIcon from "@/assets/HomePageImages/social-network-youtube.svg";
    // import facebookIcon from "@/assets/HomePageImages/social-network-facebook.svg";
    // import twitterIcon from "@/assets/HomePageImages/social-network-twitter.svg";
    import instagramIcon from "@/assets/HomePageImages/social-network-instagram.svg";

    export default {
        name: "application-footer",
        data: () => ({
            copyrightYear: new Date().getFullYear(),
            networks: [
                // {
                //     title: "Youtube",
                //     icon: youtubeIcon,
                //     link: ""
                // },
                // {
                //     title: "Facebook",
                //     icon: facebookIcon,
                //     link: ""
                // },
                // {
                //     title: "Twitter",
                //     icon: twitterIcon,
                //     link: ""
                // },
                {
                    title: "Instagram",
                    icon: instagramIcon,
                    link: "https://www.instagram.com/hellounco"
                }
            ],
            siteMapPages: [
                {
                    title: "FAQs",
                    name: "FAQ"
                },
                {
                    title: "Contact Us",
                    name: "ContactUs"
                },
                {
                    title: "Privacy Policy",
                    name: "PrivacyPolicy"
                },
                {
                    title: "Terms of Use",
                    name: "TermsOfUse"
                },
                {
                    title: "Cookie Policy",
                    name: "CookiePolicy"
                },
                {
                    title: "Sitemap",
                    name: "SiteMap"
                }
            ]
        })
    };
</script>

<style scoped lang="scss" src="./style.scss"></style>
