<template>
    <v-dialog
        v-model="dialog"
        class="ma-0 edit-modeler--dialog"
        max-width="656">
        <template v-slot:activator="{ on, attrs }">
            <v-list-item-title
                v-bind="attrs"
                v-on="on"
                class="text-body-2 actions-item__edit">
                Edit modeler information
            </v-list-item-title>
        </template>

        <v-btn
            class="edit-modeler--dialog__btn"
            icon>
            <v-img
                :src="require('@/assets/icons/ic-close-modal.svg')"
                contain
                height="36"
                max-width="36"
                @click="dialog = false"/>
        </v-btn>

        <v-card class="manager-modelers__header--edit-form pa-0 py-12 px-15 blueish">
            <template v-if="editView">
                <v-card-title class="px-0">
                    Edit Modeler
                </v-card-title>

                <v-card-text class="edit-form__info px-0 pb-9">
                    Upon adding a modeler, they will receive an email to confirm. <br>
                    Modeler will appear once they have confirmed
                </v-card-text>

                <template v-if="modeler">
                    <v-alert
                        v-if="this.errors"
                        dense
                        outlined
                        type="error">
                        <ul>
                            <li
                                v-for="(errors, field) in errors"
                                :key="field">
                                Field {{ errors['field'] }} has error: {{ validationListener(errors['code']) }}
                            </li>
                        </ul>
                    </v-alert>
                    <v-form
                        ref="form"
                        v-model="valid"
                        lazy-validation>
                        <v-row>
                            <v-col
                                v-for="(field, i) in personalInfoFields"
                                :key="i"
                                class="pb-0"
                                cols="6">
                                <div class="body-2 mb-1">
                                    {{ field.label }}
                                </div>
                                <v-text-field
                                    v-model="modeler[field.vModel]"
                                    :placeholder="field.placeholder"
                                    :required="!!field.validationRules"
                                    :rules="validationRules[field.validationRules]"
                                    :type="field.type"
                                    v-mask="field.vModel === 'phone' ? '####-###-####' : null"
                                    dense
                                    outlined/>
                            </v-col>
                        </v-row>

                        <v-card-title class="px-0">
                            Skill Set
                        </v-card-title>

                        <div class="edit-form__skills-set px-0 pb-0">
                            <v-row
                                v-for="(skill, i) in modeler.skills"
                                :key="i">
                                <v-col
                                    class="pb-0 pt-0"
                                    cols="7">
                                    <div class="body-2 mb-1">
                                        Skill {{ i + 1 }}
                                    </div>
                                    <v-text-field
                                        v-model="skill.title"
                                        :rules="validationRules.skillsRules"
                                        dense
                                        outlined
                                        placeholder="Skill"
                                        required/>
                                </v-col>
                                <v-col
                                    class="pb-0 pt-0"
                                    cols="4">
                                    <div class="body-2 mb-1">
                                        Level
                                    </div>
                                    <v-select
                                        v-model="skill.level"
                                        :items="skillLevels"
                                        :menu-props="{ button: true, offsetY: true }"
                                        :rules="validationRules.levelRules"
                                        dense
                                        outlined
                                        required/>
                                </v-col>
                                <v-col
                                    class="d-flex align-center pb-0 pt-0"
                                    cols="1">
                                    <v-btn
                                        v-if="modeler.skills.length > 1"
                                        class="mt-0"
                                        icon
                                        @click="removeSkill(i)">
                                        <svg
                                            fill="none"
                                            height="13"
                                            viewBox="0 0 12 13"
                                            width="12">
                                            <path
                                                d="M8.58023 11.7888L1.1324 3.42753C0.540753 2.76332 0.558585 1.76672 1.17355 1.12769C1.78852 0.488668 2.71123 0.507927 3.30288 1.17214L10.7855 9.57247C11.3772 10.2367 11.3593 11.2333 10.7444 11.8723C10.1294 12.5113 9.17187 12.453 8.58023 11.7888Z"
                                                fill="#7B7B7B"/>
                                            <path
                                                d="M1.04438 11.7813C0.452725 11.1171 0.470556 10.1205 1.08552 9.48148L8.82687 1.43726C9.44184 0.798238 10.3645 0.817498 10.9562 1.48171C11.5478 2.14592 11.53 3.14252 10.9151 3.78155L3.13753 11.8634C2.55873 12.4648 1.63603 12.4455 1.04438 11.7813Z"
                                                fill="#7B7B7B"/>
                                        </svg>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </div>

                        <v-btn
                            class="mt-0 py-0 edit-form__new-skill"
                            rounded
                            text
                            @click="addNewSkill">
                            Add New Skill
                        </v-btn>

                        <v-card-actions class="pa-0 d-flex justify-space-between align-center  mt-10">
                            <p
                                class="body-2 pa-0 ma-0 edit-form__remove-modeler"
                                @click="changeView(false)">
                                Remove modeler from Unco
                            </p>
                            <v-btn
                                :class="{ 'disabledBtn': processing }"
                                :disabled="processing"
                                :loading="processing"
                                class="edit-form__add-skill"
                                large
                                outlined
                                rounded
                                width="200"
                                @click="editModeler">
                                Confirm Changes
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </template>
            </template>

            <template v-if="removeView">
                <v-card-title class="px-0">
                    Remove Modeler from Unco?
                </v-card-title>

                <v-card-text class="remove-form__info px-0 pb-9">
                    <span class="font-weight-bold">Warning:</span>
                    Removing a modeler from Unco will permanently bar them from entering the app until you or another manager reinvites them.
                </v-card-text>

                <v-card-text class="px-0 pt-0 pb-4">
                    <span class="blueish">{{ modeler.first_name }} </span>
                    <span class="blueish"> {{ modeler.last_name }}</span>
                </v-card-text>

                <div class="pci-ad-options mt-6">
                    <div class="pci-ad-opt-item">
                        Email:
                        <span class="pci-ad-oi-val ttf-lc">
                            {{ modeler.email }}
                        </span>
                    </div>
                    <div class="pci-ad-opt-item mt-4">
                        Start Date:
                        <span class="pci-ad-oi-val">
                            <!-- {{ this.$moment(onGoingProject.created_at).format('d/MM/YY') }} -->
                            <!-- ({{ onGoingProject.user }}) -->
                            {{ this.$moment(modeler.created_at).format("MM/DD/YY") }} (TODO)
                        </span>
                    </div>
                    <div
                        :class="{'red-bright': ongoingProjectsLength }"
                        class="pci-ad-opt-item mt-4">
                        Ongoing Projects:
                        <span class="pci-ad-oi-val">{{ ongoingProjectsLength > 0 ? ongoingProjectsLength : "None" }}</span>
                        <div
                            v-if="ongoingProjectsLength"
                            class="mt-2 d-flex">
                            <svg
                                fill="none"
                                height="15"
                                viewBox="0 0 15 15"
                                width="15">
                                <path
                                    d="M15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0C11.6421 0 15 3.35786 15 7.5Z"
                                    fill="#D00E0E"/>
                                <path
                                    d="M8.46878 3.11658H6.66277L6.82511 8.93638H8.3105L8.46878 3.11658ZM7.5678 11.5338C8.08728 11.5338 8.53777 11.0995 8.54183 10.5598C8.53777 10.0281 8.08728 9.59385 7.5678 9.59385C7.03209 9.59385 6.58972 10.0281 6.59378 10.5598C6.58972 11.0995 7.03209 11.5338 7.5678 11.5338Z"
                                    fill="white"/>
                            </svg>
                            <p class="pa-0 ma-0 body-2 text-capitalize ml-1">
                                Must reassign projects before removing modeler
                            </p>
                        </div>
                    </div>
                </div>

                <v-card-actions class="pa-0 d-flex justify-space-between align-center  mt-10">
                    <p
                        class="body-2 pa-0 ma-0 bright-blue"
                        style="cursor: pointer"
                        @click="changeView(true)">
                        Cancel
                    </p>
                    <v-btn
                        :loading="processing"
                        :disabled="processing"
                        :class="{ 'disabledBtn': ongoingProjectsLength || processing }"
                        class="remove-form__remove px-3"
                        large
                        outlined
                        rounded
                        @click="removeModeler">
                        Remove modeler from Unco
                    </v-btn>
                </v-card-actions>
            </template>
        </v-card>
    </v-dialog>
</template>

<script>
    import http from "@/api/httpClient.api";
    import { VueMaskDirective } from "v-mask";

    export default {
        name: "edit-modeler-dialog",
        props: {
            id: {
                type: Number,
                required: true
            }
        },
        directives: {
            mask: VueMaskDirective
        },
        data: () => ({
            dialog: false,
            editView: true,
            removeView: false,
            valid: false,
            errors: null,
            processing: false,
            modeler: null,
            personalInfoFields: [
                { label: "First Name", placeholder: "First Name", vModel: "first_name", type: "text", validationRules: "firstNameRules" },
                { label: "Last Name", placeholder: "Last Name", vModel: "last_name", type: "text", validationRules: "lastNameRules" },
                { label: "Email", placeholder: "email@email.com", vModel: "email", type: "email", validationRules: "emailRules" },
                { label: "Phone Number", placeholder: "XXXX-XXX-XXXX", vModel: "phone", type: "tel", validationRules: "phoneRules" }
            ],
            skillLevels: ["Beginner", "Proficient", "Expert"],
            validationRules: {
                emailRules: [
                    (v) => !!v || "Email is required field",
                    (v) => /.+@.+\..+/.test(v) || "Invalid email format, please try again"
                ],
                phoneRules: [
                    (v) => !!v || "Phone number is a required field"
                ],
                firstNameRules: [
                    (v) => !!v || "First Name is required field"
                ],
                lastNameRules: [
                    (v) => !!v || "Last Name is required field"
                ],
                skillsRules: [
                    (v) => !!v || "Skill is required field"
                ],
                levelRules: [
                    (v) => !!v || "Please set level for skill"
                ]
            }
        }),
        methods: {
            addNewSkill () {
                const skill = {
                    title: "",
                    level: ""
                };
                this.modeler.skills.push(skill);
            },
            removeSkill (index) {
                if (this.modeler.skills.length === 1) {
                    return;
                }
                this.modeler.skills.splice(index, 1);
            },
            editModeler () {
                if (this.$refs.form.validate()) {

                    this.processing = true;
                    this.errors = null;

                    const { first_name, last_name, email, phone, skills, backup_email = "", password = "", password_confirmation = "" } = this.modeler;

                    const params = { first_name, last_name, email, phone, skills, backup_email, password, password_confirmation };

                    http.put(`/manager/modelers/${this.id}`, params).then(() => {
                        this.$store.dispatch("manager/getModelerList");
                        this.dialog = false;
                        this.processing = false;
                    }).catch((e) => {
                        if (e.response) {
                            this.errors = e.response.data.errors;
                        }
                        this.processing = false;
                    });
                }
            },
            validationListener(errorCode)
            {
                if (errorCode === `validation.unique`) {
                    return "This email address has been already registered in the system"
                }

                return errorCode
            },
            changeView (bool) {
                this.editView = bool;
                this.removeView = !bool;
            },
            fetchModeler () {
                http.get(`/manager/modelers/${this.id}`).then(({ data }) => this.modeler = data.data);
            },
            removeModeler () {

                this.processing = true;
                const ID = this.modeler.id;

                http.delete(`/manager/modelers/${ID}`).then(() => {
                    this.$store.dispatch("manager/getModelerList");
                    this.dialog = false;
                    this.processing = false;
                }).catch((e) => {
                    console.log(e);
                    this.processing = false;
                });
            }
        },
        computed: {
            ongoingProjectsLength () {
                let length = 0;
                let ongoing = this.modeler.projects_ongoing;

                for (var project in ongoing) {
                    length += ongoing[project];
                }
                return length;
            }
        },
        created () {
            this.fetchModeler();
        },
        watch: {
            dialog (newVal) {
                if (newVal) {
                    this.editView = true;
                    this.removeView = false;
                } else {
                    this.fetchModeler();
                }
            }
        }
    };
</script>

<style lang="scss">

    // .blueish {
    //   color: #0B3C49 !important;
    // }
    // .grey-lightend {
    //   color: #4E4E4E !important;
    // }
    // .red-bright {
    //   color: #C81C31 !important;
    // }
    .v-dialog {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    .v-dialog::-webkit-scrollbar {
        display: none;
    }
    .v-dialog {
        color: #fff;
        position: relative !important;
        padding-right: 30px !important;
        padding-top: 30px !important;
        border: 0 !important;
        box-shadow: none !important;
        .edit-modeler--dialog__btn {
            position: absolute !important;
            top: 12px !important;
            right: 12px !important;
            z-index: 5;
        }
        .manager-modelers__header--edit-form {
            background-color: #fff !important;
            padding-top: 36px !important;
        }
    }
    .manager-modelers__header {
        &--btn {
            color: #0046AF !important;
        }
        &--edit-form {
            .edit-form__remove-modeler {
                color: #C81C31;
                cursor: pointer;
            }
            .edit-form__new-skill {
                color: #0046AF !important;
                cursor: pointer;
                span {
                    font-size: 14px !important;
                }
            }
            .edit-form__add-skill {
                background-color: #02810F !important;
                color: #fff !important;
            }
            .edit-form__info {
                color: #4E4E4E !important;
                font-size: 16px !important;
            }
            .remove-form__remove {
                background-color: #02810F !important;
                color: #fff !important;
            }
            .disabledBtn {
                pointer-events: none;
                color: #fff !important;
                background-color: #CBD2D0 !important;
            }
            .remove-form__info {
                color: #4E4E4E !important;
                font-size: 16px !important;
                span {
                    color: #C81C31;
                }
            }
        }
    }
</style>
