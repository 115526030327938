<template>
    <div class="manager-assets__comments">
        <div class="mb-4 section-title">
            Comments
        </div>
        <div class="d-flex">
            <v-row>
                <v-col
                    v-for="comment in comments"
                    :key="comment.id"
                    cols="3">
                    <v-card
                        class="pa-0 pa-1 pt-2 pb-0 comment"
                        flat
                        width="375">
                        <div class="d-flex justify-space-between">
                            <div class="d-flex">
                                <v-card
                                    class="manager-assets__comments--img cursor-pointer"
                                    flat
                                    outlined
                                    :ripple="false"
                                    @click="openImageInDialog(comment.file.url)">
                                    <v-img
                                        :src="comment.file.url"
                                        lazy-src="@/assets/_redesigne/icons/ic-lazy-img.svg"
                                        alt="item"
                                        height="47"
                                        width="72"/>
                                    <v-sheet
                                        v-if="comment.file && comment.file.type"
                                        class="manager-assets__comments--img-type">
                                        <div class="type-wrapper" :class="{
                                            'yellow-background': comment.file.type.indexOf('Logo')>=0,
                                            'grey-background': comment.file.type.indexOf('Background')>=0,
                                            'green-background': comment.file.type.indexOf('Engraving')>=0
                                        }">
                                            {{ comment.file.type }}
                                        </div>
                                    </v-sheet>
                                </v-card>
                                <div class="ml-3">
                                    <div class="caption blueish">
                                        {{ getCommentOwner(comment) }}
                                    </div>
                                    <div class="caption dark-grey--9F">
                                        {{ $moment(comment.created_at).format("MMM DD, YYYY") }}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <v-btn
                                    class="pa-0 drawer__btn mr-1 zoom-btn"
                                    height="24"
                                    max-width="24"
                                    min-width="24"
                                    @click="openImageInDialog(comment.file.url)">
                                    <v-img
                                        :src="require('@/assets/icons/ic-draw-zoom.svg')"
                                        contain
                                        max-height="16"
                                        max-width="16"/>
                                </v-btn>
                                <v-icon
                                    v-if="false"
                                    class="cursor-pointer manager-assets__comments--dots ml-2">
                                    mdi-dots-horizontal
                                </v-icon>
                            </div>
                        </div>
                        <p class="caption blueish pa-0 mt-3 mb-0">
                            {{ comment.comment }}
                        </p>
                        <div
                            v-if="false"
                            class="mt-2 d-flex justify-end">
                            <v-btn
                                class="bright-blue manager-assets__comments--reply"
                                small
                                text
                                @click="replyToComment(comment)">
                                Reply
                            </v-btn>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";

    export default {
        props: {
            comments: {
                type: Array,
                required: true,
                default: () => []
            }
        },
        computed: {
            ...mapGetters([
                "isClient",
                "isDesigner"
            ])
        },
        methods: {
            openImageInDialog (url) {
                this.$emit("openImageInDialog", url);
            },
            replyToComment (comment) {
                console.log(comment);
                // const data = {
                //   project_id: this.project.id,
                //   parent_id: comment.id,
                //   type: 1,
                //   file_id: comment.file.id,
                //   comment: 'test test test'
                // }
            },
            getCommentOwner(comment) {
                const { client, designer } = this.$config.user.roles;
                if (this.isClient || this.isDesigner) {
                    if (this.isClient && comment.user_role === designer || this.isDesigner && comment.user_role === client) {
                        return comment.user.full_name.split(" ")[0];
                    }
                    return comment.user.full_name;
                }
                return comment.user.full_name;
            }
        }
    };
</script>

<style scoped lang="scss">
    .section-title {
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        color: #464646;
    }

    .comment {
        background: #FFFFFF;
        border: 1px solid #BCBCBC;
        border-radius: 5px;
    }

    .zoom-btn {
        background-color: #D9FBDD !important;
        box-shadow: none !important;
        border: 1px solid #464646;
        border-radius: 4px;
    }

    .yellow-background {
        background-color: #FCF037 !important;
    }
    .grey-background {
        background-color: #E8E8E8 !important;
    }
    .green-background {
        background-color: #9FF4A9 !important;
    }

    .type-wrapper {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #464646;
        background-color: #9FF4A9;
    }
</style>
