<template>
    <v-menu
        bottom
        max-width="248"
        offset-y
        offset-x
        nudge-bottom="5px"
        close-delay="300"
        open-on-hover
        content-class="tooltip-information pa-2">
        <template #activator="{on, attrs}">
            <div
                v-on="on"
                v-bind="[attrs, $attrs]"
                class="confidential rounded-pill ml-5 d-flex align-items-center sku-title-wrap px-2 overflow-hidden cursor-pointer">
                <img class="mr-1" width="16" height="16" :src="require('@/assets/icons/ic-lock.svg')"
                     alt="">
                <div class="">
                    Confidential
                </div>
            </div>
        </template>

        <div class="text-body-2">
            <div class="font-weight-bold">Confidential information</div>
            <div class="font-weight-regular">
                All visual or written information on Unco is an intellectual property of the client. Any disclosure
                of this information is illegal. It is forbidden to publicly or privately share any designs, titles
                or any other information obtained from Unco platform. Please
                refer to <router-link :to="{name: 'confidential'}" class="white--text" target="_blank">Terms & Conditions</router-link> for more information.
            </div>
        </div>
    </v-menu>
</template>

<script>
    export default {
        name: "agreement-label"
    }
</script>

<style scoped lang="scss">
.confidential {
    background: #E1F1FA;
    color: #367EB2;
    font-size: 16px;
    line-height: 21px;
    height: 25px;
}
</style>
