var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({on}){return [_c('v-btn',_vm._g(_vm._b({staticClass:"notification-btn",class:_vm.btnClass,attrs:{"icon":"","small":""}},'v-btn',_vm.$attrs,false),on),[_c('v-img',{attrs:{"src":require('@/assets/icons/ic-bell-notification.svg'),"contain":""}}),(_vm.not_read_count)?_c('span',{staticClass:"notification-calculation font-weight-bold"},[_vm._v(" "+_vm._s(_vm.not_read_count)+" ")]):_vm._e()],1)]}},{key:"default",fn:function(){return [_c('v-list',{staticClass:"notification-list py-5 px-7"},[_c('div',{staticClass:"list-header d-flex justify-space-between mb-7"},[_c('h3',{staticClass:"list-header__title"},[_vm._v("Notification")]),_c('button',{staticClass:"list-header__button text-right",on:{"click":function($event){$event.preventDefault();return _vm.readAllNotification.apply(null, arguments)}}},[_vm._v(" Mark all as read ")])]),(_vm.notifications.length === 0)?_c('div',{staticClass:"text-caption"},[_vm._v(" You haven't notifications yet ")]):_vm._e(),_vm._l((_vm.notifications),function(notification,index){return _c('v-list-item',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.readedMessage),expression:"readedMessage"}],key:index,staticClass:"notification-card pa-0",attrs:{"data-uuid":notification.uuid}},[_c('p',{staticClass:"notification-card__message pl-3 pr-5 pt-5 pb-2",domProps:{"innerHTML":_vm._s(notification.message)}}),(notification.icon)?_c('div',{class:['notification-card__icon text-center', {
                        'notification-card__icon--red' : notification.icon,
                        'notification-card__icon--green' : notification.icon === 'finance',
                        'notification-card__icon--blue' : notification.icon ==='massage'
                    }]},[(notification.icon === '')?_c('img',{attrs:{"src":require("@/assets/icons/ic_clock.svg"),"alt":""}}):_vm._e(),(notification.icon === 'warning')?_c('img',{attrs:{"src":require("@/assets/icons/ic_warning.svg"),"alt":""}}):_vm._e(),(notification.icon === 'massage')?_c('img',{attrs:{"src":require("@/assets/icons/ic_message.svg"),"alt":""}}):_vm._e(),(notification.icon === 'finance')?_c('img',{attrs:{"src":require("@/assets/icons/ic_payed.svg"),"alt":""}}):_vm._e()]):_vm._e(),_c('span',{class:['notification-card__time text-right', {
                    'notification-card__time--unreaded' : notification.read_at === null
                }]},[_vm._v(" "+_vm._s(_vm.formatDate(notification.created_at))+" ")])])}),_c('v-list-item',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.infiniteScrolling),expression:"infiniteScrolling"}],staticClass:"last-item"})],2)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }