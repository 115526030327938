<template>
    <v-dialog v-model="showExport" max-width="364" @click:outside="closeExport">
        <template v-slot:activator="{ attrs, on }">
            <v-btn v-bind="attrs"
                   v-on="on" outlined color="main-text" height="24"
                   class="px-2 export-button">
                Export CSV
            </v-btn>
        </template>
        <div class="export-dialog">
            <div class="export-dialog__title">CSV Export</div>
            <div class="divider mt-4 mb-3"></div>
            <div class="d-flex">
                <div class="export-dialog__filter mr-5 cursor-pointer text-decoration-underline" @click="select(true)">Select all</div>
                <div class="export-dialog__filter cursor-pointer text-decoration-underline" @click="select(false)">Deselect all</div>
            </div>
            <div class="mt-7">
                <simple-checkbox
                    v-for="(item, i) in checkboxesList"
                    :key="`checkbox_${i}`"
                    v-model="item.value"
                    :simple="false"
                    class="d-block mt-2 pt-0"
                    :label="item.label"
                    hide-details></simple-checkbox>
            </div>
            <div class="d-flex justify-end mt-9">
                <v-btn outlined
                       rounded
                       color="main-text"
                       :disabled="!hasSelected"
                       height="36" class="bg-primary" @click="exportProjects">
                    Export
                </v-btn>
            </div>
        </div>
    </v-dialog>
</template>

<script>
    import SimpleCheckbox from "../ui/SimpleCheckbox";
    import { projectApi } from "@/api";

    export default {
        name: "checkboxes-dialog",
        components: { SimpleCheckbox },
        props: {
            filter: {
                type: Object,
                default: () => ({
                    statuses: [],
                    types: [],
                    available: 0,
                    completed: 0,
                    cancelled: 0
                })
            }
        },
        data() {
            return {
                showExport: false,
                checkboxesList: [
                    { label: "Project name", valueName: "title", value: true },
                    { label: "SKU", valueName: "sku", value: true },
                    { label: "Project ID", valueName: "id", value: true },
                    { label: "Status", valueName: "status", value: false },
                    { label: "Project type", valueName: "type_title", value: false },
                    { label: "Created date", valueName: "created_at", value: false },
                    { label: "Current Due date", valueName: "estimated_date", value: false },
                    { label: "Last edited date", valueName: "updated_at", value: false },
                    { label: "Client email", valueName: "user_email", value: false },
                    { label: "Client name", valueName: "user_fill_name", value: false },
                    { label: "Designer email", valueName: "designer_email", value: false },
                    { label: "Designer name", valueName: "designer_full_name", value: false },
                    { label: "Client paid price", valueName: "customer_amount", value: false },
                    { label: "Client extra charge", valueName: "customer_extra_charged_amount", value: false },
                    { label: "Client refund amount", valueName: "customer_refund_amount", value: false },
                    { label: "Designer compensation", valueName: "designer_amount", value: false },
                    { label: "Extra designer compensation", valueName: "extra_designer_amount", value: false }
                ]
            }
        },
        computed: {
            hasSelected() {
                return this.checkboxesList.some(checkbox => checkbox.value)
            }
        },
        methods: {
            async exportProjects() {
                const activeFilters = Object.fromEntries(Object.entries(this.filter).filter(([, value]) => {
                    return value || value?.length
                }));
                const fields = this.checkboxesList.filter(item => item.value).map(item => item.valueName)
                this.showExport = false;
                const response = await projectApi.getProjectsCsv({ ...activeFilters, fields })

                const hiddenElement = document.createElement("a");
                hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(response.data);
                hiddenElement.target = "_blank";

                hiddenElement.download = `exports-${this.$moment().format("YYYYMMDD-[T]HHmmss")}.csv`;
                hiddenElement.click();
                hiddenElement.remove()
            },
            closeExport() {
                this.showExport = false;
            },
            select(bool) {
                this.checkboxesList = this.checkboxesList.map(item => {
                    item.value = bool;
                    return item
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
.divider {
    height: 1px;
    background: #E7E7E7;
}

.export-button {
    background: #E8E8E8;
    border-radius: 6px;
}

.export-dialog {
    padding: 32px 37px 26px 50px;
    background: #fff;
    border: 1px solid #888888;
    border-radius: 12px;

    &__title {
        font-weight: 450;
        font-size: 24px;
        line-height: 110%;
        color: #464646;
    }

    &__filter {
        font-size: 15px;
        line-height: 19px;
        color: #000000;
    }
}
</style>

