<template>
    <v-container class="pa-6 pt-md-16" fluid>
        <div class="def-page-cont">
            <h1 class="mb-6 mb-md-12">Your Account</h1>
            <v-row>
                <v-col cols="12" md="5">
                    <div class="profile-menu-wrap">
                        <profile-menu/>
                    </div>
                </v-col>
                <v-col cols="12" md="7" class="pos-rel">
                    <div v-if="loading" class="page-preloader"/>

                    <div v-else class="profile-cont-wrap">
                        <personal-information-view v-if="!showEdit" @onSwitchType="switchType"/>
                        <personal-information-edit v-if="showEdit" @onSwitchType="switchType"/>
                    </div>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>

<script>
    import ProfileMenu from "@/components/user-profile/ProfileMenu";
    import PersonalInformationView from "@/components/user-profile/personal-information/PersonalInformationView";
    import PersonalInformationEdit from "@/components/user-profile/personal-information/PersonalInformationEdit";

    export default {
        name: "personal-information",
        components: {
            ProfileMenu,
            PersonalInformationView,
            PersonalInformationEdit
        },
        data () {
            return {
                loading: true,
                showEdit: false
            };
        },
        async created() {
            await this.$store.dispatch("getProfile");
            this.loading = false;
        },
        methods: {
            switchType (view) {
                this.showEdit = view;
            }
        }
    };
</script>

<style lang="scss" scoped>
    .profile-cont-wrap {
        border: 1px solid #888888;
		box-sizing: border-box;
		border-radius: 12px;
        padding: 24px 40px 31px 38px;
        @media screen and (max-width: 1200px) {
            padding: 20px 24px;
        }
    }
    .profile-menu-wrap {
        position: sticky;
        top: 10px;
        @media screen and (min-width: 960px) {
            padding-right: 15px;
        }

		h1 {
			font-size: 36px;
			line-height: 40px;
			color: #0B3C49;
		}
    }
</style>
