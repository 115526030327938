<template>
    <v-container class="pa-6 pt-md-16" fluid>
        <div class="def-page-cont">
            <h1 class="mb-6 mb-md-12">Your Account</h1>
            <v-row>
                <v-col cols="12" md="5">
                    <div class="profile-menu-wrap">
                        <profile-menu/>
                    </div>
                </v-col>
                <v-col cols="12" md="7">
                    <div class="profile-cont-wrap pl-3 pr-10 pb-4 pt-6">
                        <h3 class="profile-ttl mb-8 ml-9">
                            Notification Setting
                        </h3>
                        <div v-if="fetchingData" class="page-preloader local"></div>
                        <notification-setting-item v-model="notification.subscribed"
                                                   :title="notification.name"
                                                   :description="notification.description"
                                                   v-for="(notification) in notificationSettings"
                                                   :key="notification.id"
                                                   class="mb-4"/>
                        <v-snackbar
                            v-model="successSnackbar"
                            :timeout="3000"
                            top
                            class="success-snackbar">
                            Success! Notification updated.
                        </v-snackbar>
                        <v-snackbar
                            v-model="errorSnackbar"
                            :timeout="5000"
                            top
                            class="error-snackbar">
                            Failure! Try again or contact our support team
                        </v-snackbar>
                        <v-btn class="btn-h-48 fs-16 let-sp-05 pl-4 pr-4 pl-sm-6 pr-sm-6 manage-btn"
                               elevation="0"
                               large
                               outlined
                               :loading="updatingData"
                               min-width="116"
                               rounded
                               @click="updateNotificationSetting">
                            Update
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>

<script>
    import ProfileMenu from "@/components/user-profile/ProfileMenu";
    import NotificationSettingItem from "@/components/user-profile/NotificationSettingItem";
    import { mapGetters } from "vuex";
    import { userApi } from "@/api";
    export default {
        name: "notification-setting",
        components: { NotificationSettingItem, ProfileMenu },
        data() {
            return {
                fetchingData: false,
                updatingData: false,
                successSnackbar: false,
                errorMessage: "",
                errorSnackbar: false,
                notificationSettings: []
            }
        },
        computed: {
            ...mapGetters([
                "isClient"
            ])
        },
        methods: {
            async getNotificationSetting() {
                this.fetchingData = true
                try {
                    const response = await userApi.getNotificationSetting()
                    this.notificationSettings = response.data.data
                } catch (e) {
                    console.error(e)
                }
                finally {
                    this.fetchingData = false
                }
            },
            async updateNotificationSetting() {
                this.updatingData = true
                try {
                    const activeSettingIds = this.notificationSettings.filter(notification => notification.subscribed).map(notification => notification.id)
                    const response = await userApi.updateNotificationSetting(activeSettingIds)
                    this.notificationSettings = response.data.data
                    this.successSnackbar = true
                } catch (e) {
                    console.error(e)
                    this.errorSnackbar= true
                }
                finally {
                    this.updatingData = false
                }
            }
        },
        created() {
            this.getNotificationSetting()
        }
    }
</script>

<style scoped lang="scss">
.profile-menu-wrap {
    position: sticky;
    top: 10px;
    @media screen and (min-width: 960px) {
        padding-right: 15px;
    }
}
.profile-cont-wrap{
    border: 1px solid #888888;
    border-radius: 12px;

    .profile-ttl {
        font-size: 24px;
        line-height: 27px;
        color: #464646;
        padding-bottom: 8px;
        border-bottom: 1px solid #E7E7E7;
        font-weight: 500;
    }
    .manage-btn {
        background-color: #9FF4A9;
        border: 1px solid #464646;
        box-sizing: border-box;
        border-radius: 50px;
        font-size: 14px;
        line-height: 18px;
        color: #464646;
        margin-left: auto;
        display: block;
        margin-top: 18px;
    }
}
</style>
