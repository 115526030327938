<template>
    <div class="card-list">
        <div class="card-list__li">
            <span
                v-if="card.default && !editMode"
                class="card-list__default-label">
                Primary Payment Method
            </span>
            <div class="d-flex justify-space-between align-center flex-wrap">
                <div class="d-flex align-center  flex-grow-1 mr-2">
                    <v-img
                        :src="getPaymentBrandImage(card.brand)"
                        class="card-list__icon"
                        height="22"
                        max-height="22"
                        max-width="31"
                        width="31"/>
                    <span class="card-list__card-title text-capitalize">{{ card.brand }}</span>
                    <v-icon
                        color="#0B3C49"
                        large>
                        mdi-dots-horizontal
                    </v-icon>
                    <span class="card-list__last4"> {{ card.last4 }}</span>
                </div>
                <v-btn
                    v-if="!card.default && !editMode"
                    class="ml-auto"
                    color="#FB3805"
                    outlined
                    rounded
                    small
                    @click="makePrimary">
                    Make Primary
                </v-btn>
                <v-btn
                    v-if="refund"
                    class="ml-auto"
                    color="#0046AF"
                    outlined
                    rounded
                    small
                    @click="selectCard">
                    Use for payment
                </v-btn>
                <div
                    v-if="editMode"
                    class="">
                    <div
                        class="def-blue-link"
                        @click="removeCard">
                        Remove
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "billing-payment-card",
        props: {
            editMode: {
                type: Boolean,
                default: false
            },
            card: {
                type: Object,
                default () {
                    return {};
                }
            },
            refund: {
                type: Boolean,
                default: false
            }
        },
        data () {
            return {};
        },
        methods: {
            makePrimary () {
                this.$emit("onMakePrimary", this.card);
            },
            selectCard () {
                console.log("cliiiiiiick", this.card);
                this.$emit("selectCard", this.card);
            },
            removeCard () {
                this.$emit("onRemoveCard", this.card);
            },
            getPaymentBrandImage(brand) {
                try {
                    return require(`@/assets/icons/payment/ic-payment-ct-${brand}.svg`)
                } catch (e) {
                    return require(`@/assets/_redesigne/icons/ic-lazy-img.svg`)
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    .card-list {
        padding-left: 0 !important;
        &__li {
            position: relative;
            list-style: none;
            margin-top: 16px;
            padding: 16px 33px 16px 24px;
			background: #FFFFFF;
			border: 1px solid #BCBCBC;
			box-sizing: border-box;
			border-radius: 4px;

            @media screen and (max-width: 500px) {
                padding: 16px 5px 16px 10px;
            }
        }
        &__default-label {
            display: none;
        }
        .card-list__card-title {
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
			line-height: 23px;
            color: #464646;
            margin-right: 8px;
            margin-left: 16px;
            @media screen and (max-width: 500px) {
                font-size: 12px;
                margin-right: 4px;
                margin-left: 8px;
            }
        }
        .card-list__last4 {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #0B3C49;
            margin-left: 8px;
            @media screen and (max-width: 500px) {
                font-size: 12px;
                margin-left: 4px;
            }
        }
        &__icon {
        }
        &__title {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #CBD2D0;
        }
        &__select-btn {
            background: #FFFFFF;
            border: 2px solid #0046AF;
            border-radius: 25px;
            ///* or 200% */
            //display: flex;
            //align-items: center;
            //text-align: center;
            color: #0046AF;
            .v-btn__content {
                font-style: normal;
                font-weight: normal;
                font-size: 12px !important;
                line-height: 24px !important;
            }
        }
    }
    .card-list__li--selected.card-list__li {
        border: 1px solid #02810F !important;
    }
    .card-list__default-label {
        font-family: "Futura PT", sans-serif;
        position: absolute;
        top: -10px;
        left: -1px;
        padding: 2px 8px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        /* or 200% */
        border-radius: 4px;
        display: block;
        align-items: center;
        justify-content: center;
        background: #D9FBDD;
        color: #464646;
		border: 1px solid #3F7359;
    }
</style>
