import Vue from "vue";
import Vuex from "vuex";
import httpClientApi from "@/api/httpClient.api";
//import adminUser from '@/store/modules/admin-user'
import sidebarState from "@/store/modules/sidebar";
import authorizations from "@/store/modules/authorizations";
//import preloader from '@/store/modules/preloader';
//import query from '@/store/modules/params';
import createProject from "@/store/modules/project";
import profile from "@/store/modules/profile";
import steps from "@/store/modules/steps";
import dialogs from "@/store/modules/dialog";
import drawer from "@/store/modules/drawer";
import manager from "@/store/modules/manager";
import view from "@/store/modules/large-view";
// import api from "../api/";
import createLogger from "vuex/dist/logger";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";
const plugins = debug ? [createLogger({})] : [];

const loadDataHelper = async (url, dataName) => {
    try {
        const data = await httpClientApi.get(url);
        console.log(data.data.data);
        return await data.data.data.map(item => {
            return {
                ...item,
                image: require(`@/assets/${dataName}/${item.type}.png`)
            };
        });
    } catch (error) {
        console.error(`Loading ${dataName} types failed with error ${error.message}`);
    }
};

const help = JSON.parse(localStorage.getItem("help")) ?? {
    configurator: 1
};

export const instanceComment = () => new Vuex.Store({
    modules: {
        createProject: createProject(),
        steps: steps(),
        dialogs: dialogs(),
        drawer: drawer(),
        view: view(),
        manager: manager()

    },
    state: {
        forceUpdate: 0,
        count: 0,
        projectTypes: null,
        projectTypesFullInfo: null
    },
    getters: {
        getProjectTypes (state) {
            return state.projectTypes;
        },
        getProjectTypesFullInfo (state) {
            return state.projectTypesFullInfo;
        }
    },
    mutations: {
        setProjectTypes (state, payload) {
            state.projectTypes = payload;
        },
        setProjectTypesFullInfo (state, payload) {
            state.projectTypesFullInfo = payload;
        }
    },
    actions: {
        inc ({ state }) {
            state.count++;
        }
    }
    //plugins
});

export default new Vuex.Store({
    modules: {
        sidebar: sidebarState,
        auth: authorizations,
        profile: profile,
        createProject: createProject(),
        steps: steps(),
        dialogs: dialogs(),
        drawer: drawer(),
        view: view(),
        manager: manager()
    },
    state: {
        help: help,
        forceUpdate: 0,
        metalTypes: [
            { type: 3, id: 5, group: "metal", name: "White Gold", color: " #343434", image: require("@/assets/metals/10KW.png"), value: "10KW" },
            { type: 3, id: 2, group: "metal", name: "Rose Gold", color: " #E8CCB0", image: require("@/assets/metals/10KR.png"), value: "10KR" },
            { type: 3, id: 8, group: "metal", name: "Yellow Gold", color: " #E19D00", image: require("@/assets/metals/10KY.png"), value: "10KY" },
            { type: 3, id: 1, group: "metal", name: "Silver", color: " #343434", image: require("@/assets/metals/10KW.png"), value: "SS" },
            { type: 3, id: 11, group: "metal", name: "Platinum", color: " #E8CCB0", image: require("@/assets/metals/10KY.png"), value: "PT" }
        ],
        stoneTypes: [
            { type: 2, id: 30, name: "Alexandrite", image: require("@/assets/stones/CA.png"), value: "CA", color: " #3f6886" },
            { type: 2, id: 9, name: "Amethyst", image: require("@/assets/stones/AM.png"), value: "AM", color: " #7e3ea2" },
            { type: 2, id: 10, name: "Aquamarine", image: require("@/assets/stones/A.png"), value: "A", color: " #72aadb" },
            // {id: 4, name: 'BlackDiamond', image: require('@/assets/stones/BlackDiamond.png'), value: '4', color:" #343434"},
            { type: 2, id: 12, name: "Yellow Citrine", image: require("@/assets/stones/CT.png"), value: "CT", color: " #c4a444" },
            { type: 2, id: 29, name: "Diamond", image: require("@/assets/stones/DIA.png"), value: "DIA", color: " #c6c7c7" },
            { type: 2, id: 8, name: "Green Emerald", image: require("@/assets/stones/EM.png"), value: "EM", color: " #326c34" },
            { type: 2, id: 14, name: "Garnet", image: require("@/assets/stones/GA.png"), value: "GA", color: " #76463e" },
            // {id: 9, name: 'Onyx', image: require('@/assets/stones/Onyx.png'), value: '9', color:" #343434"},
            { type: 2, id: 19, name: "Opal", image: require("@/assets/stones/OP.png"), value: "OP", color: " #dee7e6" },
            // {id: 11, name: 'Pearl', image: require('@/assets/stones/Pearl.png'), value: '11', color:" #343434"},
            // {id: 12, name: 'Peridot', image: require('@/assets/stones/Peridot.png'), value: '12', color:" #343434"},
            { type: 2, id: 4, name: "PinkSapphire", image: require("@/assets/stones/PK.png"), value: "PK", color: " #d77bb7" },
            { type: 2, id: 6, name: "Ruby", image: require("@/assets/stones/R.png"), value: "R", color: " #893e42" },
            { type: 2, id: 2, name: "Sapphire", image: require("@/assets/stones/S.png"), value: "S", color: " #3d4c95" },
            { type: 2, id: 20, name: "Pink Tourmaline", image: require("@/assets/stones/PT.png"), value: "PT", color: " #db83bf" }
            // {id: 17, name: 'Turquoise', image: require('@/assets/stones/Turquoise.png'), value: '17', color:" #343434"},
        ],
        //  preloader,
        // query
        // token: localStorage.getItem("token") && localStorage.getItem("token") !== 'undefined' && localStorage.getItem("token") !== undefined ? localStorage.getItem("token") : null,
        //isAdmin: localStorage.getItem("isAdmin") !== 'undefined' && localStorage.getItem("isAdmin") !== undefined ? localStorage.getItem("isAdmin") : false,
        projectTypes: null,
        projectTypesFullInfo: null,
        isInProjectView: false
    },
    getters: {
        METALS: state=>state.metalTypes,
        STONES: state=>state.stoneTypes,

        getAutocompleteData: (state) => {
            return [
                { header: "Metal Type" },
                // { divider: true },
                ...state.metalTypes,
                { header: "Stone Type" },
                // { divider: true },
                ...state.stoneTypes
            ];
        },
        getBrushColor: state => sku => {
            let result = state.metalTypes.find(stone => stone.value === sku);
            if (!result) {
                result = state.stoneTypes.find(stone => stone.value === sku);
            }
            return result ? result.color : null;
        },
        getStone: state => sku => {
            return state.stoneTypes.find(stone => stone.value === sku);
        },
        getMetal: state => sku => {
            return state.metalTypes.find(stone => stone.value === sku);
        },
        // token: state => {
        //   return state.token
        // },
        // isAdmin: state => {
        //   return state.isAdmin
        // },
        // userInfo: state => {
        //   return {
        //     name: state.firstName,
        //     lastName: state.lastName,
        //     avatar: state.userAvatar,
        //     email: state.email,
        //     id: state.id
        //   }
        // },
        // menuState: state => {
        //   return state.adminMenuStatus
        // },
        // countNewMessages: state => {
        //   return state.countNewMessages
        // },
        getProjectTypes (state) {
            return state.projectTypes;
        },
        getProjectTypesFullInfo (state) {
            return state.projectTypesFullInfo;
        },
        getIsInProjectView (state) {
            return state.isInProjectView;
        }
    },
    mutations: {
        setMetalsTypes (state, payload) {
            state.metalTypes = payload;
        },
        setStonesTypes (state, payload) {
            state.stonesTypes = payload;
        },
        setProjectTypes (state, payload) {
            state.projectTypes = payload;
        },
        setProjectTypesFullInfo (state, payload) {
            state.projectTypesFullInfo = payload;
        },
        setIsInProjectView (state, payload) {
            state.isInProjectView = payload;
        },
        SET_HELP_CONFIGURATOR (state, payload) {
            state.help.configurator = payload;
        }
    },
    actions: {
        setHelpConfigurator ({ state, commit }, payload) {
            commit("SET_HELP_CONFIGURATOR", payload)
            localStorage.setItem("help", JSON.stringify(state.help));
        },
        // setUserInfo({ commit }, payload) {
        //   if (payload.first_name)
        //     localStorage.setItem("firstName", payload.first_name);
        //   if (payload.last_name)
        //     localStorage.setItem("lastName", payload.last_name);
        //   if (payload.image)
        //     localStorage.setItem("userAvatar", payload.image);
        //   if (payload.email)
        //     localStorage.setItem("email", payload.email);
        //   if (payload.id)
        //     localStorage.setItem("id", payload.id);
        //   commit("SET_USER_INFO", payload)
        // },
        // setUserAvatar({ commit }, payload) {
        //   commit("SET_AVATAR", payload)
        // }
        async loadMetals ({ commit }) {
            const metalsTypes = await loadDataHelper("/metals", "metals");
            commit("setMetalsTypes", metalsTypes);
        },
        async loadStones ({ commit }) {
            const stoneTypes = await loadDataHelper("/stones", "stones");
            commit("setStonesTypes", stoneTypes);
        }
    }
});
