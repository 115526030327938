<template>
    <v-dialog :value="true" width="800" scrollable @input="$emit('close', false)">
        <v-card class="pt-7 pb-10 ">
            <button class="close-modal-btn" @click="$emit('close', false)">
                <img src="@/assets/icons/ic-close-modal.svg" alt="close modal">
            </button>
            <v-card-text class="pa-0">
                <div class="px-8 d-flex align-center justify-center">
                    <v-btn ref="btnPrev" icon outlined width="32"
                           height="32">
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <div ref="carousel" class="swiper flex overflow-hidden rounded-lg mb-7 mx-7">
                        <div class="swiper-wrapper ">
                            <div class="swiper-slide " v-for="(image, idx) in images"
                                 :key="idx">
                                <v-img :src="image.url" :aspect-ratio="4/3.58" contain :style="[
                                           activeSlideIndex === idx ? currentZoomStyle : {}
                                       ]"
                                       :class="{'cursor-grab': zoomState.scale > 1 && !zoomState.panning, 'cursor-grabbing': zoomState.panning}">
                                    <template v-slot:placeholder>
                                        <v-row
                                            class="fill-height ma-0"
                                            align="center"
                                            justify="center">
                                            <v-progress-circular
                                                indeterminate
                                                color="grey lighten-1"></v-progress-circular>
                                        </v-row>
                                    </template>
                                </v-img>
                            </div>
                        </div>
                    </div>
                    <v-btn ref="btnNext" icon outlined width="32"
                           height="32">
                        <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                </div>

                <div class="assets-viewer-wrapper">
                    <div ref="carousel2" class="swiper overflow-hidden ">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide swiper-slide-thumb overflow-hidden rounded"
                                 v-for="(image, idx) in images" :key="idx">
                                <v-img :src="image.url" :aspect-ratio="4/3.58">
                                    <template v-slot:placeholder>
                                        <v-row
                                            class="fill-height ma-0"
                                            align="center"
                                            justify="center">
                                            <v-progress-circular
                                                indeterminate
                                                size="24"
                                                width="2"
                                                color="grey lighten-1"></v-progress-circular>
                                        </v-row>
                                    </template>
                                </v-img>
                            </div>
                        </div>
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import Swiper, { Keyboard, Thumbs } from "swiper";
    import "swiper/swiper-bundle.min.css"

    Swiper.use([Keyboard, Thumbs])
    export default {
        name: "project-assets-viewer",
        props: {
            initialSlide: {
                type: Number,
                default: 0
            },
            images: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                carousel: null,
                thumbs: null,
                scaleBy: 1.2,
                zoomState: {
                    pointX: 0,
                    pointY: 0,
                    scale: 1,
                    start: { x: 0, y: 0 },
                    panning: false
                }
            }
        },
        computed: {
            currentZoomStyle() {
                return {
                    transform: `translate(${this.zoomState.pointX}px, ${this.zoomState.pointY}px) scale(${this.zoomState.scale})`
                }
            },
            activeSlideIndex() {
                return this.carousel?.realIndex || this.initialSlide;
            },
            currentSlideElement() {
                return this.carousel ? this.carousel.slides[this.carousel.realIndex] : null;
            }
        },
        methods: {
            createCarousel() {
                this.thumbs = new Swiper(this.$refs.carousel2, {
                    spaceBetween: 10,
                    slidesPerView: 6,
                    watchSlidesProgress: true
                });
                this.carousel = new Swiper(this.$refs.carousel, {
                    initialSlide: this.initialSlide,
                    allowTouchMove: true,
                    preloadImages: false,
                    lazy: true,
                    navigation: {
                        nextEl: this.$refs.btnNext.$el,
                        prevEl: this.$refs.btnPrev.$el
                    },
                    thumbs: {
                        swiper: this.thumbs
                    },
                    keyboard: {
                        enabled: true
                    }
                });

                this.carousel.on("slideChange", () => {
                    this.zoomState = {
                        pointX: 0,
                        pointY: 0,
                        scale: 1,
                        start: { x: 0, y: 0 },
                        panning: false
                    }
                })


                this.$refs.carousel.addEventListener("wheel", this.onMouseWheel)

                this.$refs.carousel.addEventListener("dblclick", this.onDblClick)

                this.$refs.carousel.addEventListener("mousedown", this.onMouseDown)

                document.addEventListener("mouseup", this.onMouseUp)

                this.$refs.carousel.addEventListener("mouseleave", this.onMouseLeave)

                this.$refs.carousel.addEventListener("mousemove", this.onMouseMove)
            },

            getRelativeCursorPosition(clientX, clientY) {
                if (!this.currentSlideElement) return
                const rect = this.currentSlideElement.getBoundingClientRect();
                const x = clientX - rect.left; //x position within the element.
                const y = clientY - rect.top;  //y position within the element
                return {
                    x, y
                }
            },
            onMouseWheel(evt) {
                evt.preventDefault()
                const { x, y } = this.getRelativeCursorPosition(evt.clientX, evt.clientY)
                const xs = (x - this.zoomState.pointX) / this.zoomState.scale;
                const ys = (y - this.zoomState.pointY) / this.zoomState.scale;
                // how to scale? Zoom in? Or zoom out?
                let direction = evt.deltaY > 0 ? -1 : 1;

                this.zoomState.scale = Math.max(1, Math.min(direction > 0 ? this.zoomState.scale * this.scaleBy : this.zoomState.scale / this.scaleBy, 10));
                this.zoomState.pointX = x - xs * this.zoomState.scale;
                this.zoomState.pointY = y - ys * this.zoomState.scale;
                this.handlerZoom()
            },

            onDblClick(evt) {
                evt.preventDefault()
                const { x, y } = this.getRelativeCursorPosition(evt.clientX, evt.clientY)

                const xs = (x - this.zoomState.pointX) / this.zoomState.scale;
                const ys = (y - this.zoomState.pointY) / this.zoomState.scale;

                this.zoomState.scale = this.zoomState.scale >= 10 ? 1 : 10;

                this.zoomState.pointX = x - xs * this.zoomState.scale;
                this.zoomState.pointY = y - ys * this.zoomState.scale;
                this.handlerZoom()
            },

            handlerZoom() {
                if (this.zoomState.scale <= 1) {
                    this.zoomState = {
                        pointX: 0,
                        pointY: 0,
                        scale: 1,
                        start: { x: 0, y: 0 },
                        panning: false
                    }
                    this.carousel.allowTouchMove = true
                } else {
                    this.carousel.allowTouchMove = false
                }
            },

            onMouseDown(evt) {
                if (this.zoomState.scale <= 1) return
                evt.preventDefault()
                const { x, y } = this.getRelativeCursorPosition(evt.clientX, evt.clientY)
                this.zoomState.start = { x: x - this.zoomState.pointX, y: y - this.zoomState.pointY };
                this.zoomState.panning = true;
            },

            onMouseUp() {
                if (!this.currentSlideElement) return
                this.zoomState.panning = false;

                // Handler bounds

                // const rect = this.currentSlideElement.getBoundingClientRect();
                //
                // if (this.zoomState.pointX / this.zoomState.scale > 0) {
                //     this.zoomState.pointX = 0
                // } else if (this.zoomState.pointX  <= rect.width - (rect.width * this.zoomState.scale)) {
                //     this.zoomState.pointX = rect.width - (rect.width * this.zoomState.scale)
                // }
                //
                // if (this.zoomState.pointY / this.zoomState.scale > 0) {
                //     this.zoomState.pointY = 0;  //
                // } else if (this.zoomState.pointY  < rect.height - (rect.height * this.zoomState.scale)) {
                //     this.zoomState.pointY = rect.height - (rect.height * this.zoomState.scale)
                // }
            },
            onMouseLeave() {
                this.zoomState.panning = false;
            },
            onMouseMove(evt) {
                evt.preventDefault();
                if (!this.zoomState.panning) {
                    return;
                }
                const { x, y } = this.getRelativeCursorPosition(evt.clientX, evt.clientY)

                this.zoomState.pointY = y - this.zoomState.start.y;  //y position within the element
                this.zoomState.pointX = x - this.zoomState.start.x;
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.createCarousel()
            })
        },
        beforeDestroy() {
            this.$refs.carousel.removeEventListener("wheel", this.onMouseWheel)

            this.$refs.carousel.removeEventListener("dblclick", this.onDblClick)

            this.$refs.carousel.removeEventListener("mousedown", this.onMouseDown)

            document.removeEventListener("mouseup", this.onMouseUp)

            this.$refs.carousel.removeEventListener("mouseleave", this.onMouseLeave)

            this.$refs.carousel.removeEventListener("mousemove", this.onMouseMove)
        }
    }
</script>

<style scoped lang="scss">
.v-image {
    transform-origin: 0 0;
}
.swiper{
    box-sizing: border-box;
}

.assets-viewer-wrapper {
    padding: 0 92px 0;
}

.swiper-slide-thumb {
    opacity: 0.6;
    cursor: pointer;
    border: 2px solid transparent;
    box-sizing: border-box;

    &:hover, &-active {
        border-color: #9FF4A9;
    }

    &-active {
        opacity: 1;
    }
}

.close-modal-btn {
    width: 36px;
    height: 36px;
    position: absolute;
    top: -18px;
    right: -18px;
}
</style>
