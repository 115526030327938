<template>
    <editor
        v-bind="$attrs"
        v-model="initialValue"
        initial-value=""
        :api-key="tinyKey"
        :init="editorInit(id)"
        class="tiny-editor">
    </editor>
</template>

<script>
    import Editor from "@tinymce/tinymce-vue"
    import createConfig from "@/utils/tinymceConfig";
    import UploadService from "@/api/uploadFilesService.api";
    export default {
        components: {
            Editor
        },
        props: {
            id: {
                type: [String, Number],
                default: ""
            },
            value: {
                type: String,
                require: false,
                default: ""
            }
        },
        computed: {
            initialValue: {
                get () {
                    return this.value
                },
                set (val) {
                    this.$emit("input", val)
                }
            },
            tinyKey() {
                return process.env.VUE_APP_TINY_KEY
            }
        },
        methods: {
            blurEvent() {
                this.$emit("blur")
            },
            editorInit(id) {
                return createConfig(this.uploadImage, id, this.blurEvent);
            },
            async uploadImage(blobInfo, success, failure) {
                const type = `image/${blobInfo.filename().split(".")[1]}` || "image/png";
                const filename =
                    blobInfo.filename() ||
                    [
                        "blog",
                        "post",
                        "image",
                        "-",
                        Math.floor(Math.random() * 1e12),
                        "-",
                        new Date().getTime(),
                        ".",
                        type.match(/^image\/(\w+)$/i)[1]
                    ].join("");
                const file = new File([blobInfo.blob()], filename.split(" ").join(""), {
                    type
                });
                try {
                    file.typeImage = this.$config.fileTypes.attachment
                    const res = await UploadService.upload(file)
                    success(res.url);
                } catch (err) {
                    console.log(err)
                    failure(err);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
