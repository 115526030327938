<template>
    <v-dialog
        v-model="uploadModalOpened"
        max-width="652"
        @keydown.esc="closeUpload"
        @click:outside="closeUpload">
        <div class="content-wrapper">
            <button class="close-modal-btn" @click="closeUpload">
                <img src="@/assets/icons/ic-close-modal.svg">
            </button>
            <div class="content-container">
                <div class="title">Choose a file to Upload</div>
                <div class="upload-area" :class="{'contains-image': uploadedFilePreview}">
                    <div class="placeholder" v-if="!uploadedFilePreview">
                        <img src="@/assets/_redesigne/icons/ic-plus-in-circle.svg">
                        <div class="text">Upload File</div>

                        <div class="placeholder" v-if="placeholder">{{ placeholder }}</div>
                    </div>
                    <input
                        v-if="!uploadedFilePreview"
                        type="file"
                        :multiple="multipleFilesUpload"
                        :accept="accept"
                        @change="selectFile">
                    <v-img
                        v-if="uploadedFilePreview"
                        class="preview"
                        contain
                        lazy-src="@/assets/_redesigne/icons/ic-lazy-img.svg"
                        :src="uploadedFilePreview"/>
                </div>
                <div class="controls">
                    <button class="close-btn" @click="closeUpload">Close</button>
                </div>
            </div>
        </div>
        <dialog-default type="alertInvalidFileType"/>
    </v-dialog>
</template>

<script>
    import UploadFilesServiceApi from "@/api/uploadFilesService.api"
    import DialogDefault from "@/components/ui/dialog-default"
    import { bytesToMb } from "@/utils/index.utils";
    import config from "@/utils/config";

    export default {
        props: {
            uploadModalOpened: {
                type: Boolean,
                default: false
            },
            mode: {
                type: String,
                validator: value=>{
                    return ["add", "swap", "add_new_version", "add_comment"].includes(value)
                }
            },
            accept: {
                type: String,
                default: ".jpg, .jpeg, .png"
            },
            maxFileSize: {
                type: Object,
                default: () => ({
                    size: config.fileSizeLimit,
                    label: "500MB"
                })
            },
            typeImage: {
                type: [String, Number],
                default: "any"
            },
            multipleFilesUpload: {
                type: Boolean,
                default: true
            },
            withoutUpload: {
                type: Boolean,
                default: false
            },
            placeholder: {
                type: String,
                default: null
            },
            roundBaseFileId: {
                type: Number,
                default: null
            }
        },
        data: () => ({
            uploadedFilePreview: null
        }),
        components: {
            DialogDefault
        },
        methods: {
            openUploadModal() {
                this.$emit("open")
            },
            closeUpload() {
                this.uploadedFilePreview = null
                this.$emit("close")
            },
            async selectFile(e) {
                let files = Array.from(e.target.files)

                files = files.filter(file => {
                    const fileExtension = "." + file.name.split(".").slice(-1).join("").toLowerCase();
                    if (!this.accept.includes(fileExtension.toLowerCase()) || bytesToMb(file.size) > this.maxFileSize.size) {

                        const type = "alertInvalidFileType";

                        this.$store.state.dialogs.content[type].text = `
                            Sorry, file ${file.name} is not allowed by size or format.
                            File size should not exceed ${this.$props.maxFileSize.label}
                            Allowed file formats are "${this.accept}".
                        `;

                        this.$store.commit(this.$store.state.dialogs.content[type].set, true);
                        e.target.value = null
                    } else {
                        return file;
                    }
                });

                const formattedFiles = files.map(file => {
                    const fileExtension = "." + file.name.split(".").slice(-1).join("");
                    this.uploadedFilePreview = URL.createObjectURL(new Blob([file]));

                    if (this.typeImage === "any") {
                        if(this.$config.allowedFileExtension.models.includes(fileExtension.toLowerCase())) {
                            file.typeImage = bytesToMb(file.size) > this.$config.fileSizeLimit3D ? this.$config.fileTypes.others :this.$config.fileTypes.models
                        } else if(this.$config.allowedFileExtension.images.includes(fileExtension.toLowerCase())) {
                            file.typeImage = this.$config.fileTypes.reference
                        } else if(
                            this.$config.allowedFileExtension.others.includes(fileExtension.toLowerCase()) ||
                            this.$config.allowedFileExtension.others_for_designer.includes(fileExtension.toLowerCase())
                        ) {
                            file.typeImage = this.$config.fileTypes.others
                        } else {
                            file.typeImage = this.typeImage
                        }
                    } else {
                        file.typeImage = this.typeImage;
                    }

                    if (this.mode === "add_new_version") {
                        file.round_base_file_id = this.roundBaseFileId;
                    }
                    return file
                })

                if(formattedFiles.length) {
                    if(!this.withoutUpload) {
                        formattedFiles.map(async (file) => {
                            const response = await UploadFilesServiceApi.upload(file);
                            if (this.mode === "add_new_version") {
                                this.$emit("upload", { ...response, round_base_file_id: this.roundBaseFileId });
                            } else {
                                this.$emit("upload", response);
                            }
                            this.uploadedFilePreview = null
                            return response
                        })
                    } else {
                        this.$emit("select", formattedFiles)
                        this.uploadedFilePreview = null
                    }
                }
            }
        }
    };
</script>

<style scoped lang="scss" src="./style.scss"></style>
