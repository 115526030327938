<template>
    <!--  :fullscreen="full"-->
    <v-dialog
        v-model="dialog"
        :max-width="full ? 'none' : 651"
        class="modal">
        <v-card
            :class="{'modal--error': conf.type === 'error'}"
            class="pa-1 pb-4 pa-sm-11 pb-sm-7  pa-sm-7 d-flex flex-column">
            <v-btn
                class="modal__close"
                icon
                @click="close('close')">
                <v-img
                    :src="require('@/assets/icons/ic-close-modal.svg')"
                    contain
                    height="36"
                    max-width="36"/>
            </v-btn>

            <v-card-title
                v-if="conf.title"
                class="pl-0 pr-0 modal__title">
                {{ conf.title }}
            </v-card-title>

            <v-card-text
                v-if="conf.text"
                class="pl-0 pr-0 modal__text mt-4">
                {{ conf.text }}
            </v-card-text>

            <v-card-actions
                v-if="conf.ok"
                class="pl-0 pr-0 mt-3">
                <v-btn
                    class="modal__ok ml-auto mr-auto pl-4 pr-4"
                    color="orange white--text"
                    large
                    rounded
                    @click="close('ok')">
                    {{ conf.ok }}
                </v-btn>
            </v-card-actions>

            <v-card-actions
                v-if="conf.no && conf.yes"
                class="pl-0 pr-0 mt-4">
                <v-btn
                    class="modal__no pl-4 pr-4 mr-3"
                    color="orange"
                    large
                    outlined
                    rounded
                    @click="close('no')">
                    {{ conf.no }}
                </v-btn>

                <v-spacer/>

                <v-btn
                    class="modal__yes pl-4 pr-4 ml-3"
                    color="orange white--text"
                    large
                    rounded
                    @click="close('yes')">
                    {{ conf.yes }}
                </v-btn>
            </v-card-actions>

            <v-card-actions
                v-if="conf.other"
                class="modal__other mt-3 pl-4 pr-4">
                <v-btn
                    class="ml-auto"
                    color="link"
                    large
                    rounded
                    text
                    @click="close(emitAction)">
                    {{ conf.other }}
                </v-btn>
            </v-card-actions>

            <slot class="flex-grow-1"/>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "dialog-default",
        data: () => ({
            conf: {
                type: null,
                title: null,
                titleError: null,
                text: null,
                no: null,
                yes: null,
                ok: null,
                other: null
            }
        }),
        props: {
            emitAction: {
                type: String,
                default: "yes"
            },
            type: {
                required: true,
                type: String,
                default: "dialogLeaveProject"
            },
            config: {
                type: Object,
                default: undefined
            },
            full: {
                type: Boolean,
                default: false
            }
        },
        created () {
            if (!this.config) {
                this.conf = this.$store.state.dialogs.content[this.type];
            }
        },
        methods: {
            close (emit) {
                if (emit) {
                    if (emit !== "no") {
                        this.$emit(emit, this.type);
                    }
                    this.dialog = false;
                }
            }
        },
        computed: {
            dialog: {
                get: function () {
                    return this.$store.getters[this.type]; //dialogLeaveProject
                },
                set: function (nv) {
                    this.$store.commit(this.$store.state.dialogs.content[this.type].set, nv); //dialogLeaveProject
                }
            }
        }
    };
</script>

<style lang="scss" scoped>

</style>
