function isLineObject (obj) {
    if (typeof obj === "object") {
        return obj.stroke && obj.strokeWidth && obj.points;
    }
    return false;
}

function isLineArray (array) {
    if (!Array.isArray(array)) {
        return;
    }

    for (let i = 0; i < array.length; ++i) {
        if (!isLineObject(array[0])) {
            return false;
        }
    }
    return true;
}

function lineToSaveObj (lastLine) {
    return {
        // globalCompositeOperation:"source-over",
        // points:Array[2]
        stroke: lastLine.attrs.stroke,
        strokeWidth: lastLine.attrs.strokeWidth,
        points: JSON.parse(JSON.stringify(lastLine.points()))
    };
}

const defaultColor = "#4BFFF4";
export default () => ({
    namespaced: true,
    state: () => ({
        status: false,
        grater: false,
        exit: true,
        comment: false,
        color: "#4BFFF4",
        freezeColor: false,
        colorList: ["#4BFFF4", "#4B93FF", "#854BFF", "#FF4BED", "#FF4B4B", "#FFC24B", "#E6FF4B", "#65FF4B"],
        brush: 15,
        hideBrush: false,
        hideZoom: false,
        progress: 0, //undo redo
        lines: [] //lines that we will draw
    }),
    getters: {
        isBrushHidden: state => state.hideBrush,
        isZoomHidden: state => state.hideZoom,
        isCommentAble: state => state.comment,
        isDraw: state => state.status,
        isColorFreeze: state => state.freezeColor,
        //getMarkerID: state => state.markerID,
        isExit: state => state.exit,
        isGrater: state => state.status && state.grater,
        getColor: state => state.color,
        getBrush: state => state.brush,
        getColorList: state => state.colorList,
        getLines: state => state.lines.slice(0, state.progress),
        getLastLine: state => {
            const lines = state.lines.slice(0, state.progress);
            return (lines.length > 0) ? lines[lines.length - 1] : [];
        },
        getLineExport: state => state.lines.slice(0, state.progress).map(line => lineToSaveObj(line))
    },
    mutations: {
        SET_HIDE_BRUSH (state, payload) {
            state.hideBrush = payload;
        },
        SET_HIDE_ZOOM (state, payload) {
            state.hideZoom = payload;
        },
        SET_COMMENT_STATUS (state, payload) {
            console.log("here triggers event", payload);
            state.comment = payload;
        },
        SET_STATUS_DRAW (state, payload) {
            if (payload) {
                state.status = payload;
            } else {
                state.status = false;
            }
        },
        SET_STATUS_GRATER (state, payload) {
            state.grater = payload;
        },
        SET_COLOR (state, payload) {
            state.color = payload;
        },
        SET_COLOR_FREEZE (state, payload) {
            state.freezeColor = payload;
        },
        SET_BRUSH (state, payload) {
            state.brush = payload;
        },
        SET_COLOR_LIST (state, payload) {
            state.colorList = payload;
        },
        ADD_LINE (state, lineOption) {
            //if (!isLineObject(lineOption))
            //     return
            const lines = state.lines.slice(0, state.progress);
            
            lines.push(lineOption);
            state.lines = lines;
            state.progress = state.lines.length;
        },
        UPDATE_LINE (state, line) {
            //if (!isLineObject(lineOption))
            //     return
            const index = state.lines.findIndex(item => item.attr === line.attr);
            if (index > 0) {
                state.lines = [
                    ...state.lines.slice(0, index),
                    state.lines[index],
                    ...state.lines.slice(index + 1)
                ];
            }
        },
        SET_LINES (state, array) {
            //if (!isLineArray(array))
            //   return
            state.lines = [...array];
            state.progress = state.lines.length;
        },
        REMOVE_LINE_BY_INDEX (state, index) {
            const lines = state.lines;
            state.lines = [
                ...lines.slice(0, index),
                ...lines.slice(index + 1)
            ];
            state.progress = state.progress > state.lines.length
                ? state.lines.length
                : state.progress;
        },
        REMOVE_LINE_BY_OBJECT (state, lineObject) {
            
            const index = state.lines.findIndex(
                line => line.attrs.id === lineObject.attrs.id && line.colorKey === lineObject.colorKey
            );
            state.lines = [
                ...state.lines.slice(0, index),
                ...state.lines.slice(index + 1)
            ];
            state.progress = state.progress > state.lines.length
                ? state.lines.length
                : state.progress;
        },
        SET_UNDO (state, payload) {
            if (payload >= 0) {
                state.progress = payload;
            }
        },
        SET_REDO (state, payload) {
            if (payload <= state.lines.length) {
                state.progress = payload;
            }
        }
    },
    actions: {
        toggleCommentAble ({ state, commit }) {
            commit("SET_COMMENT_STATUS", !state.comment);
        },
        unFreeze ({ commit }) {
            commit("SET_COLOR", defaultColor);
            commit("SET_COLOR_FREEZE", false);
        },
        setVisibilityOfLines ({ state }) {
            
            let lines = state.lines;
            for (let i = state.progress; i < lines.length; ++i) {
                lines[i].hide();
            }
            for (let i = 0; i < state.progress; ++i) {
                lines[i].show();
            }
        },
        forceUpdateLines ({ state }) {
            state.lines = state.lines.slice(0, state.progress);
            state.progress = state.lines.length;
        },
        clearLines ({ state }) {
            let lines = state.lines;
            for (let i = 0; i < lines.length; ++i) {
                lines[i].destroy();
            }
            state.lines = [];
            state.progress = 0;
        },
        undoDrawing ({ commit, state }) {
            
            commit("SET_UNDO", state.progress - 1);
        },
        redoDrawing ({ commit, state }) {
            
            commit("SET_REDO", state.progress + 1);
        },
        saveDrawing ({ commit, state }) {
            state.exit = false;
            commit("SET_STATUS_GRATER", false);
            commit("SET_STATUS_DRAW", false);
        },
        exitDrawing ({ commit, dispatch, state }) {
            dispatch("clearLines");
            state.exit = true;
            commit("SET_STATUS_GRATER", false);
            commit("SET_STATUS_DRAW", false);
        },
        largeView (context, payload) {
            console.log("largeView");
            context.commit("view/SET_STATUS_LARGE_VIEW", payload, { root: true });
            console.log("largeView context", context);
        }
    }
})
