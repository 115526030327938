<template>
    <contact-us class="support-page"/>
</template>

<script>
    import ContactUs from "@/views/Home/ContactUs";
    export default {
        name: "support",
        components: {
            ContactUs
        }
    };
</script>

<style scoped lang="scss" src="./style.scss"></style>
