<template>
    <div>
        <div class="profile-header">
            <v-row align="center">
                <v-col>
                    <h3 class="profile-ttl">
                        Personal information edit
                    </h3>
                </v-col>
                <v-col
                    cols="12"
                    sm="auto">
                    <v-btn
                        :class="{'disabled-btn': fileLoading}"
                        class="btn-h-48 fs-16 let-sp-05 pl-4 pr-4 pl-sm-6 pr-sm-6"
                        color="#fb3805"
                        dark
                        elevation="0"
                        large
                        rounded
                        @click="saveForm">
                        Save Information
                    </v-btn>
                </v-col>
            </v-row>
        </div>
        <validation-observer
            ref="observer"
            tag="form"
            @keyup.enter="saveForm">
            <div class="personal-head-row-wrap mt-8">
                <v-row>
                    <v-col
                        cols="12"
                        md="5">
                        <v-avatar
                            v-if="form.avatar"
                            size="112">
                            <img
                                :alt="form.firstName"
                                :src="form.avatar">
                        </v-avatar>
                        <div
                            v-else
                            class="phr-img">
                            <svg
                                fill="none"
                                height="112"
                                viewBox="0 0 112 112"
                                width="112">
                                <circle
                                    cx="56"
                                    cy="56"
                                    fill="#F4F7F6"
                                    r="56"/>
                                <path
                                    clip-rule="evenodd"
                                    d="M42.3887 66.8893H69.6109C77.1281 66.8893 83.222 72.9832 83.222 80.5004C83.222 82.0039 82.0033 83.2227 80.4998 83.2227C79.1038 83.2227 77.9532 82.1718 77.7959 80.8179L77.7637 80.0206C77.5245 75.8924 74.219 72.5868 70.0908 72.3476L69.6109 72.3338H42.3887C37.8784 72.3338 34.222 75.9901 34.222 80.5004C34.222 82.0039 33.0033 83.2227 31.4998 83.2227C29.9964 83.2227 28.7776 82.0039 28.7776 80.5004C28.7776 73.181 34.555 67.211 41.7983 66.9019L42.3887 66.8893H69.6109H42.3887ZM56 28.7812C65.0207 28.7812 72.3333 36.0939 72.3333 45.1146C72.3333 54.1352 65.0207 61.4479 56 61.4479C46.9793 61.4479 39.6667 54.1352 39.6667 45.1146C39.6667 36.0939 46.9793 28.7812 56 28.7812ZM55.9998 34.2227C49.986 34.2227 45.1109 39.0978 45.1109 45.1115C45.1109 51.1253 49.986 56.0004 55.9998 56.0004C62.0136 56.0004 66.8887 51.1253 66.8887 45.1115C66.8887 39.0978 62.0136 34.2227 55.9998 34.2227Z"
                                    fill="#FB3805"
                                    fill-rule="evenodd"/>
                            </svg>
                        </div>
                        <validation-provider
                            ref="avatarProvider"
                            v-slot="{ errors, invalid }"
                            name="File"
                            rules="size:2000"
                            tag="div">
                            <div
                                v-if="invalid"
                                class="avatar-error-msg field-error-txt fs-12 mt-2">
                                {{ errors[0] }}
                            </div>
                            <v-btn
                                class="mt-4 let-sp-05 pl-4 pr-4 pl-sm-5 pr-sm-5 avatar-upload-lbl"
                                color="#fb3805"
                                dark
                                elevation="0"
                                outlined
                                rounded
                                small
                                tag="label">
                                <input
                                    :disabled="fileLoading"
                                    accept="image/*"
                                    class="d-none"
                                    type="file"
                                    @change="uploadAvatar">
                                Upload New Picture
                            </v-btn>
                        </validation-provider>
                    </v-col>
                    <v-col
                        cols="12"
                        md="7">
                        <div class="phr-cont-wrap">
                            <v-row>
                                <v-col
                                    class="pb-1"
                                    cols="12">
                                    <validation-provider
                                        v-slot="{ errors }"
                                        mode="lazy"
                                        name="First Name"
                                        rules="required|max:50"
                                        tag="div">
                                        <div class="profile-input-label mb-3">
                                            First Name
                                        </div>
                                        <v-text-field
                                            v-model="form.firstName"
                                            :error-messages="errors"
                                            class="profile-input"
                                            hide-details="auto"
                                            outlined
                                            placeholder="First Name"
                                            type="text"/>
                                    </validation-provider>
                                </v-col>
                                <v-col
                                    class="pt-0 pb-1"
                                    cols="12">
                                    <validation-provider
                                        v-slot="{ errors }"
                                        mode="lazy"
                                        name="Last Name"
                                        rules="required|max:50"
                                        tag="div">
                                        <div class="profile-input-label mb-3">
                                            Last Name
                                        </div>
                                        <v-text-field
                                            v-model="form.lastName"
                                            :error-messages="errors"
                                            class="profile-input"
                                            hide-details="auto"
                                            outlined
                                            placeholder="Last Name"
                                            type="text"/>
                                    </validation-provider>
                                </v-col>
                                <v-col
                                    class="pt-0 pb-1"
                                    cols="12">
                                    <validation-provider
                                        v-slot="{ errors }"
                                        mode="lazy"
                                        name="Email"
                                        rules="required|email"
                                        tag="div">
                                        <div class="profile-input-label mb-3">
                                            Email
                                        </div>
                                        <v-text-field
                                            v-model="form.email"
                                            :disabled="true"
                                            :error-messages="errors"
                                            class="profile-input"
                                            hide-details="auto"
                                            outlined
                                            placeholder="Email"
                                            type="email"/>
                                    </validation-provider>
                                </v-col>
                                <v-col
                                    class="pt-0"
                                    cols="12">
                                    <validation-provider
                                        v-slot="{ errors }"
                                        mode="lazy"
                                        name="Phone"
                                        rules="max:50"
                                        tag="div">
                                        <div class="profile-input-label mb-3">
                                            Phone
                                        </div>
                                        <v-text-field
                                            v-model="form.phone"
                                            v-mask="'#-###-###-####'"
                                            :error-messages="errors"
                                            class="profile-input"
                                            hide-details="auto"
                                            outlined
                                            placeholder="Phone"
                                            type="text"/>
                                    </validation-provider>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                </v-row>
            </div>
            <template>
                <div class="section-delimiter mt-6 mb-6 mt-md-10 mb-md-10"/>
                <div class="personal-foot-row-wrap">
                    <v-row>
                        <v-col
                            v-if="form.hasPassword"
                            class="pb-1"
                            cols="12">
                            <validation-provider
                                v-slot="{ errors }"
                                :rules="{required: passwordRequired, max:50, min:8}"
                                mode="lazy"
                                name="Current Password"
                                tag="div">
                                <div class="profile-input-label mb-3">
                                    Current Password
                                </div>
                                <v-text-field
                                    v-model="currentPassword"
                                    :error-messages="errors"
                                    class="profile-input"
                                    hide-details="auto"
                                    outlined
                                    placeholder="Current Password"
                                    type="password"/>
                            </validation-provider>
                        </v-col>
                        <v-col
                            class="pt-0 pb-1"
                            cols="12">
                            <validation-provider
                                v-slot="{ errors }"
                                :rules="{required: passwordRequired, max:50, min:8}"
                                mode="lazy"
                                name="New Password"
                                tag="div"
                                vid="confirm">
                                <div class="mb-3">
                                    <v-row align="center">
                                        <v-col>
                                            <div class="profile-input-label">
                                                New Password
                                            </div>
                                        </v-col>
                                        <v-col cols="auto">
                                            <v-menu
                                                bottom
                                                content-class="helper-menu"
                                                left
                                                nudge-left="15"
                                                nudge-top="15"
                                                offset-y
                                                z-index="1">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <div
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        class="helper-btn-ic">
                                                        <svg
                                                            fill="none"
                                                            height="17"
                                                            viewBox="0 0 17 17"
                                                            width="17">
                                                            <circle
                                                                cx="8.5"
                                                                cy="8.5"
                                                                fill="#323232"
                                                                r="8.5"/>
                                                            <path
                                                                d="M7.58967 13H9.40501V6.45455H7.58967V13ZM8.5016 5.6108C9.04279 5.6108 9.48597 5.19744 9.48597 4.69034C9.48597 4.1875 9.04279 3.77415 8.5016 3.77415C7.96467 3.77415 7.52148 4.1875 7.52148 4.69034C7.52148 5.19744 7.96467 5.6108 8.5016 5.6108Z"
                                                                fill="white"/>
                                                        </svg>
                                                    </div>
                                                </template>
                                                <div class="helper-cont pa-6">
                                                    <div class="helper-head">
                                                        <div class="helper-head-ic mr-5">
                                                            <svg
                                                                fill="none"
                                                                height="21"
                                                                viewBox="0 0 17 21"
                                                                width="17"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    clip-rule="evenodd"
                                                                    d="M8.49998 0.917969C11.0767 0.917969 13.1784 2.95185 13.2872 5.50178L13.2916 5.70964V8.58464C14.8795 8.58464 16.1666 9.87182 16.1666 11.4596V17.2096C16.1666 18.7975 14.8795 20.0846 13.2916 20.0846H3.70831C2.12049 20.0846 0.833313 18.7975 0.833313 17.2096V11.4596C0.833313 9.87182 2.12049 8.58464 3.70831 8.58464V5.70964C3.70831 3.06327 5.85361 0.917969 8.49998 0.917969ZM13.2916 10.5013H3.70831C3.17904 10.5013 2.74998 10.9304 2.74998 11.4596V17.2096C2.74998 17.7389 3.17904 18.168 3.70831 18.168H13.2916C13.8209 18.168 14.25 17.7389 14.25 17.2096V11.4596C14.25 10.9304 13.8209 10.5013 13.2916 10.5013ZM8.66891 2.83952L8.49998 2.83464C6.96887 2.83464 5.7173 4.03152 5.62986 5.54071L5.62498 5.70964V8.58463H11.375V5.70964C11.375 4.17852 10.1781 2.92696 8.66891 2.83952L8.49998 2.83464L8.66891 2.83952Z"
                                                                    fill="#0046AF"
                                                                    fill-rule="evenodd"/>
                                                            </svg>
                                                        </div>
                                                        <div class="helper-head-ttl">
                                                            Password Strength
                                                        </div>
                                                    </div>
                                                    <div class="helper-txt pl-9 mt-3">
                                                        Your password needs to be at least 8 characters long. The strongest
                                                        passwords are made up of several words (eg. GreySaturnThirtyHome)
                                                    </div>
                                                </div>
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                </div>
                                <v-text-field
                                    v-model="newPassword"
                                    :error-messages="errors"
                                    class="profile-input"
                                    hide-details="auto"
                                    outlined
                                    placeholder="New Password"
                                    type="password"/>
                            </validation-provider>
                        </v-col>
                        <v-col
                            class="pt-0"
                            cols="12">
                            <validation-provider
                                v-slot="{ errors }"
                                :rules="{required: passwordRequired, max:50, min:8, confirmed: 'confirm'}"
                                mode="lazy"
                                name="Confirm New Password"
                                tag="div">
                                <div class="profile-input-label mb-3">
                                    Confirm New Password
                                </div>
                                <v-text-field
                                    v-model="confirmNewPassword"
                                    :error-messages="errors"
                                    class="profile-input"
                                    hide-details="auto"
                                    outlined
                                    placeholder="Confirm New Password"
                                    type="password"/>
                            </validation-provider>
                        </v-col>
                    </v-row>
                </div>
            </template>
        </validation-observer>
        <v-snackbar
            v-model="errorSnackbar"
            :timeout="5000"
            bottom
            class="error-snackbar">
            Error: {{ serverError }}
        </v-snackbar>
    </div>
</template>

<script>
    import http from "@/api/httpClient.api";
    import { VueMaskDirective } from "v-mask";

    import { extend, localize, ValidationObserver, ValidationProvider } from "vee-validate";
    import { confirmed, email, max, min, required, size } from "vee-validate/dist/rules";
    import en from "vee-validate/dist/locale/en.json";

    extend("required", required);
    extend("email", email);
    extend("min", min);
    extend("max", max);
    extend("size", size);
    extend("confirmed", confirmed);

    localize({
        en
    });

    export default {
        name: "personal-information-edit",
        components: {
            ValidationObserver,
            ValidationProvider
        },
        directives: {
            mask: VueMaskDirective
        },
        data () {
            return {
                fileLoading: false,
                currentPassword: null,
                newPassword: null,
                confirmNewPassword: null,
                avatarId: null,
                form: {
                    avatar: null,
                    email: null,
                    firstName: null,
                    phone: "",
                    id: 0,
                    lastName: "",
                    role: null,
                    createdAt: 0,
                    updatedAt: 0
                },
                errorSnackbar: false,
                serverError: null
            };
        },
        computed: {
            user () {
                return this.$store.getters.profile;
            },
            passwordRequired () {

                if (!this.form.hasPassword) {
                    return true;
                }

                return this.form.hasPassword && (this.currentPassword || this.newPassword || this.confirmNewPassword) || false;
            }
        },
        beforeMount () {
            this.form = { ...this.$store.getters.profile };
        },
        methods: {
            async uploadAvatar (event) {
                this.serverError = null;
                if (!event.target.files || !event.target.files.length) {
                    return;
                }
                const validateModel = await this.$refs.avatarProvider.validate(event);
                if (!validateModel.valid) {
                    console.log("invalid size");
                    return;
                }

                this.fileLoading = true;
                const file = event.target.files[0];
                const formData = new FormData();
                formData.append("file", file);
                formData.append("type", 20);

                http.post("/files", formData).then(res => {
                    console.log("uploadAvatar", res);
                    if (res.status === 201) {
                        console.log("success");
                        this.avatarId = res.data.data.id;
                        this.form.avatar = res.data.data.url;
                    } else {
                        console.log("file upload error");
                    }
                }).catch(err => {
                    console.log("error", err);
                    this.errorSnackbar = true;
                    this.serverError = err.message;
                }).then(() => {
                    this.fileLoading = false;
                });
            },
            showView () {
                this.$emit("onSwitchType", false);
            },
            saveForm () {
                this.serverError = null;
                this.$refs.observer.validate().then((success) => {
                    if (!success) {
                        return false;
                    }

                    const formData = {
                        "first_name": this.form.firstName,
                        "last_name": this.form.lastName,
                        "phone": this.form.phone
                    };
                    if (this.avatarId) {
                        formData.avatar = this.avatarId;
                    }

                    if (this.passwordRequired) {

                        if (this.form.hasPassword) {
                            formData.old_password = this.currentPassword;
                        }

                        formData.password = this.newPassword;
                        formData.password_confirmation = this.confirmNewPassword;
                    }

                    http.put(`/users/profile/${this.form.id}`, formData).then(res => {
                        console.log("saveForm", res);
                        if (res.status === 200) {
                            console.log("success");
                            this.$store.dispatch("getProfile");
                            this.showView();
                        }
                    }).catch(err => {
                        console.log("error", err);
                        this.errorSnackbar = true;
                        this.serverError = err.message;
                        if (err.response.status === 422) {
                            const errorCode = err.response.data.errors[0].code;
                            const errorField = err.response.data.errors[0].field;
                            this.serverError = `${errorField}: ${errorCode}`;
                            if (errorCode === "validation.different") {
                                this.serverError = `Wrong password`;
                            }
                        }
                    });
                });
            }
        }
    };
</script>

<style lang="scss" scoped>
    .avatar-upload-lbl {
        text-transform: none;
        cursor: pointer;
    }
    .avatar-error-msg {
        margin-bottom: -5px;
    }
</style>
