export default {
    state: () => ({
        menu: true
    }),
    mutations: {
        SET_MENU_STATUS (state, payload) {
            state.menu = payload;
        }
    },
    actions: {
        toggleSidebarMenu (context) {
            const menu = !context.state.menu;
            context.commit("SET_MENU_STATUS", menu);
            localStorage.setItem("sidebarMenu", menu);
        }
    }
};
