<template>
    <v-dialog
        v-bind="$attrs"
        v-on="$listeners"
        width="520"
        content-class="archive-modal pa-8">
        <h4 class="mb-8 text-h5 font-weight-semibold text-center main-text--text">You are about to Archive this project?</h4>
        <div class="d-flex justify-center">
            <v-btn
                width="110"
                height="40"
                outlined
                rounded
                @click="$emit('input', false)"
                class="mr-4">
                <template>Cancel</template>
            </v-btn>
            <v-btn
                width="110"
                height="40"
                outlined
                rounded
                :loading="archiveLoading"
                @click="archiveProject"
                class="ml-4 delete-btn">
                <template>Archive</template>
            </v-btn>
        </div>
    </v-dialog>
</template>

<script>
    import { projectApi } from "@/api";

    export default {
        name: "dialog-confirm-archive-project",
        props: {
            projectId: {
                type: [String, Number],
                default: null
            }
        },
        data() {
            return {
                archiveLoading: false
            }
        },
        methods: {
            async archiveProject() {
                if(!this.projectId) return
                this.archiveLoading = true;
                await projectApi.projectArchive(this.projectId);
                this.$emit("input", false)
                await this.$router.push({ name: "dashboard" });
            }
        }
    }
</script>

<style lang="scss" scoped>
::v-deep .v-dialog.archive-modal {
    border-radius: 0!important;
    background-color: #fff;
    box-shadow: 4px 4px 4px rgba(70, 70, 70, 0.15)!important;
    p {
        font-size: 18px;
        line-height: 23px;
    }
    .v-btn {
        font-size: 14px;
        letter-spacing: 0px;
        &.delete-btn {
            background-color: #9ff4a9;
        }
    }
}
</style>
