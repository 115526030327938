import { addScriptTag } from "@/utils/index.utils";

export const initGoogleAnalytics =  () => {
    addScriptTag("https://www.googletagmanager.com/gtag/js?id=G-9HGFXTGQ9X")
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag("js", new Date());

    gtag("config", "G-9HGFXTGQ9X");
}
