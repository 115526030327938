<template>
    <v-container
        class="pa-6 pt-md-16"
        fluid>
        <div class="def-page-cont">
            <h1 class="mb-6 mb-md-12">Your Account</h1>
            <v-row>
                <v-col
                    cols="12"
                    md="5">
                    <div class="profile-menu-wrap">
                        <profile-menu/>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    md="7">
                    <div class="profile-cont-wrap">
                        <billing-information-data/>
                    </div>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>

<script>
    import ProfileMenu from "@/components/user-profile/ProfileMenu";
    import BillingInformationData from "@/components/user-profile/billing-information/BillingInformationData";

    export default {
        name: "billing-information",
        components: {
            ProfileMenu,
            BillingInformationData
        }
    };
</script>

<style lang="scss" scoped>
    .profile-cont-wrap {
        border: 1px solid #888888;
        box-sizing: border-box;
        border-radius: 12px;
        padding: 50px 60px;
    }
    .profile-menu-wrap {
        position: sticky;
        top: 10px;
        @media screen and (min-width: 960px) {
            padding-right: 15px;
        }
    }
</style>
