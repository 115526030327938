<template>
    <v-dialog
        v-bind="$attrs"
        v-on="$listeners"
        persistent
        width="620"
        class="modal">
        <div class="dialog-overflow-wrap">
            <v-btn
                class="pci-ad-cl-btn"
                icon>
                <v-img
                    :src="require('@/assets/icons/ic-close-modal.svg')"
                    contain
                    height="36"
                    max-width="36"
                    @click="$emit('input', false)"/>
            </v-btn>

            <v-card class="pci-accept-dialog pa-5 pa-sm-10">
                <div class="pci-ad-head-sect flex-wrap">
                    <div class="pci-ad-ttl mb-5 text-h5 main-text--text">
                        Are you sure you wish to complete this project?
                    </div>
                    <div class="pci-ad-desc">
                        Publishing to client will change project status to “COMPLETED”
                    </div>
                </div>

                <v-card-actions class="pa-0 mt-10 justify-center">
                    <v-btn
                        v-if="!loading"
                        outlined
                        rounded
                        height="48"
                        width="calc(50% - 12px)"
                        elevation="0"
                        class="mr-6"
                        color="main-text"
                        @click="$emit('input', false)">
                        Cancel
                    </v-btn>

                    <v-btn
                        :loading="loading"
                        rounded
                        outlined
                        height="48"
                        width="calc(50% - 12px)"
                        elevation="0"
                        color="main-text"
                        class="ml-0 bg-primary"
                        @click="$emit('confirm')">
                        Publish
                    </v-btn>
                </v-card-actions>
            </v-card>
        </div>
    </v-dialog>
</template>

<script>
    export default {
        name: "publish-to-client-dialog",
        props: {
            loading: {
                type: Boolean,
                default: false
            }
        }
    }
</script>
