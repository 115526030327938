var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"extra-charge-block main-text--text"},[_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('button',{class:['button-text text-decoration-underline', _vm.projectIsOnHold ? 'is-disabled main-text--text' : 'green-100--text'],attrs:{"type":"button","disabled":_vm.projectIsOnHold},on:{"click":function($event){_vm.issueExtraChargeOpen = !_vm.issueExtraChargeOpen}}},[_vm._v(" Issue extra charge ")]),(_vm.projectIsOnHold)?_c('div',{staticClass:"amount-label"},[_vm._v(" Extra amount requested "),_c('span',{staticClass:"red-100--text"},[_vm._v("$"+_vm._s(_vm.project.extra_charge.amount))])]):_vm._e()]),_c('v-fade-transition',[(_vm.issueExtraChargeOpen && !_vm.projectIsOnHold)?_c('div',{staticClass:"issue-extra-charge mt-4"},[_c('div',{staticClass:"issue-extra-charge--title d-flex justify-space-between align-center mb-6"},[_c('div',[_vm._v("Price paid by client "),_c('span',{staticClass:"green-100--text"},[_vm._v("$"+_vm._s(_vm.project.client_amount))])])]),_c('validation-observer',{staticClass:"form-group",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function({handleSubmit:handleSubmitRefund, valid}){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmitRefund(_vm.submitRefund)}}},[_c('div',{staticClass:"font-weight-semibold mb-1"},[_vm._v("Extra charge amount")]),_c('validation-provider',{attrs:{"slim":"","name":"extra_charge","rules":"required|min_value:1","custom-messages":{
                            required: 'Extra charge amount is required',
                            min_value: 'Must be greater than 0',
                        }},scopedSlots:_vm._u([{key:"default",fn:function({errors}){return [_c('v-text-field',{staticClass:"edit-input edit-input--white",attrs:{"type":"number","outlined":"","height":"36","error-messages":errors,"placeholder":"Extra charge amount"},model:{value:(_vm.chargeData.amount),callback:function ($$v) {_vm.$set(_vm.chargeData, "amount", _vm._n($$v))},expression:"chargeData.amount"}})]}}],null,true)}),_c('div',{staticClass:"font-weight-semibold mb-1"},[_vm._v("Select reason")]),_c('v-select',{staticClass:"edit-input",attrs:{"items":_vm.reasons,"item-value":"id","menu-props":{
                            contentClass: 'assignee-autocomplete',
                            nudgeBottom: 34,
                            nudgeLeft: 1
                        },"append-icon":"mdi-chevron-down","outlined":"","height":"34","background-color":"#fff"},model:{value:(_vm.chargeData.reason),callback:function ($$v) {_vm.$set(_vm.chargeData, "reason", $$v)},expression:"chargeData.reason"}}),_c('div',{staticClass:"font-weight-semibold mb-1"},[_vm._v("Describe your reason (optional)")]),_c('v-textarea',{staticClass:"edit-input",attrs:{"outlined":"","flat":"","no-resize":"","height":"112","background-color":"#fff","placeholder":"Enter a reason"},model:{value:(_vm.chargeData.reason_details),callback:function ($$v) {_vm.$set(_vm.chargeData, "reason_details", $$v)},expression:"chargeData.reason_details"}}),_c('div',{staticClass:"font-weight-semibold mt-1 mb-1"},[_vm._v("Button set-up for client")]),_c('simple-checkbox',{staticClass:"mt-0",attrs:{"hide-details":"","simple":false,"label":"Remove “Proceed without changes” button on client side"},model:{value:(_vm.chargeData.without_skipping),callback:function ($$v) {_vm.$set(_vm.chargeData, "without_skipping", $$v)},expression:"chargeData.without_skipping"}}),_c('simple-checkbox',{staticClass:"mt-0",attrs:{"hide-details":"","simple":false,"label":"Issue refund if project gets canceled"},model:{value:(_vm.canceled_project_refund),callback:function ($$v) {_vm.canceled_project_refund=$$v},expression:"canceled_project_refund"}}),(_vm.canceled_project_refund)?[_c('div',{staticClass:"font-weight-semibold mt-1 mb-1"},[_vm._v("Refund amount")]),_c('validation-provider',{attrs:{"slim":"","name":"refund","rules":`min_value:1|max_value:${_vm.project.client_amount}`,"custom-messages":{
                                min_value: 'Must be greater than 0',
                                max_value: 'Must be less than the price paid'
                            }},scopedSlots:_vm._u([{key:"default",fn:function({errors}){return [_c('v-text-field',{staticClass:"edit-input edit-input--white",attrs:{"type":"number","outlined":"","height":"36","error-messages":errors,"placeholder":"Partial refund amount"},model:{value:(_vm.chargeData.refund_amount),callback:function ($$v) {_vm.$set(_vm.chargeData, "refund_amount", _vm._n($$v))},expression:"chargeData.refund_amount"}})]}}],null,true)})]:_vm._e(),_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"green-btn px-8 ml-auto",attrs:{"type":"submit","disabled":_vm.loading || !valid,"loading":_vm.loading,"rounded":"","depressed":"","height":"38","color":"#9FF4A9"}},[_vm._v(" Confirm ")])],1)],2)]}}],null,false,28924048)})],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }