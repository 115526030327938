<template>
    <v-container class="pa-6" fluid>
        <div class="def-page-cont pt-16">
            <div class="d-flex align-items-center">
                <v-row
                    align="center" dense class="filter-row">
                    <v-col cols="12" sm="auto">
                        <h1 class="mr-10">Projects</h1>
                    </v-col>
                    <v-col v-if="!isClient" cols="12" sm="auto">
                        <div class="projects-switcher flex-wrap">
                            <div
                                v-if="isDesigner"
                                :class="{ active: (!searchModel.availProjects && !searchModel.completed) }"
                                class="ps-item"
                                @click="switchProjects(0)">
                                My Projects
                            </div>
                            <div
                                v-if="isManager || isAdmin"
                                :class="{ active: (!searchModel.availProjects && !searchModel.completed && !searchModel.cancel) }"
                                class="ps-item"
                                @click="switchProjects(0)">
                                Active Projects
                            </div>
                            <div
                                :class="{ active: searchModel.availProjects }"
                                class="ps-item"
                                @click="switchProjects(1)">
                                Available Projects
                                <div v-if="availProjectsCount" class="ps-item-count">
                                    {{ availProjectsCount }}
                                </div>
                            </div>
                            <div
                                v-if="isManager || isAdmin"
                                :class="{ active: searchModel.completed }"
                                class="ps-item"
                                @click="switchProjects(2)">
                                Completed
                            </div>
                            <div
                                v-if="isManager || isAdmin"
                                :class="{ active: searchModel.cancel }"
                                class="ps-item"
                                @click="switchProjects(3)">
                                Cancelled
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="auto">
                        <div class="projects-filter-wrap">
                            <v-menu
                                :close-on-content-click="false"
                                bottom
                                content-class="pf-drd-wrap"
                                nudge-top="1"
                                offset-y
                                z-index="1">
                                <template v-slot:activator="{ on, attrs }">
                                    <div class="pf-drd-bw">
                                        <div v-bind="attrs" v-on="on" class="pf-drd-btn">
                                            Filter by:
                                            <img class="mr-n1" :src="require('@/assets/icons/ic-chevron-down-circle-outline.svg')" width="23" height="24"
                                                 alt="">
                                        </div>
                                    </div>
                                </template>
                                <div class="pf-drd-cw">
                                    <div class="pf-drd-sect mb-4">
                                        <div class="pf-drd-ttl">Project Type</div>
                                        <div class="pf-types">
                                            <v-chip
                                                v-for="(item, index) in $store.getters.getProjectTypes"
                                                :key="index"
                                                class="pf-chip"
                                                @click="!searchModel.type.includes(index) ? setFilter(index, 'type'): false">
                                                {{ item }}
                                                <v-btn @click.stop.prevent="setFilter(index, 'type')" icon width="13" height="13"
                                                       v-if="searchModel.type.includes(index)"
                                                       class="ml-3  mr-n1">
                                                    <svg width="12" height="13" viewBox="0 0 12 13" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="5.86255" cy="6.60083" r="5.6731" fill="#888888"/>
                                                        <rect x="3.99023" y="3.89355" width="6.44964" height="1.15785"
                                                              rx="0.2" transform="rotate(45 3.99023 3.89355)" fill="white"/>
                                                        <rect x="8.55078" y="4.71191" width="6.44964" height="1.15785"
                                                              rx="0.2" transform="rotate(135 8.55078 4.71191)" fill="white"/>
                                                    </svg>
                                                </v-btn>
                                            </v-chip>
                                        </div>
                                    </div>
                                    <div class="pf-drd-sect">
                                        <div class="pf-drd-ttl">Status</div>
                                        <div class="pf-statuses">
                                            <template v-for="(status, index) in projectStatusList">
                                                <v-chip
                                                    v-if="status !== $config.project.status.archived"
                                                    :key="index"
                                                    :class="$store.getters.getClassProjectStatus($store.getters.getValueProjectStatus(status))"
                                                    class="project-status"
                                                    @click="!searchModel.status.includes(status) ? setFilter(status, 'status'): false">
                                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                                         class="mr-1"
                                                         v-if="status === $config.project.status.canceled"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="6.27173" cy="6.22876" r="5.6731" fill="#C81C31"/>
                                                        <rect x="4.39941" y="3.52148" width="6.44964" height="1.15785"
                                                              rx="0.2" transform="rotate(45 4.39941 3.52148)" fill="white"/>
                                                        <rect x="8.95996" y="4.33984" width="6.44964" height="1.15785"
                                                              rx="0.2" transform="rotate(135 8.95996 4.33984)" fill="white"/>
                                                    </svg>
                                                    {{ $store.getters.getValueProjectStatus(status) }}
                                                    <v-btn @click.stop.prevent="setFilter(status, 'status')" icon width="13" height="13"
                                                           v-if="searchModel.status.includes(status)"
                                                           class="ml-3 mr-n1">
                                                        <svg width="12" height="13" viewBox="0 0 12 13" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="5.86255" cy="6.60083" r="5.6731" fill="#888888"/>
                                                            <rect x="3.99023" y="3.89355" width="6.44964" height="1.15785"
                                                                  rx="0.2" transform="rotate(45 3.99023 3.89355)" fill="white"/>
                                                            <rect x="8.55078" y="4.71191" width="6.44964" height="1.15785"
                                                                  rx="0.2" transform="rotate(135 8.55078 4.71191)" fill="white"/>
                                                        </svg>
                                                    </v-btn>
                                                </v-chip>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </v-menu>
                        </div>
                    </v-col>
                    <v-col class="filter-chips" cols="12" sm="auto">
                        <div class="flex flex-wrap">
                            <template v-for="item in currentFilter">
                                <v-chip v-if="item.type === 'type'"
                                        :key="item.id"
                                        class="pf-chip"
                                        @click="setFilter(item.id, 'type')">
                                    {{ item.name }}
                                    <v-btn @click="setFilter(item.id, 'type')" icon width="13" height="13"
                                           class="ml-3 mr-n1">
                                        <svg width="12" height="13" viewBox="0 0 12 13" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="5.86255" cy="6.60083" r="5.6731" fill="#888888"/>
                                            <rect x="3.99023" y="3.89355" width="6.44964" height="1.15785"
                                                  rx="0.2" transform="rotate(45 3.99023 3.89355)" fill="white"/>
                                            <rect x="8.55078" y="4.71191" width="6.44964" height="1.15785"
                                                  rx="0.2" transform="rotate(135 8.55078 4.71191)" fill="white"/>
                                        </svg>
                                    </v-btn>
                                </v-chip>
                                <v-chip
                                    v-if="item.type === 'status'"
                                    :key="item.id"
                                    :class="$store.getters['getClassProjectStatus'](item.name)"
                                    class="project-status "
                                    @click="setFilter(item.id, 'status')">
                                    {{ item.name }}
                                    <v-btn @click="setFilter(item.id, 'status')" icon width="13" height="13"
                                           class="ml-3 mr-n1">
                                        <svg width="12" height="13" viewBox="0 0 12 13" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="5.86255" cy="6.60083" r="5.6731" fill="#888888"/>
                                            <rect x="3.99023" y="3.89355" width="6.44964" height="1.15785"
                                                  rx="0.2" transform="rotate(45 3.99023 3.89355)" fill="white"/>
                                            <rect x="8.55078" y="4.71191" width="6.44964" height="1.15785"
                                                  rx="0.2" transform="rotate(135 8.55078 4.71191)" fill="white"/>
                                        </svg>
                                    </v-btn>
                                </v-chip>
                            </template>
                        </div>
                    </v-col>
                </v-row>
            </div>

            <div v-if="loading" class="page-preloader local"></div>
            <template v-else>
                <div class="d-flex mt-5 align-items-center">
                    <div class="total-count mr-5" v-if="currentFilter.length">
                        Total projects: {{ pagination.total }}
                    </div>
                    <exports-csv-dialog v-if="isManagerOrAdmin" :filter="{
                        statuses: searchModel.status,
                        types: searchModel.type,
                        available: searchModel.availProjects,
                        completed: searchModel.completed,
                        cancelled: searchModel.cancel,
                    }"/>
                </div>
                <div
                    v-if="list.length"
                    :class="[{ 'is-loading': loading }, currentFilter.length ? 'mt-8': 'mt-15']"
                    class="projects-list mt-8">
                    <project-card
                        v-for="item in list"
                        :key="item.id"
                        :avail-projects="availCardStatus"
                        :project="item"
                        class="mb-7"
                        @update-projects="getInfo"
                        @onFilter="setCardFilter"
                        @onOpenDialog="openDialog"/>
                </div>
                <div v-else-if="!list.length && !loading" class="pl-not-found mt-10 mb-5">
                    Not found
                </div>
                <div
                    v-if="false && pagination.total_pages && paginationPage < pagination.total_pages"
                    class="text-center">
                    <v-btn color="#515151" text @click="loadMore">
                        <v-icon left>mdi-reload</v-icon>
                        Load more
                    </v-btn>
                </div>
                <div v-if="showPagination" class="text-sm-center mt-3 mb-5">
                    <v-pagination
                        v-model="paginationPage"
                        :length="pagination.total_pages"
                        :total-visible="5"
                        class="pf-pagination"
                        color="#515151"
                        next-aria-label="Next"
                        previous-aria-label="Previous"
                        @input="changePage"/>
                </div>
            </template>
            <v-dialog v-model="dialog" max-width="620" @click:outside="closeDialog">
                <div v-if="dialogProject" class="dialog-overflow-wrap">
                    <v-btn class="pci-ad-cl-btn" icon @click="closeDialog">
                        <v-img
                            :src="require('@/assets/icons/ic-close-modal.svg')"
                            contain
                            height="36"
                            max-width="36"/>
                    </v-btn>
                    <v-card class="pci-accept-dialog pa-5 pa-sm-10">
                        <div
                            v-if="dialogProject.dialogType === 'manager'"
                            class="pci-ad-head-sect is-manager">
                            <div class="pci-ad-ttl">Assign A Modeler to This Project</div>
                            <div class="pci-ad-desc">
                                Assigning a modeler will notify the modeler.
                            </div>
                            <div class="pci-ad-select-block">
                                <span>Assign Modeler:</span>
                                <v-select
                                    v-model="selectModeler"
                                    :items="modelers"
                                    :menu-props="{ button: true, offsetY: true }"
                                    height="37"
                                    item-text="name"
                                    item-value="name"
                                    max-height="37"
                                    outlined
                                    return-object>
                                    <template v-slot:selection="{ item }">
                                        <v-list-item>
                                            <v-list-item-avatar color="grey lighten-3">
                                                <v-img :src="item.avatar" height="32" max-width="32"/>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ item["first_name"] }} {{ item["last_name"] }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                    <template v-slot:item="{ item, on }">
                                        <v-list-item v-on="on" ripple>
                                            <v-list-item-avatar color="grey lighten-3">
                                                <v-img :src="item.avatar" height="32" max-width="32"/>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ item["first_name"] }} {{ item["last_name"] }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-select>
                            </div>
                            <div class="pci-ad-title-block">
                                <div class="pci-ad-ttl">{{ dialogProject.title }}</div>
                                <div class="pci-ad-price">
                                    ${{ dialogProject.overall_designer_amount }}
                                </div>
                            </div>
                        </div>
                        <div v-else class="pci-ad-head-sect">
                            <div class="pci-ad-ttl">{{ dialogProject.title }}</div>
                            <div class="pci-ad-price">
                                ${{ dialogProject.overall_designer_amount }}
                            </div>
                        </div>
                        <div class="pci-ad-options mt-6">
                            <div class="pci-ad-opt-item">
                                Due date:
                                <span class="pci-ad-oi-val ttf-lc">
                                    {{ dueDate }}
                                </span>
                            </div>
                            <div class="pci-ad-opt-item">
                                Created:
                                <span class="pci-ad-oi-val ttf-lc">
                                    {{ dialogProject.created_at | moment("created_at") }}
                                    ({{ getProjectOwner }})
                                </span>
                            </div>
                            <div class="pci-ad-opt-item">
                                Project type:
                                <span class="pci-ad-oi-val">{{ dialogProject.type }}</span>
                            </div>
                            <div class="pci-ad-opt-item">
                                Project id:
                                <span class="pci-ad-oi-val">{{ dialogProject.id }}</span>
                            </div>
                        </div>

                        <template v-if="dialogProject.dialogType === 'manager'">
                            <div class="pci-ad-show-more">
                                <v-btn
                                    v-if="dialogProject.metals || dialogProject.stones || dialogProject.description"
                                    :class="{ 'is-show': showMoreStatus }"
                                    class="pci-ad-show-btn pl-0 mt-4 mb-4"
                                    depressed
                                    elevation="0"
                                    outlined
                                    plain
                                    @click="showMore">
                                    {{
                                        showMoreStatus
                                            ? "Hide information"
                                            : "Show more information"
                                    }}

                                    <v-img
                                        :src="arrowDown"
                                        class="py-1 mx-auto ml-1"
                                        contain
                                        max-width="26"
                                        position="center center"/>
                                </v-btn>
                                <template v-if="showMoreStatus">
                                    <div
                                        v-if="(dialogProject.metals && dialogProject.metals.length) || (dialogProject.stones && dialogProject.stones.length)"
                                        class="pci-ad-ms-options">
                                        <div class="pci-ad-cont-sep"></div>
                                        <div
                                            v-if="dialogProject.metals && dialogProject.metals.length"
                                            class="pci-ad-mso-item">
                                            Metal Types:
                                            <span class="pci-ad-mso-item-val">
                                                {{ dialogProject.metals.join(", ") }}
                                            </span>
                                        </div>
                                        <div
                                            v-if="dialogProject.stones && dialogProject.stones.length"
                                            class="pci-ad-mso-item mt-4">
                                            Stone Type:
                                            <span class="pci-ad-mso-item-val">
                                                {{ dialogProject.stones.join(", ") }}
                                            </span>
                                        </div>
                                    </div>

                                    <div class="pci-ad-cont-sep"></div>

                                    <div v-if="dialogProject.description" class="pci-ad-desc-wrap">
                                        <div class="pci-ad-opt-item mb-4">Description</div>
                                        <div class="pci-ad-desc" v-html="dialogProject.description"></div>
                                    </div>
                                </template>
                            </div>
                        </template>

                        <template v-else>
                            <div
                                v-if="(dialogProject.metals && dialogProject.metals.length) || (dialogProject.stones && dialogProject.stones.length)"
                                class="pci-ad-ms-options">
                                <div class="pci-ad-cont-sep"></div>
                                <div
                                    v-if="dialogProject.metals && dialogProject.metals.length"
                                    class="pci-ad-mso-item">
                                    Metal Types:
                                    <span class="pci-ad-mso-item-val">
                                        {{ dialogProject.metals.join(", ") }}
                                    </span>
                                </div>
                                <div
                                    v-if="dialogProject.stones && dialogProject.stones.length"
                                    class="pci-ad-mso-item" style="margin-top: 10px;">
                                    Stone Type:
                                    <span class="pci-ad-mso-item-val">
                                        {{ dialogProject.stones.join(", ") }}
                                    </span>
                                </div>
                            </div>

                            <div class="pci-ad-cont-sep desc-top-border"></div>

                            <div v-if="dialogProject.description" class="pci-ad-desc-wrap mb-8">
                                <div class="pci-ad-opt-item mb-4">Description</div>
                                <div class="pci-ad-desc" v-html="dialogProject.description"></div>
                            </div>

                            <div class="pci-ad-desc-wrap">
                                <div class="pci-ad-opt-item mb-4">Disclaimer</div>
                                <p class="pci-ad-desc mb-0">
                                    As a designer you are entitled to provide 1 design change for free. You will get compensated for any
                                    extra design changes. Price will be negotiated with a manager. Small changes (<10min) will not be
                                    reimbursed. Your model will usually get approved within 2 rounds.
                                </p>
                            </div>
                        </template>

                        <v-card-actions class="pa-0 mt-10">
                            <router-link
                                :to="'/project/' + dialogProject.id"
                                class="pci-ad-link">
                                View Full Project
                            </router-link>
                            <v-spacer></v-spacer>
                            <v-btn
                                v-if="isDesigner"
                                :loading="accepting"
                                class="pl-4 pr-4 pl-sm-10 pr-sm-10 designer-accept-project-btn"
                                elevation="0"
                                large
                                rounded
                                @click="acceptProject">
                                Accept Project
                            </v-btn>
                            <v-btn
                                v-if="isManager || isAdmin"
                                :disabled="isDisabled"
                                class="text--white pl-4 pr-4 pl-sm-10 pr-sm-10"
                                color="#02810F"
                                elevation="0"
                                large
                                rounded
                                @click="assignModelar">
                                Assign a Modeler
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </div>
            </v-dialog>
        </div>
    </v-container>
</template>

<script>
    import ProjectCard from "@/components/project/ProjectCard";
    import http from "@/api/httpClient.api";
    import moment from "moment";
    import arrowDown from "@/assets/icons/ic-arrow-tin-down.svg";
    import { mapGetters } from "vuex";
    import ExportsCsvDialog from "../../components/projects/ExportsCsvDialog";

    export default {
        name: "projects",
        components: {
            ExportsCsvDialog,
            ProjectCard
        },
        filters: {
            moment: function (date, type) {
                if (type === "created_at") {
                    return moment(date).format("MMM. DD, YYYY");
                }
                if (type === "updated_at") {
                    return moment(date).format("MM/DD/YY | h:mmA");
                }
            }
        },
        data () {
            return {
                accepting: false,
                arrowDown,
                showMoreStatus: false,
                selectModeler: null,
                loading: false,
                isLoadMore: false,
                list: [],
                searchModel: {
                    type: [],
                    status: [],
                    completed: 0,
                    availProjects: 0,
                    cancel: 0
                },
                availCardStatus: 0,
                paginationPage: 1,
                pagination: {
                    count: null,
                    current_page: null,
                    links: null,
                    per_page: null,
                    total: null,
                    total_pages: null
                },
                availProjectsCount: 0,
                dialog: false,
                dialogProject: null,
                isDisabled: true
            };
        },
        watch: {
            selectModeler: {
                handler (val) {
                    if (val) {
                        this.isDisabled = false;
                    }
                }
            },
            isManager () {
                if (this.isManager) {
                    this.getModelers();
                }
            },
            isClient () {
                if (!this.isClient) {
                    this.getAvailProjectsCount();
                }
            }
        },
        computed: {
            ...mapGetters([
                "isClient",
                "isDesigner",
                "isManager",
                "isAdmin",
                "isManagerOrAdmin"
            ]),
            currentFilter() {
                const types = this.searchModel.type.map(type => {
                    return {
                        type: "type",
                        id: type,
                        name: this.$store.getters.getProjectTypes[type]
                    }
                })

                const statuses = this.searchModel.status.map(status => {
                    return {
                        type: "status",
                        id: status,
                        name: this.$store.getters.getValueProjectStatus(status)
                    }
                })
                return [...types, ...statuses]
            },
            projectTypeFullInfo () {
                let type = null;
                let allTypes = this.$store.getters.getProjectTypesFullInfo;
                if (this.dialogProject) {
                    type = allTypes.find((item) => item.title === this.dialogProject.type);
                }
                return type;
            },
            dueDate () {
                const dueDateInHours = this.projectTypeFullInfo?.due_dates[0] ?? 0
                const dueDate = moment().add(dueDateInHours, "hours")
                const diffDays = dueDate.diff(moment().startOf("day"), "days")
                const dueDateInDays = diffDays ? `(${diffDays} days)`: ""
                return `${moment().add(dueDateInHours, "hours").format("MMM. DD, YYYY")} ${dueDateInDays}`
            },
            showPagination () {
                return this.pagination.total_pages > 1;
            },
            actionProjectDataText () {
                return this.isManager ? "Assign a Modeler" : "Accept Project";
            },
            modelers () {
                return this.$store.getters["manager/isModelerList"];
            },
            projectStatusList() {
                const statuses = Object.values(this.$config.project.status);
                if (this.isDesigner) {
                    const designerStatuses = [
                        this.$config.project.status.created,
                        this.$config.project.status.in_progress,
                        this.$config.project.status.in_qa,
                        this.$config.project.status.completed,
                        this.$config.project.status.on_hold
                    ]
                    return statuses.filter(status => designerStatuses.includes(status));
                }
                if(this.isClient) {
                    const ignoredStatuses = [
                        this.$config.project.status.in_qa,
                        this.$config.project.status.in_cqa,
                        this.$config.project.status.ready_for_review,
                        this.$config.project.status.on_hold
                    ]
                    return statuses.filter(status => !ignoredStatuses.includes(status));
                }
                if(this.isManagerOrAdmin) {
                    const ignoredStatuses = [
                        this.$config.project.status.in_qa,
                        this.$config.project.status.ready_for_review_renders,
                        this.$config.project.status.on_hold
                    ]
                    return statuses.filter(status => !ignoredStatuses.includes(status));
                }
                return statuses;
            },
            getProjectOwner() {
                return !this.isDesigner ? this.dialogProject.user : this.dialogProject.user.split(" ")[0];
            }
        },
        beforeMount () {
            this.getProjectTypes();
            this.getInfo();
        },
        mounted () {
            if (this.isManager || this.isAdmin) {
                this.getModelers();
            }
            setTimeout(() => {
                if (!this.isClient) {
                    this.getAvailProjectsCount();
                }
            }, 1000);
        },
        methods: {
            showMore () {
                this.showMoreStatus = !this.showMoreStatus;
            },
            openDialog (project) {
                this.dialogProject = project;
                this.dialog = true;
            },
            closeDialog () {
                this.dialogProject = null;
                this.dialog = false;
                this.showMoreStatus = false;
                this.selectModeler = null;
            },
            acceptProject () {
                this.accepting = true
                http.put(`/projects/${this.dialogProject.id}/accept`).then((res) => {
                    console.log("acceptProject", res);
                    if (res.status === 200) {
                        console.log("success");
                        this.accepting = false
                        this.closeDialog();
                        this.$router.push({ name: "project", params: { id: res.data.data.id } });
                    }
                }).catch((err) => {
                    console.log("error", err);
                });
            },
            assignModelar () {
                http.put(`/manager/modelers/${this.selectModeler.id}/projects/${this.dialogProject.id}/assign`).then((res) => {
                    console.log("assignModelar", res);
                    if (res.status === 200 || res.status === 204) {
                        console.log("success");
                        this.closeDialog();
                        this.getInfo();
                    }
                }).catch((err) => {
                    console.log("error", err);
                });
            },
            getModelers () {
                this.$store.dispatch("manager/getModelerList");
            },
            switchProjects (type) {
                this.paginationPage = 1;
                this.searchModel.status = [];
                switch (type) {
                    case 1:
                        this.searchModel.availProjects = 1;
                        this.searchModel.completed = 0;
                        this.searchModel.cancel = 0;
                        break;
                    case 2:
                        this.searchModel.availProjects = 0;
                        this.searchModel.completed = 1;
                        this.searchModel.cancel = 0;
                        break;
                    case 3:
                        this.searchModel.availProjects = 0;
                        this.searchModel.completed = 0;
                        this.searchModel.cancel = 1;
                        break;
                    default:
                        this.searchModel.availProjects = 0;
                        this.searchModel.completed = 0;
                        this.searchModel.cancel = 0;
                }
                this.getInfo();
            },
            getFilterTypeStyles (index, style) {
                let color = "#D9FBDD";
                let textColor = "#464646";
                if (
                    this.searchModel.type.length &&
                    this.searchModel.type.includes(index)
                ) {
                    color = "#0046AF";
                    textColor = "#fff";
                }
                if (style === "color") {
                    return color;
                }
                if (style === "textColor") {
                    return textColor;
                }
            },
            getFilterStatusStyles (index, style) {
                let color = "rgba(0, 70, 175, 0.05)";
                let invertColor = "#0046AF";
                let textColor = "#0046AF";
                switch (index) {
                    case "0":
                        color = "rgba(232, 111, 0, 0.05)";
                        invertColor = "#A53100";
                        textColor = "#A53100";
                        break;
                    case "1":
                        color = "rgba(208, 14, 14, 0.05)";
                        invertColor = "#D00E0E";
                        textColor = "#D00E0E";
                        break;
                    case "2":
                        color = "rgba(255, 245, 0, 0.05)";
                        invertColor = "#79750D";
                        textColor = "#79750D";
                        break;
                    case "3":
                        color = "rgba(0, 146, 32, 0.05)";
                        invertColor = "#006616";
                        textColor = "#006616";
                        break;
                }
                if (
                    this.searchModel.status.length &&
                    this.searchModel.status.includes(index)
                ) {
                    color = invertColor;
                    textColor = "#fff";
                }
                if (style === "color") {
                    return color;
                }
                if (style === "textColor") {
                    return textColor;
                }
            },
            setCardFilter (typeIndex) {
                console.log("typeIndex", typeIndex);
                this.setFilter(typeIndex, "type");
            },
            setFilter (index, filterParam) {
                this.paginationPage = 1;
                console.log("filter index", index);
                console.log("filter param", filterParam);
                if (
                    this.searchModel[filterParam].length &&
                    this.searchModel[filterParam].includes(index)
                ) {
                    this.searchModel[filterParam] = this.searchModel[filterParam].filter(
                        (item) => item !== index
                    );
                } else {
                    this.searchModel[filterParam].push(index);

                    console.log(this.searchModel)
                }
                this.getInfo();
            },
            getProjectTypes () {
                let params = {};
                http.get("/projects/types", { params }).then((res) => {
                    console.log("getProjectTypes", res);
                    if (res.status === 200) {
                        console.log("success");
                        let data = res.data.data;
                        console.log(res.data.data, "island");
                        this.$store.commit("setProjectTypesFullInfo", data);
                        let filterTypes = {};
                        data.forEach((item) => {
                            filterTypes[item.id] = item.title;
                        });
                        this.$store.commit("setProjectTypes", filterTypes);
                    }
                }).catch((err) => {
                    console.log("error", err);
                });
            },
            getAvailProjectsCount () {
                let params = {};
                http.get("/projects/available-count", { params }).then((res) => {
                    console.log("getAvailProjectsCount", res);
                    if (res.status === 200) {
                        console.log("success");
                        if (res.data && res.data.count) {
                            this.availProjectsCount = res.data.count;
                        }
                    }
                }).catch((err) => {
                    console.log("error", err);
                });
            },
            loadMore () {
                this.isLoadMore = true;
                this.getInfo();
            },
            getInfo () {
                this.loading = true;

                let params = {};

                if (this.searchModel.status.length) {
                    params.statuses = this.searchModel.status;
                }
                if (this.searchModel.type.length) {
                    params.types = this.searchModel.type;
                }
                if (this.searchModel.availProjects) {
                    params.available = this.searchModel.availProjects;
                }
                if (this.searchModel.completed) {
                    params.completed = this.searchModel.completed;
                }
                if (this.searchModel.cancel) {
                    params.cancelled = this.searchModel.cancel;
                }

                if (this.isLoadMore) {
                    this.paginationPage++;
                }

                // Page param
                if (this.paginationPage) {
                    params.page = this.paginationPage;
                }

                http.get("/projects", { params }).then((res) => {
                    console.log("getInfo-res", res.data);
                    if (res.status === 200) {
                        console.log("success");
                        let data = res.data;
                        this.pagination = data.meta.pagination;

                        if (this.isLoadMore) {
                            this.list = this.list.concat(data.data);
                            this.isLoadMore = false;
                        } else {
                            this.list = data.data;
                        }
                    }
                }).catch((err) => {
                    console.log("error", err);
                }).then(() => {
                    this.availCardStatus = this.searchModel.availProjects;
                    this.loading = false;
                    if (!this.isClient) {
                        this.getAvailProjectsCount();
                    }
                });
            },
            changePage (page) {
                if (this.pagination.current_page !== page) {
                    this.getInfo();
                }
            }
        }
    };
</script>

<style lang="scss">
    .v-btn.text--white {
        color: #fff;
    }
    .total-count{
        font-weight: 400;
        font-size: 14px;
        color: #464646;
    }
    .pci-ad-select-block {
        & .v-input__slot {
            max-width: 385px !important;
        }
        .v-list-item {
            max-height: 32px;
            padding: 0;
        }
        & .v-list-item__content {
            max-width: 385px !important;
        }
        & .v-select {
            &__selections {
                .v-avatar {
                    min-width: 32px !important;
                    height: 32px !important;
                    width: 32px !important;
                }
                .v-list-item {
                    max-height: 32px;
                    padding: 0;
                }
            }
        }
    }
    .projects-toolbar {
        width: 100%;
        display: flex;
        justify-content: space-between;
        &.projects-filter-wrap {
            max-width: inherit;
        }
    }
    // popup
    .dialog-overflow-wrap {
        position: relative;
    }
    .pci-accept-dialog {
        max-height: 90vh;
        overflow: auto !important;
    }
    .pci-ad-cl-btn {
        position: absolute;
        right: -18px;
        top: -18px;
        z-index: 1;
    }
    .pci-ad-head-sect {
        display: flex;
        &.is-manager {
            flex-direction: column;
        }
    }
    .pci-ad-ttl {
       	font-size: 24px;
		line-height: 27px;
        color: #464646;
        flex-grow: 1;
        padding-right: 15px;
        padding-top: 15px;
        word-break: break-word;
    }
    .pci-ad-title-block,
    .pci-ad-select-block {
        display: flex;
        align-items: center;
        margin-top: 33px;
        flex-wrap: nowrap;
    }
    .pci-ad-show-more {
        & .pci-ad-show-btn {
            border: none;
            padding: 0;
            font-size: 16px;
            line-height: normal;
            color: #0046af;
            text-decoration: none;
            text-align: left;
            transition: opacity 250ms ease;
            &.is-show {
                .v-image {
                    transform: rotate(90deg);
                }
            }
        }
    }
    .pci-ad-select-block {
        .v-select__slot {
            & .v-select__selections {
                padding: 0;
            }
            & .v-input__append-inner {
                margin-top: 6px;
            }
        }
        .v-text-field--outlined > .v-input__control > .v-input__slot {
            min-height: initial;
        }
        .v-text-field.v-text-field--outlined {
            border-color: #cbd2d0 !important;
            border-radius: 3px;
        }
        & .v-input__control {
            max-height: 37px;
        }
        & > span {
            font-size: 16px;
            line-height: 20px;
            color: #4e4e4e;
            font-weight: 700;
            margin-right: 14px;
        }
    }
    .pci-ad-price {
        flex: 0 0 auto;
        height: 72px;
        min-width: 72px;
        background: #9FF4A9;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 20px;
        line-height: 20px;
        color: #464646;
        padding: 5px;
    }
    .pci-ad-opt-item {
		font-size: 14px;
		line-height: 18px;
        color: #4E4E4E;
        text-transform: uppercase;
		margin-top: 10px;

        .pci-ad-oi-val {
            font-weight: 700;
            &.ttf-lc {
                text-transform: none;
            }
        }

		&:first-child {
			margin-top: 0;
		}
    }
    .pci-ad-desc {
		font-size: 14px;
		line-height: 18px;
		color: #4E4E4E;
        h1 {
            margin-bottom: 8px;
            font-size: 26px;
            line-height: 28px;
        }
        h2 {
            margin-bottom: 8px;
            font-size: 24px;
            line-height: 26px;
        }
        h3 {
            margin-bottom: 8px;
            font-size: 22px;
            line-height: 24px;
        }
    }
    .pci-ad-cont-sep {
        border-bottom: 1px solid #E7E7E7;
        margin: 26px 0 16px 0;
    }
    .pci-ad-mso-item {
    	font-size: 14px;
		line-height: 18px;
        color: #4E4E4E;
    }
    .pci-ad-mso-item-val {
        font-weight: 700;
    }
    .pci-ad-link {
    	font-size: 14px;
		line-height: 18px;
        color: #3F7359 !important;
        text-decoration: underline;
        transition: opacity 250ms ease;
        &:hover {
            opacity: 0.7;
        }
    }
	.designer-accept-project-btn {
		background-color: #9FF4A9 !important;
		border: 1px solid #464646;
		box-sizing: border-box;
		border-radius: 50px;
		font-size: 14px;
		line-height: 18px;
		color: #464646;
		height: 47px;
	}
    // popup
    .projects-switcher {
        display: flex;
        margin: -5px;
    }
    .ps-item {
        position: relative;
        font-size: 12px;
        line-height: 16px;
        color: #0b3c49;
        border: 1px solid #888888;
        box-sizing: border-box;
        border-radius: 5px;
        height: 37px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        cursor: pointer;
        margin: 5px;
        &.active {
            background: #D9FBDD;
            box-shadow: 4px 4px 4px rgba(70, 70, 70, 0.15);
        }
    }
    .ps-item-count {
        position: absolute;
        top: -9px;
        right: -9px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 3px;
        min-width: 18px;
        height: 18px;
        background-color: rgba(166, 20, 0, 0.85);
        border-radius: 50%;
        font-weight: bold;
        font-size: 10px;
        line-height: 16px;
        color: #ffffff;
    }
    .def-page-cont {
        max-width: 1160px;
        margin-left: auto;
        margin-right: auto;
    }
    .projects-filter-wrap {
        max-width: 310px;
    }
    .pf-drd-wrap {
        border: 1px solid #888888;
        box-shadow: 0 8px 10px rgba(139, 139, 139, 0.25);
        border-radius: 5px;
    }
    .pf-drd-cw {
        background: #fff;
        padding: 24px 23px;
        max-width: 308px;
    }
    .pf-drd-btn {
        font-family: 'Inter', sans-serif;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        min-width: 120px;
        padding: 6px 10px;
        font-size: 12px;
        line-height: 16px;
        color: #464646;
        border: 1px solid #888888;
        border-radius: 5px;
    }
    .pf-drd-ttl {
        font-weight: bold;
        font-size: 24px;
        line-height: 27px;
        color: #0b3c49;
        border-bottom: 1px solid #888888;
        padding-bottom: 10px;
        margin-bottom: 10px;
    }
    .v-chip {
        &.project-status, &.pf-chip {
            margin-right: 10px;
            margin-bottom: 5px;
            margin-top: 5px;
            cursor: pointer;
        }
    }
    .project-status.v-chip {
        height: 24px;

    }
    .pf-chip.v-chip{
        height: 24px;
        cursor: pointer;
        display: inline-block;
        vertical-align: top;
        white-space: nowrap;
        font-weight: 400 !important;
        font-size: 14px;
        line-height: 14px;
        text-transform: uppercase;
        letter-spacing: .4px;
        padding: 4px 10px;
        border: 1px solid #464646 !important;
        border-radius: 5px !important;
        color: #464646 !important;
        background: #d9fbdd !important;
    }
    .is-loading {
        position: relative;
        &:after {
            content: "";
            position: absolute;
            left: -10px;
            right: -10px;
            top: -10px;
            bottom: -10px;
            background-color: rgba(255, 255, 255, 0.6);
            z-index: 1;
        }
    }
    .pl-not-found {
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        color: #0b3c49;
        text-align: center;
    }

	.desc-top-border {
		margin: 19px 0 24px 0;
	}

    .filter-row{
        .col{
            padding: 5px !important;
        }
        .filter-chips{
            flex: 1 1 0;
            align-self: baseline;
        }
    }
</style>

<style lang="scss">
    .pf-pagination {
        .v-pagination__item {
            height: 25px;
            min-width: 25px;
            font-size: 14px;
            outline: none;
            margin-left: 6px;
            margin-right: 6px;
            border-radius: 2px;
        }
        .v-pagination__navigation {
            width: auto;
            height: auto;
            box-shadow: none;
            outline: none;
            margin-left: 6px;
            margin-right: 6px;
            opacity: 1;
            &--disabled {
                color: #cdcdcd;
            }
            i {
                display: none;
            }
            &:before {
                font-size: 14px;
                content: attr(aria-label);
            }
        }
    }
</style>
