<template>
    <v-simple-checkbox
        v-if="simple"
        v-bind="$attrs"
        v-on="$listeners"
        off-icon="icon-off"
        on-icon="icon-on"
        :ripple="false"
        indeterminate-icon=""/>

    <v-checkbox
        v-else
        v-bind="$attrs"
        v-on="$listeners"
        off-icon="icon-off"
        on-icon="icon-on"
        :ripple="false"
        indeterminate-icon=""/>
</template>

<script>
    export default {
        name: "simple-checkbox",
        model: {
            prop: "inputValue",
            event: "change"
        },
        props: {
            simple: {
                type: Boolean,
                default: true
            }
        }
    }
</script>

<style scoped lang="scss">
.v-simple-checkbox,
.v-input {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    ::v-deep .v-input--selection-controls__input {
        width: 20px;
        height: 20px;
        margin-right: 0;
    }

    ::v-deep .v-label {
        margin-left: 8px;
        font-size: 14px;
        color: #464646;
    }

    ::v-deep .v-icon {
        border: 1px solid #BCBCBC;
        border-radius: 4px;
        transition: none;
    }

    ::v-deep .icon-on {
        background-image: url("./../../assets/icons/ic-check.svg");
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: 80% 80%;
    }
}
</style>
