<template>
    <v-breadcrumbs
        :items="items"
        class="custom-breadcrumbs pa-0">
        <template v-slot:item="{ item }">
            <v-breadcrumbs-item
                :disabled="item.disabled"
                :to="item.href">
                {{ validateText(item.text) }}
            </v-breadcrumbs-item>
        </template>
        <template v-slot:divider>
            <img src="@/assets/_redesigne/icons/ic-shevron-right.svg">
        </template>
    </v-breadcrumbs>
</template>

<script>
    export default {
        name: "breadcrumbs",
        props: {
            items: {
                type: Array,
                default () {
                    return [];
                }
            }
        },
        methods: {
            validateText (str) {
                if (str.length > 50) {
                    return str.substring(0, 50) + "...";
                } else {
                    return str;
                }
            }
        }
    };
</script>

<style lang="scss">
    .custom-breadcrumbs {
        font-size: 16px;
        li {
            font-size: 16px !important;
            color: #464646 !important;
            &.v-breadcrumbs__divider {
                padding: 0 10px !important;
                .v-icon {
                    font-size: 24px;
                    color: #656565;
                }
            }
        }
        a {
            color: #3F7359 !important;
            text-decoration: underline;

            &:hover {
                opacity: 0.7;
            }
        }
    }
</style>
