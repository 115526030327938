<template>
    <v-dialog
        v-model="imageDialog"
        @keydown.esc="$emit('closeImgDialog')"
        @click:outside="$emit('closeImgDialog')"
        width="620">
        <div class="dialog-overflow-wrap">
            <v-btn
                class="pci-ad-cl-btn"
                icon>
                <v-img
                    :src="require('@/assets/icons/ic-close-modal.svg')"
                    contain
                    height="36"
                    max-width="36"
                    @click="$emit('closeImgDialog')"/>
            </v-btn>

            <v-card>
                <v-img :src="imageSrc"/>
            </v-card>
        </div>
    </v-dialog>
</template>

<script>
    export default {
        name: "image-resizer-dialog",
        props: {
            imageDialog: {
                type: Boolean,
                required: true
            },
            imageSrc: {
                type: String,
                required: true
            }
        }
    };
</script>

