<template>
    <div class="login-indicator text-center">
        <p>YOU ARE IMPERSONATING {{ profile.email }}</p>
        <a
            role="button"
            @click="$store.dispatch('logout')"
            class="font-weight-bold text-decoration-underline">
            Become {{ fallbackTokenData.email }}
        </a>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";

    export default {
        name: "login-as-user-indicator",
        computed: {
            ...mapGetters([
                "fallbackTokenData",
                "profile"
            ])
        }
    }
</script>

<style scoped lang="scss">
.login-indicator {
    padding: 7px 8px 9px;
    font-size: 14px;
    line-height: 18px;
    background-color: #D00E0E;
    color: #fff;

    p {
        margin-bottom: 3px;
    }

    a {
        color: #fff;
    }
}
</style>
