export default {
    fileSizeLimit: 500, //500MB
    fileSizeLimit3D: 100, //100MB
    allowedFileExtension: {
        images: [".jpg", ".png", ".jpeg", ".webp"],
        models: [".obj", ".fbx", ".stl"],
        others: [
            ".3dm",
            ".jcd",
            ".max",
            ".blend",
            ".c4d",
            ".ztl",
            ".zpr",
            ".ma",
            ".mb",
            ".zip",
            ".rar",
            ".pdf",
            ".docx",
            ".doc",
            ".xlsx",
            ".xls",
            ".pptx",
            ".ppt",
            ".txt"
        ],
        others_for_designer: [".max", ".ma", ".mb"],
        renders: [".png", ".jpg", ".jpeg", ".svg"]
        // renders: [".png", ".jpg", ".jpeg", ".svg", ".zip", ".7z", ".rar"]
    },
    commentType: {
        comment: 1,
        stone: 2,
        metal: 3
    },
    fileTypes: {
        attachment: 0,
        sketch: 1,
        reference: 2,
        models: 3,
        engraving: 4,
        logo: 5,
        type_of_views: 6,
        background: 7,
        icon_of_comment: 8,
        render: 9,
        shadow: 10,
        others: 11,
        avatar: 20
    },
    fileTypesLabels: {
        0: "Attachment",
        1: "Sketch",
        2: "Reference",
        3: "3D-model",
        4: "Engraving",
        5: "Logo",
        6: "Type of Views",
        7: "Background",
        8: "Icon for Comment",
        9: "Render",
        10: "Shadow",
        11: "Others",
        20: "Avatar"
    },
    priceConfigTypes: {
        jewelry_type: 1,
        designer_compensation: 2
    },
    priceConfigTypesLabels: {
        1: "Margin",
        2: "Render",
        3: "Extra render",
        4: "Jewelry type",
        5: "Stone quantity",
        6: "Unique stones",
        7: "Model"
    },
    refundType: {
        money: 1,
        percent: 2
    },
    extraChargeReasons: {
        1: "Unforeseen design changes",
        2: "Initial project price too low"
    },
    extraChargeStatuses: {
        awaiting: 1,
        paid: 2,
        skipped: 3,
        canceled: 4
    },
    project: {
        type: {
            necklace: 1,
            bracelet: 2,
            ring: 3,
            earrings: 4,
            cuffs: 5,
            band: 6,
            custom: 7
        },
        status: {
            pending_approval: 0,
            input_needed: 1,
            in_progress: 2,
            completed: 3,
            draft: 4,
            ready_for_review: 5,
            created: 7,
            canceled: 9,
            pricing_needed: 10,
            pricing_pending: 11,
            in_progress_rendering: 12,
            ready_for_review_renders: 13,
            final_assets_prep: 14,
            in_qa: 15,
            in_cqa: 16,
            on_hold_action_needed: 17,
            on_hold: 18,
            archived: 50
        },
        statusLabels: {
            0: "Pending Approval",
            1: "Input Needed",
            2: "In Progress",
            3: "Completed",
            4: "Draft",
            5: "Ready For Review",
            6: "Render Specs",
            7: "Created",
            8: "Render Specs (Draft)",
            9: "Canceled",
            10: "Pricing Needed",
            11: "Pricing Pending",
            12: "In Progress - Rendering",
            13: "Ready For Review - Renders",
            14: "Final Assets Prep",
            15: "In QA",
            16: "In CQA",
            17: "On Hold - Action Needed",
            18: "On Hold",
            50: "Archived"
        },
        statusTooltip: {
            2: "Your 3D model is being made",
            12: "Your renders are being made",
            13: "Your renders are ready for review"
        },
        subStatus: {
            initial: 0,
            render_specs: 1,
            waiting_for_render: 2
        },
        subStatusLabels: {
            0: "Initial",
            1: "Render Specs",
            2: "Waiting for Render"
        },
        paymentStatus: {
            awaiting: 0,
            success: 1,
            failure: 2,
            refund: 3,
            processing: 4
        },
        paymentStatusLabels: {
            0: "Awaiting for Payment",
            1: "Paid",
            2: "Payment Canceled",
            3: "Payment Refunded",
            4: "Payment is Processing"
        }
    },
    user: {
        roles: {
            client: "client",
            designer: "designer",
            manager: "manager",
            admin: "admin"
        }
    }
};
