<template>
    <v-main class="login">
        <div
            class="circle left-circle"
            :class="{'to-bottom': $route.name!=='registration'}">
            <img
                src="@/assets/_redesigne/AuthScreen/SignUp/circle-left.webp"
                v-if="$route.name=='registration'">

            <img
                src="@/assets/_redesigne/AuthScreen/SignIn/circle-left.webp"
                v-else>
        </div>
        <login-header style="z-index: 3;"/>
        <router-view style="z-index: 3; position: relative;"/>

        <div class="gradient"/>
        <div
            class="circle right-circle"
            :class="{'to-top': $route.name!=='registration'}">
            <img
                src="@/assets/_redesigne/AuthScreen/SignUp/circle-right.webp"
                v-if="$route.name=='registration'">

            <img
                src="@/assets/_redesigne/AuthScreen/SignIn/circle-right.webp"
                v-else>
        </div>
    </v-main>
</template>

<script>
    import LoginHeader from "../views/user/login/login-header";

    export default {
        name: "login",
        components: {
            LoginHeader
        }
    };
</script>

<style scoped lang="scss">
.login {
    position: relative;

    .gradient {
        background: linear-gradient(0deg,#ecfdee 9.71%,rgba(255, 255, 255, 0) 100%);
        background-position-y: bottom;
        background-repeat: no-repeat;
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 30%;
        bottom: 0;
    }

    .circle {
        position: absolute;
        z-index: 1;

        img {
            width: 100%;
            max-width: 100%;
        }
    }

    .left-circle {
        left: 0;
        top: 0;
        max-width: 33%;
    }

    .right-circle {
        right: 0;
        bottom: 0;
        max-width: 26%;
    }
}

.to-bottom {
    top: unset !important;
    bottom: 40px;
    max-width: 22% !important;
}

.to-top {
    top: 0;
    max-width: 29% !important;
}
</style>
 