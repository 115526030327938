import http from "@/api/httpClient.api";
import { managerApi } from "@/api";

export default () => ({
    namespaced: true,
    state: () => ({
        modelerList: [],
        modelersListPagination: null,
        clients: [],
        clientsListPagination: null,
        clientsProjects: [],
        modelersProjects: [],
        project: null
    }),
    getters: {
        isModelerList: state => state.modelerList,
        isProjectCompleted: state => Boolean(state.project?.status === "Completed")
    },
    mutations: {
        SET_PROJECT (state, payload) {
            state.project = payload;
        },
        SET_MODELER_LIST (state, payload) {
            state.modelerList = payload.data;
            state.modelersListPagination = payload.meta.pagination;
        },
        SET_CLIENTS_LIST (state, payload) {
            state.clients = payload.data;
            state.clientsListPagination = payload.meta.pagination;
        },
        SET_CLIENTS_PROJECTS (state, payload) {
            state.clientsProjects = payload;
        },
        FILTER_BY_STATUS (state, payload) {
            console.log(payload.filters);
            let projects = [];
            payload.filters.forEach(type => {
                let filtered = payload.projects.filter(project => project.status === type.id);
                projects = [...projects, ...filtered];
            });
            // payload.projects.filter(project => project.status === payload.filter)
            state.clientsProjects = projects;
        },
        SET_MODELERS_PROJECTS (state, payload) {
            console.log(payload);
            state.modelersProjects = payload;
        },
        FILTER_BY_STATUS_MODELERS (state, payload) {
            console.log(payload.filters);
            let projects = [];
            payload.filters.forEach(type => {
                let filtered = payload.projects.filter(project => project.status === type.id);
                projects = [...projects, ...filtered];
            });
            // payload.projects.filter(project => project.status === payload.filter)
            state.modelersProjects = projects;
        }
    },
    actions: {
        async fetchProject ({ commit }, id) {
            return new Promise((resolve, reject) => {
                http.get(`/projects/${id}`).then(({ data }) => {
                    commit("SET_PROJECT", data.data);
                    resolve(data.data.title);
                }).catch((e) => {
                    console.log(e);
                    reject(e);
                });
            });
        },
        getModelerList ({ commit }, params = {}) {
            return managerApi.getModelerList(params).then(({ data }) => {
                commit("SET_MODELER_LIST", data);
            }).catch((e) => {
                console.error(e);
            });
        },
        getClientsList ({ commit }, params = {}) {
            return managerApi.getClientsList(params).then(({ data }) => {
                commit("SET_CLIENTS_LIST", data);
            }).catch((e) => {
                console.error(e);
            });
        },
        fetchClientsOngoingProjects ({ commit }, { id, filters }) {
            http.get(`/manager/clients/${id}/projects/ongoing`).then(({ data }) => {
                if (!filters.length) {
                    commit("SET_CLIENTS_PROJECTS", data.data);
                } else {
                    commit("FILTER_BY_STATUS", { projects: data.data, filters });
                }
            }).catch(e => console.log(e.message));
        },
        fetchModelersProjects ({ commit }, { id, type, filters }) {
            http.get(`/manager/modelers/${id}/projects/${type}`).then(({ data }) => {
                if (!filters.length) {
                    commit("SET_MODELERS_PROJECTS", data.data);
                } else {
                    commit("FILTER_BY_STATUS_MODELERS", { projects: data.data, filters });
                }
            }).catch((e) => console.log(e));
        }
    }
})
