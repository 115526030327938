<template>
    <v-menu bottom left>
        <template #activator="{on}">
            <v-btn
                v-on="on"
                v-bind="$attrs"
                class="notification-btn"
                :class="btnClass"
                icon
                small>
                <v-img :src="require('@/assets/icons/ic-bell-notification.svg')" contain/>
                <span v-if="not_read_count" class="notification-calculation font-weight-bold">
                    {{ not_read_count }}
                </span>
            </v-btn>
        </template>

        <template #default>
            <v-list class="notification-list py-5 px-7">
                <div class="list-header d-flex justify-space-between mb-7">
                    <h3 class="list-header__title">Notification</h3>
                    <button class="list-header__button text-right" @click.prevent="readAllNotification">
                        Mark all as read
                    </button>
                </div>

                <div v-if="notifications.length === 0" class="text-caption">
                    You haven't notifications yet
                </div>

                <v-list-item
                    v-for="(notification, index) in notifications"
                    :key="index"
                    :data-uuid="notification.uuid"
                    v-intersect="readedMessage"
                    class="notification-card pa-0">
                    <p class="notification-card__message pl-3 pr-5 pt-5 pb-2" v-html="notification.message"/>

                    <div
                        v-if="notification.icon"
                        :class="['notification-card__icon text-center', {
                            'notification-card__icon--red' : notification.icon,
                            'notification-card__icon--green' : notification.icon === 'finance',
                            'notification-card__icon--blue' : notification.icon ==='massage'
                        }]">
                        <img
                            v-if="notification.icon === ''"
                            src="@/assets/icons/ic_clock.svg"
                            alt="">
                        <img
                            v-if="notification.icon === 'warning'"
                            src="@/assets/icons/ic_warning.svg"
                            alt="">
                        <img
                            v-if="notification.icon === 'massage'"
                            src="@/assets/icons/ic_message.svg"
                            alt="">
                        <img
                            v-if="notification.icon === 'finance'"
                            src="@/assets/icons/ic_payed.svg"
                            alt="">
                    </div>

                    <span :class="['notification-card__time text-right', {
                        'notification-card__time--unreaded' : notification.read_at === null
                    }]">
                        {{ formatDate(notification.created_at) }}
                    </span>
                </v-list-item>

                <v-list-item class="last-item" v-intersect="infiniteScrolling"/>
            </v-list>
        </template>
    </v-menu>
</template>

<script>
    import { mapGetters } from "vuex";
    import { userApi } from "@/api";

    export default {
        name: "notifications-dropdown",
        props: {
            btnClass: {
                type: [String, Array, Object],
                default: ""
            }
        },
        data() {
            return {
                notifications: [],
                page: 1,
                not_read_count: 0,
                nextPage: null
            }
        },
        computed: {
            ...mapGetters([
                "isLoggedIn"
            ])
        },
        watch: {
            isLoggedIn(val){
                if(val) {
                    this.loadNotifications();
                }
            }
        },
        created () {
            if (this.isLoggedIn) {
                this.loadNotifications();
            }
        },
        methods: {
            async loadNotifications() {
                const response = await userApi.getNotifications({ page: this.page });
                this.not_read_count = response.data.not_read_count;
                this.notifications = response.data.data;
                this.nextPage = response.data.links.next;
            },
            async infiniteScrolling (event) {
                if(event[0].isIntersecting) {
                    if (!this.nextPage) {
                        return;
                    }

                    const response = await userApi.getNotifications({ page: this.page });
                    this.not_read_count = response.data.not_read_count;

                    response.data.data.forEach(notification => {
                        this.notifications.push(notification);
                    });

                    this.page++;
                }

            },
            async readAllNotification() {
                await userApi.readNotifications();

                this.notifications.forEach(notification => {
                    notification.read_at = new Date;
                });

                this.not_read_count = 0;
            },
            readedMessage(entries, isIntersecting) {
                if(isIntersecting) {
                    this.notifications.find(notification => {
                        if(notification.uuid === entries[0].target.dataset.uuid) {
                            if (notification.read_at === null) {
                                userApi.readNotifications(entries[0].target.dataset.uuid).then(() => {
                                    notification.read_at = new Date;
                                    if(this.not_read_count === 0) {
                                        return;
                                    }
                                    this.not_read_count--;
                                });
                            }
                        }
                    });
                }
            },
            formatDate(date) {
                return this.$moment(date).fromNow();
            }
        }
    }
</script>

<style scoped lang="scss">
.notification-btn {
    position: relative;
}

.notification-calculation {
    position: absolute;
    right: -5px;
    bottom: -5px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    font-size: 10px;
    line-height: 16px;
    background-color: rgb(159, 244, 169);
    color: #464646;
}

.notification-list {
    max-width: 316px;
    min-width: 316px;
    max-height: 512px;
    width: 100%;
    background: #FEFEFE;
    border: 1px solid #D9D9D9;
    box-shadow: 4px 4px 4px rgba(70, 70, 70, 0.15) !important;
    border-radius: 6px !important;
    overflow-y: scroll;

    .list-header {
        &__title {
            font-size: 16px;
            line-height: 24px;
            color: #0B3C49;
        }

        &__button {
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.4px;
            color: #0046AF;
        }
    }

    .notification-card {
        position: relative;
        width: 100%;
        margin: 0 0 24px 0;
        border-radius: 6px;
        background: #FAFAFA;

        &__message {
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0.4px;
            color: #0B3C49;
        }

        &__icon {
            position: absolute;
            top: 0;
            transform: translateY(-50%);
            width: 20px;
            height: 20px;
            border-radius: 4px;
            font-size: 12px;
            line-height: 16px;
            font-weight: 500;
            letter-spacing: 0.4px;

            //&--red {
            //    color: #D00E0E;
            //    background: rgba(208, 14, 14, 0.05);
            //}
            &--green {
                color: #006616;
                background: rgba(0, 146, 32, 0.05);
            }
            &--blue {
                color: #0056A5;
                background: rgba(0, 232, 204, 0.05);
            }

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        &__time {
            position: absolute;
            top: 4px;
            right: 20px;
            font-size: 10px;
            line-height: 17px;
            letter-spacing: 0.4px;
            color: #0046AF;

            &--unreaded {
                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 5px;
                    right: -15px;
                    width: 8px;
                    height: 8px;
                    background: #0046AF;
                    border-radius: 50%;
                }
            }
        }
    }

    .v-list-item.last-item {
        min-height: 1px;
        height: 1px;
    }
}
</style>
