<template>
    <v-dialog
        v-model="dialog"
        persistent
        width="770"
        @keydown.esc="$emit('onClose')"
        @click:outside="$emit('onClose')"
        class="modal">
        <div class="dialog-overflow-wrap">
            <v-btn class="pci-ad-cl-btn" icon @click="$emit('onClose')">
                <v-img contain height="36" max-width="36"
                       :src="require('@/assets/icons/ic-close-modal.svg')"/>
            </v-btn>
        </div>

        <div class="renders-sliders py-7 px-8 white">
            <div class="pos-rel d-flex align-center justify-center mb-7">
                <button
                    class="slider-controls d-flex align-center justify-center flex-shrink-0 mr-4"
                    ref="slider_prev">
                    <img src="@/assets/_redesigne/icons/ic-arrow-previous.svg" alt=""/>
                </button>
                <div class="renders-image-wrapper swiper" ref="render_image_wrapper">
                    <div class="swiper-wrapper">
                        <div
                            class="swiper-slide"
                            v-for="(render, index) of renders"
                            :key="render.id">
                            <drawable-image
                                v-if="getActiveSlideIndex === index"
                                :class="{ editable: false }"
                                :ref="'drawable-image-' + render.id"
                                :key="'drawable-image-' + render.id"
                                :mode="null"
                                :file="render"
                                :allow-replay-comment="false"
                                :allow-edit-comment="false"
                                :allow-change-stone="false"
                                :allow-change-metal="false"
                                :initial-data="null"
                                :atachments="[]"
                                :background-image="render.url"/>
                        </div>
                    </div>
                </div>
                <button
                    class="slider-controls d-flex align-center justify-center flex-shrink-0 ml-4"
                    ref="slider_next">
                    <img src="@/assets/_redesigne/icons/ic-arrow-next.svg" alt=""/>
                </button>
            </div>

            <div class="carousel mx-auto overflow-hidden" ref="carousel">
                <div class="swiper-wrapper">
                    <div
                        class="swiper-slide pos-rel d-flex align-center justify-center cursor-pointer overflow-hidden"
                        v-for="render of renders"
                        :key="render.id">
                        <img :src="render.url" alt="" class="d-block w-full object-cover" width="88"
                             height="80">
                    </div>
                </div>
            </div>
        </div>
    </v-dialog>
</template>

<script>
    import Swiper, { EffectFade } from "swiper";
    Swiper.use([EffectFade]);
    import DrawableImage from "@/components/DrawableImage";

    export default {
        name: "configurator-demo-renders-modal",
        components: {
            DrawableImage
        },
        props: {
            renders: {
                type: Array,
                default: () => []
            },
            activeSlideIdx: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {
                dialog: true,
                items: [],
                carousel: null,
                previewSlider: null
            }
        },
        computed: {
            getActiveSlideIndex() {
                return this.previewSlider ? this.previewSlider.activeIndex : 0;
            }
        },
        mounted() {
            this.initCarousel();
            this.$nextTick(() => {
                this.slideTo(this.$props.activeSlideIdx);
            });
        },
        beforeDestroy() {
            this.carousel.destroy();
            this.previewSlider.destroy();
        },
        methods: {
            initCarousel() {
                const swiper = new Swiper(this.$refs.carousel, {
                    speed: 400,
                    spaceBetween: 10,
                    slidesPerView: 6,
                    width: 582,
                    resizeObserver: true,
                    watchSlidesVisibility: true,
                    watchSlidesProgress: true,
                    followFinger: false,
                    longSwipes: false,
                    shortSwipes: false,
                    allowTouchMove: false,
                    observer: true
                });

                const preview = new Swiper(this.$refs.render_image_wrapper, {
                    observer: true,
                    followFinger: false,
                    allowTouchMove: false,
                    effect: "fade",
                    fadeEffect: { crossFade: true },
                    navigation: {
                        nextEl: this.$refs.slider_next,
                        prevEl: this.$refs.slider_prev
                    },
                    thumbs: {
                        swiper: swiper
                    }
                });

                this.carousel = swiper;
                this.previewSlider = preview;
            },
            slideTo(index, speed = 200) {
                this.previewSlider.slideTo(index, speed);
            }
        }
    }
</script>

<style lang="scss">
.renders-sliders {
    .renders-image-wrapper {
        width: 582px;
        height: 530px;
    }

    .slider-controls {
        width: 32px;
        height: 32px;
        border: 1px solid #888888;
        border-radius: 50%;
        color: #464646;
    }

    .draw-wrapper,
    .draw-wrapper canvas {
        border-radius: 5px;
    }

    .carousel {
        width: 582px;
        .swiper-slide {
            img {
                border-radius: 5px;
            }

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgba(255, 255, 255, .5);
                pointer-events: none;
            }

            &.swiper-slide-thumb-active {
                img {
                    border-radius: 6px;
                }
                &::before {
                    border: 2px solid #9FF4A9;
                    background-color: transparent;
                    border-radius: 5px;
                }
            }
        }
    }
}
</style>
