<template>
    <div class="contacts-page">
        <validation-observer v-slot="{ handleSubmit }" tag="div" ref="form" class="contacts-page__col contacts-page__left-col">
            <v-form @submit.prevent="handleSubmit(submitHandler)" class="contacts-page__form">
                <h1>Contact UNCO</h1>

                <validation-provider class="d-block mb-6" mode="eager" tag="label" name="Name"
                                     rules="required" v-slot="{ errors }">
                    <div class="contacts-page__input-label mb-2">Name</div>
                    <v-text-field
                        v-model="userData.name"
                        outlined
                        hide-details
                        class="contacts-page__input"
                        placeholder="John Frances"/>
                    <div class="contacts-page__input-error error--text mt-1">{{ errors[0] }}</div>
                </validation-provider>

                <validation-provider class="d-block mb-6" mode="eager" tag="label" name="Email"
                                     rules="email|required" v-slot="{ errors }">
                    <div class="contacts-page__input-label mb-2">Email</div>
                    <v-text-field
                        v-model="userData.email"
                        type="email"
                        outlined
                        hide-details
                        class="contacts-page__input"
                        placeholder="John Frances"/>
                    <div class="contacts-page__input-error error--text mt-1">{{ errors[0] }}</div>
                </validation-provider>

                <validation-provider class="d-block mb-6" mode="eager" tag="label" name="Company"
                                     v-slot="{ errors }">
                    <div class="contacts-page__input-label mb-2">Company</div>
                    <v-text-field
                        v-model="userData.company"
                        outlined
                        hide-details
                        class="contacts-page__input"
                        placeholder="Company TM"/>
                    <div class="contacts-page__input-error error--text mt-1">{{ errors[0] }}</div>
                </validation-provider>

                <validation-provider class="d-block mb-12" mode="eager" tag="label" name="Message"
                                     rules="required" v-slot="{ errors }">
                    <div class="contacts-page__input-label mb-2">Message or question</div>
                    <v-textarea
                        v-model="userData.message"
                        outlined
                        no-resize
                        hide-details
                        class="contacts-page__input"
                        placeholder="Leave us a message..."/>
                    <div class="contacts-page__input-error error--text mt-1">{{ errors[0] }}</div>
                </validation-provider>

                <div class="text-center">
                    <v-btn
                        depressed
                        :disabled="!userData.message.trim().length"
                        width="182"
                        height="52"
                        :loading="loading"
                        type="submit">
                        Submit
                    </v-btn>
                </div>

                <p v-if="showSuccessMessage" class="contacts-page__success-message mt-7 mb-0">*Your message was sent successfully</p>

                <p class="contacts-page__mob-info mt-16 text-center">Please leave a message and we will get back to you as soon as possible</p>
                <p class="contacts-page__mob-info text-center">Or reach us directly at: <br> <a href="mailto:info@hellounco.com">info@hellounco.com</a></p>
            </v-form>
        </validation-observer>
        <div class="contacts-page__col contacts-page__right-col overflow-hidden">
            <div>
                <p>Please leave a message and we will get back to you as soon as possible</p>
                <p>Or reach us directly at: <br> <a href="mailto:info@hellounco.com">info@hellounco.com</a></p>
            </div>
            <img :src="require('@/assets/contact-us/circles.svg')" alt="">
        </div>
    </div>
</template>

<script>
    import { ValidationObserver, ValidationProvider } from "vee-validate";
    import { extend } from "vee-validate";
    import { email, required } from "vee-validate/dist/rules";
    import { userApi } from "@/api";

    extend("email", {
        ...email,
        message: "{_field_} is not a valid email address"
    });

    extend("required", {
        ...required,
        message: "{_field_} cannot be blank."
    });

    export default {
        name: "contact-us",
        components: {
            ValidationObserver,
            ValidationProvider
        },
        data() {
            return {
                userData: {
                    name: "",
                    email: "",
                    company: "",
                    message: ""
                },
                loading: false,
                showSuccessMessage: false
            }
        },
        methods: {
            async submitHandler() {
                this.loading = true;

                await userApi.contactUs(this.userData);

                this.userData = {
                    name: "",
                    email: "",
                    company: "",
                    message: ""
                };

                this.$nextTick(() => {
                    this.$refs.form.reset();
                });

                this.showSuccessMessage = true;
                this.loading = false;
            }
        }
    }
</script>

<style scoped lang="scss" src="./style.scss"></style>
