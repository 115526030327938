<template>
    <div class="home-layout">
        <application-header/>
        <main class="page-wrapper">
            <router-view/>
        </main>
        <application-footer/>
    </div>
</template>

<script>
    import ApplicationHeader from "@/components/HomePageComponents/ApplicationHeader";
    import ApplicationFooter from "@/components/HomePageComponents/ApplicationFooter";

    export default {
        name: "application-wrapper",
        components: {
            "application-header": ApplicationHeader,
            "application-footer": ApplicationFooter
        }
    };
</script>

<style>
    @import "../assets/fonts/FuturaPT/index.scss";
    .home-layout {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: normal;
        justify-content: space-between;
    }
    .page-wrapper {
        height: 100%;
    }
</style>
