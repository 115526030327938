<template>
    <v-dialog
        v-model="value"
        class="modal"
        max-width="700"
        persistent>
        <div class="dialog-overflow-wrap">
            <v-btn
                class="pci-ad-cl-btn"
                icon
                @click="closeDialog">
                <v-img
                    :src="require('@/assets/icons/ic-close-modal.svg')"
                    contain
                    height="36"
                    max-width="36"/>
            </v-btn>
            <v-card class="pci-accept-dialog pa-5 pa-sm-10">
                <div class="pci-ad-head-sect flex-wrap">
                    <div class="d-flex justify-space-between align-items-center mb-5 w-100">
                        <div class="pci-ad-ttl pt-0">
                            Cancel this project?
                        </div>

                        <v-card-subtitle
                            class="pa-0 ma-0 blueish"
                            v-if="designer">
                            <span class="font-weight-bold">
                                Current Modeler:
                            </span>
                            {{ fullName }}
                        </v-card-subtitle>
                    </div>
                    <div class="pci-ad-desc">
                        Cancelling this project will erase any progress made by the client and will delete
                        any uploads made by both modeler and client.
                    </div>
                    <v-card
                        class="project-info w-100 pa-3 mt-3 rounded-md"
                        color="#F8F8F8"
                        outlined>
                        <p class="mb-1">
                            PROJECT NAME: {{ project.title }}
                        </p>
                        <p class="mb-1">
                            STATUS:
                            <span
                                :class="$store.getters['getClassProjectStatus'](project.status)"
                                class="project-status oh-status">
                                {{ project.status }}
                            </span>
                        </p>
                        <p class="mb-1" v-if="project.estimated_date">
                            DUE DATE: {{ dueDate }}
                        </p>
                        <p class="mb-1">
                            CREATED: {{ createDate }} ({{ project.user }})
                        </p>
                        <p class="mb-1">
                            PROJECT TYPE: {{ project.type }}
                        </p>
                        <p class="mb-1">
                            PROJECT ID: {{ project.id }}
                        </p>
                        <p class="mb-1">
                            PROJECT PRICE: {{ project.designer_amount }}
                        </p>
                        <p class="mb-0">
                            PROJECT PAID: {{ project.id }}
                        </p>
                    </v-card>
                    <div
                        v-if="project.payment_status === 1"
                        class="card-footer">
                        <v-checkbox
                            v-model="checkbox"
                            color="success"
                            :label="'Refund Client'"/>
                        <p>
                            Total amount <span class="input-wrapper">
                                <input
                                    class="input-amount"
                                    type="number"
                                    v-model="amount">
                            </span>
                        </p>
                    </div>
                </div>

                <v-card-actions class="pa-0 mt-10 justify-space-between">
                    <div v-if="$route.path !== `/project/${project.id}`">
                        <router-link
                            :to="`/project/${project.id}`"
                            class="def-blue-link">
                            View Full Project
                        </router-link>
                    </div>

                    <v-btn
                        class="btn-dark-red bg-fully btn-h-48 px-5 ml-auto"
                        :class="{'disabled' : amount > project.designer_amount || amount === 0 || amount === '' || removeLoading}"
                        elevation="0"
                        outlined
                        rounded
                        :loading="removeLoading"
                        :disabled="amount > project.designer_amount || amount === 0 || amount === '' || removeLoading"
                        @click="removeHandler">
                        Cancel Project
                    </v-btn>
                </v-card-actions>
            </v-card>
        </div>
    </v-dialog>
</template>

<script>
    import moment from "moment";

    export default {
        name: "dialog-cancel-project-manager",
        props: {
            value: {
                type: Boolean,
                default: false
            },
            project: {
                type: Object,
                required: true
            }
        },
        data () {
            return {
                removeLoading: false,
                checkbox: true,
                amount: 0
            };
        },
        computed: {
            designer () {
                return null;
            },
            dueDate () {
                return this.project.estimated_date ?
                    moment(this.project.estimated_date).format("MMM. DD, YYYY") :
                    moment().add( 2, "days").format("MMM. DD, YYYY");
            },
            createDate () {
                return moment(this.project.created_at).format("MMM. DD, YYYY");
            }
        },
        methods: {
            closeDialog () {
                this.$emit("input", false);
            },
            removeHandler () {
                this.removeLoading = true;
                const PROJECT_ID = this.project.id
                if (this.project.payment_status === 0) {
                    this.$store.dispatch("cancelProject", PROJECT_ID).then(() => {
                        this.closeDialog();
                        this.afterAction();
                    }).catch(errors => {
                        console.warn("RemoveHandler::errors", errors);
                    }).finally(() => {
                        this.removeLoading = false;
                    });
                } else {
                    this.$store.dispatch("cancelPayedProject", {
                        id: PROJECT_ID,
                        amount: this.amount
                    }).then(() => {
                        this.closeDialog();
                        this.afterAction();
                    }).catch(errors => {
                        console.warn("RemoveHandler::errors", errors);
                    }).finally(() => {
                        this.removeLoading = false;
                    });
                }

            },
            afterAction () {
                if (this.$route.path === "/dashboard") {
                    this.$emit("update-projects");
                } else {
                    this.$router.push("/dashboard");
                }
            }
        },
        created () {
            console.warn("project", this.project);
        }
    };
</script>

<style lang="scss" scoped>
.input-wrapper {
    position: relative;
    margin: 0 0 0 26px;
    &:before {
        position: absolute;
        content: "$";
        top: 50%;
        left: 13px;
        transform: translateY(-50%);
        color: #A7A7A7;
    }
}
.input-amount {
    padding: 0 0 0 27px;
    max-width: 134px;
    border: 1px solid #CBD2D0;
    border-radius: 3px;
    height: 37px;
    -moz-appearance: textfield;
}
.card-footer {
    width: 100%;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.disabled {
    background: #E0E0E0 !important;
    border: 3px solid #E0E0E0;
}
::v-deep .theme--light.v-btn.v-btn--disabled{
    color: #FFFFFF !important;
}
</style>
