<template>
    <v-container fluid class="edit-manager py-12 pb-12 px-6">
        <loader v-if="loading"/>
        <template v-else>
            <validation-observer ref="form" v-slot="{handleSubmit}">
                <form @submit.prevent="handleSubmit(onSubmit)">
                    <div class="edit-manager-header">
                        <h2 class="page-title">Edit task</h2>
                        <v-spacer/>
                        <v-btn rounded depressed color="transparent" height="38"
                               :to="{name: 'project', params: {id: $route.params.id}}"
                               class="green-btn px-8" exact>
                            Cancel
                        </v-btn>
                        <v-btn type="submit" :disabled="isSubmitting" :loading="isSubmitting" rounded
                               depressed color="#9FF4A9"
                               height="38"
                               class="green-btn px-8 ml-2">
                            Save
                        </v-btn>
                    </div>
                    <v-row>
                        <v-col cols="12" md="6" class="pr-md-8">
                            <div class="editing-block">
                                <div class="block-title">
                                    General information
                                </div>
                                <v-row>
                                    <v-col cols="8" class="py-0">
                                        <div class="form-group">
                                            <span class="label">Name</span>
                                            <validation-provider name="Name" vid="title" v-slot="{errors}" rules="required">
                                                <v-text-field v-model="project.title" outlined flat placeholder="Name"
                                                              height="36"
                                                              class=" edit-input" :error-messages="errors"/>
                                            </validation-provider>
                                        </div>
                                    </v-col>
                                    <v-col cols="4" class="py-0">
                                        <div class="form-group d-flex flex-column">
                                            <span class="label" style="margin-bottom: 8px">Deadline</span>
                                            <v-menu :close-on-content-click="false" content-class="date-picker-menu"
                                                    offset-y>
                                                <template #activator="{attrs, on}">
                                                    <v-btn v-bind="attrs" v-on="on" depressed class="bordered date-picker-btn"
                                                           height="36"
                                                           outlined>
                                                        {{ $moment(deadlineDate).format('MM/DD/YYYY') }}
                                                        <img
                                                            :src="require('../../../assets/icons/ic-calendar.svg')"
                                                            alt="">
                                                    </v-btn>
                                                </template>
                                                <v-date-picker color="#3F7359" elevation="0" flat v-model="deadlineDate"
                                                               :weekday-format="getDay"
                                                               show-adjacent-months
                                                               no-title
                                                               first-day-of-week="1"/>
                                            </v-menu>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" class="py-0">
                                        <div class="form-group">
                                            <span class="label">SKU</span>
                                            <validation-provider
                                                v-slot="{ errors }"
                                                mode="eager"
                                                name="SKU"
                                                vid="sku"
                                                rules="sku-validation|min:3|max:20|required"
                                                tag="label">
                                                <v-text-field
                                                    ref="change_sku_input"
                                                    v-model.trim="project.sku"
                                                    flat
                                                    outlined
                                                    height="36"
                                                    class="edit-input"
                                                    :error-messages="errors"
                                                    placeholder="SKU"/>
                                            </validation-provider>
                                        </div>
                                    </v-col>

                                    <v-col cols="12" class="py-0 mb-7">
                                        <div class="form-group">
                                            <span class="label">Description</span>
                                            <tiptap-vuetify :card-props="{ flat: true, class: 'bordered' }" placeholder="Description" v-model="project.description" :extensions="extensions"/>
                                        </div>
                                    </v-col>

                                    <v-col cols="12" class="py-0">
                                        <div class="form-group">
                                            <span class="label">Render Description</span>
                                            <tiptap-vuetify :card-props="{ flat: true, class: 'bordered' }" placeholder="Render Description" v-model="project.render_description" :extensions="extensions"/>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row class="mt-8">
                                    <v-col cols="12" class="py-0">
                                        <div class="form-group">
                                            <span class="label">Extra references for client</span>
                                            <small-product-slider id="edit-slider" :items="projectFiles"/>
                                            <v-btn
                                                rounded
                                                depressed
                                                color="#D9FBDD"
                                                width="117" height="38"
                                                class="green-btn mt-4"
                                                @click="addReferences">
                                                Upload
                                            </v-btn>
                                        </div>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-col>
                        <v-col cols="12" md="6" class="pl-md-8">
                            <div class="editing-block">
                                <div class="block-title">
                                    Assignee information
                                </div>
                                <v-row>
                                    <v-col cols="6" class="py-0">
                                        <div class="form-group">
                                            <span class="label">Designer</span>
                                            <v-text-field disabled v-model="project.designer_name" outlined flat
                                                          placeholder="Designer"
                                                          height="34"
                                                          class="edit-input"/>
                                        </div>
                                    </v-col>
                                    <v-col cols="6" class="py-0">
                                        <div class="form-group">
                                            <span class="label">Client</span>
                                            <validation-provider name="client" vid="client" v-slot="{errors}" rules="required">
                                                <v-autocomplete v-model="project.user_id"
                                                                item-value="id" :items="clientList"
                                                                outlined
                                                                hide-no-data
                                                                no-filter
                                                                :search-input.sync="clientSearch"
                                                                :error-messages="errors"
                                                                flat
                                                                placeholder="Client"
                                                                height="34"
                                                                :menu-props="{
                                                                    contentClass: 'assignee-autocomplete',
                                                                    maxHeight: 220
                                                                }"
                                                                class="edit-input">
                                                    <template v-slot:selection="{item}">
                                                        {{ getFullName(item).trim() }}
                                                    </template>
                                                    <template v-slot:item="{item}">
                                                        {{ getFullName(item) }}
                                                    </template>
                                                    <template v-slot:append-item>
                                                        <div v-intersect="infiniteScrollClient" v-if="clients.pagination.total_pages >= 0">
                                                            <div class="pa-2 d-flex align-center justify-center" v-if="loadingClient">
                                                                <v-progress-circular indeterminate size="18" width="2"/>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </v-autocomplete>
                                            </validation-provider>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6" class="py-0">
                                        <div class="form-group">
                                            <span class="label">Designer compensation</span>
                                            <number-input
                                                v-model="project.designer_amount"
                                                type="currency"
                                                :precision="0"/>
                                        </div>
                                    </v-col>
                                    <v-col cols="6" class="py-0">
                                        <div class="form-group">
                                            <span class="label">Extra designer compensation</span>
                                            <number-input
                                                v-model="project.extra_designer_amount"
                                                type="currency"
                                                :precision="0"/>
                                        </div>
                                    </v-col>
                                </v-row>
                            </div>
                            <div class="editing-block d-flex justify-center align-center mt-8" v-if="project.substatus_id === $config.project.subStatus.waiting_for_render">
                                <div class="text-center main-text--text text-body-2">
                                    Designer pay-out status: <span class="pay-status" :class="[`pay-status--${paymentStatusDesigner.className}`]">{{ paymentStatusDesigner.text }}</span>
                                </div>
                                <v-btn v-if="paymentStatusDesigner.showResetBtn" :loading="payoutLoading" @click="manualUpdatePayment" outlined
                                       rounded color="main-text"
                                       height="24"
                                       class="px-2 bg-light-gray ml-2">
                                    Restart
                                    <template #loader>
                                        <v-progress-circular size="14" width="1" indeterminate/>
                                    </template>
                                </v-btn>
                            </div>
                            <refund-edit v-if="project.can_refund" class="mt-8" :project-id="project.id" :price="project.client_amount"
                                         @successUpdate="onRefundSuccess"/>

                            <extra-charge-edit-form v-if="canExtraCharge" class="mt-8" :project="project" @updateProject="updateProject"/>
                            <div class="editing-block mt-8">
                                <div class="block-title">
                                    Task specifications
                                </div>
                                <v-row>
                                    <v-col cols="6" class="py-0">
                                        <div class="form-group">
                                            <span class="label">Overall stone count</span>
                                            <validation-provider name="overall stone count" vid="overall_stone_count" v-slot="{errors}" rules="required|min_value:0|max_value:999">
                                                <v-text-field v-model="project.overall_stone_count" type="number"
                                                              @keyup="validateNumber($event, 'overall_stone_count')"
                                                              @keydown="validateNumber($event, 'overall_stone_count')"
                                                              @keypress="validateNumber($event, 'overall_stone_count')"
                                                              @blur="validateNumber($event, 'overall_stone_count')"
                                                              outlined flat
                                                              placeholder="Type number 0-999"
                                                              height="34"
                                                              class=" edit-input" :error-messages="errors"/>
                                            </validation-provider>
                                        </div>
                                    </v-col>
                                    <v-col cols="6" class="py-0">
                                        <div class="form-group">
                                            <span class="label">Unique stone count</span>
                                            <validation-provider name="unique stone count" vid="unique_stone_count" v-slot="{errors}" rules="required|min_value:0|max_value:999">
                                                <v-text-field type="number" v-model="project.unique_stone_count"
                                                              @keyup="validateNumber($event, 'unique_stone_count')"
                                                              @keydown="validateNumber($event, 'unique_stone_count')"
                                                              @keypress="validateNumber($event, 'unique_stone_count')"
                                                              @blur="validateNumber($event, 'unique_stone_count')"
                                                              outlined flat
                                                              placeholder="Type number 0-999"
                                                              height="34"
                                                              class="edit-input" :error-messages="errors"/>
                                            </validation-provider>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" class="py-0">
                                        <div class="form-group">
                                            <span class="label">Dimensions</span>
                                            <validation-provider name="dimensions" vid="text" v-slot="{errors}">
                                                <v-textarea v-model="project.text"
                                                            outlined flat no-resize
                                                            height="112"
                                                            placeholder="Dimensions"
                                                            class=" edit-input" :error-messages="errors"/>
                                            </validation-provider>
                                        </div>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-col>
                    </v-row>
                </form>
            </validation-observer>
        </template>
        <file-upload-modal
            :upload-modal-opened="uploadModalOpened"
            :mode="fileUploadMode"
            :accept="fileUploadTypes"
            :type-image="'any'"
            @open="uploadModalOpened = true"
            @close="uploadModalOpened = false"
            @upload="bindFileToProject"/>
        <v-snackbar
            v-model="snackbar"
            :timeout="2000"
            class="project-title__snackbar">
            Success! Project updated.
        </v-snackbar>
        <v-snackbar
            v-model="snackbarRefund"
            :timeout="2000"
            class="project-title__snackbar">
            Refund successful!
        </v-snackbar>
    </v-container>
</template>

<script>
    import NumberInput from "@/components/ui/number-input";
    import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
    import {
        Bold,
        BulletList, HardBreak,
        Heading,
        Italic,
        ListItem, OrderedList,
        Paragraph,
        Strike,
        TiptapVuetify,
        Underline
    } from "tiptap-vuetify";
    import Loader from "@/components/ui/Loader";
    import SmallProductSlider from "@/components/small-product-slider";
    import RefundEdit from "@/components/RefundEdit";
    import ExtraChargeEditForm from "@/components/ExtraCharge/ExtraChargeEditForm";
    import { managerApi, paymentApi, projectApi } from "@/api";
    import UploadService from "@/api/uploadFilesService.api";
    import FileUploadModal from "@/components/FileUploadModal";
    import { max_value, min_value, required } from "vee-validate/dist/rules";
    import { mapGetters } from "vuex";

    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    extend("required", required);
    extend("max_value", max_value);
    extend("min_value", min_value);


    export default {
        name: "manager-edit-project",
        components: {
            RefundEdit,
            ExtraChargeEditForm,
            SmallProductSlider,
            ValidationObserver,
            ValidationProvider,
            TiptapVuetify,
            NumberInput,
            Loader,
            "file-upload-modal": FileUploadModal },
        data() {
            return {
                payoutLoading: false,
                isSubmitting: false,
                snackbar: false,
                snackbarRefund: false,
                issueRefundOpen: false,
                issueRefundType: "money",
                refund: null,
                fileUploadMode: "add",
                fileUploadTypes: ".jpg, .jpeg, .png",
                uploadModalOpened: false,
                loading: true,
                extensions: [
                    [Heading, {
                        options: {
                            levels: [1, 2, 3]
                        }
                    }],
                    Paragraph,
                    Bold,
                    Strike,
                    Italic,
                    Underline,
                    ListItem,
                    BulletList,
                    OrderedList,
                    HardBreak
                ],
                project: {},
                loadingClient: false,
                clients: {
                    list: [],
                    pagination: {
                        "total": 1,
                        "count": 0,
                        "per_page": 0,
                        "current_page": 0,
                        "total_pages": 1,
                        "links": {
                            "previous": null,
                            "next": "/manager/clients"
                        }
                    }
                },
                currentClientId: 0,
                clientSearch: null,
                clientDebounce: null
            }
        },
        watch: {
            clientSearch() {
                clearTimeout(this.clientDebounce)
                this.clientDebounce = setTimeout(async () => {
                    this.clients.pagination.current_page = 0
                    this.clients.list = []
                    await this.getClients(true)
                }, 300)

            },
            async "project.user_id"(val) {
                if(val) return
                this.clientSearch = null
            }
        },
        computed: {
            ...mapGetters([
                "isManager", "isAdmin"
            ]),
            getFullName() {
                return item => {
                    const firstName = item.first_name || ""
                    const lastName = item.last_name || ""
                    return `${firstName} ${lastName}`
                }
            },
            clientList() {
                const currentClient = { id: this.projectOriginal.user_id, first_name: this.projectOriginal.user }
                const current = this.clients.list.find(item => item.id === this.projectOriginal.user_id)
                let clients = []
                if(current){
                    clients = [current, ...this.clients.list.filter(item => item.id !== this.projectOriginal.user_id)]
                } else {
                    clients = [currentClient, ...this.clients.list]
                }
                return clients
            },
            projectFiles(){
                return this.project.files ? this.project.files.filter(file => {
                    const fileAttachedToDesigners = file.props && file.props[0] && file.props[0].attachToDesigner;
                    return !fileAttachedToDesigners
                }) : []
            },
            projectOriginal() {
                return this.$store.getters.getProjectData;
            },
            canExtraCharge() {
                const { draft, completed, pricing_needed, pricing_pending } = this.$config.project.status;
                return ![draft, completed, pricing_needed, pricing_pending].includes(this.project.status_id);
            },

            deadlineDate: {
                get() {
                    return this.$moment(this.project.estimated_date || new Date()).format("YYYY-MM-DD")
                },
                set(val) {
                    this.project.estimated_date = this.$moment(val).format()
                }
            },

            paymentStatusDesigner() {
                const paymentsLabels = {
                    awaiting: {
                        showResetBtn: false,
                        text: "Awaiting for payment",
                        className: "in_progress"
                    },
                    processing: {
                        showResetBtn: false,
                        text: "In progress",
                        className: "in_progress"
                    },
                    success: {
                        showResetBtn: false,
                        text: "Completed",
                        className: "complete"
                    },
                    failure: {
                        showResetBtn: true,
                        text: "Failed",
                        className: "failed"
                    }
                }
                if(this.project.payout) {
                    switch (this.project.payout.status_id) {
                        case this.$config.project.paymentStatus.awaiting:
                            return paymentsLabels.awaiting;
                        case this.$config.project.paymentStatus.processing:
                            return paymentsLabels.processing;
                        case this.$config.project.paymentStatus.success:
                            return paymentsLabels.success;
                        case this.$config.project.paymentStatus.failure:
                        default:
                            return paymentsLabels.failure;
                    }
                } else {
                    return paymentsLabels.failure
                }

            }
        },
        methods: {
            async onSubmit() {
                this.isSubmitting = true
                const isValidEstimatedDate = this.$moment(this.project.estimated_date).isValid()
                const data = {
                    title: this.project.title,
                    estimated_date: isValidEstimatedDate ? this.$moment(this.project.estimated_date).format("MM/DD/YYYY") : null,
                    description: this.project.description,
                    render_description: this.project.render_description,
                    sku: this.project.sku,
                    text: this.project.text,
                    overall_stone_count: this.project.overall_stone_count,
                    unique_stone_count: this.project.unique_stone_count,
                    designer_amount: this.project.designer_amount,
                    extra_designer_amount: this.project.extra_designer_amount,
                    user_id: this.project.user_id
                }

                try{
                    const response = await projectApi.editProjectByAdmin(this.$route.params.id, data);
                    this.updateProject(response.data.data)
                    this.snackbar = true
                }
                catch (e) {
                    this.$refs.form.setErrors(e.response.data.errors)
                } finally {
                    this.isSubmitting = false
                }

            },
            updateProject(project) {
                this.$store.commit("updateProjectData", Object.assign(this.projectOriginal, project));
                this.project = JSON.parse(JSON.stringify(this.projectOriginal)) ;
            },
            async manualUpdatePayment() {
                this.payoutLoading = true
                try {
                    await paymentApi.manualUpdatePayment(this.$route.params.id)
                    this.updateProject( {
                        payout : {
                            status_id: this.$config.project.paymentStatus.processing
                        }
                    })
                }
                catch (e) {
                    console.error(e)
                } finally {
                    this.payoutLoading = false
                }

            },
            onRefundSuccess(project) {
                this.snackbarRefund = true
                this.updateProject(project)
                this.project.can_refund = false
            },
            addReferences(){
                this.fileUploadTypes= [
                    ...this.$config.allowedFileExtension.images,
                    ...this.$config.allowedFileExtension.models,
                    ...this.$config.allowedFileExtension.others
                ].join(", ")
                this.fileUploadMode = "add";
                this.uploadModalOpened = true;
            },
            async bindFileToProject(file){
                const response = await projectApi.bindFile(this.$route.params.id, { files:[file.id] });
                await UploadService.updateProps(file.id, [{ attachToDesigner: false }]);
                if(response) {
                    this.project.files.push(file)
                    this.fileUploadMode = "add";
                    this.uploadModalOpened = false;
                }
            },
            getDay(date){
                const idx = new Date(date).getDay(date)
                return daysOfWeek[idx]
            },
            validateNumber($event, key) {
                if ($event.target.value.toString().charAt(0) === "0" && $event.target.value.toString().length > 1) {
                    $event.target.value = $event.target.value.substring(1, 7);
                }

                if (/[^0-9]/g.test($event.target.value)) {
                    $event.target.value = $event.target.value.replace(/[^0-9]/g, "").substring(0, 7);
                }

                if (parseInt($event.target.value) > 999) {
                    $event.target.value = 999;
                }

                $event.target.value = $event.target.value.toString().substring(0, 7);
                this.project[key] = $event.target.value !== "" ? parseInt($event.target.value, 10) : "";
            },
            async getProject()  {
                try {
                    if (this.$route?.params?.id) {
                        this.$store.commit("setProjectId", parseInt(this.$route?.params?.id));
                        await this.$store.dispatch("loadProject");
                        this.project = JSON.parse(JSON.stringify(this.projectOriginal)) ;
                    }
                } catch (e) {
                    console.log(e)
                } finally {
                    this.loading = false
                }
            },
            async getClients(replace) {
                if(this.clients.pagination.current_page + 1 > this.clients.pagination.total_pages) return
                this.loadingClient = true
                try{
                    const response = await managerApi.getClientsList({
                        keywords: this.clientSearch,
                        page: this.clients.pagination.current_page + 1
                    })
                    let data = response.data.data

                    this.clients.list = replace ? data :  [...this.clients.list, ...data]
                    this.clients.pagination = response.data.meta.pagination
                }catch (e) {
                    console.error(e)
                } finally {
                    this.loadingClient = false
                }
            },
            async infiniteScrollClient(entries, observer, isIntersecting) {
                if(!isIntersecting || this.loadingClient) return
                await this.getClients()
            }
        },
        mounted() {
            if(!this.isManager && !this.isAdmin) {
                this.$router.replace({ name: "not-found" })
                return
            }

            this.getProject();
        }
    }
</script>

<style scoped lang="scss">
.pay-status {
    &--complete{
        color: #3F7359;
    }
    &--in_progress{
        color: #FCC537;
    }
    &--failed{
        color: #D00E0E;
    }
}

.green-btn{
    border: 1px solid #464646 !important;
   ::v-deep .v-btn__content{
        font-size: 14px !important;
    }
}

.page-title{
    font-size: 36px;
    color: #464646;
}

.edit-manager-header{
    margin-bottom: 30px;
    display: flex;
    align-items: center;
}
.edit-manager{
    max-width: 1158px;
}

.editing-block{
    +.editing-block{
        margin-top: 40px;
    }
    .block-title{
        font-size: 18px;
        color: #464646;
        margin-bottom: 16px;
    }

   .label{
        font-size: 14px;
        color: #464646;
        margin-bottom: 7px;
        display: inline-block;
    }
}

::v-deep .bordered{
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
    border: 1px solid #464646 !important;
}



::v-deep .edit-input{
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
    &--white{
        fieldset {
            background: #ffffff;
        }
    }
    fieldset{
        border: 1px solid #464646 !important;
    }
    textarea{
        line-height: 1.2;
    }
    .v-input__slot{
        min-height: auto;
        font-size: 12px;
    }
    &.v-text-field--enclosed .v-input__append-inner{
        margin-top: auto;
        margin-bottom: auto;
    }
}

::v-deep .v-btn.date-picker-btn{
    padding-left: 10px;
    padding-right: 8px;

    .v-btn__content{
        font-size: 14px;
        justify-content: space-between;
    }
}

.v-menu__content{
    &.date-picker-menu{
        box-shadow: 4px 4px 4px rgba(70, 70, 70, 0.15);
        border-radius: 0 !important;
        padding-bottom: 10px;
        background: #ffffff;
        ::v-deep .v-picker{
            border-radius: 0 !important;
        }
        ::v-deep .v-date-picker-header{
            padding: 10px 10px 0;
            .v-date-picker-header__value button{
                font-size: 14px;
            }
            .v-date-picker-header__value button:not(:hover){
                color: #464646;
            }
            .v-btn--icon{
                border: 1px solid #888888;
                width: 22px;
                height: 22px;
                .v-icon{
                    font-size: 16px;
                }
            }
        }
        ::v-deep .v-date-picker-table{
            padding: 0 10px;
            thead{
                th{
                    color: #888888;
                }
            }
            tbody{
                .v-btn{
                    &.v-btn--disabled{
                        .v-btn__content {
                            color: #BCBCBC !important;
                        }
                    }
                    &.v-btn--active{
                        .v-btn__content{
                            color: #ffffff;
                        }
                    }
                    &.v-btn--rounded{
                        border-radius: 2px;
                    }
                    .v-btn__content{
                        color: #464646;
                    }
                }
            }

            .v-date-picker-table__current{
                border: none;
                .v-btn__content{
                    font-weight: 700 !important;
                }
            }


            .v-date-picker-table__current:not(.v-btn--active){
                border: none;
                .v-btn__content{
                    color: #464646;
                }
            }
        }
    }
}

::v-deep .project-title__snackbar .v-snack__wrapper.theme--dark {
    min-width: 240px;
    box-shadow: none !important;
    background: #f2faf3;
    border-radius: 1px;
}

::v-deep .project-title__snackbar .v-snack__content {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: #009A22;
}

::v-deep .project-title__snackbar .v-snack__action {
    display: none;
}
</style>
