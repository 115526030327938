<template>
    <div>
        <div class="profile-header">
            <v-row align="center">
                <v-col>
                    <h3 class="profile-ttl" style="font-size: 24px;line-height: 27px;color: #464646; border-bottom: 1px solid #E7E7E7; padding-bottom: 8px;">Personal Information</h3>
                </v-col>
                <v-col cols="12" sm="auto">
                </v-col>
            </v-row>
        </div>
        <div class="personal-head-row-wrap mt-8">
            <v-row>
                <v-col cols="12" md="5">
                    <v-avatar v-if="user.avatar" size="112">
                        <img :alt="user.firstName" :src="user.avatar">
                    </v-avatar>
                    <div v-else class="phr-img">
                        <svg fill="none"
                             height="112"
                             viewBox="0 0 112 112"
                             width="112">
                            <circle cx="56" cy="56"
                                    fill="#F4F7F6" r="56"/>
                            <path clip-rule="evenodd"
                                  d="M42.3887 66.8893H69.6109C77.1281 66.8893 83.222 72.9832 83.222 80.5004C83.222 82.0039 82.0033 83.2227 80.4998 83.2227C79.1038 83.2227 77.9532 82.1718 77.7959 80.8179L77.7637 80.0206C77.5245 75.8924 74.219 72.5868 70.0908 72.3476L69.6109 72.3338H42.3887C37.8784 72.3338 34.222 75.9901 34.222 80.5004C34.222 82.0039 33.0033 83.2227 31.4998 83.2227C29.9964 83.2227 28.7776 82.0039 28.7776 80.5004C28.7776 73.181 34.555 67.211 41.7983 66.9019L42.3887 66.8893H69.6109H42.3887ZM56 28.7812C65.0207 28.7812 72.3333 36.0939 72.3333 45.1146C72.3333 54.1352 65.0207 61.4479 56 61.4479C46.9793 61.4479 39.6667 54.1352 39.6667 45.1146C39.6667 36.0939 46.9793 28.7812 56 28.7812ZM55.9998 34.2227C49.986 34.2227 45.1109 39.0978 45.1109 45.1115C45.1109 51.1253 49.986 56.0004 55.9998 56.0004C62.0136 56.0004 66.8887 51.1253 66.8887 45.1115C66.8887 39.0978 62.0136 34.2227 55.9998 34.2227Z"
                                  fill="#FB3805"
                                  fill-rule="evenodd"/>
                        </svg>
                    </div>
                </v-col>
                <v-col cols="12" md="7">
                    <div class="phr-cont-wrap">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <div class="user-profile-label">First Name</div>
                                <div class="user-profile-value mt-2">{{ user.firstName || noData }}</div>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <div class="user-profile-label">Last Name</div>
                                <div class="user-profile-value mt-2">{{ user.lastName || noData }}</div>
                            </v-col>
                            <v-col cols="12">
                                <div class="user-profile-label">Email</div>
                                <div class="user-profile-value mt-2">{{ user.email || noData }}</div>
                            </v-col>
                            <v-col cols="12">
                                <div class="user-profile-label">Phone</div>
                                <div class="user-profile-value mt-2">{{ user.phone || noData }}</div>
                            </v-col>
                            <v-col cols="12" sm="6"
                                   v-if="user.hasPassword">
                                <div class="user-profile-label">Password</div>
                                <div class="user-profile-value mt-2">•••••••••••••••••••••••</div>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </div>
        <div class="section-delimiter mt-4 mb-4 mt-md-4 mb-md-4"></div>
        <div class="personal-foot-row-wrap">
            <div class="d-flex align-center justify-space-between">
                <div class="user-profile-value">Last Updated: {{ user.updatedAt | moment }}</div>
                <v-btn
                    width="160"
                    height="40"
                    outlined
                    rounded
                    elevation="0"
                    class="main-text--text"
                    style="background-color: #9FF4A9"
                    @click="showEdit">
                    Edit Information
                </v-btn>
            </div>
            <div class="d-flex justify-end">
                <v-tooltip
                    v-if="isClient || isDesigner"
                    top
                    left
                    nudge-bottom="10px"
                    max-width="214"
                    :disabled="user.projectsActiveCount === 0"
                    content-class="delete-account-tooltip">
                    <template #activator="{on, attrs}">
                        <div
                            v-on="on" v-bind="attrs"
                            class="mt-4 cursor-pointer text-center text-decoration-underline text-body-2"
                            :style="{width: '160px', color: user.projectsActiveCount === 0 ? '#FB3805' : '#BCBCBC'}"
                            @click="showWarningModal">
                            Delete Account
                        </div>
                    </template>

                    <span class="font-weight-regular" style="font-size: 14px;">
                        <template v-if="isClient">Please complete any outstanding projects in order to delete account</template>
                        <template v-if="isDesigner">Please wait until all projects are fully completed on your and admin side in order to delete account</template>
                    </span>
                </v-tooltip>
            </div>
        </div>

        <v-dialog
            v-if="isClient || isDesigner"
            v-model="warningModal"
            width="620"
            content-class="delete-modal pt-16 pb-13 px-6 px-sm-12">
            <h4 class="mt-1 mb-4 text-h5 font-weight-regular text-center main-text--text">Are you sure you wish to delete you account?</h4>
            <p class="mb-9 text-center orange--text">
                <template v-if="isClient">
                    Deleting your account will delete all the media associated with it. <br>
                    All completed renders will be deleted and <strong>unrecoverable</strong>.
                </template>
                <template v-else>
                    Deleting your account will delete all the media associated with it. <br>
                    All incomplete <strong>payouts</strong> will be <strong>unrecoverable</strong>.
                </template>
            </p>
            <div class="d-flex justify-space-between">
                <v-btn
                    width="calc(50% - 12px)"
                    height="48"
                    outlined
                    rounded
                    @click="warningModal = false"
                    class="main-text--text">
                    Cancel
                </v-btn>
                <v-btn
                    width="calc(50% - 12px)"
                    height="48"
                    outlined
                    rounded
                    @click="warningModal = false; deleteModal = true"
                    class="main-text--text"
                    style="background-color: #D00E0E;">
                    Delete
                </v-btn>
            </div>
        </v-dialog>

        <v-dialog
            v-if="isClient || isDesigner"
            v-model="deleteModal"
            :persistent="deleteLoading"
            width="620"
            content-class="delete-modal pt-16 pb-13 px-6 px-sm-12 overflow-visible">
            <button class="close-modal-btn" @click="deleteModal = false">
                <img src="@/assets/icons/ic-close-modal.svg" alt="Close modal icon">
            </button>
            <h4 class="mt-1 mb-10 font-weight-regular text-center orange--text">Are you sure ? <br> This action is undoable</h4>
            <div class="d-flex justify-space-between">
                <v-btn
                    width="calc(50% - 12px)"
                    height="48"
                    outlined
                    rounded
                    :disabled="deleteLoading"
                    @click="deleteModal = false"
                    class="main-text--text">
                    Cancel
                </v-btn>
                <v-btn
                    width="calc(50% - 12px)"
                    height="48"
                    outlined
                    rounded
                    :loading="deleteLoading"
                    @click="deleteAccount"
                    class="main-text--text"
                    style="background-color: #D00E0E;">
                    Delete
                </v-btn>
            </div>
        </v-dialog>
    </div>
</template>

<script>
    import moment from "moment";
    import { mapGetters } from "vuex";
    import { userApi } from "@/api";

    export default {
        name: "personal-information-view",
        filters: {
            moment: function (date) {
                return moment(date).format("MMM. DD, YYYY");
            }
        },
        data () {
            return {
                noData: "No data",
                warningModal: false,
                deleteModal: false,
                deleteLoading: false
            };
        },
        computed: {
            ...mapGetters([
                "isClient",
                "isDesigner",
                "fallbackTokenData"
            ]),
            user () {
                return this.$store.getters.profile;
            }
        },
        methods: {
            showEdit () {
                this.$emit("onSwitchType", true);
            },
            showWarningModal() {
                if (this.user.projectsActiveCount !== 0) return;
                this.warningModal = true
            },
            async deleteAccount() {
                this.deleteLoading = true;

                try {
                    await userApi.deleteProfile();

                    if (this.fallbackTokenData) {
                        await this.$store.dispatch("logoutImpersonatingAccount");
                        return;
                    }

                    localStorage.removeItem("jwt_token");
                    await this.$router.push({ name: "home" });
                } catch (error) {
                    console.error(error);
                } finally {
                    this.deleteLoading = false;
                    this.deleteModal = false;
                }
            }
        }
    };
</script>

<style scoped lang="scss">
.delete-account-tooltip {
    background: rgba(70, 70, 70, 0.8);
    padding: 10px;
    border-radius: 10px;
    color: #ffffff;
    box-shadow: none;
}

::v-deep .delete-modal {
    border-radius: 8px;
    box-shadow: 4px 4px 4px rgba(70, 70, 70, 0.15) !important;
    background-color: #FFFFFF;

    h4 {
        font-size: 40px;
        line-height: 45px;
    }

    p {
        font-size: 18px;
        line-height: 23px;
    }

    .close-modal-btn {
        position: absolute;
        top: -18px;
        right: -18px;
        width: 36px;
        height: 36px;
    }
}
</style>
