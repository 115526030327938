<template>
    <div class="manager-assets__ref-images mt-8">
        <div class="mb-4 section-title">
            Reference Images
        </div>

        <v-sheet
            class="mx-auto mt-5"
            width="100%">
            <v-slide-group
                multiple
                show-arrows>
                <template #prev>
                    <v-btn icon>
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                </template>

                <template #next>
                    <v-btn icon>
                        <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                </template>

                <v-slide-item
                    v-for="img in reference"
                    :key="img.id">
                    <v-card
                        class="mr-5 ml-0 ref-images"
                        flat
                        outlined>
                        <div class="ref-image-wrapper">
                            <div class="ref-type">
                                {{ typeCodes[img.type] }}
                            </div>
                            <v-img
                                :src="img.url"
                                class="ref-image"
                                lazy-src="@/assets/_redesigne/icons/ic-lazy-img.svg"
                                height="75px"
                                width="105"/>
                        </div>

                        <v-sheet
                            v-if="img.comments_count"
                            class="ref-images__comment-count d-flex align-center justify-center subtitle-2 blueish"
                            height="25"
                            rounded
                            width="36">
                            <svg
                                fill="none"
                                height="16"
                                viewBox="0 0 16 16"
                                width="16">
                                <path
                                    clip-rule="evenodd"
                                    d="M12.9726 9.72931C12.9726 10.0867 12.6813 10.378 12.3239 10.378H9.72931C9.52498 10.378 9.33298 10.474 9.21039 10.6374L7.78336 12.5399L6.35633 10.6374C6.23374 10.474 6.04174 10.378 5.83742 10.378H3.24282C2.88542 10.378 2.59417 10.0867 2.59417 9.72931V3.24282C2.59417 2.88542 2.88542 2.59417 3.24282 2.59417H12.3239C12.6813 2.59417 12.9726 2.88542 12.9726 3.24282V9.72931ZM12.3239 1.29688H3.24282C2.16996 1.29688 1.29688 2.16996 1.29688 3.24282V9.72931C1.29688 10.8022 2.16996 11.6753 3.24282 11.6753H5.51309L7.26444 14.0104C7.38704 14.1738 7.57904 14.2698 7.78336 14.2698C7.98769 14.2698 8.17969 14.1738 8.30228 14.0104L10.0536 11.6753H12.3239C13.3968 11.6753 14.2698 10.8022 14.2698 9.72931V3.24282C14.2698 2.16996 13.3968 1.29688 12.3239 1.29688ZM11.0266 4.54012H4.54012C4.18206 4.54012 3.89147 4.83071 3.89147 5.18877C3.89147 5.54682 4.18206 5.83742 4.54012 5.83742H11.0266C11.3847 5.83742 11.6753 5.54682 11.6753 5.18877C11.6753 4.83071 11.3847 4.54012 11.0266 4.54012ZM4.54012 7.13471H11.0266C11.3847 7.13471 11.6753 7.42531 11.6753 7.78336C11.6753 8.14142 11.3847 8.43201 11.0266 8.43201H4.54012C4.18206 8.43201 3.89147 8.14142 3.89147 7.78336C3.89147 7.42531 4.18206 7.13471 4.54012 7.13471Z"
                                    fill="#000000"
                                    fill-rule="evenodd"/>
                            </svg>
                            {{ img.comments_count.data }}
                        </v-sheet>
                    </v-card>
                </v-slide-item>
            </v-slide-group>
        </v-sheet>
    </div>
</template>

<script>
    export default {
        props: {
            reference: {
                type: Array,
                required: true,
                default: () => []
            }
        },
        data: ()=>({
            typeCodes: {
                1: "Sketch",
                2: "Reference",
                3: "3D Model"
            }
        })
    };
</script>

<style scoped lang="scss">
    .section-title {
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        color: #464646;
    }

    .ref-images__comment-count {
        background-color: #9FF4A9 !important;
        border: 1px solid #464646;
    }

    .ref-image-wrapper {
        position: relative;

        .ref-type {
            position: absolute;
            right: -3px;
            top: -7px;
            z-index: 2;
            padding: 0 8px;
            background: #FFFFFF;
            border: 1px solid #BCBCBC;
            border-radius: 5px;
            font-size: 12px;
            line-height: 15px;
            color: #696969;
            text-transform: uppercase;
            height: 15px;
        }
    }

    .ref-images {
        padding-top: 7px;
        border: none;

        .ref-image {
            border: 1px solid #BCBCBC;
            border-radius: 5px;
        }
    }
</style>