import router from "@/router";
import jwt_decode from "jwt-decode";
import { apiDeleteCurrentToken, apiRefreshToken, apiRegisterClient, apiSetUserToken } from "@/api/authorizations.api";

const tokenName = "jwt_token";
const getUnixTimestampDate = date => moment(date).valueOf();

export default {
    state: {
        authStatus: false,
        resetStatus: {
            success: false,
            message: ""
        },
        tokenData: JSON.parse(localStorage.getItem(tokenName)) ?? null,
        fallbackTokenData: JSON.parse(localStorage.getItem("fallback_jwt_token")) ?? null
    },
    getters: {
        tokenData: state => state.tokenData,
        fallbackTokenData: state => state.fallbackTokenData,
        authStatus: state => state.authStatus,
        isLoggedIn: state => state.tokenData?.refresh_expired_at && Date.now() < getUnixTimestampDate(state.tokenData?.refresh_expired_at),
        isTokenUrlValid: state => jwt_decode(state.tokenData?.token).iss.includes(process.env.VUE_APP_BASE_API_URL),
        isAccessTokenValid: state => now => now < getUnixTimestampDate(state.tokenData?.expired_at),
        isRefreshTokenValid: state => now => now < getUnixTimestampDate(state.tokenData?.refresh_expired_at),
        resetStatus: state => state.resetStatus
    },
    mutations: {
        showResetStatus (state, payload) {
            state.resetStatus = { ...payload };
        },
        seTokenData (state, tokenData) {
            state.tokenData = { ...tokenData };
        },
        setAuthStatus (state, payload) {
            state.authStatus = payload;
        },
        logout (state) {
            state.tokenData = null;
            localStorage.removeItem(tokenName);
            if (router.currentRoute.name !== "login") { //&& process.env.VUE_APP_EMBEDDED == 0
                router.push({ name: "login" });
            }
        }
    },
    actions: {
        async setAuthStatus ({ commit }, payload) {
            await commit("setAuthStatus", payload);
        },
        async retrieveToken ({ commit, dispatch }, credentials) {
            const { data } = await apiSetUserToken(credentials);

            if ("status_code" in data) {
                dispatch("logout");
            } else {
                dispatch("setToken", data)
            }

            return data;
        },
        setToken({ commit }, payload)  {
            commit("seTokenData", payload);
            localStorage.setItem(tokenName, JSON.stringify(payload));
        },
        async refreshToken ({ commit, dispatch }) {
            const { data } = await apiRefreshToken();

            if ("status_code" in data) {
                dispatch("logout");
            } else {
                commit("seTokenData", data);
                localStorage.setItem(tokenName, JSON.stringify(data));
            }
            return data.token;
        },
        async logout ({ commit, getters, dispatch }) {
            if (getters.isTokenUrlValid) {
                await apiDeleteCurrentToken();
            }

            if (getters.fallbackTokenData) {
                dispatch("logoutImpersonatingAccount");
                return;
            }

            commit("CLEAR_PROFILE", null, { root: true });
            commit("logout");
        },
        async login ({ dispatch }, credentials) {
            const data = await dispatch("retrieveToken", credentials);
            if ("status_code" in data) {
                return data;
            } else {
                if (router.currentRoute.query.project_id) {
                    router.push({ name: "render-review", params: { id: router.currentRoute.query.project_id } });
                } else {
                    router.push("/dashboard").catch(() => {});
                }
                return data.token;
            }
        },
        async registration ({}, credentials) {
            const { data } = await apiRegisterClient(credentials);

            if ("status_code" in data) {
                return data;
            } else {
                router.push("/login");
                return data.token;
            }
        },
        logoutImpersonatingAccount({ getters }) {
            localStorage.setItem(tokenName, JSON.stringify(getters.fallbackTokenData));
            localStorage.removeItem("fallback_jwt_token");

            window.location.reload();
        }
    }
};
