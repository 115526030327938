<template>
    <v-dialog v-model="value"
              class="modal"
              max-width="700"
              persistent>
        <div class="dialog-overflow-wrap">
            <v-btn class="pci-ad-cl-btn" icon
                   @click="closeDialog">
                <v-img
                    :src="require('@/assets/icons/ic-close-modal.svg')"
                    contain
                    height="36"
                    max-width="36"/>
            </v-btn>
            <v-card class="pci-accept-dialog pa-5 pa-sm-10">
                <div class="pci-ad-head-sect flex-wrap">
                    <div class="pci-ad-ttl mb-5" style="font-size: 24px;line-height: 27px;color: #464646;">Are you sure you want to cancel this project?</div>
                    <div class="pci-ad-desc">
                        If you cancel now you will not be charged for this project.
                    </div>
                </div>

                <v-card-actions class="pa-0 mt-10"
                                :class="draft ? 'justify-center' : 'justify-space-between'">
                    <v-btn
                        style="background:#9FF4A9;border:1px solid #464646;box-sizing:border-box;border-radius:50px;"
                        class="btn-green bg-transparent btn-h-48 px-4"
                        elevation="0"
                        outlined
                        rounded
                        :loading="removeLoading"
                        :disabled="removeLoading || draftLoading"
                        @click="removeHandler">
                        Cancel Project
                    </v-btn>

                    <v-btn
                        v-if="!draft"
                        style="background:#9FF4A9;border:1px solid #464646;box-sizing:border-box;border-radius:50px;"
                        class="btn-green bg-fully btn-h-48 px-4"
                        elevation="0"
                        outlined
                        rounded
                        :loading="draftLoading"
                        :disabled="draftLoading || removeLoading"
                        @click="draftHandler">
                        Cancel but Keep Draft Project
                    </v-btn>
                </v-card-actions>
            </v-card>
        </div>
    </v-dialog>
</template>

<script>
    import { projectApi } from "@/api";

    export default {
        name: "dialog-cancel-project",
        props: {
            value: {
                type: Boolean,
                default: false
            },
            project: {
                type: Object,
                required: true
            }
        },
        data () {
            return {
                removeLoading: false,
                draftLoading: false
            };
        },
        computed: {
            draft () {
                return true;
                // return this.project.status === "Draft";
            },
            isClient () {
                return this.$store.getters.profile.role === "Client"
            }
        },
        methods: {
            closeDialog () {
                this.$emit("input", false);
            },
            removeHandler () {
                this.removeLoading = true;
                this.$store.dispatch("cancelProject", this.project.id).then(() => {
                    this.closeDialog();
                    this.afterAction();
                }).catch(errors => {
                    console.warn("RemoveHandler::errors", errors);
                }).finally(() => {
                    this.removeLoading = false;
                });
            },
            draftHandler () {
                this.draftLoading = true;
                projectApi.edit(this.project.id, {
                    status: this.$store.getters.getKeyProjectStatus("Draft")
                }).then(() => {
                    this.closeDialog();
                    this.afterAction();
                }).catch(errors => {
                    console.warn("DraftHandler::errors", errors);
                }).finally(() => {
                    this.draftLoading = false;
                });
            },
            afterAction () {
                if (this.$route.path === "/dashboard") {
                    this.$emit("update-projects");
                } else {
                    this.$router.push("/dashboard");
                }
            }
        }
    };
</script>
