<template>
    <component
        :is="tag"
        v-bind="$attrs"
        v-on="$listeners"
        @drop.prevent="dropFiles"
        @dragover.prevent
        :class="['files-uploader', {'is-disabled': disabled}]">
        <input
            v-if="inputFileSelect && !disabled"
            type="file"
            :accept="filesFormats"
            :multiple="multiple"
            ref="files_input"
            @change="uploadFilesInput">
        <slot></slot>
    </component>
</template>

<script>
    import { mapGetters } from "vuex";

    export default {
        name: "files-uploader",
        props: {
            filesFormats: {
                type: [String, Array],
                default: "image/jpg, image/jpeg, image/png, image/gif, image/.webp, image/svg"
            },
            maxFileSize: {
                type: Number,
                default: 1024 * 1000 * 10 // 10MB
            },
            maxFilesQuantity: {
                type: Number,
                default: 20
            },
            disabled: {
                type: Boolean,
                default: false
            },
            inputFileSelect: {
                type: Boolean,
                default: true
            },
            dragAndDrop: {
                type: Boolean,
                default: true
            },
            multiple: {
                type: Boolean,
                default: false
            },
            tag: {
                type: String,
                default: "label"
            }
        },
        methods: {
            getFilesFormats() {
                if (typeof this.filesFormats === "string") {
                    return this.filesFormats.split(",").map(item => item.replace(/^[^/]*\//, ".")).join(", ");
                } else {
                    return this.filesFormats.map(item => item.replace(/^[^/]*\//, ".")).join(", ");
                }
            },
            uploadFilesInput(event) {
                if (!event.target.files.length) return;

                if (event.target.files.length > this.maxFilesQuantity) {
                    this.$emit("onError", {
                        type: "quantityError",
                        maxFilesQuantity: this.maxFilesQuantity
                    });
                    return;
                }

                const filesNames = [];
                const files = [...event.target.files].reduce((acc, file) => {
                    if (!this.filesFormats.includes(file.type) || file.size > this.maxFileSize) {
                        filesNames.push(file.name);
                        return acc;
                    } else {
                        acc.push(file);
                    }

                    return acc;
                }, []);

                if (filesNames.length) {
                    this.$emit("onError", {
                        filesNames,
                        type: "type_or_size",
                        allowedFilesFormats: this.getFilesFormats(),
                        maxFileSizeLabel: `${this.maxFileSize / 1024 / 1000}MB`
                    });
                }

                if (files.length) {
                    this.$emit("onChange", files);
                }

                this.$refs.files_input.value = "";
            },
            dropFiles(event) {
                if (!this.dragAndDrop || this.disabled) return;

                let droppedItems = event.dataTransfer.items || event.dataTransfer.files;
                const files = [];

                if (droppedItems.length > this.maxFilesQuantity || !this.multiple && droppedItems.length >= 2) {
                    this.$emit("onError", {
                        type: "quantityError",
                        maxFilesQuantity: this.multiple ? this.maxFilesQuantity : 1
                    });
                    return
                }

                const filesNames = [];
                for (let i = 0; i < droppedItems.length; i++) {
                    if (droppedItems[i].kind === "file") {
                        let file = droppedItems[i].getAsFile();
                        if (!this.filesFormats.includes(file.type) || file.size > this.maxFileSize) {
                            filesNames.push(file.name);
                        } else {
                            files.push(file);
                        }
                    }
                }

                if (filesNames.length) {
                    this.$emit("onError", {
                        filesNames,
                        type: "type_or_size",
                        allowedFilesFormats: this.getFilesFormats(),
                        maxFileSizeLabel: `${this.maxFileSize / 1024 / 1000}MB`
                    });
                }

                if (files.length) {
                    this.$emit("onChange", files);
                }
            }
        }
    }
</script>

<style scoped lang="scss">
.files-uploader {
    position: relative;
    overflow: hidden;
    input {
        position: absolute;
        top: -987654321px;
        left: -987654321px;
    }
}
</style>
