<template>
    <v-dialog
        v-model="dialog"
        :max-width="800"
        scrollable
        class="projects-reassign-dialog">
        <template v-slot:activator="{ attrs, on }">
            <span
                v-bind="attrs"
                v-on="on"
                @click="fetchClientsHistory">
                {{ fullName }}
            </span>
        </template>

        <v-card class="pa-0 py-12 px-15 blueish">
            <v-btn
                class="pci-ad-cl-btn"
                icon @click="dialog = false">
                <v-img
                    :src="require('@/assets/icons/ic-close-modal.svg')"
                    contain
                    height="36"
                    max-width="36"/>
            </v-btn>
            <v-card-subtitle class="subtitle-1 pa-0 ma-0 blueish mb-5">
                <v-row>
                    <v-col cols="5">
                        {{ fullName }}
                    </v-col>
                    <v-col
                        class="d-flex justify-end"
                        cols="7">
                        <span>
                            {{ clientPhone }}
                            <span class="ml-5">
                                {{ clientEmail }}
                            </span>
                        </span>
                    </v-col>
                </v-row>
            </v-card-subtitle>

            <v-divider/>

            <p class="font-weight-bold subtitle-1 mt-5">
                Order History
            </p>

            <v-card-text class="pa-0">
                <order-history-data :list="history" :pagination="pagination" :loading="loading" @changePage="changePage"/>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import OrderHistoryData from "@/components/user-profile/OrderHistoryData";
    import { managerApi } from "@/api";

    export default {
        name: "client-dialog",
        components: { OrderHistoryData },
        props: {
            firstName: {
                type: String,
                required: true
            },
            lastName: {
                type: String,
                required: true
            },
            clientId: {
                type: Number,
                required: true
            },
            clientEmail: {
                type: String,
                required: true
            },
            clientPhone: {
                type: String,
                required: true
            }
        },
        data: () => ({
            loading: true,
            dialog: false,
            history: [],
            pagination: {
                count: null,
                current_page: null,
                links: null,
                per_page: null,
                total: null,
                total_pages: null
            }
        }),
        computed: {
            fullName() {
                const firstName = this.firstName || "";
                const lastName = this.lastName || "";
                return `${firstName} ${lastName}`;
            }
        },
        methods: {
            async fetchClientsHistory (page = 1) {
                this.loading = true

                try {
                    const response = await managerApi.getClientOrderHistory(this.clientId, { page })
                    this.history = response.data.data;
                    this.pagination = response.data.meta.pagination;
                } catch (e) {
                    console.log(e)
                }
                finally {
                    this.loading = false
                }
            },
            async changePage (page) {
                if (this.pagination.current_page !== page) {
                    await this.fetchClientsHistory(page);
                }
            }
        }
    };
</script>

<style lang="scss">
    .data-table__clients-history {
        thead th {
            font-size: 12px !important;
            font-weight: normal !important;
            color: #777777 !important;
            padding-right: 0 !important;
            padding-left: 0 !important;
        }
        tr {
            &:hover {
                background-color: unset !important;
            }
        }
        tr td {
            font-size: 12px !important;
            color: #202121 !important;
            vertical-align: top !important;
            padding-top: 16px !important;
            padding-left: 0 !important;
        }
    }
</style>

