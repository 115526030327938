<template>
    <v-container
        class="pa-6"
        fluid>
        <div class="manager-modelers">
            <div class="manager-modelers__header d-flex justify-space-between align-center mb-6">
                <div class="d-flex align-center">
                    <h1>Modelers</h1>
                    <add-modeler-dialog/>
                </div>
                <!-- <div>
                  <v-menu
                    :close-on-content-click="false"
                    bottom
                    offset-y
                    nudge-top="1"
                    z-index="1"
                    content-class="pf-drd-wrap"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div class="pf-drd-bw">
                        <div class="pf-drd-btn" v-bind="attrs" v-on="on">
                          Filter by:
                          <svg
                            width="12"
                            height="7"
                            viewBox="0 0 12 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M1.70724 0.29275C1.31624 -0.09825 0.684239 -0.09825 0.293239 0.29275C-0.0977611 0.68375 -0.0977611 1.31575 0.293239 1.70675L5.29324 6.70675C5.68424 7.09775 6.31624 7.09775 6.70724 6.70675L11.7072 1.70675C12.0982 1.31575 12.0982 0.68375 11.7072 0.29275C11.3162 -0.0982499 10.6842 -0.0982499 10.2932 0.29275L6.00024 4.58575L1.70724 0.29275Z"
                              fill="#656565"
                            />
                          </svg>
                        </div>
                      </div>
                    </template>
                    <div class="pf-drd-cw">
                      <div class="pf-drd-sect">
                        <div class="pf-drd-ttl">Status</div>
                        <div class="pf-statuses">
                          <v-chip
                            v-for="(status, i) in statuses"
                            :key="i"
                            :class="status.class"
                            class="ma-2"
                            :style="chipStyle"
                          >
                            {{ status.title }}
                          </v-chip>
                        </div>
                      </div>
                    </div>
                  </v-menu>
                </div> -->
            </div>

            <div
                v-if="loading"
                class="page-preloader local"/>

            <v-data-table
                v-else
                :headers="headers"
                :items="modelers"
                :items-per-page="pagination.per_page"
                class="data-table__all-modelers"
                hide-default-footer>
                <template #[`item.avatar`]="{ item }">
                    <v-menu
                        min-width="220"
                        max-width="220"
                        nudge-right="10"
                        nudge-top="7"
                        offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                                class="data-table__all-modelers--dots ml-1 mr-2"
                                small>
                                mdi-dots-vertical
                            </v-icon>
                        </template>
                        <v-list class="data-table__all-modelers--actions py-0">
                            <v-list-item @click="takeOverUser(item.id)">
                                <div class="text-body-2 actions-item__edit">Take Over</div>
                            </v-list-item>
                            <v-list-item>
                                <edit-modeler-dialog :id="item.id"/>
                            </v-list-item>
                            <v-list-item>
                                <remove-modeler-dialog :modeler="item"/>
                            </v-list-item>
                        </v-list>
                    </v-menu>

                    <v-avatar size="32">
                        <img
                            :src="item.avatar && item.avatar.length > 0 ? item.avatar : avatarStub"
                            :alt="`${item.first_name} ${item.last_name}`">
                    </v-avatar>
                </template>

                <template #[`item.fullName`]="{ item }">
                    {{ item.first_name }} {{ item.last_name }}
                </template>

                <template #[`item.approval`]="{ item }">
                    <div class="d-flex flex-column justify-center fill-height">
                        <div v-if="item.approved" :style="{color: item.approved ? '#02810F' : '#FF1257'}" class="font-italic">
                            {{ item.approved ? 'Approved' : 'Rejected' }}
                        </div>
                        <template v-else>
                            <div
                                @click="toggleApproveDialog({action: 'approve', modeler: item})"
                                class="font-italic text-decoration-underline cursor-pointer"
                                style="color: #02810F;">
                                Approve
                            </div>
                            <div
                                @click="toggleApproveDialog({action: 'reject', modeler: item})"
                                class="font-italic text-decoration-underline cursor-pointer"
                                style="color: #FF1257;">
                                Reject
                            </div>
                        </template>
                    </div>
                </template>

                <template #[`item.skills`]="{ item }">
                    <v-row
                        v-for="(skill, i) in item.skills"
                        :key="i"
                        class="ma-0"
                        style="padding: 0 0 12px 0">
                        <v-col
                            class="pa-0"
                            cols="6">
                            {{ skill.title }}
                        </v-col>
                        <v-col
                            class="pa-0 light-grey"
                            cols="5">
                            <div class="d-flex justify-space-between">
                                <span>{{ skill.level }}</span>
                                <v-icon
                                    color="#656565"
                                    size="20">
                                    mdi-chevron-right
                                </v-icon>
                            </div>
                        </v-col>
                    </v-row>
                </template>

                <template #[`item.projects_ongoing`]="{ item }">
                    <span v-if="!item.approved" style="color:#FF1257;">Not Approved</span>
                    <reassign-dialog
                        v-else
                        :id="item.id"
                        :first-name="item.first_name"
                        :last-name="item.last_name"
                        :ongoing="item.projects_ongoing"
                        type="ongoing"/>
                </template>

                <template #[`item.projects_completed`]="{ item }">
                    <reassign-dialog
                        :id="item.id"
                        :amount="item.projects_completed"
                        :first-name="item.first_name"
                        :last-name="item.last_name"
                        type="completed"/>
                </template>
            </v-data-table>

            <div v-if="showPagination && !loading" class="text-sm-center mt-4 mb-6">
                <v-pagination
                    v-model="paginationPage"
                    :length="pagination.total_pages"
                    :total-visible="6"
                    class="pf-pagination"
                    color="#515151"
                    next-aria-label="Next"
                    previous-aria-label="Previous"
                    @input="changePage"/>
            </div>
        </div>

        <v-dialog
            v-model="approveDialog"
            :persistent="approveDialogData.loading"
            :max-width="620">
            <div class="dialog-overflow-wrap">
                <v-btn
                    class="pci-ad-cl-btn"
                    icon>
                    <v-img
                        :src="require('@/assets/icons/ic-close-modal.svg')"
                        contain
                        height="36"
                        max-width="36"
                        @click="toggleApproveDialog({action: 'close'})"/>
                </v-btn>

                <div class="white pa-8">
                    <h4 class="mb-8 text-h5 font-weight-semibold text-center main-text--text">
                        Are you sure you want to {{ approveDialogData.approve ? 'Approve' : 'Reject' }} this designer?
                    </h4>
                    <div class="d-flex justify-center">
                        <v-btn
                            width="100%"
                            max-width="200px"
                            height="40"
                            outlined
                            rounded
                            color="#BCBCBC"
                            :disabled="approveDialogData.loading"
                            @click="toggleApproveDialog({action: 'close'})">
                            Cancel
                        </v-btn>
                        <v-btn
                            width="100%"
                            max-width="200px"
                            height="40"
                            outlined
                            rounded
                            class="ml-6"
                            :loading="approveDialogData.loading"
                            :style="{backgroundColor: approveDialogData.approve ? '#9ff4a9' : '#C81C31'}"
                            @click="setModellerStatus">
                            {{ approveDialogData.approve ? 'Approve' : 'Reject' }}
                        </v-btn>
                    </div>
                </div>
            </div>
        </v-dialog>
    </v-container>
</template>

<script>
    import { mapGetters } from "vuex";
    import { managerApi, userApi } from "@/api";
    import AddModelerDialog from "./AddModelerDialog.vue";
    import ReassignDialog from "./ReassignDIalog";
    import EditModelerDialog from "./EditModelerDialog.vue";
    import RemoveModelerDialog from "./RemoveModelerDialog.vue";
    import avatarStub from "@/assets/img/user-avatar-stub.svg"

    export default {
        components: {
            AddModelerDialog,
            ReassignDialog,
            EditModelerDialog,
            RemoveModelerDialog
        },
        data: () => ({
            avatarStub: avatarStub,
            approveDialog: false,
            approveDialogData: {
                approve: true,
                modeler: null,
                loading: false
            },
            headers: [
                {
                    text: "",
                    align: "start",
                    sortable: false,
                    value: "avatar"
                },
                {
                    text: "Name",
                    align: "start",
                    sortable: false,
                    value: "fullName"
                },
                { text: "Email", value: "email" },
                { text: "Approval", value: "approval" },
                { text: "Skill Sets", value: "skills" },
                { text: "Ongoing Projects", value: "projects_ongoing" },
                { text: "Completed Projects", value: "projects_completed" }
            ],
            statuses: [
                { class: "open-for-work", title: "Open For Work" },
                { class: "in-progress", title: "In Progress" },
                { class: "input-needed", title: "Input Needed" },
                { class: "pending-approval", title: "Pending Approval" },
                { class: "ready-for-review", title: "Ready For Review" }
            ],
            chipStyle: {
                cursor: "pointer",
                borderRadius: "18px"
            },
            loading: true,
            paginationPage: 1
        }),
        computed: {
            ...mapGetters([
                "tokenData",
                "profile"
            ]),
            modelers () {
                return this.$store.state.manager.modelerList;
            },
            pagination() {
                return this.$store.state.manager.modelersListPagination;
            },
            showPagination() {
                return this.pagination?.total_pages > 1;
            }
        },
        created () {
            this.loadModelerList();
        },
        methods: {
            async takeOverUser(user_id) {
                const res = await userApi.loginAsUser({ user_id });
                localStorage.setItem("jwt_token", JSON.stringify(res.data.data));
                localStorage.setItem("fallback_jwt_token", JSON.stringify({
                    ...this.tokenData,
                    email: this.profile.email
                }));

                window.location.href = "/dashboard";
            },
            async loadModelerList(params = {}) {
                this.loading = true;

                await this.$store.dispatch("manager/getModelerList", params);
                this.loading = false;
            },
            changePage() {
                this.loadModelerList({ page: this.paginationPage });
            },
            toggleApproveDialog(data) {
                const { action, modeler = null } = data;
                switch (action) {
                    case "approve":
                        this.approveDialogData = {
                            approve: true,
                            modeler
                        }
                        this.approveDialog = true;
                        break;
                    case "reject":
                        this.approveDialogData = {
                            approve: false,
                            modeler
                        }
                        this.approveDialog = true;
                        break;
                    case "close":
                        this.approveDialog = false;
                }
            },
            async setModellerStatus() {
                this.approveDialogData.loading = true;

                try {
                    if (this.approveDialogData.approve) {
                        await managerApi.approveModeler(this.approveDialogData.modeler.id);
                    } else {
                        await managerApi.rejectModeler(this.approveDialogData.modeler.id);
                    }

                    await this.loadModelerList({ page: this.paginationPage });
                } catch (error) {
                    console.error(error);
                } finally {
                    this.approveDialogData.loading = false;
                    this.approveDialog = false;
                }
            }
        }
    };
</script>

<style lang="scss">
    .manager-modelers {
        margin: 80px auto 0 auto;
        max-width: 1160px;
    }
    .disabledBtn {
        pointer-events: none !important;
        color: #fff !important;
        background-color: #CBD2D0 !important;
    }
    .data-table__all-modelers {
        tr td:first-child {
            padding-top: 9px!important;
        }
        &--dots {
            cursor: pointer;
        }
        &--actions {
            .v-list-item {
                min-height: 25px !important;
                cursor: pointer;
                &:first-child {
                    padding-top: 5px;
                }
                &:last-child {
                    padding-bottom: 5px;
                }
                &:hover {
                    background-color: rgb(243, 243, 243) !important;
                }
            }
            .actions-item {
                &__edit {
                    color: #0046AF;
                }
                &__remove {
                    color: #C81C31;
                }
            }
        }
        thead tr th {
            font-size: 14px !important;
            padding-right: 0 !important;
            padding-left: 0 !important;
        }
        tr {
            &:hover {
                background-color: unset !important;
            }
            &:first-child {
                font-size: 10px !important;
                color: #7B7B7B !important;
            }
            td {
                font-size: 13px !important;
                color: #0B3C49 !important;
                vertical-align: top !important;
                padding-top: 16px !important;
                padding-left: 0 !important;
                &:first-child {
                    position: relative;
                }
                &:nth-child(4) {
                    padding-top: 0!important;
                }
                &:nth-child(5) {
                    button {
                        color: #0046AF !important;
                    }
                }
                &:last-child {
                    button {
                        color: #0046AF !important;
                    }
                }
            }
        }
    }
</style>
