<template>
    <v-container class="conf-demos-page py-0 pb-12 px-6">
        <loader v-if="isAdmin && loading"/>

        <template v-if="isAdmin && !loading">
            <div class="d-flex align-center mt-12 mb-6">
                <h1>Articles</h1>

                <v-btn
                    v-if="$vuetify.breakpoint.xsOnly"
                    outlined
                    rounded
                    width="146"
                    height="38"
                    color="#464646"
                    class="ml-auto bg-primary"
                    :to="'/blog-cms/create'">
                    <v-icon size="18" class="mr-2">mdi-plus</v-icon>
                    New article
                </v-btn>
            </div>

            <div class="conf-demos-page__header d-flex align-center mb-5">
                <v-text-field
                    v-model="searchVal"
                    outlined
                    dense
                    hide-details
                    height="34"
                    placeholder="Search by name"
                    prepend-inner-icon="mdi-magnify"
                    @keyup="search"
                    class="conf-demos-page_search mr-5 rounded-0"/>

                <!--                <v-btn-->
                <!--                    outlined-->
                <!--                    width="120"-->
                <!--                    height="36"-->
                <!--                    color="#888888"-->
                <!--                    :ripple="false"-->
                <!--                    style="padding: 0 10px;">-->
                <!--                    <span class="main-text&#45;&#45;text">Filter by:</span>-->
                <!--                    <img class="ml-auto" :src="require('@/assets/icons/ic-chevron-down-circle-outline.svg')" width="23" height="24"-->
                <!--                         alt="">-->
                <!--                </v-btn>-->

                <v-btn
                    v-if="$vuetify.breakpoint.smAndUp"
                    outlined
                    rounded
                    width="146"
                    height="38"
                    color="#464646"
                    class="ml-auto bg-primary"
                    :to="'/blog-cms/create'">
                    <v-icon size="18" class="mr-2">mdi-plus</v-icon>
                    New article
                </v-btn>
            </div>

            <v-data-table
                v-model="selected"
                :headers="headers"
                :items="list"
                :items-per-page="-1"
                :mobile-breakpoint="280"
                :loading="dataLoading"
                :custom-sort="customSort"
                must-sort
                hide-default-footer>
                <template #item="{item, isSelected, select}">
                    <tr :class="{'is-selected': isSelected}">
                        <td class="pr-0">
                            <div class="d-flex align-center">
                                <simple-checkbox :value="isSelected" @input="select" class="justify-start"/>
                            </div>
                        </td>
                        <td class="pl-4 main-text--text w-full">
                            {{ item.title }}
                        </td>
                        <td class="text-right main-text--text">
                            {{ item.views }}
                        </td>
                        <td>
                            <div class="d-flex">
                                <v-btn rounded outlined @click="onDelete(item)" width="122"
                                       class="mr-3 main-text--text">
                                    <!-- <img :src="require('@/assets/icons/ic-exit.svg')" alt="" width="20"
                                         class="mr-1"> -->
                                    <v-icon color="#464646" size="18" class="mr-2">mdi-close</v-icon>
                                    Delete
                                </v-btn>
                                <v-btn rounded outlined width="102" class="main-text--text"
                                       :to="`/blog-cms/edit/${item.id}`">
                                    <img :src="require('@/assets/icons/ic-edit.svg')" alt="" width="13" style="margin-right: 6px;">
                                    Edit
                                </v-btn>
                            </div>
                        </td>
                    </tr>
                    <tr class="spacer">
                        <td style="height: 10px; border:none;"/>
                    </tr>
                </template>
            </v-data-table>

            <div v-if="showPagination" class="text-sm-center mt-4 mb-6">
                <v-pagination
                    v-model="paginationPage"
                    :length="pagination.total_pages"
                    :total-visible="6"
                    class="pf-pagination"
                    color="#515151"
                    next-aria-label="Next"
                    previous-aria-label="Previous"
                    @input="changePage"/>
            </div>
        </template>
        <dialog-confirm v-model="confirmDialog" @onSuccess="deleteItem"/>
    </v-container>
</template>

<script>
    import { mapGetters } from "vuex";
    import { adminApi, managerApi } from "@/api";
    import Loader from "@/components/ui/Loader";
    import SimpleCheckbox from "@/components/ui/SimpleCheckbox";
    import DialogConfirm from "@/components/ui/DialogConfirm"

    export default {
        components: {
            Loader,
            SimpleCheckbox,
            DialogConfirm
        },
        data() {
            return {
                loading: true,
                dataLoading: false,
                searchVal: "",
                searchDebounce: null,
                paginationPage: 1,
                perPage: 10,
                pagination: null,
                selected: [],
                headers: [
                    { text: "", value: "select", sortable: false, width: "30px" },
                    { text: "Article Title", value: "title", width: "298px" },
                    { text: "# of views", value: "views", width: "130px" },
                    { text: "", value: "actions", sortable: false }
                ],
                list: [],
                sortModel: {
                    col: "id",
                    order: "desc"
                },
                confirmDialog: false,
                deletedItemId: null
            }
        },
        computed: {
            ...mapGetters([
                "isAdmin"
            ]),
            showPagination() {
                return this.pagination?.total_pages > 1;
            },

            query() {
                const temp = {
                    page: this.paginationPage,
                    per_page: this.perPage,
                    search: this.searchVal,
                    "conditional_filters[0][field]": "title",
                    "conditional_filters[0][operator]": "ilike",
                    "conditional_filters[0][value]": this.searchVal || ""
                }
                temp[`order_by[${this.sortModel.col}]`] = this.sortModel.order
                return temp
            }
        },
        watch: {
            sortModel: {
                deep: true,
                async handler() {
                    await this.getBlogList()
                }
            }
        },
        async created() {
            await this.getBlogList()
            this.loading = false;
        },
        methods: {
            async getBlogList() {
                try {
                    this.dataLoading = true;
                    const { data } = await managerApi.getBlogList(this.query)
                    this.list = data.data
                    this.pagination = data.meta.pagination
                } catch(err) {
                    console.error(error);
                } finally {
                    this.dataLoading = false;
                }
            },
            customSort(items, sortBy, sortDesc) {
                if (sortBy.length && sortDesc.length) {
                    this.sortModel.col = sortBy[0];
                    this.sortModel.order = sortDesc[0] ? "desc" : "asc";
                }
                return items;
            },
            changePage() {
                this.getBlogList({ page: this.paginationPage })
            },
            search() {
                if (this.searchDebounce) {
                    clearTimeout(this.searchDebounce);
                }

                this.searchDebounce = setTimeout(() => {
                    this.getBlogList();
                }, 350);
            },
            async onDelete(item) {
                this.deletedItemId = item.id
                this.confirmDialog = true
            },
            async deleteItem() {

                try {
                    const { data } = await managerApi.deleteArticle(this.deletedItemId)
                    await this.getBlogList()

                    this.deletedItemId = null
                } catch (err) {
                    console.log(err)
                }
            }
            // onCloseDialog() {
            //     this.dialog = false; this.dialogItem = null
            // }
        }
    }
</script>

<style scoped lang="scss">
.conf-demos-page {
  max-width: 948px;

  h1 {
      font-size: 36px;
      line-height: 40px;
      color: #464646;
  }

  ::v-deep .conf-demos-page_search {
      max-width: 300px;

      .v-input__slot {
          padding: 0 10px!important;
          min-height: 36px!important;
      }

      fieldset {
          border-color: #464646;
      }

      input {
          font-size: 12px;
          &::placeholder {
              color: #BCBCBC;
          }
      }

      .v-input__prepend-inner {
          margin-top: 6px!important;
          padding-right: 2px;
      }

      .v-icon {
          font-size: 20px;
          color: #464646;
      }
  }

  ::v-deep .v-data-table {
      tr:not(.spacer) {
          th {
              height: auto;
              padding-bottom: 12px;
              border-bottom: none!important;
              font-weight: 400;
              font-size: 14px;
              line-height: 18px;
              color: #464646!important;

              &.sortable {
                  i {
                      width: 20px;
                      height: 20px;
                      background: transparent url('~@/assets/icons/ic-expand.svg') no-repeat center center;
                      &::before {
                          display: none;
                      }
                  }
              }
          }

          td {
              height: 58px;
              padding: 6px 20px;
              border-top: 1px solid;
              border-bottom: 1px solid;
              border-color: #E8E8E8!important;
              font-size: 18px;
              line-height: 23px;

              &:first-child {
                  border-left: 1px solid;
                  border-top-left-radius: 5px;
                  border-bottom-left-radius: 5px;
              }

              &:last-child {
                  border-right: 1px solid;
                  border-top-right-radius: 5px;
                  border-bottom-right-radius: 5px;
              }
          }
      }
  }
}
</style>
