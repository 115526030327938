<template>
    <v-dialog
        v-bind="$attrs"
        v-on="$listeners"
        max-width="620"
        @click:outside="closeDialog">
        <div class="dialog__overflow-wrap">
            <v-btn
                class="dialog__close"
                icon
                @click="closeDialog">
                <v-img
                    :src="require('@/assets/icons/ic-close-modal.svg')"
                    contain
                    height="36"
                    max-width="36"/>
            </v-btn>
            <v-card class="dialog__card pa-5 pa-sm-10">
                <payment
                    :amount="amount"
                    :default-value="defaultValue"
                    data="data"
                    :type="type"
                    @loading="loading"
                    @closeDialog="closeDialog"
                    @paymentSucceeded="paymentSucceeded"/>
            </v-card>
        </div>
    </v-dialog>
</template>

<script>
    // import http from "@/api/httpClient.api";
    // import moment from "moment";

    import { mapGetters } from "vuex";
    import Payment from "@/components/ui/payment/Payment";

    export default {
        name: "dialog-payment",
        components: {
            Payment
        },
        data: () => ({
            loadingLocal: false,
            statusPayment: false
        }),
        props: {
            type: {
                type: String, // projectPayment | extraCharge
                default: "projectPayment"
            },
            defaultValue: {
                type: String,
                default: "stripe"
            },
            autocloseable: {
                type: Boolean,
                default: true
            },
            amount: {
                type: Number,
                default: null
            },
            data: {
                type: Object,
                default: () => {
                }
            }
        },
        created () {
        },
        computed: {
            ...mapGetters([
                "getProjectData"
            ])
        },
        methods: {
            paymentSucceeded () {
                this.statusPayment = true;
                this.$emit("paymentSucceeded");
            },
            loading (loading) {
                this.loadingLocal = loading;
                this.$emit("loading", loading);
            },
            closeDialog () {
                this.$emit("input", false);
                this.$emit("closeDialog");
                if (this.autocloseable) {// && this.statusPayment
                    this.$router.push("/dashboard");
                }

                if (this.type === "extraCharge" && this.statusPayment) {
                    this.$store.dispatch("updateProjectData", {
                        client_amount: +this.getProjectData.client_amount + +this.getProjectData.extra_charge.amount,
                        extra_charge: {
                            ...this.getProjectData.extra_charge,
                            status: this.$config.extraChargeStatuses.paid
                        }
                    })
                }
            }
        }
    };
</script>

<style lang="scss" scoped>


</style>
