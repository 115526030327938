<template>
    <div><h1>Please wait...</h1></div>
</template>

<script>
    import axios from "axios";

    export default {
        created () {
            let parameters = this.$route.query;
            axios.get(process.env.VUE_APP_BASE_API_URL + `/authorizations/google/callback`, {
                params: parameters
            }).then(response => {
                this.$store.commit("seTokenData", response.data.data);
                localStorage.setItem("jwt_token", JSON.stringify(response.data.data));
                this.$router.push("/dashboard");
            });
        }
    };
</script>
