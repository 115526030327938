<template>
    <v-radio
        v-bind="$attrs"
        v-on="$listeners"
        color="#9FF4A9"
        on-icon="ic-on"
        off-icon="ic-off"
        :ripple="false"
        class="simple-radio-checkbox"/>
</template>

<script>
    export default {
        name: "simple-radio-checkbox",
        model: {
            prop: "inputValue",
            event: "change"
        }
    }
</script>

<style scoped lang="scss">
.simple-radio-checkbox {
    ::v-deep .v-input--selection-controls__input {
        width: 20px;
        height: 20px;
    }

    ::v-deep .ic-on {
        font-size: 20px;
        background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTAiIGN5PSIxMCIgcj0iOS41IiBmaWxsPSJ3aGl0ZSIgc3Ryb2tlPSIjNDY0NjQ2Ii8+CjxjaXJjbGUgY3g9IjEwIiBjeT0iMTAiIHI9IjUuNSIgZmlsbD0iIzlGRjRBOSIgc3Ryb2tlPSIjNDY0NjQ2Ii8+Cjwvc3ZnPgo=") no-repeat;
    }

    ::v-deep .ic-off {
        font-size: 20px;
        background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTAiIGN5PSIxMCIgcj0iOS41IiBmaWxsPSJ3aGl0ZSIgc3Ryb2tlPSIjNDY0NjQ2Ii8+Cjwvc3ZnPgo=") no-repeat;
    }
}
</style>
