<template>
    <v-dialog
        v-model="dialog"
        persistent
        width="900"
        @keydown.esc="$emit('onClose')"
        @click:outside="$emit('onClose')"
        class="modal">
        <div class="dialog-overflow-wrap">
            <v-btn class="pci-ad-cl-btn" icon @click="$emit('onClose')">
                <v-img contain height="36" max-width="36"
                       :src="require('@/assets/icons/ic-close-modal.svg')"/>
            </v-btn>

            <validation-observer class="new-demo pa-5 pa-sm-8 overflow-y-auto white" tag="div" ref="form" v-slot="{ handleSubmit }">
                <v-form @submit.prevent="handleSubmit(submitHandler)">
                    <h2 class="mb-7 font-weight-regular">New demo page</h2>

                    <div class="d-flex justify-space-between">
                        <div class="new-demo-options-col">
                            <h3 class="mb-4 font-weight-regular main-text--text">Company info</h3>

                            <div class="mb-3">
                                <h4 class="new-demo-input-label mb-2 font-weight-semibold main-text--text">Company name</h4>
                                <validation-provider
                                    v-slot="{ errors }"
                                    mode="eager"
                                    name="SKU"
                                    vid="sku"
                                    rules="min:3|max:20|required"
                                    tag="label">
                                    <v-text-field
                                        v-model="demo.company"
                                        flat
                                        outlined
                                        hide-details
                                        height="34"
                                        class="new-demo-input rounded-0"
                                        placeholder="Company TM"/>
                                    <div v-if="errors.length" class="text-caption error--text">{{ errors[0] }}</div>
                                </validation-provider>
                            </div>

                            <div class="mb-3">
                                <h4 class="new-demo-input-label mb-1 font-weight-semibold main-text--text">Generated URL</h4>
                                <p class="mb-0 main-text--text" style="font-size: 14px;">{{ getCompanyName }}{{ subdomain }}.hellounco.com</p>
                            </div>

                            <div class="mb-3">
                                <h4 class="new-demo-input-label mb-2 font-weight-semibold main-text--text">Theme</h4>
                                <v-select
                                    v-model="demo.theme"
                                    :items="themes"
                                    item-value="value"
                                    item-text="title"
                                    :menu-props="{
                                        nudgeBottom: 34,
                                        nudgeLeft: 1

                                    }"
                                    append-icon="mdi-chevron-down"
                                    flat
                                    dense
                                    outlined
                                    hide-details
                                    height="34"
                                    background-color="#fff"
                                    class="new-demo-input rounded-0"/>
                            </div>
                            <div class="mb-7">
                                <h4 class="new-demo-input-label mb-1 font-weight-semibold main-text--text">Company logo</h4>
                                <files-uploader
                                    v-if="!demo.logo"
                                    @onChange="files => uploadFilePreview({key: 'logo', files})"
                                    class="upload-form d-flex flex-column align-center justify-center cursor-pointer">
                                    <span class="d-flex align-center font-weight-semibold main-text--text">
                                        <img class="mr-2" width="40" :src="require('@/assets/icons/ic-upload-dark.svg')"
                                             alt="">
                                        Upload File
                                    </span>
                                    <p class="mt-1 mb-0 main-text--text">Drag and drop your file here</p>
                                </files-uploader>

                                <div v-else class="pos-rel d-inline-flex mt-2 w-full">
                                    <img class="d-block w-full" :src="demo.logo.url" alt="" style="max-height: 200px;">
                                    <v-btn class="pci-ad-cl-btn" icon @click="removeLogo(demo.logo.id)">
                                        <v-img contain height="24" max-width="24"
                                               :src="require('@/assets/icons/ic-close-modal.svg')"/>
                                    </v-btn>
                                </div>
                            </div>

                            <div class="mb-3">
                                <h4 class="new-demo-input-label mb-2 font-weight-semibold main-text--text">Access password</h4>
                                <validation-provider
                                    v-slot="{ errors }"
                                    mode="eager"
                                    name="Password"
                                    vid="password"
                                    rules="|min:3|max:20|required"
                                    tag="label">
                                    <v-text-field
                                        v-model="demo.password"
                                        flat
                                        outlined
                                        hide-details
                                        height="34"
                                        class="new-demo-input rounded-0"
                                        placeholder="Password"/>
                                    <div v-if="errors.length" class="text-caption error--text">{{ errors[0] }}</div>
                                </validation-provider>
                            </div>
                        </div>

                        <div class="new-demo-options-col">
                            <h3 class="mb-4 font-weight-regular main-text--text">Configurators</h3>

                            <div v-for="(configurator, idx) in demo.configurators" :key="idx" class="mb-3">
                                <h4 class="new-demo-input-label mb-2 font-weight-semibold main-text--text">Paste configurator embed</h4>
                                <div class="d-flex">
                                    <div class="pos-rel w-full">
                                        <validation-provider v-slot="{ errors }"
                                                             mode="eager"
                                                             name="configurator" rules="required">
                                            <v-textarea
                                                v-model="configurator.code"
                                                outlined
                                                no-resize
                                                hide-details
                                                :rows="3"
                                                class="new-demo-textarea rounded-0"
                                                placeholder="<configurator code>"/>
                                            <div v-if="errors.length" class="text-caption error--text">{{ errors[0] }}</div>
                                        </validation-provider>
                                        <v-btn v-if="idx > 0" class="pci-ad-cl-btn" icon @click="removeConfigurator(configurator, idx)">
                                            <v-img contain height="24" max-width="24"
                                                   :src="require('@/assets/icons/ic-close-modal.svg')"/>
                                        </v-btn>
                                    </div>

                                    <div class="d-flex flex-shrink-0 ml-4" style="width: 108px;">
                                        <files-uploader
                                            v-if="!configurator.preview"
                                            @onChange="files => uploadFilePreview({key: 'conf-preview', idx, files})"
                                            class="upload-form conf-upload-form d-flex flex-column align-center justify-center w-full cursor-pointer">
                                            <span class="d-flex align-center font-weight-semibold main-text--text">
                                                <img class="mr-2" width="40"
                                                     :src="require('@/assets/icons/ic-upload-dark.svg')" alt="">
                                                PLP
                                            </span>
                                            <p class="mt-1 mb-0 main-text--text">Drag and drop</p>
                                        </files-uploader>

                                        <div v-else class="pos-rel d-inline-flex w-full">
                                            <img class="d-block object-cover w-full" :src="configurator.preview.url" alt=""
                                                 style="max-height: 80px; max-width: 100%">
                                            <v-btn class="pci-ad-cl-btn" icon @click="removeConfiguratorImage(configurator)">
                                                <v-img contain height="24" max-width="24"
                                                       :src="require('@/assets/icons/ic-close-modal.svg')"/>
                                            </v-btn>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <v-btn
                                v-if="demo.configurators.length < 6"
                                outlined
                                rounded
                                width="100%"
                                height="40"
                                color="#464646"
                                class="mb-3"
                                @click="addNewConfigurator">
                                <v-icon size="18" class="mr-2">mdi-plus</v-icon>
                                Add configurator
                            </v-btn>

                            <div class="mt-4 mb-7">
                                <h4 class="new-demo-input-label mb-1 font-weight-semibold main-text--text">Renders</h4>
                                <files-uploader
                                    v-if="!demo.renders.length"
                                    multiple
                                    @onChange="files => uploadFilePreview({key: 'renders', files})"
                                    class="upload-form d-flex flex-column align-center justify-center cursor-pointer">
                                    <span class="d-flex align-center font-weight-semibold main-text--text">
                                        <img class="mr-2" width="40" :src="require('@/assets/icons/ic-upload-dark.svg')"
                                             alt="">
                                        Upload Files
                                    </span>
                                    <p class="mt-1 mb-0 main-text--text">Drag and drop your files here</p>
                                </files-uploader>

                                <div v-else class="d-flex pt-3 mt-n1 overflow-y-auto">
                                    <div v-for="render in demo.renders" :key="render.id" class="pos-rel mr-5">
                                        <div class="d-flex cursor-pointer" @click="showRendersModal(render)">
                                            <img class="mb-1 object-cover" :src="render.url" alt=""
                                                 width="110" height="110">
                                        </div>
                                        <v-btn class="pci-ad-cl-btn remove-render-btn" icon @click="removeRender(render.id)">
                                            <v-img contain height="24" max-width="24"
                                                   :src="require('@/assets/icons/ic-close-modal.svg')"/>
                                        </v-btn>
                                    </div>
                                </div>

                                <files-uploader
                                    v-if="demo.renders.length"
                                    multiple
                                    @onChange="files => uploadFilePreview({key: 'renders', files})"
                                    class="d-inline-flex cursor-pointer">
                                    <v-btn
                                        outlined
                                        rounded
                                        width="156"
                                        height="40"
                                        color="#464646"
                                        class="mt-2 pointer-events-none">
                                        <img class="mr-2" width="20" :src="require('@/assets/icons/ic-upload-dark.svg')" alt="">
                                        Upload more
                                    </v-btn>
                                </files-uploader>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex justify-end">
                        <v-btn
                            outlined
                            rounded
                            width="108"
                            height="40"
                            color="#464646"
                            class="mr-3"
                            @click="$emit('onClose')">
                            Cancel
                        </v-btn>
                        <v-btn
                            type="submit"
                            outlined
                            rounded
                            width="168"
                            height="40"
                            color="#464646"
                            :loading="loading"
                            class="bg-primary">
                            {{ item ? 'Update' : 'Create new' }} demo
                        </v-btn>
                    </div>
                </v-form>
            </validation-observer>
        </div>

        <configurator-demo-renders-modal
            v-if="rendersModal && demo.renders.length"
            :renders="demo.renders"
            :active-slide-idx="rendersActiveSlide"
            @onClose="rendersModal = false; rendersActiveSlide = null"/>
    </v-dialog>
</template>


<script>
    import { ValidationObserver, ValidationProvider } from "vee-validate";
    import FilesUploader from "@/components/ui/FilesUploader";
    import ConfiguratorDemoRendersModal from "@/views/admin/configurator-demos/ConfiguratorDemoRendersModal";
    import UploadService from "@/api/uploadFilesService.api";
    import { adminApi } from "@/api";

    export default {

        /*
         * NOTICE
         *
         * if you add the files and don't save the demo
         * files are not deleted from the server
         *
         * */
        name: "new-configurator-demo-modal",
        components: {
            ValidationObserver,
            ValidationProvider,
            FilesUploader,
            ConfiguratorDemoRendersModal
        },
        props: {
            item: {
                type: Object,
                default: null
            }
        },

        data() {
            return {
                themes: [
                    { title: "Default", value: "default" },
                    { title: "Light", value: "light" },
                    { title: "Dark", value: "dark" }
                ],
                dialog: true,
                loading: false,
                demo: {
                    id: null,
                    company: "",
                    slug: "",
                    logo: null,
                    password: "",
                    configurators: [
                        { code: "", preview: null }
                    ],
                    renders: [],
                    theme: "default"
                },
                rendersModal: false,
                rendersActiveSlide: null
            }
        },
        computed: {
            getCompanyName() {
                return this.demo.slug || this.demo.company.replace(/ /g, "").toLowerCase();
            },
            subdomain() {
                if(process.env.VUE_APP_ENVIRONMENT === "development") {
                    return ".dev"
                } else if(process.env.VUE_APP_ENVIRONMENT === "stage") {
                    return ".dev"
                } else {
                    return ""
                }
            }
        },
        created() {
            if (this.$props.item) {
                this.demo = JSON.parse(JSON.stringify(this.formatDataFromApi(this.$props.item)));
            }
        },
        methods: {
            async submitHandler() {
                this.loading = true
                try {
                    const configurators = this.demo.configurators.map(configurator => {
                        return {
                            code: configurator.code,
                            plp_file_id: configurator.preview.id
                        }
                    })
                    const renders = this.demo.renders.map(render => {
                        return render.id
                    })
                    const updateData = {
                        company_name: this.demo.company,
                        logo_file_id: this.demo.logo?.id || null,
                        password: this.demo.password,
                        configurators: configurators,
                        renders,
                        theme: this.demo.theme
                    }

                    const { data } = this.item ? await adminApi.updateDemo(this.item.id, updateData) : await adminApi.createDemo(updateData)

                    this.demo = this.formatDataFromApi(data.data)

                    this.$emit("update")
                } catch (e) {
                    this.errorSnackbar = true;
                    this.errorMessage = "Failure. Try again or contact our support team";
                } finally {
                    this.loading = false
                }
            },
            addNewConfigurator() {
                this.demo.configurators.push({
                    code: "",
                    preview: null
                });
            },
            removeConfigurator(configurator, idx) {
                this.demo.configurators = this.demo.configurators.filter((configurator, i) => i !== idx);
                this.removeConfiguratorImage(configurator)
            },
            async removeRender(render_id) {
                this.demo.renders = this.demo.renders.filter(render => render.id !== render_id);
                await this.removeFile(render_id)
            },
            async uploadFilePreview(filesData) {
                const { key, idx, files } = filesData;

                const result = await Promise.all(files.map(async (file) => {
                    file.typeImage = this.$config.fileTypes.attachment
                    return await UploadService.upload(file)
                }));

                switch (key) {
                    case "logo": {
                        this.demo[key] = result[0];
                        break;
                    }
                    case "conf-preview": {
                        this.demo.configurators = this.demo.configurators.map((configurator, ind) => {
                            if (ind === idx) {
                                return { ...configurator, preview: result[0] }
                            }
                            return configurator;
                        });
                        break;
                    }
                    case "renders": {
                        this.demo.renders = this.demo.renders.concat(result)
                        break;
                    }
                }
            },

            async removeLogo(id) {
                try {
                    await this.removeFile(id)
                    this.demo.logo = null
                } catch (e) {
                    console.log(e)
                }
            },

            async removeConfiguratorImage(configurator) {
                try {
                    await this.removeFile(configurator.preview.id)
                    configurator.preview = null
                } catch (e) {
                    console.log(e)
                }
            },

            async removeFile(id) {
                try {
                    await UploadService.removeFile(id)
                    await this.submitHandler()
                } catch (e) {
                    console.log(e)
                }
            },

            showRendersModal(render) {
                this.rendersActiveSlide = this.demo.renders.findIndex(dRender => dRender.id === render.id);
                this.rendersModal = true;
            },

            formatDataFromApi(item) {

                const configurators = item.configurators.map(configurator => {
                    return {
                        code: configurator.code,
                        preview: configurator.plp_file
                    }
                })

                return {
                    company: item.company_name,
                    slug: item.slug,
                    logo: item.logo_file,
                    password: item.password,
                    configurators: configurators.length > 0 ? configurators : this.demo.configurators,
                    renders: item.renders,
                    theme: item.theme
                }
            }
        }
    }
</script>

<style scoped lang="scss">
.new-demo {
    max-height: 87vh;
  h2 {
    font-size: 24px;
    line-height: 26px;
  }

  h3 {
    font-size: 18px;
    line-height: 19px;
  }

  .new-demo-options-col {
    width: calc(50% - 25px);
  }

  .new-demo-input-label {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.04em;
  }

  ::v-deep .new-demo-input {
    .v-input__slot {
      padding: 0 10px !important;
      min-height: 34px;
    }
     .v-input__append-inner {
       margin-top: 6px;
     }

    fieldset {
      border-color: #464646;
    }

    input, .v-select__selections {
      font-size: 12px;

      &::placeholder {
        color: #BCBCBC;
      }
    }
  }

  .upload-form {
    height: 220px;
    border-radius: 5px;
    border: 1px dashed #464646;
    font-size: 32px;
    line-height: 35px;

    p {
      font-size: 18px;
      line-height: 23px;
    }

    &.conf-upload-form {
      height: 80px;
      font-size: 24px;
      line-height: 26px;
    }

    p {
      font-size: 14px;
      line-height: 17px;
    }
  }

  ::v-deep .new-demo-textarea {
    .v-input__slot {
      padding: 0 10px !important;
      min-height: 80px;
    }

    fieldset {
      border-color: #464646;
    }

    textarea {
      font-size: 12px;
      line-height: 15px;
      margin-right: 4px;
      margin-bottom: 10px;

      &::placeholder {
        color: #BCBCBC;
      }
    }
  }

  .remove-render-btn::before {
    display: none;
  }
}
</style>
