<template>
    <div class="manager-assets__description mt-8">
        <div class="description-title text-uppercase">{{ descriptionTitle }}</div>
        <p v-html="description" class="description-text"></p>
    </div>
</template>

<script>
    export default {
        props: {
            descriptionTitle: {
                type: String,
                default: "Description"
            },
            description: {
                type: String,
                default: ""
            }
        }
    };
</script>


<style scoped lang="scss">
    .manager-assets__description {
        padding: 14px 20px 16px 20px;
        box-shadow: 4px 4px 4px rgb(70 70 70 / 15%);
        border: 1px solid #BCBCBC;
        border-radius: 5px;
    }

    .description-title {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #464646;
        padding-bottom: 11px;
        border-bottom: 1px solid #BCBCBC;
        margin-bottom: 12px;
    }

    .description-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #4E4E4E;

        ::v-deep h1 {
            margin-bottom: 8px;
            font-size: 26px;
            font-weight: 600;
            line-height: 28px;
        }

        ::v-deep h2 {
            margin-bottom: 8px;
            font-size: 24px;
            font-weight: 600;
            line-height: 26px;
        }

        ::v-deep h3 {
            margin-bottom: 8px;
            font-size: 22px;
            font-weight: 600;
            line-height: 24px;
        }
    }
</style>
