<template>
    <div class="not-found">
        404
    </div>
</template>

<script>
    export default {};
</script>

<style lang="scss" scoped>
    .not-found {
        width: 100%;
        height: calc(100vh - 78px);
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 1920px;
        margin: 0 auto;
        font-size: 20vh;
        overflow: hidden;
    }
</style>
