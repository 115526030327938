<template>
    <div class="d-flex align-center background-menu ml-3">
        <div class="head">Background:</div>
        <v-btn
            v-if="backgroundColor"
            small
            text
            @click="checkBackground">
            <v-sheet
                class="mr-1"
                :color="backgroundColor"
                height="13"
                rounded
                width="13"/>
            <span>{{ backgroundColor }}</span>
        </v-btn>
        <div v-if="hasBackgroundImage" class="custom-image">
            {{ backgroundColor ? ",":"" }}Custom Image
        </div>
    </div>
</template>

<script>
    export default {
        name: "hex-color-menu",
        props: {
            background: {
                type: Object,
                default: null
            }
        },
        data: () => ({
            menu: false
        }),
        methods: {
            checkBackground () {
                console.log("test");
            }
        },
        computed: {
            backgroundColor(){
                const hasBackground = this.background;
                const hasProps = this.background.props && this.background.props.length>0;

                if(hasBackground){
                    if(hasProps){
                        const backgroundProp = this.background.props.find(prop=>!!JSON.parse(prop).backgroundColor)
                        return JSON.parse(backgroundProp).backgroundColor
                    }
                }
                return false
            },
            hasBackgroundImage(){
                return !this.backgroundColor && this.background.url && this.background.url.length>0
            }
        }
    };
</script>

<style scoped lang="scss">
    .head, .custom-image {
        font-size: 14px;
        line-height: 18px;
        color: #888888;
        font-weight: 400;
    }

    .custom-image {
        font-weight: 600;
    }
</style>
