<template>
    <div class="extra-charge-block main-text--text">
        <div class="d-flex align-center justify-space-between">
            <button type="button" :class="['button-text text-decoration-underline', projectIsOnHold ? 'is-disabled main-text--text' : 'green-100--text']"
                    :disabled="projectIsOnHold"
                    @click="issueExtraChargeOpen = !issueExtraChargeOpen">
                Issue extra charge
            </button>

            <div v-if="projectIsOnHold" class="amount-label">
                Extra amount requested
                <span class="red-100--text">${{ project.extra_charge.amount }}</span>
            </div>
        </div>

        <v-fade-transition>
            <div v-if="issueExtraChargeOpen && !projectIsOnHold" class="issue-extra-charge mt-4">
                <div class="issue-extra-charge--title d-flex justify-space-between align-center mb-6">
                    <div>Price paid by client <span class="green-100--text">${{ project.client_amount }}</span></div>
                </div>

                <validation-observer tag="div" class="form-group" v-slot="{handleSubmit:handleSubmitRefund, valid}">
                    <form @submit.prevent="handleSubmitRefund(submitRefund)">
                        <div class="font-weight-semibold mb-1">Extra charge amount</div>
                        <validation-provider
                            v-slot="{errors}"
                            slim
                            name="extra_charge"
                            rules="required|min_value:1"
                            :custom-messages="{
                                required: 'Extra charge amount is required',
                                min_value: 'Must be greater than 0',
                            }">
                            <v-text-field
                                v-model.number="chargeData.amount"
                                type="number"
                                outlined
                                height="36"
                                :error-messages="errors"
                                placeholder="Extra charge amount"
                                class="edit-input edit-input--white"/>
                        </validation-provider>

                        <div class="font-weight-semibold mb-1">Select reason</div>
                        <v-select
                            v-model="chargeData.reason"
                            :items="reasons"
                            item-value="id"
                            :menu-props="{
                                contentClass: 'assignee-autocomplete',
                                nudgeBottom: 34,
                                nudgeLeft: 1
                            }"
                            append-icon="mdi-chevron-down"
                            outlined
                            height="34"
                            background-color="#fff"
                            class="edit-input"/>

                        <div class="font-weight-semibold mb-1">Describe your reason (optional)</div>
                        <v-textarea
                            v-model="chargeData.reason_details"
                            outlined
                            flat
                            no-resize
                            height="112"
                            background-color="#fff"
                            placeholder="Enter a reason"
                            class="edit-input"/>

                        <div class="font-weight-semibold mt-1 mb-1">Button set-up for client</div>
                        <simple-checkbox
                            v-model="chargeData.without_skipping"
                            hide-details
                            :simple="false"
                            class="mt-0"
                            label="Remove “Proceed without changes” button on client side"/>
                        <simple-checkbox
                            v-model="canceled_project_refund"
                            hide-details
                            :simple="false"
                            class="mt-0"
                            label="Issue refund if project gets canceled"/>

                        <template v-if="canceled_project_refund">
                            <div class="font-weight-semibold mt-1 mb-1">Refund amount</div>
                            <validation-provider
                                v-slot="{errors}"
                                slim
                                name="refund"
                                :rules="`min_value:1|max_value:${project.client_amount}`"
                                :custom-messages="{
                                    min_value: 'Must be greater than 0',
                                    max_value: 'Must be less than the price paid'
                                }">
                                <v-text-field
                                    v-model.number="chargeData.refund_amount"
                                    type="number"
                                    outlined
                                    height="36"
                                    :error-messages="errors"
                                    placeholder="Partial refund amount"
                                    class="edit-input edit-input--white"/>
                            </validation-provider>
                        </template>

                        <div class="d-flex">
                            <v-btn
                                type="submit"
                                :disabled="loading || !valid"
                                :loading="loading"
                                rounded
                                depressed
                                height="38"
                                color="#9FF4A9"
                                class="green-btn px-8 ml-auto">
                                Confirm
                            </v-btn>
                        </div>
                    </form>
                </validation-observer>
            </div>
        </v-fade-transition>
    </div>
</template>

<script>
    import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
    import { max_value, min_value, required } from "vee-validate/dist/rules";
    import SimpleCheckbox from "@/components/ui/SimpleCheckbox";
    import { projectApi } from "@/api";
    import { mapGetters } from "vuex";

    extend("required", required);
    extend("max_value", max_value);
    extend("min_value", min_value);
    export default {
        name: "extra-charge-edit-form",
        components: {
            ValidationObserver,
            ValidationProvider,
            SimpleCheckbox
        },
        props: {
            project: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                loading: false,
                issueExtraChargeOpen: false,
                canceled_project_refund: false,
                chargeData: {
                    amount: 0,
                    reason: 1,
                    reason_details: "",
                    without_skipping: false,
                    refund_amount: 0
                }
            }
        },
        computed: {
            ...mapGetters([
                "projectIsOnHold"
            ]),
            reasons() {
                return Object.keys(this.$config.extraChargeReasons).reduce((acc, key) => {
                    acc.push({ id: +key, text: this.$config.extraChargeReasons[key] });
                    return acc;
                }, [])
            }
        },
        methods: {
            async submitRefund() {
                if (!this.$props.project.id) return
                this.loading = true
                try {
                    const res = await projectApi.createExtraCharge(this.$props.project.id, {
                        ...this.chargeData,
                        without_skipping: this.chargeData.without_skipping ? 1 : 0,
                        refund_amount: this.canceled_project_refund ? this.chargeData.refund_amount : 0
                    });

                    this.$emit("updateProject", {
                        status_id: this.$config.project.status.on_hold,
                        extra_charge: res.data.data
                    });
                } catch (error) {
                    console.error(error);
                } finally {
                    this.loading = false;
                }
            }
        }
    }
</script>

<style scoped lang="scss">
.extra-charge-block {
    font-size: 14px;
}

.button-text {
    font-size: 14px;
    line-height: 19px;

    &.is-disabled {
        opacity: .6;
        cursor: initial;
    }
}

.amount-label {
    font-size: 14px;
    line-height: 18px;

    span {
        font-size: 18px;
        line-height: 19px;
    }
}

.issue-extra-charge {
    padding: 20px;
    background: #F4F4F4;

    &--title {
        font-size: 18px;

        span {
            font-size: 24px;
        }
    }
}

::v-deep .edit-input {
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;

    &--white {
        fieldset {
            background: #ffffff;
        }
    }

    fieldset {
        border: 1px solid #464646 !important;
    }

    textarea {
        line-height: 1.2;
    }

    .v-input__slot {
        min-height: auto;
        font-size: 12px;
    }

    &.v-text-field--enclosed .v-input__append-inner {
        margin-top: auto;
        margin-bottom: auto;
    }
}

.green-btn {
    border: 1px solid #464646 !important;
}

</style>
