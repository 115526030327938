<template>
    <div>
        <div class="profile-header">
            <v-row>
                <v-col>
                    <h3 class="profile-ttl">Billing Information</h3>
                </v-col>
            </v-row>
        </div>
        <div class="payment-section mt-3">
            <div class="user-profile-label">Payment Methods on File</div>
            <div v-if="loading" class="page-preloader local"></div>
            <template v-else>
                <div v-if="cards.length" class="payment-list mt-8">
                    <billing-payment-card v-for="(item, index) in cards" :key="index"
                                          :card="item" @onMakePrimary="makePrimary"/>
                </div>
            </template>
        </div>
        <v-dialog v-model="dialog" max-width="620"
                  @click:outside="closeDialog">
            <div class="dialog__overflow-wrap">
                <v-btn class="dialog__close" icon
                       @click="closeDialog">
                    <v-img :src="require('@/assets/icons/ic-close-modal.svg')" contain
                           height="36" max-width="36"></v-img>
                </v-btn>
                <v-card class="dialog__card pa-5 pa-sm-10">
                    <payment v-if="dialog" billing-view/>
                </v-card>
            </div>
        </v-dialog>
        <v-snackbar
            v-model="successSnackbar"
            :timeout="3000"
            bottom
            class="success-snackbar">
            {{ successMessage }}
        </v-snackbar>
        <v-snackbar
            v-model="errorSnackbar"
            :timeout="5000"
            bottom
            class="error-snackbar">
            {{ errorMessage }}
        </v-snackbar>
        <v-col cols="12" sm="auto">
            <v-btn class="btn-h-48 fs-16 let-sp-05 pl-4 pr-4 pl-sm-6 pr-sm-6 manage-btn"
                   elevation="0"
                   large
                   outlined
                   rounded
                   @click="openDialog">
                Manage Payment Methods
            </v-btn>
        </v-col>
    </div>
</template>

<script>
    import http from "@/api/httpClient.api";
    import Payment from "@/components/ui/payment/Payment";
    import BillingPaymentCard from "@/components/user-profile/billing-information/BillingPaymentCard";
    import { paymentApi } from "@/api";

    export default {
        name: "billing-information-data",
        components: {
            Payment,
            BillingPaymentCard
        },
        data () {
            return {
                dialog: false,
                successMessage: "",
                successSnackbar: false,
                errorMessage: "",
                errorSnackbar: false,
                gatewayId: 1,
                cards: [],
                loading: true
            };
        },
        created () {
            this.$root.$on("onPaymentError", this.showPaymentError);
            this.$root.$on("onPaymentSuccess", this.showPaymentSuccess);
            this.getCards();
        },
        methods: {
            showPaymentSuccess (successMessage) {
                this.closeDialog();
                this.successMessage = successMessage;
                this.successSnackbar = true;
                this.getCards();
            },
            showPaymentError (errorMessage) {
                this.closeDialog();
                this.errorMessage = errorMessage;
                this.errorSnackbar = true;
            },
            clearErrors () {
                this.successMessage = "";
                this.errorMessage = "";
            },
            closeDialog () {
                this.dialog = false;
            },
            openDialog () {
                this.dialog = true;
            },
            async getCards () {
                this.cards = await paymentApi.gateMethods(this.gatewayId);
                this.loading = false;
            },
            async makePrimary (card) {
                this.clearErrors();
                const formData = {
                    "payment_gateway_id": this.gatewayId,
                    "payment_method_id": card.payment_method_id
                };
                http.put("/users/payment-methods/default", formData).then(res => {
                    if (res.status === 204) {
                        this.successSnackbar = true;
                        this.successMessage = "Success!";
                        this.getCards();
                    }
                }).catch(err => {

                    this.errorSnackbar = true;
                    this.errorMessage = "Failure Make Primary. Try again or contact our support team";
                });
            }
        }
    };
</script>

<style lang="scss" scoped>
.profile-ttl {
	font-size: 24px;
	line-height: 27px;
	color: #464646;
	padding-bottom: 8px;
	border-bottom: 1px solid #E7E7E7;
}
.manage-btn {
	background-color: #9FF4A9;
	border: 1px solid #464646;
	box-sizing: border-box;
	border-radius: 50px;
	font-size: 14px;
	line-height: 18px;
	color: #464646;
	margin-left: auto;
    display: block;
    margin-top: 18px;
}
.user-profile-label {
	font-size: 14px;
	line-height: 18px;
	color: #464646;
}
</style>
