<template>
    <v-dialog
        v-model="dialog"
        max-width="620">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                class="manager-modelers__header--btn caption px-5 ml-5"
                outlined
                rounded
                small>
                <v-icon small>
                    mdi-plus
                </v-icon>
                Add New Modeler
            </v-btn>
        </template>

        <div class="dialog-overflow-wrap">
            <v-btn
                class="pci-ad-cl-btn"
                icon>
                <v-img
                    :src="require('@/assets/icons/ic-close-modal.svg')"
                    contain
                    height="36"
                    max-width="36"
                    @click="dialog = false"/>
            </v-btn>
            <v-card class="manager-modelers__header--add-form pa-0 py-12 px-15 blueish">
                <v-card-title class="px-0">
                    Add Modeler
                </v-card-title>

                <v-card-text class="add-form__info px-0 pb-9">
                    Upon adding a modeler, they will receive an email to confirm. <br>
                    Modeler will appear once they have confirmed
                </v-card-text>

                <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation>
                    <v-row>
                        <v-col
                            v-for="(field, i) in personalInfoFields"
                            :key="i"
                            cols="6">
                            <div class="body-2 mb-1">
                                {{ field.label }}
                            </div>
                            <div class="field-wrap">
                                <v-text-field
                                    v-model="formData[field.vModel]"
                                    :placeholder="field.placeholder"
                                    :required="!!field.validationRules"
                                    :rules="validationRules[field.validationRules]"
                                    :type="field.type"
                                    v-mask="field.vModel === 'phone' ? '####-###-####' : null"
                                    dense
                                    outlined/>
                                <div
                                    v-if="field.type === 'email'"
                                    class="v-text-field__details field-wrap__error-message">
                                    <div
                                        v-for="(error, index) in errors"
                                        :key="index">
                                        <div
                                            v-if="error.code === 'validation.unique'"
                                            class="v-messages theme--light error--text"
                                            role="alert">
                                            <div class="v-messages__wrapper">
                                                <div class="v-messages__message">
                                                    This email address has been already registered in the system
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </v-col>
                    </v-row>

                    <v-card-title class="px-0">
                        Skill Set
                    </v-card-title>

                    <v-row
                        v-for="(skill, i) in skills"
                        :key="i">
                        <v-col
                            class="pb-0"
                            cols="7">
                            <div class="body-2 mb-1">
                                Skill {{ i + 1 }}
                            </div>
                            <v-text-field
                                v-model="skill.title"
                                :rules="validationRules.skillsRules"
                                dense
                                outlined
                                placeholder="Skill"
                                required/>
                        </v-col>
                        <v-col
                            class="pb-0"
                            cols="5">
                            <div class="body-2 mb-1">
                                Level
                            </div>
                            <v-select
                                v-model="skill.level"
                                :items="skillLevels"
                                :menu-props="{ button: true, offsetY: true }"
                                :rules="validationRules.levelRules"
                                dense
                                outlined
                                required/>
                        </v-col>
                        <v-col
                            class="d-flex align-center pb-0"
                            cols="1">
                            <v-btn
                                v-if="skills.length > 1"
                                class="mt-0"
                                icon
                                @click="removeSkill(i)">
                                <svg
                                    fill="none"
                                    height="13"
                                    viewBox="0 0 12 13"
                                    width="12">
                                    <path
                                        d="M8.58023 11.7888L1.1324 3.42753C0.540753 2.76332 0.558585 1.76672 1.17355 1.12769C1.78852 0.488668 2.71123 0.507927 3.30288 1.17214L10.7855 9.57247C11.3772 10.2367 11.3593 11.2333 10.7444 11.8723C10.1294 12.5113 9.17187 12.453 8.58023 11.7888Z"
                                        fill="#7B7B7B"/>
                                    <path
                                        d="M1.04438 11.7813C0.452725 11.1171 0.470556 10.1205 1.08552 9.48148L8.82687 1.43726C9.44184 0.798238 10.3645 0.817498 10.9562 1.48171C11.5478 2.14592 11.53 3.14252 10.9151 3.78155L3.13753 11.8634C2.55873 12.4648 1.63603 12.4455 1.04438 11.7813Z"
                                        fill="#7B7B7B"/>
                                </svg>
                            </v-btn>
                        </v-col>
                    </v-row>

                    <!-- small -->
                    <v-btn
                        class="mt-0 py-0 add-form__new-skill"
                        rounded
                        text
                        @click="addNewSkill">
                        Add New Skill
                    </v-btn>

                    <v-card-actions class="pa-0 d-flex justify-end">
                        <v-btn
                            :class="{ 'disabledBtn': processing }"
                            :disabled="processing"
                            :loading="processing"
                            class="add-form__add-skill mt-10"
                            large
                            outlined
                            rounded
                            width="200"
                            @click="addModeler">
                            Add New Modeler
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </div>
    </v-dialog>
</template>

<script>
    import http from "@/api/httpClient.api";
    import { VueMaskDirective } from "v-mask";

    export default {
        name: "add-new-modeler-dialog",
        directives: {
            mask: VueMaskDirective
        },
        data: () => ({
            dialog: false,
            valid: false,
            processing: false,
            formData: {
                first_name: "",
                last_name: "",
                email: "",
                phone: "",
                password: ""
            },
            skillLevels: ["Beginner", "Proficient", "Expert"],
            personalInfoFields: [
                { label: "First Name", placeholder: "First Name", vModel: "first_name", type: "text", validationRules: "firstNameRules" },
                { label: "Last Name", placeholder: "Last Name", vModel: "last_name", type: "text", validationRules: "lastNameRules" },
                { label: "Email", placeholder: "email@email.com", vModel: "email", type: "email", validationRules: "emailRules" },
                { label: "Password", placeholder: "", vModel: "password", type: "password", validationRules: "passwordRules" },
                { label: "Phone Number", placeholder: "XXXX-XXX-XXXX", vModel: "phone", type: "tel", validationRules: "phoneRules" }
            ],
            skills: [{ title: "", level: "" }],
            validationRules: {
                emailRules: [
                    (v) => !!v || "Email is required field",
                    (v) => /.+@.+\..+/.test(v) || "Invalid email format, please try again"
                ],
                phoneRules: [
                    (v) => !!v || "Phone number is a required field"
                ],
                firstNameRules: [
                    (v) => !!v || "First Name is a required field"
                ],
                lastNameRules: [
                    (v) => !!v || "Last Name is a required field"
                ],
                skillsRules: [
                    (v) => !!v || "At least one skill must be added"
                ],
                levelRules: [
                    (v) => !!v || "Please set level for skill"
                ],
                passwordRules: [
                    (v) => (v || "").length <= 50 || "Max 50 characters"
                ]
            },
            errors: []
        }),
        methods: {
            addNewSkill () {
                const skill = {
                    title: "",
                    level: ""
                };
                this.skills.push(skill);
            },
            removeSkill (index) {
                if (this.skills.length === 1) {
                    return;
                }
                this.skills.splice(index, 1);
            },
            addModeler () {
                if (this.$refs.form.validate()) {

                    this.processing = true;
                    const params = {
                        ...this.formData,
                        skills: this.skills,
                        backup_email: "",
                        country: "",
                        time_zone: ""
                    };

                    http.post("/manager/modelers", params).then(() => {
                        this.$store.dispatch("manager/getModelerList");
                        this.dialog = false;
                        this.processing = false;
                    }).catch(error => {
                        console.log(error.response.data.errors);
                        this.errors = error.response.data.errors;
                        this.processing = false;
                    });
                }
            }
        },
        watch: {
            dialog (val) {
                if (!val) {
                    this.formData = {
                        first_name: "",
                        last_name: "",
                        email: "",
                        phone: ""
                    };
                    this.skills = [{ title: "", level: "" }];
                }
            }
        }
    };
</script>

<style lang="scss">
    // .blueish {
    //   color: #0B3C49 !important;
    // }
    // .grey-lightend {
    //   color: #4E4E4E !important;
    // }
    .manager-modelers__header {
        &--btn {
            color: #0046AF !important;
        }
        &--add-form {
            .add-form__new-skill {
                color: #0046AF !important;
                cursor: pointer;
                span {
                    font-size: 14px !important;
                }
            }
            .add-form__add-skill {
                background-color: #02810F !important;
                color: #fff !important;
            }
            .disabledBtn {
                pointer-events: none;
                color: #fff !important;
                background-color: #CBD2D0 !important;
            }
            .add-form__info {
                color: #4E4E4E !important;
                font-size: 16px !important;
            }
        }
    }
    .field-wrap {
        position: relative;
        &__error-message {
            position: absolute;
            bottom: -5px;
            .v-messages__message {
                line-height: 15px;
            }
        }
    }
</style>
