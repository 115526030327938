<template>
    <nav class="profile-menu">
        <router-link
            :to="{ name: 'user-personal-information' }"
            class="profile-menu-item"
            tag="div">
            <div class="pmi-header">
                <div class="pmi-header-ic">
                    <img :src="require('@/assets/user/user.svg')" alt="">
                </div>
            </div>
            <div class="pmi-desc">
                <div class="pmi-header-ttl">
                    Personal Information
                </div>
                <div class="placeholder">
                    Personalize your account and update your login preferences.
                </div>
            </div>
        </router-link>
        <router-link
            v-if="isClient || isDesigner"
            class="profile-menu-item mt-6"
            tag="div"
            :to="{ name: 'user-billing-information' }">
            <div class="pmi-header">
                <div class="pmi-header-ic">
                    <img :src="require('@/assets/user/billing-info.svg')" alt="">
                </div>
            </div>
            <div class="pmi-desc">
                <div class="pmi-header-ttl">
                    Billing Information
                </div>
                <div class="placeholder">
                    Add or remove cards. Update your payment methods.
                </div>
            </div>
        </router-link>
        <router-link
            v-if="isClient || isDesigner"
            class="profile-menu-item mt-6"
            tag="div"
            :to="{ name: 'user-order-history' }">
            <div class="pmi-header">
                <div class="pmi-header-ic">
                    <img :src="require('@/assets/user/order-history.svg')" alt="">
                </div>
            </div>
            <div class="pmi-desc">
                <div class="pmi-header-ttl">
                    Order History
                </div>
                <div class="placeholder">
                    Inspect all your projects you have created.
                </div>
            </div>
        </router-link>
        <router-link
            v-if="isClient"
            class="profile-menu-item mt-6"
            tag="div"
            :to="{ name: 'user-receipts' }">
            <div class="pmi-header">
                <div class="pmi-header-ic">
                    <img :src="require('@/assets/user/receipts.svg')" alt="">
                </div>
            </div>
            <div class="pmi-desc">
                <div class="pmi-header-ttl">
                    Receipts
                </div>
                <div class="placeholder">
                    View your transactions and download receipts.
                </div>
            </div>
        </router-link>
        <router-link
            v-if="isClient || isDesigner"
            class="profile-menu-item mt-6"
            tag="div"
            :to="{ name: 'user-notification' }">
            <div class="pmi-header">
                <div class="pmi-header-ic">
                    <img :src="require('@/assets/user/order-history.svg')" alt="">
                </div>
            </div>
            <div class="pmi-desc">
                <div class="pmi-header-ttl">
                    Notification Setting
                </div>
                <div class="placeholder">
                    Control what emails are being sent to you.
                </div>
            </div>
        </router-link>
    </nav>
</template>

<script>
    import { mapGetters } from "vuex";

    export default {
        name: "profile-menu",
        computed: {
            ...mapGetters([
                "isClient",
                "isDesigner"
            ])
        }
    };
</script>

<style lang="scss" scoped>
.router-link-active {
	background: #D9FBDD !important;
	border: 2px solid #464646 !important;
	box-shadow: 4px 4px 4px rgba(70, 70, 70, 0.15) !important;
}

.profile-menu-item {
    position: relative;
    cursor: pointer;
    transition: opacity 250ms ease;
    padding: 14px 25px;
	display: flex;
    align-items: center;
    background: transparent;
    border: 2px solid #464646;
    box-shadow: 4px 4px 4px rgba(70, 70, 70, 0.15);
    border-radius: 5px;

    &:hover {
        opacity: 0.7;
    }
}
.pmi-header {
    display: flex;
    align-items: center;
}
.pmi-header-ic {
    flex: 0 0 42px;
    max-width: 42px;
    width: 100%;
    margin-right: 20px;
    img {
        max-width: 100%;
        vertical-align: top;
    }
}
.pmi-header-ttl {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #464646;
    word-break: break-word;
}
.pmi-desc {
    font-size: 14px;
    line-height: 20px;
    color: #0b3c49;
	margin-left: 17px;
}
.pmi-ic {
    position: absolute;
    right: 0;
    top: 50%;
    line-height: 20px;
    margin-top: -10px;
    svg {
        vertical-align: top;
    }
}
</style>
