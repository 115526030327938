export function dragBoundFunc (data, scale = 1) {
    let { pos, minX, maxX, minY, maxY } = data;
    maxX = maxX * scale;
    maxY = maxY * scale;
    console.log("dragBoundFunc", data);
    let X = pos.x;
    let Y = pos.y;
    if (X < minX) {
        X = minX;
    }
    if (X > maxX) {
        X = maxX;
    }
    if (Y < minY) {
        Y = minY;
    }
    if (Y > maxY) {
        Y = maxY;
    }

    return {
        x: X,
        y: Y
    };
}

export function showCommentMenu (event = null, layer, item = null, commentData) {
    ;
    this.commentData = { ...commentData };
    //this.currentMarkerID = commentData.comment_id;
    const pos = {
        x: event ? event.offsetX ?? event.x : item.attrs.x,
        y: event ? event.offsetY ?? event.y : item.attrs.y
    };
    this.referenceMenu.style.display = "block";
    this.referenceMenu.style.top = (pos.y / this.canvasHeight) * 100 + "%";
    if (pos.y > this.canvasHeight / 2) {
        this.referenceMenu.style.top = "auto";
        this.referenceMenu.style.bottom =
            ((this.canvasHeight - pos.y + 24) / this.canvasHeight) * 100 + "%";
    } else {
        this.referenceMenu.style.bottom = "auto";
        this.referenceMenu.style.top =
            ((pos.y + 20) / this.canvasHeight) * 100 + "%";
    }

    if (pos.x > this.canvasWidth / 2) {
        this.referenceMenu.style.left = "auto";
        this.referenceMenu.style.right =
            ((this.canvasWidth - pos.x) / this.canvasWidth) * 100 + "%";
    } else {
        this.referenceMenu.style.right = "auto";
        this.referenceMenu.style.left =
            ((pos.x + 24) / this.canvasWidth) * 100 + "%";
    }
    if (typeof this.currentShowLines === "function") this.currentShowLines();
}

export function offset (elem) {
    let top = 0,
        left = 0;
    while (elem) {
        top = top + parseFloat(elem.offsetTop);
        left = left + parseFloat(elem.offsetLeft);
        elem = elem.offsetParent;
    }
    return { top: top, left: left };
}

export function getHeight (el, type = "full") {
    if (type === "inner")
        // .innerWidth()
    {
        return el.clientHeight;
    } else if (type === "outer")
        // .outerWidth()
    {
        return el.offsetHeight;
    }
    let s = window.getComputedStyle(el, null);
    if (type === "height")
        // .width()
    {
        return (
            el.clientHeight -
            parseInt(s.getPropertyValue("padding-top")) -
            parseInt(s.getPropertyValue("padding-bottom"))
        );
    } else if (type === "full")
        // .outerWidth(includeMargins = true)
    {
        return (
            el.offsetHeight +
            parseInt(s.getPropertyValue("margin-top")) +
            parseInt(s.getPropertyValue("margin-bottom"))
        );
    }
    return null;
}

export const checkLink = function (link) {
    let pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
        "i"
    ); // fragment locator
    return !!pattern.test(link);
};

export const scrollTo = function (x, y) {
    if (document.scrollingElement || document.documentElemen) {
        (document.scrollingElement || document.documentElemen)["scrollTop"] = y;
    } else if (window?.scrollTo) window.scrollTo(x, y);
};

/**
 * Convert a base64 string in a File.
 *
 * @param dataUrl {String} Base64 string with contentType
 * @param filename {String} the name of future file
 * @return File
 */
export const dataURLtoFile = function (dataUrl, filename) {
    var arr = dataUrl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
};

export const hasExtension = function (fileName = "", exts = []) {
    const result = new RegExp(
        "(" + exts.join("|").replace(/\./g, "\\.") + ")$"
    ).test(fileName.toLowerCase());
    if (result) return result;
};

export const getExtension = (filename) => {
    return filename.split(".").pop();
}

/**
 * Get width DOM element
 *
 * @param el DOM element
 * @param type {String} the type of width
 * @return Number
 */
export const getWidth = function (el, type = "full") {
    if (type === "inner")
        // .innerWidth()
    {
        return el.clientWidth;
    } else if (type === "outer")
        // .outerWidth()
    {
        return el.offsetWidth;
    }
    let s = window.getComputedStyle(el, null);
    if (type === "width")
        // .width()
    {
        return (
            el.clientWidth -
            parseInt(s.getPropertyValue("padding-left")) -
            parseInt(s.getPropertyValue("padding-right"))
        );
    } else if (type === "full")
        // .outerWidth(includeMargins = true)
    {
        return (
            el.offsetWidth +
            parseInt(s.getPropertyValue("margin-left")) +
            parseInt(s.getPropertyValue("margin-right"))
        );
    }
    return null;
};

/**
 * Capitalize a string
 * @param str
 * @returns {string}
 */
export const capitalize = (str) => typeof str === "string"  ? `${str.charAt(0).toUpperCase()}${str.slice(1)}` : str;


/**
 * Script tag injecting.
 * @param url
 */
export const addScriptTag = (url) => {
    const script = document.createElement("script");

    script.setAttribute("src", url);

    document.head.appendChild(script);
}

/**
 *  Convert bytes to mb
 * @param size
 * @returns {number}
 */
export const bytesToMb = (size) => {
    return size / (1024 * 1000)
}

/**
 *  Convert mb to kb
 * @param size
 * @returns {number}
 */
export const MbToKb = (size) => {
    return  1000 * size
}

export const copyTextToClipboard = text => {
    const input = document.createElement("input");
    input.type = "text";
    input.value = text;

    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);
}
