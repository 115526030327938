import { userApi } from "@/api";

const PROFILE_INITIAL = {
    avatar: null,
    email: null,
    firstName: "User",
    id: null,
    lastName: "",
    role: null,
    createdAt: 0,
    updatedAt: 0
};

export default {
    state: {
        profile: PROFILE_INITIAL
    },
    getters: {
        profile (state) {
            return state.profile;
        },
        isAdmin (state) {
            return state.profile.role && state.profile.role === "Admin";
        },
        isManager (state) {
            return state.profile.role && state.profile.role === "Manager";
        },
        isDesigner (state) {
            return state.profile.role && state.profile.role === "Designer";
        },
        isClient (state) {
            return state.profile.role && state.profile.role === "Client";
        },
        isManagerOrAdmin(state) {
            return state.profile.role && (state.profile.role === "Manager" || state.profile.role === "Admin");
        }
    },
    mutations: {
        SET_USER_PROFILE (state, payload) {
            state.profile = {
                ...payload,
                firstName: payload.firstName || "User"
            };
        },
        CLEAR_PROFILE (state) {
            state.profile = PROFILE_INITIAL;
        }
    },
    actions: {
        getProfile ({ commit }) {
            return new Promise(resolve => {
                userApi.getProfile().then((data) => {
                    commit("SET_USER_PROFILE", data);
                    commit("setUser", data);
                    resolve(data)
                });
            })
        }
    }
};
