<template>
    <v-dialog
        v-bind="$attrs"
        v-on="$listeners"
        width="520"
        @keydown.esc="$emit('cancel')"
        @click:outside="$emit('cancel')"
        content-class="delete-all-versions-modal pa-8">
        <h4 class="mb-8">Are you sure you want to delete this render?</h4>
        <div class="d-flex justify-end">
            <v-btn
                width="110"
                height="40"
                outlined
                rounded
                @click="$emit('cancel')"
                class="mr-3">
                <template>Cancel</template>
            </v-btn>
            <v-btn
                width="110"
                height="40"
                outlined
                rounded
                :loading="isDeleting"
                @click="$emit('delete')"
                class="delete-btn">
                <template>Delete</template>
            </v-btn>
        </div>
    </v-dialog>
</template>

<script>
    export default {
        name: "dialog-delete-render-asset",
        props: {
            isDeleting: {
                type: Boolean,
                default: false
            }
        }
    }
</script>

<style lang="scss" scoped>
 ::v-deep .v-dialog.delete-all-versions-modal {
    border-radius: 0!important;
    background-color: #fff;
    box-shadow: 4px 4px 4px rgba(70, 70, 70, 0.15)!important;
    color: #464646;
    h4 {
        font-size: 24px;
        line-height: 27px;
        font-weight: 600;
        color: #464646;
    }
    p {
        font-size: 18px;
        line-height: 23px;
    }
    .v-btn {
        font-size: 14px;
        letter-spacing: 0px;
        &.delete-btn {
            background-color: #9ff4a9;
        }
    }
}
</style>

