import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                "red-100": "#D00E0E",
                "red-30": "#FF9797",


                "green-100": "#3F7359",
                "green-60": "#9FF4A9",


                orange: "#FB3805",
                purple: "#C81C31",
                link: "#0046AF",
                "main-text": "#464646"
            }
        }
    },
    breakpoint: {
        thresholds: {
            xs: 767,
            sm: 768
        }
    }
});
