import http, { getData } from "@/api/httpClient.api";

//import axios from "axios";

// const http = axios.create({
//   baseURL: "http://localhost:8080",
//   headers: {
//     "Content-type": "application/json",
//   }
// });
export const transformFiles = (dataArr) => {
    if (dataArr && Array.isArray(dataArr)) {
        return dataArr.map((item) => transformFile(item));
    }

    new Error("data in _transformArr must be Array");
};

export const transformFile = (data) => {
    return {
        ...data,
        id: data.id,
        name: data["original_name"],
        type: data.type,
        createdAt: moment(data.created_at).unix(),
        updatedAt: moment(data.updated_at).unix(),
        url: data.url,
        comments: []
        // markers: [
        //   {
        //     type: 1, // 2, 3 	Type (1 - Comment, 2 - Stone, 3 - Metal)
        //     id: 201,
        //     x: 150,
        //     y: 150
        //   }
        // ]
    };
};

class UploadFilesServiceApi {
    updateProps (file_id, props) {
        return http.patch(`/files/${file_id}`, { "props": props }).then(res => {
            if (res.status === 200) {
                return transformFile(res.data.data)
            }
        }).catch(err => {

        });
    }

    upload2 = async (file, props) => {
        let formData = new FormData();

        formData.append("file", file);
        Object.keys(props).forEach((key) => {
            formData.append(key, props[key]);
        });
        console.warn("upload2:file", file);
        console.warn("upload2:props", props);
        const res = await http.post("/files", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });

        return transformFile(res?.data?.data);
    };

    upload = async (file, onUploadProgress) => {

        let formData = new FormData();

        formData.append("file", file);
        formData.append("type", file.typeImage);

        if (file.parent_id) {
            formData.append("parent_id", file.parent_id);
        }

        if (file.round_base_file_id) {
            formData.append("round_base_file_id", file.round_base_file_id);
        }

        //  return  Promise.reject()

        const res = await http.post("/files", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress
        });

        return transformFile(res?.data?.data);
    };

    getFiles = async () => {
        const res = await http.get("/files");
        transformFile(res?.data?.data);
    };

    removeFile (id, allVersions) {
        if (id === undefined) {
            return Promise.reject("id of project is undefined");
        }
        return http.delete(`/files/${id}`, { params: { all_versions: allVersions ? 1 : 0 } });
    }

    getFilesByProject = async (id) => {
        if (id === undefined || id === null) {
            return Promise.reject("id of project is undefined");
        }
        const data = await getData({ path: `/projects/${id}/files` });
        return transformFiles(data?.data);
    };

    filterFiles (arr) {
        return arr.filter((item) => !(item instanceof File)).map((item) => item.id);
    }
}

export default new UploadFilesServiceApi();
