<template>
    <v-container fluid class="price-config py-0 pb-12 px-6">
        <loader v-if="isAdmin && loading"/>

        <template v-if="isAdmin && !loading">
            <div class="price-config__header d-flex flex-wrap align-center mt-12 mb-10">
                <h1 class="mr-auto">Price Configuration</h1>
                <div class="mt-2">
                    <v-btn
                        v-if="isDirty"
                        outlined
                        rounded
                        width="110"
                        height="38"
                        @click="cancelChanges"
                        color="#464646"
                        class="mr-3">
                        Cancel
                    </v-btn>
                    <v-btn
                        v-if="isDirty"
                        outlined
                        rounded
                        width="110"
                        height="38"
                        @click="saveChanges"
                        color="#464646"
                        style="background-color:#9FF4A9;">
                        Save
                    </v-btn>
                </div>
            </div>


            <div class="price-config-category mb-10">
                <h3 class="mb-4 text-uppercase font-weight-regular">Jewelry type</h3>
                <div class="mb-10">
                    <h5 class="mb-4 text-caption font-weight-regular">Client price</h5>
                    <div class="d-flex flex-wrap">
                        <div v-for="(jewelry_type, idx) in data.jewelry_types"
                             :key="idx" class="price-config-category-row mr-4">
                            <div><span>{{ jewelry_type.type }}</span></div>
                            <number-input
                                v-model="jewelry_type.cost"
                                type="currency" :min="0"
                                :precision="0"/>
                        </div>
                    </div>
                </div>

                <div>
                    <h5 class="mb-4 text-caption font-weight-regular">Base Designer Compensation</h5>
                    <div class="d-flex flex-wrap">
                        <div v-for="(jewelry_type, idx) in data.designer_compensation"
                             :key="idx" class="price-config-category-row mr-4">
                            <div><span>{{ jewelry_type.type }}</span></div>
                            <number-input
                                v-model="jewelry_type.cost"
                                type="currency" :min="0"
                                :precision="0"/>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </v-container>
</template>

<script>
    import { mapGetters } from "vuex";
    import { adminApi } from "@/api";
    import NumberInput from "@/components/ui/number-input";
    import Loader from "@/components/ui/Loader";

    export default {
        components: {
            NumberInput,
            Loader
        },
        data() {
            return {
                loading: true,
                shallowData: [],
                data: {
                    overall_margin: null,
                    rendering_price: null,
                    jewelry_types: [],
                    designer_compensation: []
                }
            }
        },
        computed: {
            ...mapGetters([
                "isAdmin"
            ]),
            isDirty() {
                return JSON.stringify(this.shallowData) !== JSON.stringify(this.data);
            }
        },
        async created() {
            const res = await adminApi.getPriceConfig();
            this.data = this.parseDataFromBackend(res.data);
            this.shallowData = JSON.parse(JSON.stringify(this.data));

            this.loading = false;
        },
        methods: {
            cancelChanges() {
                this.data = JSON.parse(JSON.stringify(this.shallowData));
            },
            async saveChanges() {
                this.loading = true;

                const { jewelry_types,designer_compensation } = this.data;
                const res = await adminApi.updatePriceConfig({
                    price_configurations: [ ...jewelry_types, ...designer_compensation]
                });

                this.data = this.parseDataFromBackend(res.data.data);
                this.shallowData = JSON.parse(JSON.stringify(this.data));

                console.log("Update price config", res.data.data);
                this.loading = false;
            },
            parseDataFromBackend(data) {
                return {
                    jewelry_types: data.filter(item => item.cost_type === this.$config.priceConfigTypes.jewelry_type),
                    designer_compensation: data.filter(item => item.cost_type === this.$config.priceConfigTypes.designer_compensation)
                }
            }
        }
    }
</script>

<style lang="scss">
.price-config {
    max-width: 1158px;

    .price-config__header h1 {
        font-size: 36px;
        line-height: 40px;
        color: #464646;
    }

    .v-btn--outlined .v-btn__content {
        font-size: 14px;
        letter-spacing: 0;
    }

    .price-config-category {
        h3 {
            font-size: 18px;
            line-height: 20px;
            color: #464646;
        }

        .price-config-category-row {
            width: 140px;
            margin-bottom: 6px;
            span {
                font-size: 14px;
                line-height: 18px;
                font-weight: 600;
            }
        }

        .price-config-category-col {
            width: 200px;
            margin-right: 60px;

            .price-config-category-row {
                width: 100%;
                & > div:first-child {
                    width: 90px;
                }

                & > div:last-child {
                    width: 100px;
                }
            }
        }
    }

}
</style>
