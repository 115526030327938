var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{staticClass:"projects-reassign-dialog",attrs:{"max-width":_vm.maxWidth},scopedSlots:_vm._u([{key:"activator",fn:function({ attrs }){return [(_vm.type === 'completed')?[_c('v-btn',_vm._b({staticClass:"caption",attrs:{"rounded":"","small":"","text":"","disabled":_vm.amount === 0},on:{"click":function($event){return _vm.openDialog('completed')}}},'v-btn',attrs,false),[_c('span',{staticClass:"link--text"},[_vm._v(_vm._s(_vm.amount)+" Completed")])])]:[(Object.keys(_vm.ongoing).length !== 0)?_vm._l((_vm.ongoing),function(project,i){return _c('v-row',_vm._b({key:i,staticClass:"pa-0 ma-0 pb-3",on:{"click":function($event){return _vm.openDialog('ongoing')}}},'v-row',attrs,false),[_c('v-col',{staticClass:"pa-0 font-weight-bold",staticStyle:{"color":"#7B7B7B"},attrs:{"cols":"1"}},[_vm._v(" "+_vm._s(project)+" ")]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"11"}},[_c('v-chip',{class:{
                                'in-progress': i === 'In Progress',
                                'input-needed': i === 'Input Needed',
                                'pending-approval': i === 'Pending Approval',
                                'open-for-work': i === 'Open For Work',
                                'ready-for-review': i === 'Ready For Review',
                                'draft': i === 'Draft',
                            },style:(_vm.chipStyles)},[_vm._v(" "+_vm._s(i)+" ")])],1)],1)}):[_c('span',{staticClass:"brightGreen"},[_vm._v("Available")])]]]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',{staticClass:"dialog-overflow-wrap"},[_c('v-btn',{staticClass:"pci-ad-cl-btn",attrs:{"icon":""}},[_c('v-img',{attrs:{"src":require('@/assets/icons/ic-close-modal.svg'),"contain":"","height":"36","max-width":"36"},on:{"click":_vm.closeDialog}})],1),(_vm.projectsView)?[_c('projects-view',{attrs:{"id":_vm.id,"amount":_vm.amount,"full-name":_vm.fullName,"ongoing":_vm.ongoing,"type":_vm.type},on:{"showReassignView":_vm.showReassignView}})]:[_c('reassign-view',{attrs:{"current-modeler-i-d":_vm.id,"full-name":_vm.fullName,"on-going-project-id":_vm.onGoingProjectId},on:{"closeDialog":function($event){_vm.dialog = false}}})]],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }