<template>
    <project-footer>
        <div class="def-page-cont pos-rel">
            <dialog-cancel-project
                v-model="cancelDialog"
                :project="project"/>
            <div class="d-flex align-center">
                <div class="breadcrumbs-group">
                    <div
                        class="bs-back-link"
                        @click="$router.go(-1)">
                        <div class="circle">
                            <img
                                src="@/assets/_redesigne/icons/ic-shevron-left.svg"
                                alt="">
                        </div>
                        Back
                    </div>
                    <breadcrumbs
                        :items="breadcrumbs"
                        class="inline-bc"/>
                    <project-step-dropdown class="project-step-dd" :links="projectStepsLinks" :selected="currentLink"/>
                </div>
                <div class="d-flex align-center controls-group" v-if="!isProjectCompleted">
                    <v-btn
                        v-if="allowedCancel(project.status_id) && isClient"
                        elevation="0"
                        outlined
                        rounded
                        height="24"
                        color="#FB3805"
                        small
                        class="btn--link px-5 mr-3"
                        @click.stop="cancelDialog = true">
                        Cancel Project
                    </v-btn>
                    <v-btn
                        v-if="!isManagerOrAdmin"
                        class="px-5 ml-3 issues-and-support"
                        link
                        rounded
                        text
                        height="24"
                        small
                        :to="{name: 'support'}">
                        Issues and support
                    </v-btn>

                    <v-btn
                        v-if="projectIsOnHold && (isClient || isManagerOrAdmin)"
                        outlined
                        width="160"
                        height="48"
                        rounded
                        color="main-text"
                        class="ml-auto bg-primary"
                        @click="$emit('openExtraChargeDialog')">
                        Continue
                    </v-btn>
                </div>
            </div>
        </div>
    </project-footer>
</template>

<script>
    import { mapGetters } from "vuex";
    import DialogCancelProject from "@/components/ui/dialog-cancel-project";
    import Breadcrumbs from "@/components/Breadcrumbs";
    import ProjectStepDropdown from "@/components/ProjectStepDropdown"
    import ProjectFooter from "@/components/project/ProjectFooter";

    export default {
        components: {
            ProjectFooter,
            DialogCancelProject,
            Breadcrumbs,
            ProjectStepDropdown
        },
        mounted(){
            this.breadcrumbs.push({
                text: this.project ? this.project.title : "",
                href: null
            })
        },
        data () {
            return {
                cancelDialog: false,
                breadcrumbs: [
                    {
                        text: "Dashboard",
                        href: "/dashboard"
                    }
                ]
            };
        },
        computed: {
            ...mapGetters([
                "isClient",
                "isManager",
                "isAdmin",
                "isManagerOrAdmin",
                "getProjectData",
                "allowedCancel",
                "projectIsOnHold"
            ]),

            currentLink(){
                return this.projectStepsLinks ? this.projectStepsLinks.find(link => link.to.name === this.$route.name) || this.projectStepsLinks : null
            },

            projectStepsLinks() {
                return [
                    {
                        to: { name:"project", params: { id: this.$route.params.id } },
                        title: "References & Comments",
                        disabled: false,
                        visible: !this.isClient
                    },
                    {
                        to: { name:"project-assets", params: { id: this.$route.params.id } },
                        title: "Final Assets",
                        disabled: false,
                        visible: true
                    },
                    {
                        to: { name:"render-review", params: { id: this.$route.params.id } },
                        title: "Render Review",
                        disabled: false,
                        visible: true
                    }
                ].filter(link => link.visible)
            },
            isProjectCompleted () {
                return this.$store.getters["manager/isProjectCompleted"];
            },
            project () {
                return this.$store.state.manager.project;
            },
            type () {
                return this.$store.getters["getKeyProjectType"](this.project.type);
            }
        }
    };
</script>


<style scoped lang="scss">
    .breadcrumbs-group {
        display: flex;
        align-items: center;

        .bs-back-link {
            font-size: 14px;
            line-height: 18px;
            color: #3f7359;

            .circle {
                width: 22px;
                height: 22px;
                border-radius: 100%;
                border: 1px solid #888888;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 6px;

                img {
                display: block;
                width: 5px;
                height: 10px;
                }
            }
        }
    }

    .controls-group {
        margin-left: auto;
    }

    .inline-bc {
        margin-right: 20px;
    }

    .issues-and-support {
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: #3F7359;
        text-decoration: underline;
    }
</style>
