<template>
    <v-dialog
        v-model="dialog"
        :max-width="maxWidth"
        class="projects-reassign-dialog">
        <template v-slot:activator="{ attrs }">
            <template v-if="type === 'completed'">
                <v-btn
                    v-bind="attrs"
                    class="caption"
                    rounded
                    small
                    text
                    :disabled="amount === 0"
                    @click="openDialog('completed')">
                    <span class="link--text">{{ amount }} Completed</span>
                </v-btn>
            </template>

            <template v-else>
                <template v-if="Object.keys(ongoing).length !== 0">
                    <v-row
                        v-for="(project, i) in ongoing"
                        :key="i"
                        v-bind="attrs"
                        class="pa-0 ma-0 pb-3"
                        @click="openDialog('ongoing')">
                        <v-col
                            class="pa-0 font-weight-bold"
                            cols="1"
                            style="color: #7B7B7B">
                            {{ project }}
                        </v-col>
                        <v-col
                            class="pa-0"
                            cols="11">
                            <v-chip
                                :class="{
                                    'in-progress': i === 'In Progress',
                                    'input-needed': i === 'Input Needed',
                                    'pending-approval': i === 'Pending Approval',
                                    'open-for-work': i === 'Open For Work',
                                    'ready-for-review': i === 'Ready For Review',
                                    'draft': i === 'Draft',
                                }"
                                :style="chipStyles">
                                {{ i }}
                            </v-chip>
                        </v-col>
                    </v-row>
                </template>
                <template v-else>
                    <span class="brightGreen">Available</span>
                </template>
            </template>
        </template>

        <div class="dialog-overflow-wrap">
            <v-btn
                class="pci-ad-cl-btn"
                icon>
                <v-img
                    :src="require('@/assets/icons/ic-close-modal.svg')"
                    contain
                    height="36"
                    max-width="36"
                    @click="closeDialog"/>
            </v-btn>

            <template v-if="projectsView">
                <projects-view
                    :id="id"
                    :amount="amount"
                    :full-name="fullName"
                    :ongoing="ongoing"
                    :type="type"
                    @showReassignView="showReassignView"/>
                <!-- :items="items" -->
            </template>

            <template v-else>
                <reassign-view
                    :current-modeler-i-d="id"
                    :full-name="fullName"
                    :on-going-project-id="onGoingProjectId"
                    @closeDialog="dialog = false"/>
            </template>
        </div>
    </v-dialog>
</template>

<script>
    import ReassignView from "./ReassignView.vue";
    import ProjectsView from "./ProjectsView.vue";

    export default {
        components: {
            ReassignView,
            ProjectsView
        },
        name: "projects-reassign-dialog",
        props: {
            type: {
                type: String,
                required: true
            },
            ongoing: {
                type: Object,
                required: false,
                default: () => {
                    return {};
                }
            },
            amount: {
                type: Number,
                required: false,
                default: 0
            },
            id: {
                type: Number,
                required: true
            },
            firstName: {
                type: String,
                required: true
            },
            lastName: {
                type: String,
                required: true
            }
        },
        data: () => ({
            dialog: false,
            // items: [],
            projectsView: true,
            onGoingProjectId: "",
            onGoingProjectTEST: "",
            chipStyles: {
                cursor: "pointer",
                borderRadius: "4px",
                height: "21px",
                marginLeft: "5px"
            }
        }),
        methods: {
            openDialog (type) {
                if (type === "completed") {
                    if (this.amount > 0) {
                        this.dialog = true;
                        this.fetchModelersProjects(this.type);
                    }
                } else {
                    this.dialog = true;
                    this.fetchModelersProjects(this.type);
                }
            },
            fetchModelersProjects (type) {

                this.$store.dispatch("manager/fetchModelersProjects", { id: this.id, type, filters: [] });

                // http
                //   .get(`/manager/modelers/${this.id}/projects/${type}`)
                //   .then(({data}) => this.items = data.data)
                //   .catch((e) => console.log(e))
            },
            showReassignView (id) {
                this.onGoingProjectId = id;
                this.projectsView = false;
            },
            closeDialog () {
                this.projectsView = true;
                this.dialog = false;
            }
        },
        computed: {
            maxWidth () {
                if (this.type === "completed") {
                    return 635;
                }
                return 810;
            },
            fullName () {
                return `${this.firstName} ${this.lastName}`;
            }
        },
        watch: {
            dialog (val) {
                if (val) {
                    this.projectsView = true;
                }
            }
        }
    };
</script>

<style lang="scss">
    .dialog-overflow-wrap {
        position: relative;
    }
    .in-progress, .payment-awaiting {
        background-color: rgba(255, 245, 0, 0.05) !important;
        border-color: rgba(255, 245, 0, 0.05) !important;
        color: rgb(121, 117, 13) !important;
        caret-color: rgb(121, 117, 13) !important;
        text-transform: uppercase !important;
        font-size: 12px !important;
    }
    .input-needed {
        background-color: rgba(208, 14, 14, 0.05) !important;
        border-color: rgba(208, 14, 14, 0.05) !important;
        color: rgb(208, 14, 14) !important;
        caret-color: rgb(208, 14, 14) !important;
        text-transform: uppercase !important;
        font-size: 12px !important;
    }
    .pending-approval, .failure {
        background-color: rgba(232, 111, 0, 0.05) !important;
        border-color: rgba(232, 111, 0, 0.05) !important;
        color: rgb(165, 49, 0) !important;
        caret-color: rgb(165, 49, 0) !important;
        text-transform: uppercase !important;
        font-size: 12px !important;
    }
    .open-for-work, .payment-success {
        background-color: rgba(0, 146, 32, 0.05) !important;
        border-color: rgba(0, 146, 32, 0.05) !important;
        color: rgb(0, 102, 22) !important;
        caret-color: rgb(0, 102, 22) !important;
        text-transform: uppercase !important;
        font-size: 12px !important;
    }
    .ready-for-review {
        background-color: rgba(0, 70, 175, 0.05) !important;
        border-color: rgba(0, 70, 175, 0.05) !important;
        color: rgb(0, 70, 175) !important;
        caret-color: rgb(0, 70, 175) !important;
        text-transform: uppercase !important;
        font-size: 12px !important;
    }
    .draft {
        text-transform: uppercase !important;
        font-size: 12px !important;
    }
</style>
