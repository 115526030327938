<template>
    <v-container
        class="pa-6"
        fluid>
        <div class="manager-modelers">
            <div class="manager-modelers__header d-flex justify-space-between align-center mb-6">
                <div class="d-flex align-center">
                    <h1>Clients</h1>
                </div>
                <!-- <div>
                  <v-menu
                    :close-on-content-click="false"
                    bottom
                    offset-y
                    nudge-top="1"
                    z-index="1"
                    content-class="pf-drd-wrap"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div class="pf-drd-bw">
                        <div class="pf-drd-btn" v-bind="attrs" v-on="on">
                          Filter by:
                          <svg
                            width="12"
                            height="7"
                            viewBox="0 0 12 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M1.70724 0.29275C1.31624 -0.09825 0.684239 -0.09825 0.293239 0.29275C-0.0977611 0.68375 -0.0977611 1.31575 0.293239 1.70675L5.29324 6.70675C5.68424 7.09775 6.31624 7.09775 6.70724 6.70675L11.7072 1.70675C12.0982 1.31575 12.0982 0.68375 11.7072 0.29275C11.3162 -0.0982499 10.6842 -0.0982499 10.2932 0.29275L6.00024 4.58575L1.70724 0.29275Z"
                              fill="#656565"
                            />
                          </svg>
                        </div>
                      </div>
                    </template>
                    <div class="pf-drd-cw">
                      <div class="pf-drd-sect">
                        <div class="pf-drd-ttl">Status</div>
                        <div class="pf-statuses">
                          <v-chip
                            v-for="(status, i) in statuses"
                            :key="i"
                            :class="status.class"
                            class="ma-2"
                            :style="statusesChipsStyles"
                            @click="filterByStatus(status.title, status.id)"
                          >
                            {{ status.title }}
                          </v-chip>
                        </div>
                      </div>
                    </div>
                  </v-menu>
                </div> -->
            </div>

            <div
                v-if="loading"
                class="page-preloader local"/>

            <v-data-table
                v-else
                :headers="headers"
                :items="clients"
                :items-per-page="pagination.per_page"
                class="data-table__all-modelers"
                hide-default-footer>
                <template #[`item.avatar`]="{ item }">
                    <v-menu
                        min-width="220"
                        max-width="220"
                        nudge-right="10"
                        nudge-top="7"
                        offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                                class="data-table__all-modelers--dots ml-1 mr-2"
                                small>
                                mdi-dots-vertical
                            </v-icon>
                        </template>
                        <v-list class="data-table__all-modelers--actions py-0">
                            <v-list-item @click="takeOverUser(item.id)">
                                <div class="text-body-2 actions-item__edit">Take Over</div>
                            </v-list-item>
                        </v-list>
                    </v-menu>

                    <v-avatar size="32">
                        <img
                            :src="item.avatar && item.avatar.length > 0 ? item.avatar : avatarStub"
                            :alt="`${item.first_name} ${item.last_name}`">
                    </v-avatar>
                </template>
                <template #[`item.fullName`]="{ item }">
                    <client-dialog
                        :client-email="item.email"
                        :client-id="item.id"
                        :client-phone="item.phone"
                        :first-name="item.first_name"
                        :last-name="item.last_name || ''"/>
                </template>

                <template #[`item.updated_at`]="{ item }">
                    {{ $moment(item.updated_at).format("MM/DD/YY") }}
                </template>

                <template #[`item.projects_completed`]="{ item }">
                    <completed-dialog
                        :client-id="item.id"
                        :first-name="item.first_name"
                        :last-name="item.last_name || ''"
                        :projects-completed="item.projects_completed"/>
                </template>

                <template #[`item.total_spent`]="{ item }">
                    $ {{ item.total_spent }}
                </template>

                <template #[`item.projects_ongoing`]="{ item }">
                    <ongoing-dialog
                        :client-id="item.id"
                        :first-name="item.first_name"
                        :last-name="item.last_name || ''"
                        :projects-ongoing="item.projects_ongoing"/>
                </template>
            </v-data-table>

            <div v-if="showPagination && !loading" class="text-sm-center mt-4 mb-6">
                <v-pagination
                    v-model="paginationPage"
                    :length="pagination.total_pages"
                    :total-visible="6"
                    class="pf-pagination"
                    color="#515151"
                    next-aria-label="Next"
                    previous-aria-label="Previous"
                    @input="changePage"/>
            </div>
        </div>
    </v-container>
</template>

<script>
    import { mapGetters } from "vuex";
    import { userApi } from "@/api";
    import OngoingDialog from "./OngoingDialog.vue";
    import CompletedDialog from "./CompletedDialog.vue";
    import ClientDialog from "./ClientDialog.vue";
    import avatarStub from "@/assets/img/user-avatar-stub.svg"

    export default {
        components: {
            OngoingDialog,
            CompletedDialog,
            ClientDialog
        },
        data () {
            return {
                avatarStub: avatarStub,
                headers: [
                    { text: "", align: "start", sortable: false, value: "avatar" },
                    { text: "Name", align: "start", sortable: false, value: "fullName" },
                    { text: "Ongoing Projects", value: "projects_ongoing" },
                    { text: "Last active", value: "updated_at" },
                    { text: "Completed Projects", value: "projects_completed" },
                    { text: "Total spent", value: "total_spent" }
                ],
                statuses: [
                    { id: 0, class: "pending-approval", title: "Pending Approval" },
                    { id: 1, class: "input-needed", title: "Input Needed" },
                    { id: 2, class: "in-progress", title: "In Progress" },
                    { id: 5, class: "ready-for-review", title: "Ready For Review" }
                ],
                statusesChipsStyles: {
                    cursor: "pointer",
                    borderRadius: "18px"
                },
                loading: true,
                paginationPage: 1
            };
        },
        methods: {
            filterByStatus (status, ID) {
                this.$store.commit("manager/FILTER_BY_STATUS", { status, ID, role: "client" });
            },
            async takeOverUser(user_id) {
                const res = await userApi.loginAsUser({ user_id });
                localStorage.setItem("jwt_token", JSON.stringify(res.data.data));
                localStorage.setItem("fallback_jwt_token", JSON.stringify({
                    ...this.tokenData,
                    email: this.profile.email
                }));

                window.location.href = "/dashboard";
            },
            async loadClientsList(params = {}) {
                this.loading = true;

                await this.$store.dispatch("manager/getClientsList", params);
                this.loading = false;
            },
            changePage() {
                this.loadClientsList({ page: this.paginationPage });
            }
        },
        computed: {
            ...mapGetters([
                "tokenData",
                "profile"
            ]),
            clients () {
                return this.$store.state.manager.clients;
            },
            pagination() {
                return this.$store.state.manager.clientsListPagination;
            },
            showPagination() {
                return this.pagination?.total_pages > 1;
            }
        },
        created() {
            this.loadClientsList();
        }
    };
</script>
