var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"pa-0 py-12 px-15"},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('v-card-title',{staticClass:"px-0 assign-modeler-title"},[_vm._v(" Assign new modeler? ")]),_c('v-card-subtitle',{staticClass:"pa-0 ma-0"},[_c('span',{staticClass:"font-weight-bold current-modeler"},[_vm._v(" Current Modeler: ")]),_vm._v(" "+_vm._s(_vm.fullName)+" ")])],1),_c('v-card-text',{staticClass:"px-0 pb-9 grey-lightend body-1",staticStyle:{"font-size":"14px","line-height":"18px","color":"#4e4e4e"}},[_vm._v(" Assigning a modeler will notify the modeler. The current modeler will be notified that they have been moved off the project. ")]),_c('v-row',{staticClass:"d-flex align-center"},[_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"grey-lightend font-weight-bold",staticStyle:{"font-size":"14px","line-height":"18px","color":"#4e4e4e"}},[_vm._v(" Assign Modeler: ")])]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-select',{attrs:{"items":_vm.modelers,"menu-props":{ button: true, offsetY: true },"dense":"","hide-details":"","item-value":"id","outlined":""},scopedSlots:_vm._u([{key:"selection",fn:function({ item }){return [_vm._v(" "+_vm._s(item.first_name)+" "+_vm._s(item.last_name)+" ")]}},{key:"item",fn:function({ item }){return [_vm._v(" "+_vm._s(item.first_name)+" "+_vm._s(item.last_name)+" ")]}}]),model:{value:(_vm.modeler),callback:function ($$v) {_vm.modeler=$$v},expression:"modeler"}})],1),_c('v-col')],1),(_vm.onGoingProject)?_c('div',[_c('div',{staticClass:"d-flex align-center justify-space-between mt-8"},[_c('div',[_c('div',{staticClass:"text-h6 font-weight-regular"},[_vm._v(" "+_vm._s(_vm.onGoingProject.title)+" ")]),_c('v-chip',{staticClass:"pf-chip mr-4 mb-4",class:{
                        'in-progress':
                            _vm.onGoingProject.status === 'In Progress',
                        'input-needed':
                            _vm.onGoingProject.status === 'Input Needed',
                        'pending-approval':
                            _vm.onGoingProject.status === 'Pending Approval',
                        'open-for-work':
                            _vm.onGoingProject.status === 'Open For Work',
                        'ready-for-review':
                            _vm.onGoingProject.status === 'Ready For Review',
                    },style:(_vm.chipStyles)},[_vm._v(" "+_vm._s(_vm.onGoingProject.status)+" ")])],1),_c('div',{staticClass:"pci-ad-price"},[_vm._v(" $"+_vm._s(_vm.onGoingProject.overall_designer_amount)+" ")])]),_c('div',{staticClass:"pci-ad-options mt-6"},[_c('div',{staticClass:"pci-ad-opt-item"},[_vm._v(" Due date: TODO ")]),_c('div',{staticClass:"pci-ad-opt-item mt-4"},[_vm._v(" Created: "),_c('span',{staticClass:"pci-ad-oi-val"},[_vm._v(" "+_vm._s(this.$moment(_vm.onGoingProject.created_at).format( "MM/DD/YY" ))+" ("+_vm._s(_vm.onGoingProject.user)+") ")])]),_c('div',{staticClass:"pci-ad-opt-item mt-4"},[_vm._v(" Project type: "),_c('span',{staticClass:"pci-ad-oi-val"},[_vm._v(_vm._s(_vm.onGoingProject.type))])]),_c('div',{staticClass:"pci-ad-opt-item mt-4"},[_vm._v(" Project id: "),_c('span',{staticClass:"pci-ad-oi-val"},[_vm._v(_vm._s(_vm.onGoingProject.id))])])]),_c('div',[(
                    _vm.onGoingProject.metals ||
                        _vm.onGoingProject.stones ||
                        _vm.onGoingProject.description
                )?[_c('p',{staticClass:"mt-7 cursor-pointer green-link",on:{"click":_vm.showInfo}},[_vm._v(" Show more information "),(!_vm.isInfoVisible)?_c('v-icon',{staticStyle:{"text-decoration":"none"},attrs:{"color":"#3F7359"}},[_vm._v(" mdi-chevron-right ")]):_vm._e(),(_vm.isInfoVisible)?_c('v-icon',{staticStyle:{"text-decoration":"none"},attrs:{"color":"#3F7359"}},[_vm._v(" mdi-chevron-down ")]):_vm._e()],1),(_vm.isInfoVisible)?[(
                            _vm.onGoingProject.metals || _vm.onGoingProject.stones
                        )?_c('v-divider'):_vm._e(),(_vm.onGoingProject.metals)?_c('p',{staticClass:"bright-grey mt-4"},[_c('span',[_vm._v("Metal Types:")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.onGoingProject.metals.join(", "))+" ")])]):_vm._e(),(_vm.onGoingProject.stones)?_c('p',{staticClass:"bright-grey mb-4"},[_c('span',[_vm._v("Stone Types:")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.onGoingProject.stones.join(", "))+" ")])]):_vm._e(),_c('v-divider'),(_vm.onGoingProject.description)?_c('p',{staticClass:"bright-grey mt-4"},[_vm._v(" DESCRIPTION: "+_vm._s(_vm.onGoingProject.description)+" ")]):_vm._e()]:_vm._e()]:_vm._e(),_c('div',{staticClass:"d-flex mt-7 justify-space-between align-center"},[(_vm.$route.path !== `/project/${_vm.onGoingProjectId}`)?_c('p',{staticClass:"pa-0 ma-0 cursor-pointer green-link",on:{"click":_vm.openProject}},[_vm._v(" View Full Project ")]):_vm._e(),_c('v-btn',{staticClass:"green-btn ml-auto",class:{ disabledBtn: _vm.processing || !_vm.modeler },attrs:{"loading":_vm.processing,"disabled":_vm.processing || !_vm.modeler,"large":"","min-width":"200","outlined":"","rounded":""},on:{"click":_vm.reassignProject}},[_vm._v(" Reassign Project ")])],1)],2)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }