import { productFruits } from "product-fruits";

export const initProductFruitsAnalytics = user => {
    productFruits.init(
        "H8KKJgjZxngnqtev",
        "en",
        {
            username: user.email,
            email: user.email,
            firstname: user.firstName,
            lastname: user.lastName || "",
            role: user.role
        },
        {
            disableLocationChangeDetection: true
        }
    );
}
