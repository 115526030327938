<template>
    <v-dialog
        v-model="dialog"
        :max-width="825"
        class="projects-reassign-dialog">
        <template v-slot:activator="{ attrs, on }">
            <template v-if="Object.keys(projectsOngoing).length !== 0">
                <v-row
                    v-for="(project, i) in projectsOngoing"
                    :key="i"
                    v-bind="attrs"
                    v-on="on"
                    class="pa-0 ma-0 pb-3">
                    <v-col
                        class="pa-0 font-weight-bold"
                        cols="1"
                        style="color: #7B7B7B">
                        {{ project }}
                    </v-col>
                    <v-col
                        class="pa-0"
                        cols="11">
                        <v-chip
                            :class="{
                                'in-progress': i === 'In Progress',
                                'input-needed': i === 'Input Needed',
                                'pending-approval': i === 'Pending Approval',
                                'open-for-work': i === 'Open For Work',
                                'ready-for-review': i === 'Ready For Review',
                                'draft': i === 'Draft',
                            }"
                            :style="projectsChipsStyles">
                            {{ i }}
                        </v-chip>
                    </v-col>
                </v-row>
            </template>
            <template v-else>
                <span class="brightGreen">Available</span>
            </template>
        </template>

        <div class="dialog-overflow-wrap">
            <v-btn
                class="pci-ad-cl-btn"
                icon>
                <v-img
                    :src="require('@/assets/icons/ic-close-modal.svg')"
                    contain
                    height="36"
                    max-width="36"
                    @click="dialog = false"/>
            </v-btn>

            <v-card class="pa-0 py-12 px-15 blueish">
                <template v-if="projectsView">
                    <div class="d-flex justify-space-between align-center">
                        <v-card-title class="px-0">
                            Ongoing Projects
                        </v-card-title>
                        <v-card-subtitle class="pa-0 ma-0 blueish d-flex align-center">
                            <div>
                                {{ firstName }}
                                {{ lastName }}
                            </div>
                            <v-menu
                                :close-on-content-click="false"
                                bottom
                                nudge-bottom="1"
                                nudge-top="1"
                                offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <div class="pf-drd-bw ml-5">
                                        <div
                                            v-bind="attrs"
                                            v-on="on"
                                            class="pf-drd-btn">
                                            Filter by:
                                            <svg
                                                fill="none"
                                                height="7"
                                                viewBox="0 0 12 7"
                                                width="12">
                                                <path
                                                    clip-rule="evenodd"
                                                    d="M1.70724 0.29275C1.31624 -0.09825 0.684239 -0.09825 0.293239 0.29275C-0.0977611 0.68375 -0.0977611 1.31575 0.293239 1.70675L5.29324 6.70675C5.68424 7.09775 6.31624 7.09775 6.70724 6.70675L11.7072 1.70675C12.0982 1.31575 12.0982 0.68375 11.7072 0.29275C11.3162 -0.0982499 10.6842 -0.0982499 10.2932 0.29275L6.00024 4.58575L1.70724 0.29275Z"
                                                    fill="#656565"
                                                    fill-rule="evenodd"/>
                                            </svg>
                                        </div>
                                    </div>
                                </template>
                                <div class="pf-drd-cw">
                                    <div class="pf-drd-sect">
                                        <div class="pf-drd-ttl">
                                            Status
                                        </div>
                                        <div class="pf-statuses">
                                            <v-chip
                                                v-for="(status, i) in statuses"
                                                :key="i"
                                                :class="[status.class, { [`${status.activeClass}`]: status.isActive }]"
                                                :style="chipStyle"
                                                class="ma-2"
                                                @click="filterByStatus(i, status.id)">
                                                {{ status.title }}
                                            </v-chip>
                                        </div>
                                    </div>
                                </div>
                            </v-menu>
                        </v-card-subtitle>
                    </div>

                    <v-divider/>

                    <v-card-text class="px-0 mt-5">
                        <v-data-table
                            :headers="headers"
                            :items="projects"
                            :items-per-page="100"
                            class="data-table__modelers-completed"
                            hide-default-footer>
                            <template #[`item.created_at`]="{ item }">
                                {{ $moment(item.created_at).format("MM/DD/YY") }}
                            </template>

                            <template #[`item.status`]="{ item }">
                                {{ getValueProjectStatus(item.status) }}
                                <v-menu
                                    left
                                    nudge-left="10"
                                    nudge-top="7"
                                    offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            v-bind="attrs"
                                            v-on="on"
                                            small>
                                            mdi-dots-vertical
                                        </v-icon>
                                    </template>
                                    <v-list class="data-table__modelers-completed--menu-list py-0">
                                        <v-list-item @click="fetchOngoingProject(item)">
                                            <v-list-item-title
                                                class="menu-list__reassign text-body-2">
                                                Reassign
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </template>
                <!-- ------------------------------------------------ -->
                <template v-else>
                    <div class="d-flex justify-space-between align-center">
                        <v-card-title class="px-0">
                            Assign new modeler?
                        </v-card-title>
                        <v-card-subtitle class="pa-0 ma-0 blueish">
                            <span class="font-weight-bold">
                                Current Modeler:
                            </span>
                            {{ fullName }}
                            <!-- {{ this.modeler.lastName }} -->
                        </v-card-subtitle>
                    </div>

                    <v-card-text class="px-0 pb-9 grey-lightend body-1">
                        Assigning a modeler will notify the modeler.
                        The current modeler will be notified that they have been moved off the project.
                    </v-card-text>

                    <v-row class="d-flex align-center">
                        <v-col cols="4">
                            <div class="grey-lightend font-weight-bold">
                                Assign Modeler:
                            </div>
                        </v-col>
                        <v-col cols="8">
                            <v-select
                                v-model="modeler"
                                :items="modelers"
                                :menu-props="{ button: true, offsetY: true }"
                                dense
                                hide-details
                                item-value="id"
                                outlined>
                                <template v-slot:selection="{ item }">
                                    {{ item.firstName }} {{ item.lastName }}
                                </template>
                                <template v-slot:item="{ item }">
                                    {{ item.firstName }} {{ item.lastName }}
                                </template>
                            </v-select>
                        </v-col>
                        <v-col/>
                    </v-row>

                    <div v-if="onGoingProject">
                        <div class="d-flex align-center justify-space-between mt-8">
                            <div>
                                <div class="text-h6 font-weight-regular">
                                    {{ onGoingProject.title }}
                                </div>
                                <v-chip
                                    :class="{
                                        'in-progress': onGoingProject.status === 'In Progress',
                                        'input-needed': onGoingProject.status === 'Input Needed',
                                        'pending-approval': onGoingProject.status === 'Pending Approval',
                                        'open-for-work': onGoingProject.status === 'Open For Work',
                                        'ready-for-review': onGoingProject.status === 'Ready For Review',
                                    }"
                                    :style="chipStyles"
                                    class="pf-chip mr-4 mb-4">
                                    {{ onGoingProject.status }}
                                </v-chip>
                            </div>
                            <div class="pci-ad-price">
                                ${{ onGoingProject.overall_designer_amount }}
                            </div>
                        </div>

                        <div class="pci-ad-options mt-6">
                            <div class="pci-ad-opt-item">
                                Due date: TODO
                            </div>
                            <div class="pci-ad-opt-item mt-4">
                                Created:
                                <span class="pci-ad-oi-val">
                                    {{ this.$moment(onGoingProject.created_at).format("MM/DD/YY") }}
                                    ({{ onGoingProject.user }})
                                </span>
                            </div>
                            <div class="pci-ad-opt-item mt-4">
                                Project type:
                                <span class="pci-ad-oi-val">{{ onGoingProject.type }}</span>
                            </div>
                            <div class="pci-ad-opt-item mt-4">
                                Project id:
                                <span class="pci-ad-oi-val">{{ onGoingProject.id }}</span>
                            </div>
                        </div>

                        <div>
                            <template v-if="onGoingProject.metals || onGoingProject.stones || onGoingProject.description">
                                <p
                                    class="bright-blue mt-7 cursor-pointer"
                                    @click="showInfo">
                                    Show more information
                                    <v-icon
                                        v-if="!isInfoVisible"
                                        color="#656565">
                                        mdi-chevron-right
                                    </v-icon>
                                    <v-icon
                                        v-if="isInfoVisible"
                                        color="#656565">
                                        mdi-chevron-down
                                    </v-icon>
                                </p>

                                <template v-if="isInfoVisible">
                                    <v-divider v-if="onGoingProject.metals || onGoingProject.stones"/>
                                    <p
                                        v-if="onGoingProject.metals"
                                        class="bright-grey mt-4">
                                        <span>Metal Types:</span>
                                        <span class="font-weight-bold"> {{ onGoingProject.metals.join(", ") }}</span>
                                    </p>
                                    <p
                                        v-if="onGoingProject.stones"
                                        class="bright-grey mb-4">
                                        <span>Stone Types:</span>
                                        <span class="font-weight-bold"> {{ onGoingProject.stones.join(", ") }}</span>
                                    </p>
                                    <v-divider/>
                                    <p
                                        v-if="onGoingProject.description"
                                        class="bright-grey mt-4">
                                        DESCRIPTION: {{ onGoingProject.description }}
                                    </p>
                                </template>
                            </template>

                            <div class="d-flex mt-7 justify-space-between align-center ">
                                <p
                                    class="bright-blue pa-0 ma-0 cursor-pointer"
                                    @click="openProject">
                                    View Full Project
                                </p>
                                <v-btn
                                    :loading="processing"
                                    :disabled="processing"
                                    :class="{ 'disabledBtn': processing }"
                                    class="bright-green white--text"
                                    large
                                    min-width="200"
                                    outlined
                                    rounded
                                    @click="reassignProject">
                                    Reassign Project
                                </v-btn>
                            </div>
                        </div>
                    </div>
                </template>
            </v-card>
        </div>
    </v-dialog>
</template>

<script>
    import http from "@/api/httpClient.api";
    import { mapGetters } from "vuex";

    export default {
        name: "ongoing-dialog",
        props: {
            projectsOngoing: {
                type: Object,
                required: true
            },
            firstName: {
                type: String,
                required: true
            },
            lastName: {
                type: String,
                required: true
            },
            clientId: {
                type: Number,
                required: true
            }
        },
        data: () => ({
            dialog: false,
            projectsView: true,
            onGoingProject: null,
            processing: false,
            fullName: "",
            modelers: [],
            modeler: "",
            isInfoVisible: false,
            headers: [
                { text: "Started", align: "start", value: "created_at" },
                { text: "Modeler", align: "start", value: "modeler" },
                { text: "Title", align: "start", value: "title" },
                { text: "Project ID", align: "start", value: "id" },
                { text: "Status", align: "start", value: "status" }
            ],
            statuses: [
                { id: 3, isActive: false, activeClass: "open-for-work__active", class: "open-for-work", title: "Completed" },
                { id: 2, isActive: false, activeClass: "in-progress__active", class: "in-progress", title: "In Progress" },
                { id: 1, isActive: false, activeClass: "input-needed__active", class: "input-needed", title: "Input Needed" },
                { id: 0, isActive: false, activeClass: "pending-approval__active", class: "pending-approval", title: "Pending Approval" }
            ],
            chipStyle: {
                cursor: "pointer",
                borderRadius: "18px"
            },
            chipStyles: {
                borderRadius: "4px",
                height: "21px"
            },
            projectsChipsStyles: {
                cursor: "pointer",
                borderRadius: "4px",
                height: "21px",
                marginLeft: "5px"
            }
        }),
        methods: {
            fetchOngoingProject (item) {

                this.fullName = item.modeler;

                http.get("/manager/modelers").then(({ data }) => {
                    this.modelers = data.data.filter(modeler => {
                        let fullName = `${modeler.firstName} ${modeler.lastName}`;
                        return fullName !== item.modeler;
                        // modeler.id !== item.id
                    });
                    console.log(this.modelers);
                    console.log(this.modelers);
                });

                http.get(`/projects/${item.id}`).then(({ data }) => {
                    console.log(data.data, "test");
                    this.onGoingProject = data.data;
                    this.projectsView = false;
                });
            },
            fetchClientsOngoingProjects (id) {
                this.$store.dispatch("manager/fetchClientsOngoingProjects", id);
            },
            filterByStatus (index) {
                if (this.statuses[index].isActive) {
                    this.statuses[index].isActive = false;
                } else {
                    this.statuses[index].isActive = true;
                }

                let filters = this.statuses.filter(status => status.isActive);

                this.fetchClientsOngoingProjects({ id: this.clientId, filters });
            },
            reassignProject () {

                if (this.modeler) {
                    this.processing = true;

                    const MODELER_ID = this.modeler;
                    const PROJECT_ID = this.onGoingProject.id;
                    const ENDPOINT = `/manager/modelers/${MODELER_ID}/projects/${PROJECT_ID}/assign`;

                    http.put(ENDPOINT).then(() => {
                        this.processing = false;
                        this.dialog = false;
                        this.projectsView = false;
                        this.fullName = "";
                        this.modelers = [];
                        this.modeler = "";
                    }).catch((e) => {
                        console.log(e);
                        this.processing = false;
                    });
                }

            },
            showInfo () {
                this.isInfoVisible = !this.isInfoVisible;
            },
            openProject () {
                this.$router.push(`/project/${this.onGoingProjectId}`);
            }
        },
        computed: {
            ...mapGetters([
                "getValueProjectStatus"
            ]),
            projects () {
                return this.$store.state.manager.clientsProjects;
            }
        },
        watch: {
            dialog (val) {
                if (val) {
                    this.statuses.forEach(status => status.isActive = false);
                    this.fetchClientsOngoingProjects({ id: this.clientId, filters: [] });
                    this.projectsView = true;
                }
            }
        }
    };


    // {id: 0, title: "Pending Approval"}
    // {id: 1, title: "Input Needed"}
    // {id: 2, title: "In Progress"}
    // {id: 3, title: "Completed"}
    // {id: 4, title: "Draft"}
    // {id: 5, title: "Ready For Review"}
    // {id: 6, title: "Render Specs"}
    // {id: 9, title: "Canceled"}


</script>

<style lang="scss">
    .pending-approval__active {
        background-color: rgb(165, 49, 0) !important;
        border-color: rgb(165, 49, 0) !important;
        color: rgb(255, 255, 255) !important;
        caret-color: rgb(255, 255, 255) !important;
    }
    .open-for-work__active {
        background-color: rgb(0, 102, 22) !important;
        border-color: rgb(0, 102, 22) !important;
        color: rgb(255, 255, 255) !important;
        caret-color: rgb(255, 255, 255) !important;
    }
    .in-progress__active {
        background-color: rgb(121, 117, 13) !important;
        border-color: rgb(121, 117, 13) !important;
        color: rgb(255, 255, 255) !important;
        caret-color: rgb(255, 255, 255) !important;
    }
    .input-needed__active {
        background-color: rgb(208, 14, 14) !important;
        border-color: rgb(208, 14, 14) !important;
        color: rgb(255, 255, 255) !important;
        caret-color: rgb(255, 255, 255) !important;
    }
</style>
