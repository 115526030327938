<template>
    <div class="order-history-wrap">
        <div v-if="loading" class="page-preloader"></div>
        <div class="order-history-table-wrap">
            <table class="order-history-table">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Project Title</th>
                        <th>Status</th>
                        <th>Amount</th>
                        <th>Payment Method</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="list.length">
                        <tr v-for="item in list" :key="item.id"
                            class="table-row">
                            <td class="text-no-wrap">{{ item.date | moment }}</td>
                            <td>
                                <div class="oh-project-ttl">
                                    <router-link :to="`/project/${item.project_id}`" class="project_title">{{ item.title }}</router-link>
                                    <a v-if="item.url" :href="item.url">
                                        <svg fill="none" height="9"
                                             viewBox="0 0 9 9" width="9">
                                            <path d="M1.57361e-07 7.80078H8.4V9.00078H1.57361e-07V7.80078Z" fill="#0046AF"/>
                                            <path
                                                d="M0.6 9.00078C0.268629 9.00078 1.45619e-07 8.73215 1.31134e-07 8.40078L0 5.40078C-1.44845e-08 5.06941 0.268629 4.80078 0.6 4.80078C0.931371 4.80078 1.2 5.06941 1.2 5.40078L1.2 8.40078C1.2 8.73215 0.931371 9.00078 0.6 9.00078Z"
                                                fill="#0046AF"/>
                                            <path
                                                d="M7.8 9.00078C7.46863 9.00078 7.2 8.73215 7.2 8.40078V5.40078C7.2 5.06941 7.46863 4.80078 7.8 4.80078C8.13137 4.80078 8.4 5.06941 8.4 5.40078V8.40078C8.4 8.73215 8.13137 9.00078 7.8 9.00078Z"
                                                fill="#0046AF"/>
                                            <path clip-rule="evenodd"
                                                  d="M1.98251 3.07709C1.79111 2.91384 1.62528 3.03628 1.43388 3.19953C1.24248 3.36279 1.09894 3.50422 1.29034 3.66748L3.88146 5.87756C4.07286 6.04081 4.38224 6.04081 4.57364 5.87756L7.084 3.66748C7.2754 3.50422 7.21262 3.36279 7.02122 3.19953C6.82982 3.03628 6.58322 2.91384 6.39182 3.07709C5.20031 4.13131 3.23661 4.14677 2.02283 3.11148L1.98251 3.07709Z"
                                                  fill="#0046AF"
                                                  fill-rule="evenodd"/>
                                            <path d="M4.19995 4.8L4.19995 0" stroke="#0046AF"
                                                  stroke-width="2"/>
                                        </svg>
                                    </a>
                                </div>
                            </td>
                            <td>
                                <div :class="$store.getters['getClassProjectStatus'](item.project_status_name)" class="project-status oh-status" v-if="item.project_status_name">{{ item.project_status_name }}</div>
                                <div class="oh-status" v-else>-</div>
                            </td>
                            <td class="text-no-wrap">
                                <div class="d-flex align-center">
                                    {{ getCurrencySymbol(item.currency) }}{{ item.amount | numeral }}
                                    <information-tooltip activator-class="ml-1" v-if="orderPaymentStatus(item)" :color="orderPaymentStatus(item).color" :message="orderPaymentStatus(item).message"/>
                                </div>
                            </td>
                            <td class="text-no-wrap" :class="{'text-uppercase': isClient}">
                                <div v-if="item.payment_method.trim()">{{ item.payment_method }}</div>
                                <div v-else class="text-center">-</div>
                            </td>
                        </tr>
                    </template>
                    <tr v-else>
                        <td colspan="5">
                            <div class="oh-no-data-text text-center">No data</div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-if="showPagination" class="text-sm-center mt-4 mb-6">
            <v-pagination
                :value="pagination.current_page"
                :length="pagination.total_pages"
                :total-visible="6"
                class="pf-pagination"
                color="#515151"
                next-aria-label="Next"
                previous-aria-label="Previous"
                @input="changePage"/>
        </div>
    </div>
</template>

<script>
    import moment from "moment";
    import numeral from "numeral";
    import InformationTooltip from "@/components/ui/InformationTooltip";
    import { capitalize } from "@/utils/index.utils";
    import { mapGetters } from "vuex";

    export default {
        name: "order-history-data",
        components: { InformationTooltip },
        filters: {
            moment: (date) => {
                return moment(new Date(date)).format("MMM. DD, YYYY");
            },
            numeral: (value) => {
                return numeral(value).format("0,0.00");
            }
        },
        props: {
            loading: {
                type: Boolean,
                default: false
            },
            list: {
                type: Array,
                default: () => []
            },
            pagination: {
                type: Object,
                default: () => ({
                    count: null,
                    current_page: null,
                    links: null,
                    per_page: null,
                    total: null,
                    total_pages: null
                })
            }
        },
        computed: {
            ...mapGetters([
                "isClient"
            ]),
            orderPaymentStatus() {
                return ({ payment_status_id, refund_type = "" }) => {
                    switch (payment_status_id) {
                        case this.$config.project.paymentStatus.awaiting:
                        case this.$config.project.paymentStatus.processing:
                            return {
                                color: "#FFFDCC",
                                message: "Payment pending"
                            }
                        case this.$config.project.paymentStatus.success:
                            return {
                                color: "#9FF4A9",
                                message: "Payment completed"
                            }
                        case this.$config.project.paymentStatus.failure:
                            return {
                                color: "#FF9696",
                                message: "Payment failed"
                            }
                        case this.$config.project.paymentStatus.refund:
                            return {
                                color: "#F0FFF3",
                                message: `${capitalize(refund_type)} refund`
                            }
                        default:
                            return null
                    }
                }
            },
            showPagination () {
                return this.pagination.total_pages > 1;
            },
            getCurrencySymbol () {
                return currency => {
                    let result = "";
                    if (currency === "$" || currency.toLowerCase() === "usd") {
                        result = "$";
                    }
                    return result;
                }
            }
        },

        methods: {
            changePage (page) {
                this.$emit("changePage", page)
            }
        }
    };
</script>

<style lang="scss" scoped>
    .order-history-wrap{
        position: relative;
        min-height: 200px;
    }
    .order-history-table-wrap:not(.is-loading) {
        overflow: auto;
    }
    .order-history-table {
        min-width: 450px;
        width: 100%;
        font-size: 12px;
        line-height: 16px;
        color: #000;
        text-align: left;
		border-collapse: separate;
        border-spacing: 0 8px;

        .table-row{
            &:hover{
                background: #f5fef6;
                td{
                    border-color: #3F7359;
                }
            }
        }

        td, th {
            vertical-align: baseline;
            padding: 7px;
            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
            }
        }
        th {
            padding-bottom: 0;
            padding-top: 0;
            font-weight: 400;
            font-size: 12px;
			line-height: 16px;
			color: #777777;
            white-space: nowrap;
        }
        td {
            padding-top: 12px;
            padding-bottom: 12px;
            word-break: break-word;
			font-size: 14px;
			line-height: 18px;
			color: #202121;
			border-top: 1px solid #BCBCBC;
			border-bottom: 1px solid #BCBCBC;

			&:first-child {
				border-top-left-radius: 4px;
				border-bottom-left-radius:  4px;
				border-left: 1px solid #BCBCBC;
				padding-left: 18px;
			}

			&:last-child {
				border-top-right-radius: 4px;
				border-bottom-right-radius:  4px;
				border-right: 1px solid #BCBCBC;
				padding-right: 18px;
			}
        }
    }
    .oh-project-ttl {
        max-width: 120px;

        .project_title{
            color: inherit;
            text-decoration: none;
            padding-right: 4px;
        }
        a {
            transition: opacity 250ms ease;
            &:hover {
                opacity: 0.7;
            }
        }
    }
    .oh-no-data-text {
        font-size: 18px;
        line-height: 22px;
        padding-top: 15px;
        padding-bottom: 15px;
    }
    .oh-status {
        font-size: 12px;
        max-width: 94px;
        padding: 2px 5px;
        text-align: center;
        white-space: normal;
        word-break: normal;
    }
</style>
