<template>
    <v-dialog
        v-model="dialog"
        :max-width="750"
        class="projects-reassign-dialog">
        <template v-slot:activator="{ attrs }">
            <v-btn
                v-bind="attrs"
                class="caption"
                rounded
                small
                text
                :disabled="projectsCompleted === 0"
                @click="openDialog">
                <span class="main-text--text">{{ projectsCompleted }} Completed</span>
            </v-btn>
        </template>

        <div class="dialog-overflow-wrap white">
            <v-btn
                class="pci-ad-cl-btn"
                icon>
                <v-img
                    :src="require('@/assets/icons/ic-close-modal.svg')"
                    contain
                    height="36"
                    max-width="36"
                    @click="dialog = false"/>
            </v-btn>

            <div
                v-if="loading"
                class="page-preloader local"/>

            <v-card v-if="!loading" class="pa-0 py-12 px-15 blueish">
                <div class="d-flex justify-space-between align-center">
                    <v-card-title class="px-0">
                        Completed Projects
                    </v-card-title>
                    <v-card-subtitle class="pa-0 ma-0 blueish d-flex align-center">
                        <div>
                            {{ firstName }}
                            {{ lastName }}
                        </div>
                    </v-card-subtitle>
                </div>

                <v-divider/>

                <v-card-text class="px-0 mt-5">
                    <v-data-table
                        :headers="headers"
                        :items="projects"
                        class="data-table__modelers-completed"
                        hide-default-footer>
                        <!-- <template #[`item.finished_at`]="{ item }">
                          {{ item.finished_at }}
                        </template> -->
                    </v-data-table>
                </v-card-text>
            </v-card>
        </div>
    </v-dialog>
</template>

<script>
    import { managerApi } from "@/api";

    export default {
        name: "completed-dialog",
        props: {
            projectsCompleted: {
                type: Number,
                required: true
            },
            firstName: {
                type: String,
                required: true
            },
            lastName: {
                type: String,
                required: true
            },
            clientId: {
                type: Number,
                required: true
            }
        },
        data: () => ({
            loading: true,
            dialog: false,
            projects: [],
            headers: [
                { text: "Completed", align: "start", value: "finished_at" },
                { text: "Title", align: "start", value: "title" },
                { text: "Project ID", align: "start", value: "id" },
                { text: "Modeler", align: "start", value: "modeler" }
            ]
        }),
        methods: {
            async openDialog () {
                this.dialog = true;
                this.loading = true;

                const res = await managerApi.getClientProjectsCompleted(this.clientId);

                this.projects = res.data.data;
                this.loading = false
            }
        }
    };
</script>
