// import { userApi } from "@/api"

export default () => ({
    namespace: true,
    state: () => ({
        startProject: 1
    }),
    getters: {
        startProject: (state) => state.startProject
    },
    mutations: {
        SET_START_PROJECT (state, step) {
            state.startProject = step;
        }
    },
    actions: {
        updateStep ({ commit }, payload) {
            commit("SET_START_PROJECT", payload);
            // userApi.getProfile().then((data) => {
            //    commit('SET_USER_PROFILE', data);
            //  });
        }
    }
})
