<template>
    <div class="d-flex align-center">
        <simple-checkbox v-bind="$attrs" v-on="$listeners"/>
        <div class="notification-wrap main-text--text d-flex align-center w-100 px-6 py-2 ml-4">
            <div class="notification-wrap--title mr-6" v-text="title"/>
            <div class="notification-wrap--description" v-text="description"/>
        </div>
    </div>
</template>

<script>
    import SimpleCheckbox from "@/components/ui/SimpleCheckbox";
    export default {
        name: "notification-setting-item",
        components: { SimpleCheckbox },
        props: {
            title: {
                type: String,
                default: ""
            },
            description: {
                type: String,
                default: ""
            }
        }
    }
</script>

<style scoped lang="scss">
.notification-wrap{
    border: 1px solid #BCBCBC;
    border-radius: 4px;
    min-height: 72px;

    &--title{
        font-weight: 700;
        font-size: 24px;
        line-height: 31px;
        white-space: nowrap;
        width: 100%;
        max-width: 170px;
    }
}
</style>
