<template>
    <v-container class="conf-demos-page py-0 pb-12 px-6">
        <loader v-if="isAdmin && loading"/>

        <template v-if="isAdmin && !loading">
            <div class="d-flex align-center mt-12 mb-6">
                <h1>Demos</h1>

                <v-btn
                    v-if="$vuetify.breakpoint.xsOnly"
                    outlined
                    rounded
                    width="146"
                    height="38"
                    color="#464646"
                    class="ml-auto bg-primary"
                    @click="dialog = true">
                    <v-icon size="18" class="mr-2">mdi-plus</v-icon>
                    New demo
                </v-btn>
            </div>

            <div class="conf-demos-page__header d-flex align-center mb-5">
                <v-text-field
                    v-model="searchVal"
                    outlined
                    dense
                    hide-details
                    height="34"
                    placeholder="Search by name"
                    prepend-inner-icon="mdi-magnify"
                    @keyup="search"
                    class="conf-demos-page_search mr-5 rounded-0"/>

                <!--                <v-btn-->
                <!--                    outlined-->
                <!--                    width="120"-->
                <!--                    height="36"-->
                <!--                    color="#888888"-->
                <!--                    :ripple="false"-->
                <!--                    style="padding: 0 10px;">-->
                <!--                    <span class="main-text&#45;&#45;text">Filter by:</span>-->
                <!--                    <img class="ml-auto" :src="require('@/assets/icons/ic-chevron-down-circle-outline.svg')" width="23" height="24"-->
                <!--                         alt="">-->
                <!--                </v-btn>-->

                <v-btn
                    v-if="$vuetify.breakpoint.smAndUp"
                    outlined
                    rounded
                    width="146"
                    height="38"
                    color="#464646"
                    class="ml-auto bg-primary"
                    @click="dialog = true">
                    <v-icon size="18" class="mr-2">mdi-plus</v-icon>
                    New demo
                </v-btn>
            </div>

            <v-data-table
                v-model="selected"
                :headers="headers"
                :items="list"
                :items-per-page="-1"
                :mobile-breakpoint="280"
                :loading="dataLoading"
                :custom-sort="customSort"
                must-sort
                sort-by="company_name"
                hide-default-footer>
                <template #item="{item, isSelected, select}">
                    <tr :class="{'is-selected': isSelected}">
                        <td class="pr-0">
                            <div class="d-flex align-center">
                                <simple-checkbox :value="isSelected" @input="select" class="justify-start"/>
                            </div>
                        </td>
                        <td class="pl-4 main-text--text">
                            {{ item.company_name }}
                        </td>
                        <td class="text-right main-text--text">
                            {{ item.accessions_count }}
                        </td>
                        <td>
                            <div class="d-flex">
                                <v-btn rounded outlined width="126" class="mr-3 main-text--text"
                                       :href="item.preview_url" target="_blank">
                                    <img :src="require('@/assets/icons/ic-ext-link.svg')" alt="" width="20"
                                         class="mr-1">
                                    Preview
                                </v-btn>
                                <v-btn rounded outlined width="138" class="mr-3 main-text--text"
                                       @click="copyTextToClipboard(item.url)">
                                    <img :src="require('@/assets/icons/ic-copy-solid.svg')" alt="" width="20"
                                         class="mr-1">
                                    Copy URL
                                </v-btn>
                                <v-btn rounded outlined width="102" class="main-text--text"
                                       @click="editDemo(item.id)">
                                    <img :src="require('@/assets/icons/ic-edit.svg')" alt="" width="13" style="margin-right: 6px;">
                                    Edit
                                </v-btn>
                                <v-btn icon class="main-text--text"
                                       @click="openConfirmDelete(item.id)">
                                    <v-img width="24" height="24" contain :src="require('@/assets/icons/ic-trash.svg')"/>
                                </v-btn>
                            </div>
                        </td>
                    </tr>
                    <tr class="spacer">
                        <td style="height: 10px; border:none;"/>
                    </tr>
                </template>
            </v-data-table>

            <div v-if="showPagination" class="text-sm-center mt-4 mb-6">
                <v-pagination
                    v-model="paginationPage"
                    :length="pagination.total_pages"
                    :total-visible="6"
                    class="pf-pagination"
                    color="#515151"
                    next-aria-label="Next"
                    previous-aria-label="Previous"
                    @input="changePage"/>
            </div>

            <new-configurator-demo-modal v-if="dialog" :item="dialogItem" @onClose="onCloseDialog" @update="loadConfigurators"/>
        </template>
        <dialog-confirm v-model="deleteDialog" @onSuccess="deleteDemo"/>

        <v-snackbar
            v-model="errorSnackbar"
            :timeout="5000"
            top
            class="error-snackbar">
            Failure! Try again or contact our support team
        </v-snackbar>
    </v-container>
</template>

<script>
    import { mapGetters } from "vuex";
    import { adminApi } from "@/api";
    import { copyTextToClipboard } from "@/utils/index.utils";
    import Loader from "@/components/ui/Loader";
    import SimpleCheckbox from "@/components/ui/SimpleCheckbox";
    import NewConfiguratorDemoModal from "@/views/admin/configurator-demos/NewConfiguratorDemoModal";
    import DialogConfirm from "@/components/ui/DialogConfirm.vue";

    export default {
        components: {
            DialogConfirm,
            Loader,
            SimpleCheckbox,
            NewConfiguratorDemoModal
        },
        data() {
            return {
                loading: true,
                dataLoading: false,
                searchVal: "",
                searchDebounce: null,
                paginationPage: 1,
                perPage: 10,
                pagination: null,
                selected: [],
                headers: [
                    { text: "", value: "select", sortable: false, width: "30px" },
                    { text: "Company name", value: "company_name", width: "260px" },
                    { text: "# of accesses", value: "accessions_count", width: "130px" },
                    { text: "", value: "actions", sortable: false }
                ],
                list: [],
                sortModel: {
                    col: "company_name",
                    order: "asc"
                },
                dialog: false,
                dialogItem: null,
                deleteItemId: null,
                deleteDialog: false,
                deleteLoading: false,

                errorSnackbar: false
            }
        },
        computed: {
            ...mapGetters([
                "isAdmin"
            ]),
            showPagination() {
                return this.pagination?.total_pages > 1;
            },

            query() {
                return {
                    company_name:  this.searchVal,
                    sorting_filed: this.sortModel.col,
                    sorting_direction: this.sortModel.order,
                    page: this.paginationPage,
                    per_page: this.perPage
                }
            }
        },
        watch: {
            sortModel: {
                deep: true,
                async handler() {
                    await this.loadConfigurators();
                }
            }
        },
        async created() {
            await this.loadConfigurators();
            this.loading = false;
        },
        methods: {
            copyTextToClipboard,
            async loadConfigurators() {
                try {
                    const { data } = await adminApi.getDemoList(this.query)
                    this.list = data.data
                    this.pagination = data.meta.pagination
                } catch (error) {
                    console.error(error);
                } finally {
                    this.dataLoading = false;
                }
            },
            customSort(items, sortBy, sortDesc) {
                if (sortBy.length && sortDesc.length) {
                    this.sortModel.col = sortBy[0];
                    this.sortModel.order = sortDesc[0] ? "desc" : "asc";
                }
                return items;
            },
            changePage() {
                this.loadConfigurators({ page: this.paginationPage });
            },
            search() {
                if (this.searchDebounce) {
                    clearTimeout(this.searchDebounce);
                }

                this.searchDebounce = setTimeout(() => {
                    this.dataLoading = true;
                    this.loadConfigurators();
                }, 350);
            },
            async editDemo(id) {
                const { data } = await adminApi.getDemoById(id)
                this.dialogItem = data.data
                this.dialog = true
            },
            openConfirmDelete(id) {
                this.deleteItemId = id
                this.deleteDialog = true
            },
            async deleteDemo() {
                if(!this.deleteItemId) return
                this.deleteLoading = true
                try {
                    await adminApi.deleteDemo(this.deleteItemId)
                    await this.loadConfigurators()
                } catch (e) {
                    this.deleteLoading = false
                    this.errorSnackbar = true
                }

            },
            onCloseDialog() {
                this.dialog = false; this.dialogItem = null
            }
        }
    }
</script>

<style scoped lang="scss">
.conf-demos-page {
    max-width: 948px;

    h1 {
        font-size: 36px;
        line-height: 40px;
        color: #464646;
    }

    ::v-deep .conf-demos-page_search {
        max-width: 300px;

        .v-input__slot {
            padding: 0 10px!important;
            min-height: 36px!important;
        }

        fieldset {
            border-color: #464646;
        }

        input {
            font-size: 12px;
            &::placeholder {
                color: #BCBCBC;
            }
        }

        .v-input__prepend-inner {
            margin-top: 6px!important;
            padding-right: 2px;
        }

        .v-icon {
            font-size: 20px;
            color: #464646;
        }
    }

    ::v-deep .v-data-table {
        tr:not(.spacer) {
            th {
                height: auto;
                padding-bottom: 12px;
                border-bottom: none!important;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                color: #464646!important;

                &.sortable {
                    i {
                        width: 20px;
                        height: 20px;
                        background: transparent url('~@/assets/icons/ic-expand.svg') no-repeat center center;
                        &::before {
                            display: none;
                        }
                    }
                }
            }

            td {
                height: 58px;
                padding: 6px 20px;
                border-top: 1px solid;
                border-bottom: 1px solid;
                border-color: #E8E8E8!important;
                font-size: 18px;
                line-height: 23px;

                &:first-child {
                    border-left: 1px solid;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                }

                &:last-child {
                    border-right: 1px solid;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                }
            }
        }
    }
}
</style>
