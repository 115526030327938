export default () => ({
    state: () => ({
        dialogLeaveProject: false,
        dialogLeaveProject2: false,
        alertCrashProject: false,
        alertCrashAddFileToProject: false,
        alertInvalidFileType: false,
        content: {
            dialogLeaveProject2: {
                set: "SET_DIALOG_LEAVE_PROJECT2",
                title: "Leave this project?",
                text: "Would you like to exit and save your current progress on this project? You’ll be able to locate it within your dashboard.",
                no: "No, I want to keep working",
                yes: "Yes, save my progress and exit"
            },
            dialogLeaveProject: {
                set: "SET_DIALOG_LEAVE_PROJECT",
                title: "Leave this project?",
                text: "Would you like to exit and save your current progress on this project? You’ll be able to locate it within your dashboard.",
                no: "No, I want to keep working",
                yes: "Yes, save my progress and exit",
                other: "Abandon project and don’t save"
            },
            alertCrashProject: {
                set: "SET_ALERT_CRASH_PROJECT",
                type: "error",
                title: "Error",
                text: "File isn't added to project, try again",
                ok: "Ok"
            },
            alertCrashAddFileToProject: {
                set: "SET_ALERT_CRASH_ADD_FILE_TO_PROJECT",
                type: "error",
                title: "Error",
                text: "File isn't added to project, try again",
                ok: "Ok"
            },
            alertInvalidFileType: {
                set: "SET_ALERT_INVALID_FILETYPE",
                type: "error",
                title: "Error",
                text: "File is Invalid",
                ok: "Ok"
            },
            large: {
                set: "SET_STATUS_LARGE_VIEW"
            }
        }
    }),
    getters: {
        dialogLeaveProject: (state) => state.dialogLeaveProject,
        dialogLeaveProject2: (state) => state.dialogLeaveProject2,
        alertCrashProject: (state) => state.alertCrashProject,
        alertCrashAddFileToProject: (state) => state.alertCrashAddFileToProject,
        alertInvalidFileType: (state) => state.alertInvalidFileType
    },
    mutations: {
        SET_DIALOG_LEAVE_PROJECT2 (state, status) {
            state.dialogLeaveProject2 = status;
        },
        SET_DIALOG_LEAVE_PROJECT (state, status) {
            console.log(state.dialogLeaveProject, status);
            state.dialogLeaveProject = status;
        },
        SET_ALERT_CRASH_PROJECT (state, status) {
            state.alertCrashProject = status;
        },
        SET_ALERT_CRASH_ADD_FILE_TO_PROJECT (state, status) {
            state.alertCrashAddFileToProject = status;
        },
        SET_ALERT_INVALID_FILETYPE (state, status) {
            state.alertInvalidFileType = status;
        }
    }
})
