import axios from "axios";
import store from "@/store";


let refreshTokenRequest = null;

const requestToken = async () => {
    let _token;
    if (refreshTokenRequest === null) {
        refreshTokenRequest = store.dispatch("refreshToken");
    }
    _token = await refreshTokenRequest;
    refreshTokenRequest = null;
    return _token;
};

const requestValidAccessToken = async () => {
    let token;
    const now = Date.now();

    if (!store.getters.isTokenUrlValid) {
        await store.dispatch("logout");
        return;
    }

    token = store.state.auth.tokenData.token;

    if (!store.getters.isRefreshTokenValid(now)) {
        store.dispatch("logout");
    } else if (!store.getters.isAccessTokenValid(now)) {
        token = await requestToken("refreshToken");
    }

    return token;
};

const httpClientApi = axios.create({
    baseURL: process.env.VUE_APP_BASE_API_URL,
    headers: {
        "Content-Type": "application/json"
    }
});

httpClientApi.interceptors.request.use(
    async config => {
        if (config.skipAuth) {
            return config;
        }

        const accessToken = await requestValidAccessToken();

        return {
            ...config,
            headers: {
                common: {
                    ["Authorization"]: `Bearer ${accessToken}`
                }
            }
        };
    }
);

httpClientApi.interceptors.response.use(
    response => {
        const { status } = response;

        if (status === 401) {
            store.dispatch("logout");
        }

        return response;
    },
    error => {
        // if(error.response.status === 404 || error.response.status === 403) {
        //     router.push('/404');
        // }
        return Promise.reject(error);
    }
);

export const getData = async ({ path, params = {} }) => { //customer_number
    const res = await httpClientApi.get(path, { params });
    if (res.status === 200) {
        return res.data;
    }
    new Error(res.config.baseURL + path + " Data is not loaded", res);
};

export const post = async ({ path, formData, config = {} }) => { //customer_number
    const res = await httpClientApi.post(path, formData, config);
    return res?.data?.data || res?.data;
};

export default httpClientApi;
