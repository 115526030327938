<template>
    <v-container
        v-if="isClient || isManagerOrAdmin"
        class="manager-assets py-6 pb-12"
        fluid>
        <template v-if="project && assets && reference && comments">
            <project-header class="manager-assets__project-info">
                <template #actions>
                    <div class="mt-n3 mt-sm-0 ml-auto mr-sm-7 flex-grow-1 order-2 text-right">
                        <v-btn
                            v-if="!isClient && !projectIsOnHold"
                            :disabled="processingPublishing || !isRenderUploaded"
                            class="publish-btn"
                            @click="toggleDialogs">
                            {{ !isProjectCompleted ? 'Publish to Client' : 'Return to Final Assets prep' }}
                        </v-btn>
                    </div>
                </template>
            </project-header>

            <div class="manager-assets__info-ready d-flex align-center justify-space-between">
                <p v-text="displayInfoText"/>
                <v-btn
                    v-if="(isClient && isProjectCompleted) || (isManagerOrAdmin && isRenderUploaded)"
                    color="#9FF4A9"
                    class="download-all-btn"
                    min-width="126"
                    height="30"
                    @click.native.stop="downloadAll">
                    Download All
                </v-btn>
            </div>

            <div class="manager-assets__shimmer-loader mt-7 assets-list">
                <v-expansion-panels multiple v-model="openedExpansionPanels">
                    <v-expansion-panel
                        v-for="(asset, assetIndex) in assets"
                        :key="assetIndex"
                        :disabled="!asset.renders.length || (isClient && !isProjectCompleted)"
                        class="mb-4 expansion">
                        <v-expansion-panel-header
                            class="expansion-header">
                            <div class="d-flex">
                                <div
                                    class="d-flex align-center text-h6 font-weight-light asset-name">
                                    {{ asset.original_name }}
                                </div>
                                <div
                                    class="d-flex align-center justify-end mx-5 flex">
                                    <div class="mx-auto text-caption" v-if="!asset.renders.length || (isClient && !isProjectCompleted)">No Renders have been uploaded yet</div>
                                    <div class="d-flex align-center pointer-events-all">
                                        <div v-if="!isProjectCompleted" class="mr-2 d-none">
                                            <engraving-menu v-if="asset.has_engraving.data"/>
                                            <hex-color-menu v-if="asset.has_background.data" :background="asset.has_background.data"/>
                                        </div>

                                        <v-btn
                                            v-if="(isClient && isProjectCompleted && asset.renders.length) || (isManagerOrAdmin && asset.renders.length)"
                                            :disabled="!isProjectCompleted && !asset.renders.length"
                                            color="#9FF4A9"
                                            class="download-all-btn"
                                            min-width="126"
                                            height="30"
                                            @click.native.stop="downloadAll(asset.selected_renders.length ? asset.selected_renders : asset.renders)">
                                            {{ asset.selected_renders.length ? 'Download Selected' : 'Download All' }}
                                        </v-btn>

                                        <upload-as-zip-dialog
                                            v-if="isManagerOrAdmin && !isProjectCompleted"
                                            :assets="assets"
                                            :item="asset"
                                            btn-classes="ml-4"
                                            @refetchAssets="refetchAssets"/>
                                    </div>
                                </div>
                            </div>
                            <template v-slot:actions>
                                <v-icon
                                    color="#888888">
                                    mdi-chevron-down-circle-outline
                                </v-icon>
                            </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-data-table
                                v-model="asset.selected_renders"
                                :headers="headers"
                                :items-per-page="-1"
                                :items="asset.renders"
                                show-select
                                hide-default-footer>
                                <template v-slot:[`header.data-table-select`]="{ props, on }">
                                    <simple-checkbox
                                        v-bind="props"
                                        v-on="on"/>
                                </template>
                                <template v-slot:[`item.data-table-select`]="{ isSelected, select }">
                                    <simple-checkbox
                                        :value="isSelected"
                                        @input="select"/>
                                </template>
                                <template #[`item.original_name`]="{ item }">
                                    {{ item.original_name.substring(0, item.original_name.lastIndexOf('.')) }}
                                </template>

                                <template #[`item.url`]="{ item }">
                                    <div class="d-flex align-center">
                                        <v-card
                                            class="ml-6"
                                            flat
                                            outlined @click="openPreview(item)">
                                            <v-img
                                                :src="item.url"
                                                height="48"
                                                width="74"/>
                                        </v-card>
                                    </div>
                                </template>
                                <template #[`item.resolution`]="{ item }">
                                    <span class="caption">
                                        {{ getRenderResolution(item) }}
                                    </span>
                                </template>
                                <template #[`item.format`]="{ item }">
                                    .{{ item.format }}
                                </template>
                                <template #[`item.download`]="{item}">
                                    <v-btn icon class="ml-2" v-if="isManagerOrAdmin && !projectIsOnHold"
                                           @click="openDeleteRenderDialog(item)">
                                        <v-img width="24" height="24" contain :src="require('@/assets/icons/ic-trash.svg')"/>
                                    </v-btn>
                                </template>
                            </v-data-table>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </div>

            <assets-comments
                v-if="comments.length"
                :comments="comments"
                @openImageInDialog="openImageInDialog"/>

            <assets-reference-images
                v-if="reference.length"
                :reference="projectReferencesAndSketches"/>

            <v-row>
                <v-col
                    cols="12"
                    md="6">
                    <assets-project-description
                        v-if="project.render_description"
                        :description-title="'Render description'"
                        :description="project.render_description"/>
                    <assets-project-description
                        v-if="project.description"
                        :description="project.description"/>
                </v-col>

                <v-col cols="12" md="6">
                    <assets-project-details class="mt-8" :project="project"/>
                    <assets-project-links class="mt-4"/>
                </v-col>
            </v-row>

            <assets-footer
                v-if="isClient || isManagerOrAdmin"
                @fetchProject="fetchProject"
                @fetchProjectData="fetchProjectData"
                @openExtraChargeDialog="openExtraChargeDialog"/>

            <image-resizer-dialog
                :image-dialog="imageDialog"
                :image-src="imageSrc"
                @closeImgDialog="closeImgDialog"/>

            <publish-to-client-dialog v-model="publishToClientModal" :loading="processingPublishing" @confirm="publishToClient"/>
            <to-final-assets-prep-dialog v-model="projectToFinalAssetsPreModal" :loading="processingPublishing" @confirm="projectToFinalAssetsPrep"/>
            <project-assets-viewer v-if="assetsViewerState.open" @close="assetsViewerState.open = false" :initial-slide="assetsViewerState.initIndex" :images="assetsFiles"/>
            <dialog-delete-render-asset v-model="deleteRenderDialog" :is-deleting="deleteRenderFile ? deleteRenderFile.deleting_progress : false" @delete="deleteRender" @cancel="closeDeleteRenderDialog"/>

            <admin-extra-charge-dialog
                v-if="isManagerOrAdmin && projectIsOnHold"
                v-model="adminExtraChargeDialog"/>

            <client-extra-charge-dialog
                v-if="isClient && projectIsOnHold"
                v-model="clientExtraChargeDialog"/>
        </template>
        <loader v-else/>
    </v-container>
</template>

<script>
    import ProjectHeader from "@/components/project/ProjectHeader";
    import AssetsFooter from "./AssetsFooter.vue";
    import HexColorMenu from "./HexColorMenu.vue";
    import EngravingMenu from "./EngravingMenu.vue";
    import AssetsComments from "./AssetsComments.vue";
    import Loader from "../../../components/ui/Loader.vue";
    import UploadAsZipDialog from "./UploadAsZipDialog.vue";
    import ImageResizerDialog from "./ImageResizerDialog.vue";
    import AssetsReferenceImages from "./AssetsReferenceImages.vue";
    import AssetsProjectDescription from "./AssetsProjectDescription.vue";
    import AssetsProjectLinks from "./AssetsProjectLinks.vue";
    import { mapGetters } from "vuex";
    import AssetsProjectDetails from "./AssetsProjectDetails.vue";
    import ProjectAssetsViewer from "@/components/project/ProjectAssetsViewer";
    import SimpleCheckbox from "@/components/ui/SimpleCheckbox";
    import DialogDeleteRenderAsset from "@/components/ui/dialog-delete-render-asset";
    import AdminExtraChargeDialog from "@/components/ExtraCharge/AdminExtraChargeDialog";
    import ClientExtraChargeDialog from "@/components/ExtraCharge/ClientExtraChargeDialog";
    import { projectApi } from "@/api";
    import PublishToClientDialog from "@/views/manager/assets-view/publishToClientDialog";
    import ToFinalAssetsPrepDialog from "@/views/manager/assets-view/toFinalAssetsPrepDialog";

    export default {
        components: {
            ToFinalAssetsPrepDialog,
            PublishToClientDialog,
            DialogDeleteRenderAsset,
            SimpleCheckbox,
            ProjectAssetsViewer,
            ProjectHeader,
            Loader,
            HexColorMenu,
            AssetsFooter,
            EngravingMenu,
            AssetsComments,
            UploadAsZipDialog,
            ImageResizerDialog,
            AssetsReferenceImages,
            AssetsProjectDescription,
            AssetsProjectLinks,
            AssetsProjectDetails,
            AdminExtraChargeDialog,
            ClientExtraChargeDialog
        },
        data () {
            return {
                deleteRenderDialog: false,
                deleteRenderFile: null,
                openedExpansionPanels: [],
                breadcrumbs: [],
                assetsViewerState: {
                    open: false,
                    initIndex: 0
                },
                isPublishDisabled: true,
                assets: [],
                comments: null,
                reference: null,
                imageSrc: "",
                imageDialog: false,
                headers: [
                    { text: "", align: "start", value: "url", sortable: false, width: "15%" },
                    { text: "Render name", align: "start", value: "original_name", sortable: false, width: "20%" },
                    { text: "Resolution", align: "start", value: "resolution", sortable: false, width: "20%" },
                    { text: "Format", align: "start", value: "format", sortable: false, width: "20%" },
                    { text: "", align: "start", value: "download", sortable: false }
                ],
                processingPublishing: false,
                publishToClientModal: false,
                projectToFinalAssetsPreModal: false,
                adminExtraChargeDialog: true,
                clientExtraChargeDialog: true
            };
        },
        methods: {
            openExtraChargeDialog() {
                if (this.isClient) {
                    this.clientExtraChargeDialog = true;
                } else if (this.isManagerOrAdmin) {
                    this.adminExtraChargeDialog = true;
                }
            },
            openPreview(asset){
                const assetIndex = this.assetsFiles.findIndex(item => item.id === asset.id)
                this.assetsViewerState.open = true
                this.assetsViewerState.initIndex = assetIndex
            },

            async fetchProjectData () {
                const allPromise = Promise.all([this.getAssets(), projectApi.getAssetsReference(this.projectId), projectApi.getAssetsComments(this.projectId)]);
                try {
                    const [assets, reference, comments] = await allPromise;
                    this.assets = assets
                    this.reference = reference.data.data;
                    this.comments = comments.data.data;
                } catch (e) {
                    console.log(e)
                }
            },

            openImageInDialog (src) {
                this.imageSrc = src;
                this.imageDialog = true;
            },

            closeImgDialog () {
                this.imageSrc = "";
                this.imageDialog = false;
            },

            toggleDialogs() {
                if(this.isProjectCompleted) {
                    this.projectToFinalAssetsPreModal = true
                } else {
                    this.publishToClientModal = true
                }
            },

            async publishToClient () {
                this.processingPublishing = true;
                try{
                    await projectApi.completeProject(this.projectId)
                    this.isPublishDisabled = true;
                    await this.fetchProject();
                    await this.fetchProjectData();
                } catch (e) {
                    console.error(e)
                } finally {
                    this.processingPublishing = false;
                    this.publishToClientModal = false;
                }
            },
            async projectToFinalAssetsPrep () {
                this.processingPublishing = true;
                try{
                    await projectApi.projectToFinalAssetsPrep(this.projectId)
                    this.isPublishDisabled = false;
                    await this.fetchProject();
                } catch (e) {
                    console.error(e)
                } finally {
                    this.processingPublishing = false;
                    this.projectToFinalAssetsPreModal = false;
                }
            },

            async deleteRender () {
                this.deleteRenderFile.deleting_progress = true;
                try {
                    await projectApi.deleteAssetsRender(this.projectId, [this.deleteRenderFile.id])
                    await this.refetchAssets(false);
                    this.assets.forEach((asset, idx) => {
                        if(!asset.renders.length) {
                            this.closeExpansionPanelByIdx(idx)
                        }
                    })
                    this.deleteRenderDialog = false
                } catch (e) {
                    console.error(e)
                }
                finally {
                    this.deleteRenderFile.deleting_progress = false
                }
            },

            closeExpansionPanelByIdx(idx) {
                this.openedExpansionPanels = this.openedExpansionPanels.filter(item => item !== idx)
            },

            openDeleteRenderDialog(render) {
                this.deleteRenderDialog = true
                this.deleteRenderFile = render
            },

            closeDeleteRenderDialog() {
                this.deleteRenderDialog = false
                this.deleteRenderFile = null
            },

            async getAssets(){
                try {
                    const { data } = await projectApi.getAssets(this.projectId)
                    return data.data.map((asset) => {
                        const assetLogos = asset.children.filter( child => child.type_id === this.$config.fileTypes.logo)
                        const assetEngravings = asset.children.filter( child => child.type_id === this.$config.fileTypes.engraving)
                        const assetViews = asset.children.filter( child => child.type_id === this.$config.fileTypes.type_of_views)
                        const assetShadow = asset.children.filter( child => child.type_id === this.$config.fileTypes.shadow)

                        const titleGroups = {
                            [this.$config.fileTypes.logo]: assetLogos,
                            [this.$config.fileTypes.engraving]: assetEngravings,
                            [this.$config.fileTypes.type_of_views]: assetViews,
                            [this.$config.fileTypes.shadow]: assetShadow
                        }

                        asset.children.forEach(elem=>{
                            elem.children = elem.children.filter(child=> child.type_id!==this.$config.fileTypes.icon_of_comment)
                            if([ this.$config.fileTypes.logo,
                                 this.$config.fileTypes.engraving,
                                 this.$config.fileTypes.shadow].includes(elem.type_id) ){
                                     elem.title += titleGroups[elem.type_id].findIndex(groupElement => elem.id === groupElement.id) + 1
                                 } else if(elem.type_id === this.$config.fileTypes.type_of_views){
                                elem.title = elem.title.split(" ").join("")
                            }
                        })

                        return {
                            ...asset,
                            children: asset.children.filter(child => child.title !== "" ),
                            selected_renders: [],
                            renders: asset.renders.map((render) => {
                                return {
                                    ...render,
                                    deleting_progress: false
                                }
                            })
                        }
                    });
                } catch (e) {
                    console.error(e)
                }
            },

            async refetchAssets (value) {
                try {
                    this.assets = await this.getAssets()
                    if (value) {
                        this.isPublishDisabled = false;
                    }
                } catch (e) {
                    console.error(e)
                }
            },

            async downloadAll (files=[]) {
                const files_id = Array.isArray(files) ? files.map(file => file.id) : []
                const { data } = await projectApi.downloadFinalAssetsRender(this.projectId, files_id)
                this.forceFileDownload(data);
            },

            forceFileDownload (data) {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `${this.project.title.trim()}.zip`);
                document.body.appendChild(link);
                link.click();
            },

            async fetchProject () {
                try {
                    await this.$store.dispatch("manager/fetchProject", this.projectId);
                    await this.$store.dispatch("updateProjectData", this.project);
                } catch (error) {
                    if (error.response.status === 404) {
                        await this.$router.push("/404");
                    }
                }
            }
        },
        computed: {
            ...mapGetters([
                "isClient",
                "isManagerOrAdmin",
                "projectIsOnHold"
            ]),
            displayInfoText() {
                return this.isClient ? "Your Renders will be displayed here" : "Your Render Specifications will be displayed here"
            },

            projectId() {
                return +this.$route.params.id;
            },

            assetsFiles() {
                return this.assets.flatMap(asset => asset.renders)
            },

            isProjectCompleted () {
                return this.$store.getters["manager/isProjectCompleted"];
            },

            project () {
                return this.$store.state.manager.project;
            },

            projectReferencesAndSketches(){
                if(!Array.isArray(this.project.files) || this.project.files.length < 1 ){
                    return []
                }
                return this.project.files.filter(file=>{
                    return file.type === this.$config.fileTypes.sketch || file.type === this.$config.fileTypes.reference
                })
            },

            type () {
                return this.$store.getters["getKeyProjectType"](this.project.type);
            },

            isRenderUploaded() {
                return this.assets.some(asset => asset.renders.length);
            },

            getRenderResolution() {
                return (render) => {
                    const { width, height } = render.resolution || {}
                    return width && height ? `${width} x ${height}` : "-"
                }
            }
        },
        created () {
            this.fetchProject();
            this.fetchProjectData();
        }
    };
</script>

<style lang="scss">
    .manager-assets {
        max-width: 1200px;
        position: relative;


        .btn-text-bold {
            span {
                font-weight: bold !important;
            }
        }

        &__project-info {
            .project-status-wrap {
                order: 4;
                margin-left: 0!important;
            }
        }

        &__shimmer-loader {
            margin: 0 auto !important;


            // if client view & not completed status
            .not-completed__assets--client {
                color: #bababa !important;
                min-height: 55px !important;
                pointer-events: none;
                background-color: #fff !important;
                animation-duration: 2.5s;
                animation-fill-mode: forwards;
                animation-iteration-count: infinite;
                animation-name: shimmer;
                animation-timing-function: linear;
                background: #fff;
                background: linear-gradient(to right, #f7f7f7 20%, #e9e9e9 48%, #F6F6F6 68%);
                background-size: 1200px 100%;
                @-webkit-keyframes shimmer {
                    0% {
                        background-position: -100% 0;
                    }
                    100% {
                        background-position: 100% 0;
                    }
                }
                @keyframes shimmer {
                    0% {
                        background-position: -1200px 0;
                    }
                    100% {
                        background-position: 1200px 0;
                    }
                }
            }
            thead tr th {
                font-size: 12px !important;
                text-transform: uppercase !important;
                color: #A5A5A5 !important;
                font-weight: 500 !important;
            }
            tr {
                height: 70px !important;
                &.v-data-table__selected{
                    background: initial !important;
                }
                &:hover {
                    background-color: unset !important;
                }
                td {
                    border-bottom: 1px solid #ddd !important;
                    padding-top: 10px !important;
                    padding-bottom: 10px !important;
                    &:last-child {
                        text-align: center !important;
                    }
                }
            }
        }
        &__comments--img {
            position: relative;
            &:hover .manager-assets__comments--img-type {
                display: block !important;
            }
            &-type {
                display: none;
                position: absolute;
                top: 0;
                width: 100%;
                height: 50%;
                border: 0 !important;
                border-top-left-radius: 3px;
                border-top-right-radius: 3px;
                border-bottom-left-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
                background-color: #0046AF !important;
                color: #fff !important;
                text-align: center !important;
                font-size: 12px;
                .type-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                }
            }
        }
        &__comments--magnify,
        &__comments--dots {
            &:hover {
                color: #000;
            }
        }
        &__comments--dots {
            vertical-align: top !important;
            font-size: 20px !important;
        }
        &__comments--reply {
            span {
                font-size: 13px !important;
            }
        }
        &__ref-images {
            .ref-images {
                position: relative;
                &__comment-count {
                    position: absolute;
                    bottom: 4px;
                    left: 4px;
                }
            }
        }
        &__footer {
            bottom: -25px !important;
            box-shadow: 0px -1px 80px rgba(148, 148, 148, 0.25) !important;
            button span, a span {
                font-size: 12px !important;
            }
        }

        @media screen and (max-width: 768px) {
            padding-left: 24px;
            padding-right: 24px;

            &__project-info {
                .project-status {
                    order: 4;
                    margin-left: 10px!important;
                }
            }
        }
    }
</style>

<style scoped lang="scss">

    .manager-assets__info-ready {
        margin-top: 48px;
        margin-bottom: 32px;
        padding-right: 72px;

        p {
            font-size: 18px;
            line-height: 20px;
            color: #464646;
            margin: 0;
        }
    }

    .publish-btn {
        background-color: #9FF4A9 !important;
        box-shadow: none !important;
        border: 1px solid #464646;
        border-radius: 50px;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: #464646;
        width: 200px;
    }

    .manager-assets {
        .assets-list {
            ::v-deep .expansion {
                color: #484848 !important;
                border: 1px solid #D8D8D8;
                border-radius: 5px !important;
                margin-top: 0 !important;
                .completed__assets--client {

                }
                &.v-expansion-panel--disabled{
                        color: #BCBCBC !important;
                    cursor: default !important;
                }
                .v-expansion-panel-header__icon{
                    display: block !important;
                }
            }

            .expansion-header {
                min-height: 55px;
                padding: 12px 28px;

                .asset-name {
                    font-family: "Futura PT" !important;
                    font-size: 18px !important;
                    font-weight: 400 !important;
                    line-height: 20px;
                }
            }
        }
    }

    .v-expansion-panel::after{
        border: none !important;
    }
    .v-expansion-panel::before {
        box-shadow: none;
    }

    .scale-up-btn {
        background-color: #D9FBDD !important;
        box-shadow: none !important;
        border: 1px solid #464646;
    }

    .download-all-btn {
        border: 1px solid #464646 !important;
        border-radius: 50px;
        box-shadow: none;
        &.v-btn.v-btn--disabled.v-btn--has-bg{
            background-color: #E8E8E8 !important;
            border: 1px solid #BCBCBC !important;
        }
    }
</style>
