<template>
    <div class="no-select">
        <template v-if="billingView">
            <template v-if="!showRemoveStatus">
                <h2 class="manage-payments">Manage Payment Methods</h2>
                <div v-if="cards.length" class="payment-list mt-4">
                    <div class="user-profile-label fs-16">Secure Credit Card Payment</div>
                    <billing-payment-card
                        v-for="(item, index) in cards"
                        :key="index"
                        :card="item"
                        :edit-mode="true"
                        @onRemoveCard="showRemove"/>
                </div>
                <v-btn
                    class="btn-h-48 fs-16 let-sp-05 pl-4 pr-4 w-100 mt-8 add-payment-btn"
                    dark
                    elevation="0"
                    large
                    rounded
                    @click="$emit('addPayment')">
                    Add Payment Method
                </v-btn>
            </template>
            <template v-if="showRemoveStatus">
                <h2>Confirm deletion</h2>
                <div class="mt-12">
                    <v-row>
                        <v-col>
                            <v-btn
                                class="btn-h-48 fs-16 let-sp-05 pl-4 pr-4 w-100"
                                color="#fb3805"
                                dark
                                elevation="0"
                                large
                                outlined
                                rounded
                                @click="hideRemove">
                                Cancel
                            </v-btn>
                        </v-col>
                        <v-col>
                            <v-btn
                                class="btn-h-48 fs-16 let-sp-05 pl-4 pr-4 w-100"
                                color="#fb3805"
                                dark
                                elevation="0"
                                large
                                rounded
                                @click="removeCard">
                                Remove
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
            </template>
        </template>
        <template v-else>
            <h2 class="mt-3 payment-title">Choose Payment Method</h2>
            <div class="amount d-flex align-center mt-4">
                Amount Due:
                <span class="amount_value ml-2">{{ amount !== null ? "$" + amount : amount }}</span>
            </div>
            <div v-if="type !== 'extraCharge' && !getProjectData.discount_applied" class="discount mb-7">
                <a class="discount-btn text-decoration-underline" @click="discountData.showForm = !discountData.showForm">I have discount code</a>
                <div v-if="discountData.showForm" class="mb-12">
                    <h6 class="discount-title">Discount code</h6>
                    <form class="d-flex flex-wrap" @submit.prevent="submitDiscount">
                        <input
                            v-model="discountData.input"
                            type="text"
                            :disabled="discountData.loading"
                            placeholder="Enter code"
                            class="discount-input mt-2 mr-3"
                            @change="discountData.error = ''">
                        <v-btn
                            type="submit"
                            width="104"
                            dark
                            elevation="0"
                            depressed
                            rounded
                            :loading="discountData.loading"
                            class="add-payment-btn mt-2">
                            Apply
                        </v-btn>
                    </form>
                    <span v-if="discountData.error" class="discount-error">{{ discountData.error }}</span>
                </div>
            </div>
            <ul class="card-list">
                <li
                    v-for="(item, index) in cards"
                    :key="index"
                    :class="{
                        'card-list__li--default': index === 0,
                        'card-list__li--selected': selected === index,
                    }"
                    class="card-list__li"
                    style="cursor: pointer"
                    @click="selected !== index ? select(index) : select(-1)">
                    <span class="card-list__default-label">Primary Payment Menthod</span>
                    <div class="d-flex justify-space-between align-center flex-wrap">
                        <div class="d-flex align-center flex-grow-1 mr-2">
                            <v-img
                                :src="getPaymentBrandImage(item.brand)"
                                class="card-list__icon"
                                height="22"
                                max-height="22"
                                max-width="31"
                                width="31"/>
                            <span class="card-list__card-title text-capitalize">
                                {{ item.brand }}
                            </span>
                            <v-icon color="#464646" large>mdi-dots-horizontal</v-icon>
                            <span class="card-list__last4"> {{ item.last4 }}</span>
                        </div>
                        <v-btn
                            class="ml-auto card-list__select-btn"
                            color="#464646"
                            outlined
                            small>
                            {{ selected === index ? "Unselect" : "Select" }}
                        </v-btn>
                    </div>
                </li>
            </ul>
            <a
                class="btn__add-payment btn--link"
                role="button"
                @click="$emit('addPayment')">
                <v-img
                    :src="require(`@/assets/icons/payment/ic-payment-add.svg`)"
                    class="card-list__icon"
                    contain
                    height="22"
                    max-width="31"
                    width="31"/>
                Add Payment Method
            </a>
            <div v-if="errorMsg" class="msg--error">{{ errorMsg }}</div>
            <div class="disclaimer mt-5">
                <div class="text-h5 font-weight-medium">Disclaimer</div>
                <ol class="disclaimer-list mb-5">
                    <li v-for="(item, idx) in disclaimerList" :key="idx">
                        {{ item }}
                    </li>
                </ol>
                <p>By clicking “Submit Payment” you accept our policy.</p>
            </div>

            <div class="d-flex justify-end">
                <btn-green
                    :disabled="!isPaymentInfo"
                    :loading="loading"
                    class="ml-auto mt-6 submit-btn"
                    @click="submit()">
                    Submit Payment
                </btn-green>
            </div>
        </template>
    </div>
</template>

<script>
    import http from "@/api/httpClient.api";
    import BtnGreen from "@/components/ui/btn/BtnGreen";
    import { paymentApi } from "@/api";
    import BillingPaymentCard from "@/components/user-profile/billing-information/BillingPaymentCard";
    import { mapGetters } from "vuex";

    export default {
        name: "payment-cards",
        components: {
            BtnGreen,
            BillingPaymentCard
        },
        data: () => ({
            selected: 0,
            loading: false,
            errorMsg: null,
            removeCardInfo: null,
            showRemoveStatus: false,
            discountData: {
                showForm: false,
                loading: false,
                input: "",
                error: ""
            },
            disclaimerList: [
                "UNCO leaves a right to re-evaluate a project if it deviates too much from our standards at any given time.",
                "You will get 1 free change to your design as long as it isn’t a completely new design.",
                "Small changes to design will not be charged extra.",
                "Standard renders resolution is 1400px x 1400px.",
                "Indicated due date is for first submission only.",
                "Submitted models needs to be reviewed within 7 days.",
                "Visual quality is subjected to 3D photorealistic rendering standards."
            ]
        }),
        props: {
            type: {
                type: String, // projectPayment | extraCharge
                default: "projectPayment"
            },
            billingView: {
                type: Boolean,
                default: false
            },
            cards: {
                type: Array,
                default: () => []
            },
            amount: {
                type: Number,
                default: null
            },
            paymentGatewayId: {
                type: Number,
                default: null
            }
        },
        created() {
            if (this.amount === 1) {
                this.select(-1);
            }
        },
        computed: {
            ...mapGetters([
                "getProjectData"
            ]),
            isPaymentInfo() {
                return this.getProjectData.id && this.paymentGatewayId && this.cards[this.selected]?.payment_method_id ||
                    this.getProjectData.id && this.paymentGatewayId && this.amount === 0 && (this.getProjectData.discount_applied || this.getProjectData.status_id === this.$config.project.status.pricing_pending);
            }
        },
        methods: {
            removeCard() {
                const data = {
                    payment_gateway_id: 1,
                    payment_method_id: this.removeCardInfo.payment_method_id
                };
                http
                    .delete("/users/payment-methods", { data })
                    .then((res) => {
                        if (res.status === 204) {
                            this.$root.$emit("onPaymentSuccess", "Success!");
                        }
                    })
                    .catch((err) => {
                        this.$root.$emit(
                            "onPaymentError",
                            "Failure to remove payment. Try again or contact our support team"
                        );
                    })
                    .then(() => {
                        this.hideRemove();
                    });
            },
            showRemove(card) {
                this.removeCardInfo = card;
                this.showRemoveStatus = true;
            },
            hideRemove() {
                this.removeCardInfo = null;
                this.showRemoveStatus = false;
            },
            select(index) {
                this.selected = index;
            },
            async submit() {
                const paymentData = {
                    payment_gateway_id: this.paymentGatewayId,
                    payment_method_id: this.selected !== -1 && this.amount > 0 ? this.cards[this.selected].payment_method_id : null
                }

                if (this.type === "extraCharge") {
                    paymentData.extra_charge_id = this.getProjectData.extra_charge.id;
                } else {
                    paymentData.project_id = this.getProjectData.id;
                }

                this.loading = true;
                this.errorMsg = null;
                try {
                    await paymentApi.create(paymentData);
                    this.$emit("succeededPayment");
                } catch (error) {
                    this.errorMsg = "Oops, the payment won't be affected. Try again or contact our support team.";
                    console.error(error);
                } finally {
                    this.loading = false;
                }
            },
            async submitDiscount() {
                if (this.discountData.input.trim().length === 0) {
                    this.discountData.error = "Invalid code is entered";
                    return;
                }

                try {
                    this.discountData.loading = true;
                    const res = await paymentApi.applyDiscount(this.getProjectData.id, this.discountData.input);
                    const { client_amount, discount_amount, discount_applied, discount_code, discount_type, discount_value } = res.data.data;

                    await this.$store.dispatch("updateProjectData", {
                        client_amount, discount_amount, discount_applied, discount_code, discount_type, discount_value
                    });

                    if (client_amount === 0) {
                        this.select(-1);
                    }
                } catch (e) {
                    this.discountData.error = "Invalid code is entered";
                } finally {
                    this.discountData.loading = false;
                }
            },
            getPaymentBrandImage(brand) {
                try {
                    return require(`@/assets/icons/payment/ic-payment-ct-${brand}.svg`)
                } catch (e) {
                    return require(`@/assets/_redesigne/icons/ic-lazy-img.svg`)
                }
            }
            // changeView(view) {
            //   this.currentView = view;
            // }
        }
    };
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/variables";
.disclaimer{
    font-size: 18px;
    color: $unko-dark-gray;
    font-family: 'Futura PT Book', sans-serif;
    line-height: 1.3;
    max-width: 429px;
    width: 100%;
}

.submit-btn {
		background: #9FF4A9 !important;
		border: 1px solid #464646 !important;
		box-sizing: border-box;
		border-radius: 50px !important;
		font-size: 14px;
		line-height: 18px;
		color: #464646;
		height: 47px;
}

.manage-payments {
	font-size: 24px;
	line-height: 27px;
	color: #464646
}

.add-payment-btn {
	background-color: #9FF4A9 !important;
	border: 1px solid #464646;
	box-sizing: border-box;
	border-radius: 50px;
	font-size: 14px;
	line-height: 18px;
	color: #464646;
}

.payment-title {
  font-size: 24px;
  line-height: 27px;
  color: #464646;
  margin-bottom: 31px;
}

.amount {
    font-size: 18px;
    line-height: 20px;
    font-style: normal;
    padding-bottom: 20px;
    color: #464646;

    .amount_value {
        font-weight: normal;
        font-size: 24px;
        line-height: 27px;
        color: #3f7359;
    }
}
.discount {
    margin-bottom: 26px;
    .discount-btn {
        font-size: 14px;
        line-height: 18px;
        color: #464646;
    }
    .discount-title {
        margin-top: 10px;
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
    }
    .discount-input {
        width: 160px;
        height: 36px;
        padding: 0 10px;
        border: 1px solid #464646;
        font-size: 12px;
        color: #464646;
        &::placeholder {
            color: #BCBCBC;
        }
    }
    .discount-error {
        margin-top: 10px;
        font-size: 12px;
        line-height: 16px;
        color: #D00E0E;
    }
}
.btn__add-payment {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  width: 100%;
  min-height: 62px;
  border: 1px dashed #bcbcbc;
  box-shadow: 4px 4px 4px rgba(70, 70, 70, 0.15);
  border-radius: 5px;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #bcbcbc;
}
</style>

<style lang="scss">
.card-list {
  padding-left: 0 !important;
  &__li {
    position: relative;
    list-style: none;
    border: 1px solid #cbd2d0;
    box-sizing: border-box;
    border-radius: 4px;
    margin-top: 16px;
    padding: 16px 33px 16px 24px;
    box-shadow: 4px 4px 4px rgba(70, 70, 70, 0.15);

    @media screen and (max-width: 500px) {
      padding: 16px 5px 16px 10px;
    }
  }
  &__default-label {
    display: none;
  }
  .card-list__card-title {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    color: #464646;

    margin-right: 8px;
    margin-left: 16px;
    @media screen and (max-width: 500px) {
      font-size: 12px;
      margin-right: 4px;
      margin-left: 8px;
    }
  }
  .card-list__last4 {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    color: #464646;
    margin-left: 8px;
    @media screen and (max-width: 500px) {
      font-size: 12px;
      margin-left: 4px;
    }
  }
  &__icon {
  }
  &__title {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #cbd2d0;
  }
  &__select-btn {
    background: #ffffff;
    border: 1px solid #464646;
    box-sizing: border-box;
    border-radius: 50px;
    font-size: 14px;
    line-height: 18px;
    color: #464646;

    .v-btn__content {
      font-style: normal;
      font-weight: normal;
      font-size: 12px !important;
      line-height: 24px !important;
    }
  }
}
.card-list__li--selected.card-list__li {
  border: 1px solid #3f7359 !important;
}
.card-list__li--default {
  .card-list__default-label {
    font-family: "Futura PT", sans-serif;
    position: absolute;
    top: -10px;
    left: -1px;
    padding: 1px 17px 1px 17px;
    font-style: normal;
    font-weight: 500;
    /* or 200% */
    border-radius: 4px;
    display: block;
    align-items: center;
    justify-content: center;

    background: #d9fbdd;
    border: 1px solid #bcbcbc;
    font-size: 12px;
    line-height: 15px;
    color: #464646;
  }
}

</style>
