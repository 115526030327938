<template>
    <header>
        <div class="header-content">
            <router-link
                to="/"
                class="logo-wrapper">
                <img
                    src="@/assets/HomePageImages/logo.svg"
                    alt="unco">
            </router-link>
            <nav class="home-row pages">
                <router-link
                    to="/how-it-works"
                    exact
                    class="route-link">
                    How it works
                </router-link>
                <router-link
                    to="/visuals"
                    exact
                    class="route-link">
                    Visuals
                </router-link>
                <router-link
                    to="/configurator"
                    exact
                    class="route-link">
                    Configurator
                </router-link>
                <router-link
                    v-if="environment !== 'production'"
                    to="/blog"
                    exact
                    class="route-link">
                    Blog
                </router-link>
            </nav>
            <nav class="home-row controls">
                <router-link
                    to="/contact-us"
                    exact
                    class="route-link">
                    Contact
                </router-link>
                <router-link
                    to="/login"
                    exact
                    class="route-link">
                    Log In
                </router-link>
                <button
                    class="create-btn"
                    @click="createAccount">
                    Create now
                </button>
            </nav>
            <button @click="menu = !menu" class="mobile-menu-icon">
                <img
                    src="@/assets/HomePageImages/icon-menu.svg"
                    alt="menu">
            </button>
        </div>
        <v-menu v-model="menu" attach="" bottom
                v-if="$vuetify.breakpoint.xsOnly"
                class="mobile-menu"
                content-class="mobile-menu-wrap elevation-1">
            <ul class="mb-4">
                <li>
                    <router-link
                        to="/how-it-works"
                        exact
                        class="route-link">
                        How it works
                    </router-link>
                </li>
                <li>
                    <router-link
                        to="/visuals"
                        exact
                        class="route-link">
                        Visuals
                    </router-link>
                </li>
                <li>
                    <router-link
                        to="/configurator"
                        exact
                        class="route-link">
                        Configurator
                    </router-link>
                </li>
                <li>
                    <router-link
                        to="/contact-us"
                        exact
                        class="route-link">
                        Contact
                    </router-link>
                </li>
            </ul>

            <div class="d-flex justify-center align-center flex-column px-4">
                <router-link
                    to="/login"
                    exact
                    class="route-link mobile-btn mobile-login">
                    Log In
                </router-link>
                <button
                    class="create-btn mobile-btn ml-0"
                    @click="createAccount">
                    Create now
                </button>
            </div>
        </v-menu>
    </header>
</template>

<script>
    export default {
        name: "application-header",
        data() {
            return {
                menu: false
            }
        },
        computed: {
            environment() {
                return process.env.VUE_APP_ENVIRONMENT
            }
        },
        methods: {
            createAccount(){
                if(this.$route.name!="registration") {
                    this.$router.push("/registration")
                }
            }
        }
    };
</script>

<style scoped lang="scss" src="./style.scss"></style>
