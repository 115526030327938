<template>
    <v-btn :disabled="disabled"
           :loading="loading"
           :small="$vuetify.breakpoint.smAndDown"
           class="disable-btn ml-0 ml-sm-5 pl-9 pr-9"
           color="#464646"
           dark
           elevation="0"
           rounded
           @click="onClick">
        <slot></slot>
    </v-btn>
</template>

<script>
    export default {
        name: "btn-green",
        props: {
            disabled: {
                type: Boolean,
                default: false
            },
            loading: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            onClick (e) {
                this.$emit("click", e);
            }
        }
    };
</script>
