<template>
    <div class="wrapper-assets-links" v-if="hasLinks">
        <div class="links-container">
            <div class="links-container-title">LINKS</div>
            <div v-for="(item, index) in project.links" :key="index" class="pl-iw">
                <a :href="item" class="pl-item" target="_blank">
                    {{ item }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        computed: {
            project() {
                return this.$store.state.manager.project;
            },
            hasLinks() {
                return this.project.links && this.project.links.length;
            }
        }
    };
</script>

<style lang="scss" scoped>
.links-container {
  border: 1px solid #bcbcbc;
  box-sizing: border-box;
  box-shadow: 4px 4px 4px rgba(70, 70, 70, 0.15);
  border-radius: 5px;
  padding: 16px 20px 17px 20px;

  &-title {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #464646;
    padding-bottom: 11px;
    border-bottom: 1px solid #bcbcbc;
    margin-bottom: 14px;
  }
}

.pl-iw {
  text-align: right;
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 0;
  }
  @media screen and (max-width: 959px) {
    text-align: left;
  }
}

.pl-item {
    background-color: #D9FBDD;
    border: 1px solid #464646;
    border-radius: 5px;
    font-size: 14px;
    line-height: 18px;
    color: #464646;
    padding: 3px 10px;
    display: inline-block;
    vertical-align: top;
    text-align: left;
    text-decoration: none;
    word-break: break-word;
}
a.pl-item {
  transition: all 250ms ease;
  &:hover {
    opacity: 0.7;
  }
}
</style>
