<template>
    <div class="card-radio-wrap">
        <label
            v-for="item in values"
            :key="item.value"
            :class="{'radio--disabled': item.disabled}"
            :style="{visibility: item.hide ? 'hidden': ''}"
            class="card-radio mr-2 mr-lg-5 mt-5">
            <input
                :checked="value === item.value"
                :disabled="item.disabled"
                :name="name"
                :value="item.value"
                type="radio"
                @change="change">

            <div class="card-radio__content">
                <v-img
                    v-show="value !== item.value"
                    :height="item.h"
                    :src="require('@/assets/icons/' + item.icon)"
                    :width="item.w"
                    contain/>
                <v-img
                    v-show="value === item.value"
                    :height="item.h"
                    :src="require('@/assets/icons/' + item.iconActive)"
                    :width="item.w"
                    contain/>
                <div class="card-radio__title mt-4">
                    {{ item.title }}
                </div>
            </div>
        </label>
    </div>
</template>

<script>
    export default {
        name: "payment-radio",
        data: () => ({}),
        methods: {
            change (e) {
                this.$emit("input", e.target.value);
                this.$emit("change", e.target.value);
            }
        },
        props: {
            name: {
                type: String,
                default: "card-radio"
            },
            value: {
                type: String,
                default: ""
            },
            values: {
                type: Array,
                default: () => []
            }
        }
    };
</script>

<style lang="scss">
 
    .card-radio-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
    .card-radio {
        cursor: pointer;
        font-family: "Futura PT", sans-serif;
        box-sizing: border-box;
        border-radius: 4px;
        input[type=radio] {
            position: absolute;
            opacity: 0;
            width: 0;
            height: 0;
        }
        &__content {
            border-radius: 4px;
            border: 1px solid #9A9A9A;
            box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 110px;
            max-width: 110px;
            padding-top: 34px;
            padding-bottom: 12px;
            // height: 90px;
        }
        &__title {
            font-weight: bold;
            font-size: 18px;
			line-height: 20px;
			text-align: center;
			color: #464646;
        }
        [type=radio]:checked + &__content {
            border: 1px solid #CBD2D0;
            background: #9FF4A9;
            color: #FFFFFF;
        }
        [type=radio]:checked + &__content &__title {
            font-weight: bold;
            color: #464646;
        }
        [type=radio]:disabled + &__content {
            border: 0;
            cursor: not-allowed;
        }
    }
    .radio--disabled {
        cursor: not-allowed;
    }
    // MEDIA
    .card-radio {
        //@media screen and (max-width: 900px){
        //  flex-grow: 1;
        //  margin-right: 10px!important;
        //}
        //@media screen and (max-width: 680px) {
        //  .v-image {
        //    transform: scale(0.8);
        //  }
        //  flex-grow: 1;
        //  margin-top: 20px!important;
        //  margin-right: 10px!important;
        //}
        @media screen and (max-width: 500px) {
            .v-image {
                transform: scale(0.7);
            }
            flex: 1 !important;
            max-width: 100% !important;
            margin-right: 10px !important;
            // max-width: 100%;
        }
        //@media screen and (max-width: 300px) {
        //  .v-image {
        //    transform: scale(0.8);
        //  }
        //  max-width: 100%;
        //}
        &__content {
            //height: 90px;
            //@media screen and (max-width: 771px) {
            //  width: 100%;
            //  flex-grow: 1;
            //  min-width: 180px;
            //}
            @media screen and (max-width: 500px) {
                width: 100% !important;
                flex: 1 !important;
                max-width: 100% !important;
                min-width: 80px !important;
            }
            //@media screen and (max-width: 300px) {
            //  width: 100%;
            //  flex-grow: 1;
            //  min-width: 100%;
            //}
        }
        &__title {
            @media screen and (max-width: 500px) {
                font-size: 12px;
                //line-height: 24px;
            }
        }
        [type=radio]:checked + &__content {
        }
        [type=radio]:checked + &__title {
        }
        [type=radio]:disabled + &__content {
        }
    }
</style>
