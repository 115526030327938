<template>
    <div :class="['project-header d-flex align-center', {'active-form': renameProject || changeSku}]">
        <div v-if="showTitle && !changeSku" class="project-title-wrapper d-flex flex-wrap">
            <div v-if="!renameProject" class="d-flex align-items-center">
                <h3 class="project-title">{{ getProjectData.title }}</h3>
                <v-btn
                    v-if="!isDesigner && (isClient || isAdminProject) && editTitle && getProjectData.status_id === $config.project.status.draft"
                    icon
                    width="auto"
                    height="auto"
                    class="mt-1"
                    @click="showRenameProjectForm">
                    <img width="16" height="16" :src="require('@/assets/icons/ic-edit-title.svg')" alt="">
                </v-btn>
            </div>

            <validation-observer
                v-else
                v-slot="{ handleSubmit }"
                tag="div"
                class="form-wrapper"
                ref="rename_project_form">
                <form
                    class="d-flex align-center"
                    @keyup.esc="renameProjectCancel"
                    @submit.prevent="handleSubmit(renameProjectSubmit)">
                    <validation-provider
                        v-slot="{ errors }"
                        mode="eager"
                        name="Project name"
                        vid="title"
                        rules="required|max:255"
                        tag="label">
                        <v-text-field
                            ref="rename_project_input"
                            v-model="projectTitleInput"
                            class="input mb-2 mr-sm-4 mb-sm-0"
                            counter
                            dense
                            maxlength="255"
                            :error-messages="errors[0]"
                            outlined
                            placeholder="Enter project title"/>
                    </validation-provider>
                    <div class="d-flex align-self-start pt-3">
                        <v-btn
                            type="submit"
                            text
                            width="auto"
                            height="auto"
                            color="#3F7359"
                            :ripple="false"
                            :loading="loading"
                            class="project-title__btn mr-4 px-0">
                            Save
                        </v-btn>
                        <v-btn
                            text
                            height="auto"
                            color="#3F7359"
                            :ripple="false"
                            :disabled="loading"
                            @click="renameProjectCancel"
                            class="project-title__btn mr-4 px-0">
                            Cancel
                        </v-btn>
                    </div>
                </form>
            </validation-observer>
        </div>

        <div v-if="showSku && !renameProject"
             :class="['project-title-wrapper d-flex flex-wrap mt-2 mt-sm-0', {'ml-7': !changeSku}]">
            <div v-if="!changeSku" class="d-flex align-items-center sku-title-wrap px-2 overflow-hidden">
                <input type="text" :value="getProjectData.sku" ref="sku_input"/>
                <h3 class="project-title">
                    SKU: {{ getProjectData.sku }}
                </h3>
                <v-btn
                    icon
                    width="auto"
                    height="auto"
                    class="ml-2"
                    @click="copySkuToClipboard">
                    <img width="16" height="16" :src="require('@/assets/icons/ic-copy.svg')" alt="">
                </v-btn>
                <v-btn
                    v-if="!isDesigner && (isClient || isAdminProject) && editSku"
                    icon
                    width="auto"
                    height="auto"
                    class="ml-1"
                    @click="showChangeSkuForm">
                    <img width="16" height="16" :src="require('@/assets/icons/ic-edit-title.svg')" alt="">
                </v-btn>
            </div>

            <validation-observer
                v-else
                v-slot="{ handleSubmit }"
                tag="div"
                class="form-wrapper"
                ref="change_sku_form">
                <form
                    class="d-flex align-center"
                    @keyup.esc="changeSkuCancel"
                    @submit.prevent="handleSubmit(changeSkuSubmit)">
                    <validation-provider
                        v-slot="{ errors }"
                        mode="eager"
                        name="Project SKU"
                        vid="sku"
                        rules="sku-validation|min:3|max:20|required"
                        tag="label">
                        <v-text-field
                            ref="change_sku_input"
                            v-model.trim="projectSkuInput"
                            class="input mb-2 mr-sm-4 mb-sm-0"
                            dense
                            outlined
                            :error-messages="errors[0]"
                            placeholder="Enter project SKU"/>
                    </validation-provider>
                    <div class="d-flex align-self-start pt-3">
                        <v-btn
                            type="submit"
                            text
                            width="auto"
                            height="auto"
                            color="#3F7359"
                            :ripple="false"
                            :loading="loading"
                            class="project-title__btn mr-4 px-0">
                            Save
                        </v-btn>
                        <v-btn
                            text
                            height="auto"
                            color="#3F7359"
                            :ripple="false"
                            :disabled="loading"
                            @click="changeSkuCancel"
                            class="project-title__btn mr-4 px-0">
                            Cancel
                        </v-btn>
                    </div>
                </form>
            </validation-observer>
        </div>

        <div v-if="showType"
             class="project-type d-inline-flex align-center justify-center text-uppercase text-center">
            {{ getProjectData.type }}
        </div>

        <agreement-label v-if="isDesigner"/>

        <div class="d-flex align-center project-status-wrap">
            <div v-if="showStatus"
                 :class="['project-status d-inline-flex', $store.getters['getClassProjectStatus'](getProjectStatus.status)]">
                {{ getProjectStatus.status }}
            </div>
            <status-tooltip :status-id="getProjectStatus.status_id" activator-class="ml-1" v-if="showTooltip"/>
        </div>


        <slot name="actions"/>

        <v-snackbar
            v-model="renameSnackbar"
            :timeout="1000"
            class="snackbar"
            top>
            Success! Title updated.
        </v-snackbar>

        <v-snackbar
            v-model="skuSnackbar"
            :timeout="1000"
            class="snackbar"
            top>
            Success! SKU updated.
        </v-snackbar>

        <v-snackbar
            v-model="skuClipboardSnackbar"
            :timeout="1000"
            class="snackbar"
            top>
            Success! SKU copied to clipboard.
        </v-snackbar>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
    import { projectApi } from "@/api";
    import StatusTooltip from "@/components/ui/StatusTooltip";
    import AgreementLabel from "./AgreementLabel";

    extend("sku-validation", {
        validate: value => !/[^a-zA-Z0-9!@#$%^&*()_+-={}[\]|;:'"/?.>,< ]+/g.test(value),
        message: () => "Invalid symbols found in SKU"
    });

    export default {
        name: "project-header",
        components: {
            AgreementLabel,
            StatusTooltip,
            ValidationObserver,
            ValidationProvider
        },
        props: {
            showTitle: {
                type: Boolean,
                default: true
            },
            editTitle: {
                type: Boolean,
                default: true
            },
            showSku: {
                type: Boolean,
                default: true
            },
            editSku: {
                type: Boolean,
                default: false
            },
            showType: {
                type: Boolean,
                default: true
            },
            showStatus: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                loading: false,
                renameProject: false,
                changeSku: false,
                renameSnackbar: false,
                skuSnackbar: false,
                skuClipboardSnackbar: false,
                projectTitleInput: "",
                projectSkuInput: ""
            }
        },
        computed: {
            ...mapGetters([
                "isClient",
                "getProjectData",
                "isDesigner",
                "isAdminProject",
                "projectIsOnHold"
            ]),
            showTooltip() {
                const { in_progress, in_progress_rendering, ready_for_review_renders } = this.$config.project.status
                const availableStatus = [in_progress, in_progress_rendering, ready_for_review_renders]
                return this.isClient && availableStatus.includes(this.getProjectData.status_id)
            },
            getProjectStatus() {
                if (this.isDesigner && this.getProjectData.status_id !== this.$config.project.status.completed && this.projectIsOnHold) {
                    return {
                        status_id: this.$config.project.status.on_hold,
                        status: this.$config.project.statusLabels[this.$config.project.status.on_hold]
                    }
                }
                if (!this.isDesigner && this.projectIsOnHold) {
                    return {
                        status_id: this.$config.project.status.on_hold_action_needed,
                        status: this.$config.project.statusLabels[this.$config.project.status.on_hold_action_needed]
                    }
                }
                return {
                    status_id: this.getProjectData.status_id,
                    status: this.getProjectData.status
                }
            }
        },
        methods: {
            copySkuToClipboard() {
                this.skuClipboardSnackbar = true;
                this.$refs.sku_input.select();
                document.execCommand("copy");
            },
            showRenameProjectForm() {
                this.changeSkuCancel();

                this.projectTitleInput = this.getProjectData.title;
                this.renameProject = true;

                this.$nextTick(() => {
                    this.$refs.rename_project_input.focus();
                });
            },
            renameProjectCancel() {
                this.projectTitleInput = this.getProjectData.title;
                this.renameProject = false;
            },
            async renameProjectSubmit() {
                try {
                    this.loading = true;

                    await projectApi.edit(this.getProjectData.id, {
                        title: this.projectTitleInput
                    });

                    await this.$store.dispatch("updateProjectData", { title: this.projectTitleInput });

                    this.renameProject = false;
                    this.renameSnackbar = true;
                } catch (error) {
                    console.error(error);
                    this.$refs.rename_project_form.setErrors(error.response.data.errors);
                } finally {
                    this.loading = false;
                }
            },
            showChangeSkuForm() {
                this.renameProjectCancel();

                this.projectSkuInput = this.getProjectData.sku;
                this.changeSku = true;

                this.$nextTick(() => {
                    this.$refs.change_sku_input.focus();
                });
            },
            changeSkuCancel() {
                this.projectSkuInput = this.getProjectData.sku;
                this.changeSku = false;
            },
            async changeSkuSubmit() {
                try {
                    this.loading = true;

                    await projectApi.edit(this.getProjectData.id, {
                        sku: this.projectSkuInput
                    });

                    await this.$store.dispatch("updateProjectData", { sku: this.projectSkuInput });

                    this.changeSku = false;
                    this.skuSnackbar = true;
                } catch (error) {
                    console.error(error);
                    this.$refs.change_sku_form.setErrors(error.response.data.errors);
                } finally {
                    this.loading = false;
                }
            }
        }
    }
</script>

<style scoped lang="scss">
.project-header {
    align-items: center;

    .sku-title-wrap {
        position: relative;
        background-color: #E8E8E8;
        border-radius: 18px;

        input {
            position: absolute;
            top: -98765px;
            left: -98765px;
        }

        .project-title {
            font-size: 16px;
        }
    }

    .project-title {
        font-size: 24px;
        line-height: 27px;
        color: #464646;
        font-weight: 400;
    }

    .project-type {
        width: 68px;
        height: 24px;
        margin-left: 20px;
        border-radius: 5px;
        border: 1px solid #464646;
        font-size: 12px;
        line-height: 14px;
        background: #d9fbdd;
        color: #464646;
    }

    .project-status-wrap {
        margin-left: auto;
    }

    .form-wrapper {
        width: 100%;

        .v-btn {
            min-width: auto;

            &::before {
                display: none;
            }
        }
    }

    ::v-deep .input {
        width: 460px;

        .v-text-field__details {
            margin-bottom: 0;
        }
    }

    .input-error {
        font-size: 12px;
        color: #ff5252;
    }

    ::v-deep .snackbar .v-snack__wrapper.theme--dark {
        min-width: 240px;
        box-shadow: none !important;
        background: #f2faf3;
        border-radius: 1px;
    }

    ::v-deep .snackbar .v-snack__content {
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        color: #009A22;
    }

    ::v-deep .snackbar .v-snack__action {
        display: none;
    }

    @media (min-width: 769px) {
        &.active-form {
            .project-type {
                display: none !important;
            }
        }
    }

    @media (max-width: 768px) {
        flex-wrap: wrap;

        .project-type {
            order: 1;
            margin: 0 0 10px auto;
        }

        .project-status-wrap {
            margin: 0 0 10px 8px;
            order: 2;
        }

        .project-title-wrapper {
            width: 100%;
            order: 3;
            margin-left: 0 !important;
        }

        .form-wrapper {
            label {
                width: 100%;
                margin-right: 12px;
            }
        }

        ::v-deep .input {
            width: 100%;
        }
    }
}

</style>
