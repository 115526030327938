<template>
    <v-toolbar
        flat
        tile
        width="100%">
        <v-btn
            :to="placePath"
            class="login__back-link d-inline-flex align-center"
            depressed
            height="30"
            outlined
            plain
            text
            color="#464646">
            <v-img
                :src="arrowBoldLeft"
                class="pr-6"
                contain
                height="12"
                width="12"/>
            Back to {{ placeText }}
        </v-btn>
        <v-spacer/>
        <v-btn
            :class="classButton"
            :to="{name: pathButton}"
            class="login__toolbar-btn"
            depressed
            height="30"
            min-width="108">
            {{ textButton }}
        </v-btn>
    </v-toolbar>
</template>

<script>
    import arrowBoldLeft from "@/assets/icons/ic-arrow-bold-left.svg";

    export default {
        name: "login-header",
        data () {
            return {
                arrowBoldLeft
            };
        },
        created () {
            this.$root.$on("showLoginHelpers", this.getToHelper);
        },
        beforeDestroy () {
            this.$root.$off("showLoginHelpers");
        },
        methods: {
            back(){
                if(this.placeText == "Homepage"){
                    this.$router.push("/")
                }
            }
        },
        computed: {
            placePath () {
                if(this.placeText == "Homepage"){
                    return { name: "home" }
                } else {
                    return { name: "login" };
                }
            },
            placeText () {
                if (this.$route.name !== "login") {
                    return "Sign In";
                }
                return "Homepage";
            },
            textButton () {
                return this.$route.name === "login" ? "Register" : "Sing In";
            },
            pathButton () {
                return this.$route.name === "login" ? "registration" : "login";
            },
            classButton () {
                return this.$route.name === "login" ? "is-register" : "is-sigin";
            }
        }
    };
</script>

<style lang="scss">
    $color-red: #ff1257;
    $color-orange: #ffef01;
    $color-grey: #464646;

    .login {
        .v__toolbar {
            width: 100%;
        }
        &__toolbar-btn {
            .v-btn__content {
                font-size: 14px !important;
                font-weight: 400 !important;
            }
            &.v-btn {
                border-radius: 4px;
                border: 1px solid $color-grey !important;
                &.is-register {
                    background-color: #9FF4A9;
                    height: 30px;
                    width: 108px;
                    border: 1px solid #464646;
                    border-radius: 50px;
                }
                &.is-sigin {
                    background-color: #9FF4A9;
                    height: 30px;
                    width: 108px;
                    border: 1px solid #464646;
                    border-radius: 50px;
                }
            }
        }
        &__back-link {
            color: $color-grey !important;
            border: none;
            font-weight: 500;
            text-decoration: none;
        }
    }
</style>
