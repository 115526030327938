<template>
    <div class="badge" :class="type === 1 ? '':'badge--case-study'">{{types[type]}}</div>
</template>

<script>
    export default {
        props: {
            type: {
                type: Number,
                default: 1
            }
        },
        data() {
            return {
                types: {
                    2: "Case study",
                    1: "Article"
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.badge {
  width: fit-content;
  padding: 4px 10px;
  background: #FFE9E9;
  border: 1px solid #464646;
  border-radius: 4px;
  margin-right: 18px;

  font-family: 'Inter';
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  &--case-study {
    background: #F0FFF3;
  }
}
</style>