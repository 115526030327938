<template>
    <v-container
        class="pa-6 pt-md-16"
        fluid>
        <div class="def-page-cont">
            <h1 class="mb-6 mb-md-12">Your Account</h1>
            <v-row>
                <v-col
                    cols="12"
                    md="5">
                    <div class="profile-menu-wrap">
                        <profile-menu/>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    md="7">
                    <div class="order-history-cont-wrap">
                        <h3 class="profile-ttl mb-8">
                            Order History
                        </h3>
                        <order-history-data :list="list" :pagination="pagination" :loading="loading" @changePage="changePage"/>
                    </div>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>

<script>
    import ProfileMenu from "@/components/user-profile/ProfileMenu";
    import OrderHistoryData from "@/components/user-profile/OrderHistoryData";
    import { userApi } from "@/api";

    export default {
        name: "order-history",
        components: {
            ProfileMenu,
            OrderHistoryData
        },
        data () {
            return {
                loading: false,
                list: [],
                pagination: {
                    count: null,
                    current_page: null,
                    links: null,
                    per_page: null,
                    total: null,
                    total_pages: null
                }
            };
        },
        beforeMount () {
            this.getInfo();
        },
        methods: {
            async queryReplaceHandler (query) {
                await this.$router.push({
                    query: {
                        ...this.$route.query,
                        "page": query
                    }
                });
            },
            async getInfo () {
                this.loading = true;

                const params = { page:  Math.abs(this.$route.query.page) || 1 };

                try{
                    const response = await userApi.getOrderHistory(params)
                    let data = response.data;
                    this.pagination = data.meta.pagination;
                    this.list = data.data;
                } catch (e) {
                    console.log(e)
                } finally {
                    this.loading = false;
                }
            },
            async changePage (page) {
                if (this.pagination.current_page !== page) {
                    await this.queryReplaceHandler(page);
                    await this.getInfo();
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    .profile-menu-wrap {
        position: sticky;
        top: 10px;
        @media screen and (min-width: 960px) {
            padding-right: 15px;
        }
    }
    .order-history-cont-wrap {
        padding: 24px 28px 20px;
		border: 1px solid #888888;
		box-sizing: border-box;
		border-radius: 12px;

		.profile-ttl {
			font-size: 24px;
			line-height: 27px;
			color: #464646;
			padding-bottom: 8px;
			border-bottom: 1px solid #E7E7E7;
            font-weight: 500;
		}

        @media screen and (max-width: 1200px) {
            padding: 24px;
        }
        @media screen and (max-width: 959px) {
            margin-top: 24px;
            padding: 24px 16px;
        }
    }
</style>
