<template>
    <div class="container w-full">
        <validation-observer class="new-article" v-show="!preview" tag="div" ref="form"
                             v-slot="{ handleSubmit }">
            <v-form @submit.prevent="handleSubmit(submitHandler)">
                <h2 class="mb-7 font-weight-regular">{{ isEdit ? 'Edit Article' : 'New Article' }}</h2>
                <div class="mb-6 d-flex justify-space-between">
                    <div class="mr-10 w-full">
                        <h4 class="new-article-input-label mb-2 font-weight-semibold main-text--text">Article Title</h4>
                        <validation-provider
                            v-slot="{ errors }"
                            mode="eager"
                            name="Title"
                            vid="title"
                            rules="required|min:5"
                            tag="label">
                            <v-text-field
                                v-model="form.title"
                                flat
                                outlined
                                hide-details
                                height="34"
                                class="new-article-input rounded-0"
                                placeholder="Title"/>
                            <div v-if="errors.length" class="text-caption error--text">{{ errors[0] }}</div>
                        </validation-provider>
                    </div>
                    <div class="flex-shrink-0">
                        <h4 class="new-article-input-label mb-2 font-weight-semibold main-text--text">Publish Date</h4>
                        <v-menu
                            v-model="menu1"
                            :close-on-content-click="true"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="computedDateFormatted"
                                    class="mt-0 pt-0 article-date-picker-input"
                                    color="#3F7359"
                                    hide-details
                                        
                                    append-icon="mdi-calendar-month-outline"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"></v-text-field>
                            </template>
                            <v-date-picker
                                class="article-date-picker"
                                color="#3F7359"
                                v-model="form.post_date"
                                no-title
                                placeholder="10 / 12 / 2021"
                                :weekday-format="formatWeekDay"
                                :show-current="false"
                                @input="menu2 = false"></v-date-picker>
                        </v-menu>
                    </div>
                </div>
                <div class="mb-6 d-flex justify-space-between align-center">
                    <div class="d-flex">
                        <div class="flex-shrink-0 mr-6">
                            <h4 class="new-article-input-label mb-2 font-weight-semibold main-text--text">Type</h4>
                            <validation-provider
                                v-slot="{ errors }"
                                mode="eager"
                                name="Type"
                                vid="type"
                                rules="required"
                                tag="label">
                                <v-select
                                    class="article-select"
                                    v-model="form.type"
                                    :items="typesOptions"
                                    :menu-props="{ button: true, offsetY: true }"
                                    :color="'#464646'"
                                    :item-color="'#464646'"
                                    dense
                                    placeholder="Type"
                                    hide-details
                                    outlined/>
                                <div v-if="errors.length" class="text-caption error--text">{{ errors[0] }}</div>
                            </validation-provider>
                        </div>
                        <div class="flex-shrink-0">
                            <h4 class="new-article-input-label mb-2 font-weight-semibold main-text--text">Category</h4>
                            <validation-provider
                                v-slot="{ errors }"
                                mode="eager"
                                name="Category"
                                vid="blog_category"
                                rules="required"
                                tag="label">
                                <v-combobox
                                    class="article-select"
                                    v-model="form.blog_category"
                                    placeholder="Category"
                                    dense
                                    hide-details
                                    outlined
                                    :items="categoryItems"></v-combobox>
                                <div v-if="errors.length" class="text-caption error--text">{{ errors[0] }}</div>
                            </validation-provider>
                        </div>
                    </div>
                       
                    <div class="d-flex">
                        <div class="d-flex flex-shrink-0 mr-6" style="width: 108px;">
                            <validation-provider
                                ref="list_image"
                                v-slot="{ errors }"
                                mode="eager"
                                name="List image"
                                vid="list_image"
                                rules="required"
                                class="w-full"
                                tag="div">
                                <files-uploader
                                    v-if="!form.list_image || !form.list_image.url"
                                    @onChange="files => uploadFilePreview({ key: 'list_image', files })"
                                    class="upload-form conf-upload-form d-flex flex-column align-center justify-center w-full cursor-pointer">
                                    <span class="d-flex align-center font-weight-semibold main-text--text">
                                        <img class="mr-2" width="40"
                                             :src="require('@/assets/icons/ic-upload-dark.svg')" alt="">
                                        PLP
                                    </span>
                                    <p class="mt-1 mb-0 main-text--text">Drag and drop</p>
                                </files-uploader>
                                    

                                <div v-else-if="form.list_image && form.list_image.url" class="pos-rel d-inline-flex w-full">
                                    <img class="d-block object-cover w-full" :src="form.list_image.url" alt=""
                                         style="max-height: 80px; max-width: 100%">
                                    <v-btn class="pci-ad-cl-btn" icon @click="removeFile(form.list_image.id, 'list_image')">
                                        <v-img contain height="24" max-width="24"
                                               :src="require('@/assets/icons/ic-close-modal.svg')"/>
                                    </v-btn>
                                </div>
                                <div v-if="errors.length" class="text-caption error--text">{{ errors[0] }}</div>
                            </validation-provider>
                        </div>
                        <div class="d-flex flex-shrink-0" style="width: 108px;">
                            <validation-provider
                                ref="page_image"
                                v-slot="{ errors }"
                                mode="eager"
                                name="Page image"
                                vid="page_image"
                                rules="required"
                                class="w-full"
                                tag="div">
                                <files-uploader
                            
                                    v-if="!form.page_image || !form.page_image.url"
                                    @onChange="files => uploadFilePreview({ key: 'page_image', files })"
                                    class="upload-form conf-upload-form d-flex flex-column align-center justify-center w-full cursor-pointer">
                                    <span class="d-flex align-center font-weight-semibold main-text--text">
                                        <img class="mr-2" width="40"
                                             :src="require('@/assets/icons/ic-upload-dark.svg')" alt="">
                                        PDP
                                    </span>
                                    <p class="mt-1 mb-0 main-text--text">Drag and drop</p>
                                </files-uploader>

                                <div v-else-if="form.page_image && form.page_image.url" class="pos-rel d-inline-flex w-full">
                                    <img class="d-block object-cover w-full" :src="form.page_image.url" alt=""
                                         style="max-height: 80px; max-width: 100%">
                                    <v-btn class="pci-ad-cl-btn" icon @click="removeFile(form.page_image.id, 'page_image')">
                                        <v-img contain height="24" max-width="24"
                                               :src="require('@/assets/icons/ic-close-modal.svg')"/>
                                    </v-btn>
                                </div>
                                <div v-if="errors.length" class="text-caption error--text">{{ errors[0] }}</div>
                            </validation-provider>
                        </div>
                    </div>
                </div>
                <div class="mb-6 d-flex align-center">
                    <div class="flex-shrink-0 mr-6">
                        <h4 class="new-article-input-label mb-2 font-weight-semibold main-text--text">Status</h4>
                        <div class="d-flex align-center">
                            <v-select
                                class="article-select mr-6"
                                v-model="form.status"
                                :items="statusOptions"
                                :menu-props="{ button: true, offsetY: true }"
                                :color="'#464646'"
                                :item-color="'#464646'"
                                dense
                                placeholder="Status"
                                hide-details
                                outlined/>
                            <simple-checkbox
                                v-model="form.is_promoted"
                                hide-details
                                :simple="false"
                                class="mt-0 pt-0"
                                label="Promoted"/>
                        </div>
                    </div>
                </div>
                <div class="mb-6">
                    <h4 class="new-article-input-label mb-2 font-weight-semibold main-text--text">Short description</h4>
                       
                    <validation-provider
                        v-slot="{ errors }"
                        mode="eager"
                        name="Short description"
                        vid="short_description"
                        rules="required"
                        tag="label">
                        <v-textarea
                            v-model="form.short_description"
                            flat
                            outlined
                            hide-details
                            no-resize
                            rows="4"
                            class="new-article-input new-article-textarea rounded-0"
                            placeholder=""/>
                        <div v-if="errors.length" class="text-caption error--text">{{ errors[0] }}</div>
                    </validation-provider>
                </div>
                <div class="mb-6">
                    <h4 class="new-article-input-label mb-2 font-weight-semibold main-text--text">Description</h4>
                       
                    <validation-provider
                        v-slot="{ errors }"
                        mode="eager"
                        name="Description"
                        vid="description"
                        rules="required"
                        tag="label">
                        <tiny-editor
                            v-model="form.description"
                            plugins="customem customadv"
                            placeholder-fields
                            id="tiny-editor"/>
                        <div v-if="errors.length" class="text-caption error--text">{{ errors[0] }}</div>
                    </validation-provider>
                </div>
                <div class="mb-6">
                    <h4 class="new-article-input-label mb-2 font-weight-semibold main-text--text">Learn more description</h4>
                       
                    <validation-provider
                        v-slot="{ errors }"
                        mode="eager"
                        name="Learn more description"
                        vid="learn_more_description"
                        rules="required"
                        tag="label">
                        <tiny-editor
                            v-model="form.learn_more_description"
                            plugins="customlink"
                            id="tiny-editor"/>
                        <div v-if="errors.length" class="text-caption error--text">{{ errors[0] }}</div>
                    </validation-provider>
                </div>
                <h2 class="mb-7 font-weight-regular">SEO</h2>
                <div>
                    <div class="mb-6 d-flex justify-space-between">
                        <div class="mr-10 w-full">
                            <h4 class="new-article-input-label mb-2 font-weight-semibold main-text--text">Meta title</h4>
                            <validation-provider
                                v-slot="{ errors }"
                                mode="eager"
                                name="Meta title"
                                vid="meta_title"
                                rules="required|min:5"
                                tag="label">
                                <v-text-field
                                    v-model="form.meta_title"
                                    flat
                                    outlined
                                    hide-details
                                    height="34"
                                    class="new-article-input rounded-0"
                                    placeholder="Meta title"/>
                                <div v-if="errors.length" class="text-caption error--text">{{ errors[0] }}</div>
                            </validation-provider>
                        </div>
                        <div class="d-flex flex-shrink-0 mr-6" style="width: 108px;">
                            <validation-provider
                                ref="og_image"
                                v-slot="{ errors }"
                                mode="eager"
                                name="Og image"
                                vid="og_image"
                                rules="required"
                                class="w-full"
                                tag="div">
                                <files-uploader
                               
                                    v-if="!form.og_image || !form.og_image.url"
                                    @onChange="files => uploadFilePreview({ key: 'og_image', files })"
                                    class="upload-form conf-upload-form d-flex flex-column align-center justify-center w-full cursor-pointer">
                                    <span class="d-flex align-center font-weight-semibold main-text--text">
                                        <img class="mr-2" width="40"
                                             :src="require('@/assets/icons/ic-upload-dark.svg')" alt="">
                                        OG img
                                    </span>
                                    <p class="mt-1 mb-0 main-text--text">Drag and drop</p>
                                </files-uploader>

                                <div v-else-if="form.og_image && form.og_image.url" class="pos-rel d-inline-flex w-full">
                                    <img class="d-block object-cover w-full" :src="form.og_image.url" alt=""
                                         style="max-height: 80px; max-width: 100%">
                                    <v-btn class="pci-ad-cl-btn" icon @click="removeFile(form.og_image.id, 'og_image')">
                                        <v-img contain height="24" max-width="24"
                                               :src="require('@/assets/icons/ic-close-modal.svg')"/>
                                    </v-btn>
                                </div>
                                <div v-if="errors.length" class="text-caption error--text">{{ errors[0] }}</div>
                            </validation-provider>
                        </div>
                    </div>
                </div>
                <div class="mb-6">
                    <h4 class="new-article-input-label mb-2 font-weight-semibold main-text--text">Meta keywords</h4>
                    <validation-provider
                        v-slot="{ errors }"
                        mode="eager"
                        name="Meta keywords"
                        vid="meta_keywords"
                        rules=""
                        tag="label">
                        <v-text-field
                            v-model="form.meta_keywords"
                            flat
                            outlined
                            hide-details
                            height="34"
                            class="new-article-input rounded-0"
                            placeholder="Keywords"/>
                        <div v-if="errors.length" class="text-caption error--text">{{ errors[0] }}</div>
                    </validation-provider>
                </div>
                <div class="mb-6">
                    <h4 class="new-article-input-label mb-2 font-weight-semibold main-text--text">Meta description</h4>
                    <validation-provider
                        v-slot="{ errors }"
                        mode="eager"
                        name="Meta description"
                        vid="meta_description"
                        rules="required"
                        tag="label">
                        <v-textarea
                            v-model="form.meta_description"
                            flat
                            outlined
                            hide-details
                            no-resize
                            rows="5"
                            class="new-article-input new-article-textarea  rounded-0"
                            placeholder="Meta description"/>
                        <div v-if="errors.length" class="text-caption error--text">{{ errors[0] }}</div>
                    </validation-provider>
                </div>
                <h2 class="mb-7 font-weight-regular">Advertisement blocks</h2>
               
                <div class="d-flex align-center mb-6">
                    <div class="blocks d-flex mr-6">
                        <div class="blocks_item mr-6" v-for="(block, idx) in form.blog_ads" :key="idx">
                            <div class="d-flex flex-shrink-0 mb-6" style="width: 108px;">
                                <validation-provider
                                    :ref="`blog_ads${idx}`"
                                    v-slot="{ errors }"
                                    mode="eager"
                                    name="Block icon"
                                    :vid="`blog_ads${idx}`"
                                    rules="required"
                                    class="w-full"
                                    tag="div">
                                    <files-uploader 
                                        v-if="(form.blog_ads[idx] && !form.blog_ads[idx].icon)"
                                        @onChange="files => uploadFilePreview({ key: 'blog_ads', idx: idx, files })"
                                        class="upload-form conf-upload-form d-flex flex-column align-center justify-center w-full cursor-pointer">
                                        <span class="d-flex align-center font-weight-semibold main-text--text">
                                            <img class="mr-2" width="40"
                                                 :src="require('@/assets/icons/ic-upload-dark.svg')" alt="">
                                            icon
                                        </span>
                                        <p class="mt-1 mb-0 main-text--text">Drag and drop</p>
                                    </files-uploader>

                                    <div v-else-if="form.blog_ads[idx] && form.blog_ads[idx].icon && form.blog_ads[idx].icon.url" class="pos-rel d-inline-flex w-full">
                                        <img class="d-block object-cover w-full" :src="form.blog_ads[idx].icon.url" alt=""
                                             style="max-height: 80px; max-width: 100%">
                                        <v-btn class="pci-ad-cl-btn" icon @click="removeFile(form.blog_ads[idx].icon.id, 'blog_ads', idx)">
                                            <v-img contain height="24" max-width="24"
                                                   :src="require('@/assets/icons/ic-close-modal.svg')"/>
                                        </v-btn>
                                    </div>
                                    <div v-if="errors.length" class="text-caption error--text">{{ errors[0] }}</div>
                                </validation-provider>
                            </div>
                            <div class="w-full mb-6">
                                <h4 class="new-article-input-label mb-2 font-weight-semibold main-text--text">Title</h4>
                                <validation-provider
                                    v-slot="{ errors }"
                                    mode="eager"
                                    name="Title"
                                    vid="blog_ads[idx].title"
                                    rules="required"
                                    tag="label">
                                    <v-text-field
                                        v-model="form.blog_ads[idx].title"
                                        flat
                                        outlined
                                        hide-details
                                        height="34"
                                        class="new-article-input rounded-0"
                                        placeholder="Title"/>
                                    <div v-if="errors.length" class="text-caption error--text">{{ errors[0] }}</div>
                                </validation-provider>
                            </div>
                            <div class="w-full mb-6">
                                <h4 class="new-article-input-label mb-2 font-weight-semibold main-text--text">Description</h4>
                                <validation-provider
                                    v-slot="{ errors }"
                                    mode="eager"
                                    name="Description"
                                    vid="blog_ads[idx].description"
                                    rules="required"
                                    tag="label">
                                    <v-textarea
                                        v-model="form.blog_ads[idx].description"
                                        flat
                                        outlined
                                        hide-details
                                        no-resize
                                        rows="4"
                                        class="new-article-input new-article-textarea rounded-0"
                                        placeholder="Title"/>
                                
                                    <div v-if="errors.length" class="text-caption error--text">{{ errors[0] }}</div>
                                </validation-provider>
                            </div>
                            <v-btn
                                outlined
                                rounded
                                width="108"
                                height="40"
                                color="#464646"
                                class="mr-3"
                                @click="removeBlock(idx)">
                                <v-icon color="#464646" size="18" class="mr-2">mdi-close</v-icon>
                                Remove
                            </v-btn>
                        </div>
                    </div>
                    <v-btn
                        v-show="form.blog_ads.length <3"
                        outlined
                        rounded
                        width="108"
                        height="40"
                        color="#464646"
                        class="mr-3"
                        @click="addBlock">
                        Add block
                    </v-btn>
                </div>
    
                <div class="d-flex justify-end">
                    <v-btn
                        outlined
                        rounded
                        width="108"
                        height="40"
                        color="#464646"
                        class="mr-3"
                        to="/blog-cms">
                        Cancel
                    </v-btn>
                    <v-btn
                        outlined
                        rounded
                        width="108"
                        height="40"
                        color="#464646"
                        class="mr-3"
                        @click.prevent="handleSubmit(onPreview)">
                        <v-icon size="16" class="mr-1">mdi-open-in-new</v-icon>
                        Preview
                    </v-btn>
                    <v-btn
                        type="submit"
                        outlined
                        rounded
                        width="168"
                        height="40"
                        color="#464646"
                        :loading="loading"
                        class="bg-primary">
                        Save and Publish
                    </v-btn>
                </div>
            </v-form>
        </validation-observer>
        <div class="bg-white" v-if="preview">
            <article-component @closePreview="preview = false" :content="form" :preview-data="true"/>
        </div>
    </div>
</template>

<script>
    import { ValidationObserver, ValidationProvider } from "vee-validate";
    import FilesUploader from "@/components/ui/FilesUploader";
    import UploadService from "@/api/uploadFilesService.api";
    import { managerApi } from "@/api";
    import TinyEditor from "@/components/ui/TinyEditor"
    import SimpleCheckbox from "@/components/ui/SimpleCheckbox";
    import ArticleComponent from "@/components/blog/Article"

    export default {

        /*
         * NOTICE
         *
         * if you add the files and don't save the demo
         * files are not deleted from the server
         *
         * */
        name: "article-form",
        components: {
            ValidationObserver,
            ValidationProvider,
            FilesUploader,
            TinyEditor,
            SimpleCheckbox,
            ArticleComponent
        },
        data() {
            return {
                loading: false,
                form: {
                    title: "",
                    post_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                    list_image: null,
                    page_image: null,
                    type: null,
                    blog_category: "",
                    short_description: "",
                    description: "",
                    learn_more_description: "",
                    status: 1,
                    meta_title: "",
                    og_image: null,
                    meta_keywords: "",
                    meta_description: "",
                    is_promoted: false,
                    blog_ads: []
                },
                menu1: false,
                typesOptions: [{ text: "Article", value: 1 }, { text: "Case study", value: 2 }],
                statusOptions: [{ text: "Active", value: 1 }, { text: "Inactive", value: 0 }],
                categoryItems: [],
                preview: false,
                isEdit: !!this.$route.params.id
            }
        },
        computed: {
            computedDateFormatted () {
                return this.formatDate(this.form.post_date)
            }
        },
        async mounted() {
            if(this.isEdit) {
                await this.getArticle()
                await this.validateImages()
                
                
                
            }
            await this.getCategoryList()
        },
        methods: {
            async getArticle() {
                try {
                    const { data } = await managerApi.getArticle(this.$route.params.id)
                    this.form = { 
                        ...data.data, 
                        blog_category: data.data.category.title, 
                        post_date: this.$moment(data.data.post_date).format("YYYY-MM-DD")
                    }
                    
                } catch(err) {
                    this.errorSnackbar = true;
                    this.errorMessage = "Failure. Try again or contact our support team";
                }
            },
            async getCategoryList() {
                try {
                    const { data } = await managerApi.getCategoryList()
                    this.categoryItems = data.data.map(el => el.title)

                } catch(err) {
                    this.errorSnackbar = true;
                    this.errorMessage = "Failure. Try again or contact our support team";
                }
            },
            onPreview() {
                this.preview = true
                window.scrollTo(0, 0)
            },
            formatWeekDay(props) {
                return this.$moment(props).format("ddd")
            },
            formatDate (date) {
                if (!date) return null

                const [year, month, day] = date.split("-")
                return `${day}/${month}/${year}`
            },
            async submitHandler() {
                const validImages = await this.validateImages()
                if(!validImages) {
                    return
                }
                this.loading = true
                const payload = {
                    ...this.form,
                    list_image_id: this.form.list_image.id,
                    page_image_id: this.form.page_image.id,
                    og_image_id: this.form.og_image.id,
                    blog_ads: this.form.blog_ads.map(el => ({
                        ...el,
                        icon_id: el.icon.id
                    })),
                    post_date: this.$moment(this.form.post_date).format("MM/DD/yyyy"),
                    blog_ads: this.form.blog_ads.map((el) => {
                        const temp = {
                            ...el,
                            icon_id: el.icon.id,
                            id: el.id || null
                        }
                        delete temp.icon
                        return temp
                    })
                }
                delete payload.category
                delete payload.list_image
                delete payload.page_image
                delete payload.og_image
                try {
                
                    const { data } = this.isEdit ? await managerApi.editArticle(payload, this.$route.params.id) : await managerApi.createArticle(payload)
                    this.$router.push("/blog-cms")
                } catch (e) {
                    this.errorSnackbar = true;
                    this.errorMessage = "Failure. Try again or contact our support team";
                } finally {
                    this.loading = false
                }
            },
            async uploadFilePreview(filesData) {
                
                const { key, files, idx } = filesData;

                const result = await Promise.all(files.map(async (file) => {
                    file.typeImage = this.$config.fileTypes.attachment
                    return await UploadService.upload(file)
                }));


                if(idx || idx === 0) {
                    const { valid } = await this.$refs[key+idx][0].validate(result[0]);
                    if(valid) {
                        this.form[key][idx].icon = result[0];
                    }
                } else {

                    const { valid } = await this.$refs[key].validate(result[0]);

                    if(valid) {
                        this.form[key] = result[0];
                    }
                }
                
                
            },
            async validateImages() {
                const og_image = await this.$refs["og_image"].validate(this.form.og_image);
                const page_image = await this.$refs["page_image"].validate(this.form.page_image);
                const list_image = await this.$refs["list_image"].validate(this.form.list_image);
                let blog_ads0 = { valid: true };
                let blog_ads1 = { valid: true };
                let blog_ads2 = { valid: true };
                if(this.$refs["blog_ads0"] && this.$refs["blog_ads0"].length) {
                    blog_ads0 = await this.$refs["blog_ads0"][0].validate(this.form.blog_ads[0]);
                }
                if(this.$refs["blog_ads1"] && this.$refs["blog_ads1"].length) {
                    blog_ads1 = await this.$refs["blog_ads1"][0].validate(this.form.blog_ads[1]);
                }
                if(this.$refs["blog_ads2"] && this.$refs["blog_ads2"].length) {
                    blog_ads2 = await this.$refs["blog_ads2"][0].validate(this.form.blog_ads[2]);
                }

                if(!og_image.valid || !page_image.valid || !list_image.valid || !blog_ads0.valid || !blog_ads1.valid || !blog_ads2.valid) {
                    return false
                } else {
                    return true
                }
            },
            async removeFile(id, key, idx) {
                if(idx || idx === 0) {
                    this.form[key][idx].icon = null
                    await this.$refs[key+idx][0].validate(this.form[key][idx].icon);
                } else {
                    this.form[key] = null
                    await this.$refs[key].validate(this.form[key]);
                }
            },
            addBlock() {
                this.form.blog_ads.push({
                    icon: null,
                    title: "",
                    description: ""
                })
            },
            removeBlock(idx) {
                this.form.blog_ads = this.form.blog_ads.filter((el, ind) => ind !== idx)
            }
        }
    }
</script>

<style scoped lang="scss">
.container {
  margin-bottom: 50px;
  margin-top: 50px;
}
// .page {
//   overflow-y: auto;
//   padding: 50px;
// }
.new-article {
h2 {
  font-size: 24px;
  line-height: 26px;
}

h3 {
  font-size: 18px;
  line-height: 19px;
}

.new-article-input-label {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.04em;
}

::v-deep .new-article-input {
  .v-input__slot {
    padding: 0 10px !important;
    min-height: 34px;
  }

  fieldset {
    border-color: #464646;
  }

  input {
    font-size: 12px;

    &::placeholder {
      color: #BCBCBC;
    }
  }
}

.upload-form {
  height: 220px;
  border-radius: 5px;
  border: 1px dashed #464646;
  font-size: 32px;
  line-height: 35px;

  p {
    font-size: 18px;
    line-height: 23px;
  }

  &.conf-upload-form {
    height: 80px;
    font-size: 24px;
    line-height: 26px;
  }

  p {
    font-size: 14px;
    line-height: 17px;
  }
}

::v-deep .new-article-textarea {
  .v-input__slot {
    padding: 0 10px !important;
    min-height: 80px;
  }

  fieldset {
    border-color: #464646;
  }

  textarea {
    font-size: 12px;
    line-height: 15px;
    margin-right: 4px;
    margin-bottom: 10px;

    &::placeholder {
      color: #BCBCBC;
    }
  }
}

.remove-render-btn::before {
  display: none;
}
}

.article-date-picker-input {
  &::v-deep {
    .v-input__control {
      border: 1px solid #464646;
      height: 34px;
    }
    .v-input__slot {
      border-bottom: none;
      margin: 0;
      &::before {
        display: none;
      }
      &::after {
        display: none;
      }
    }
    .v-input__append-inner {
      padding: 0;
      margin: 0;
      margin-top: 5px;
    }
    input {
      padding-left: 9px;
      padding-top: 9px;
      padding-bottom: 9px;
      font-size: 12px;
      line-height: 16px;
      width: 80px;
      margin-right: 6px;
    }
    i {
      font-size: 20px !important;
      padding-right: 9px;
    }
    
  }
}

.article-date-picker {
  &::v-deep {
    .v-date-picker-table {
      button {
        border-radius: 2px;
      }
    }
    .v-date-picker-header {
      .v-btn {
        border: 1px solid #888888;
        width: 22px;
        height: 22px;
        i {
          font-size: 16px;
        }
      }
    }
  }
}
.article-select {
  width: 176px;
  &::v-deep {
    .v-select__selections {
      padding: 0 !important;
    }
    fieldset {
      border: 1px solid #464646;
      border-radius: 0;
    }
    .v-input__slot {
      height: 34px !important;
      min-height: 34px !important;
    }
    .v-input__append-inner {
      margin-top: 5px !important;
    }
    .v-select__selection {
      font-size: 12px;
    }
    input {
      font-size: 12px;
    }
    input::placeholder {
      color: #BCBCBC;
    }
  }
}
.new-article-textarea {
  font-size: 12px;
}
.bg-white {
  background: #fff;
  color: #000;
}
.blocks {
  &_item {
    width: 100%;
    max-width: 250px;
  }
}
</style>