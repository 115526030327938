<template>
    <div :class="['number-input', {'hide-controls': hideControls}]">
        <money :disabled="disabled" v-model="inputVal" v-bind="money"/>
        <div v-if="!hideControls" class="number-input-controls d-flex flex-column align-center">
            <button :disabled="disabled" type="button" tabindex="-1" @click="increment">
                <img :src="require('@/assets/icons/ic-caret-up.svg')">
            </button>
            <button :disabled="disabled" type="button" tabindex="-1" @click="decrement">
                <img :src="require('@/assets/icons/ic-caret-down.svg')">
            </button>
        </div>
    </div>
</template>

<script>
    import { Money } from "v-money"
    export default {
        name: "number-input",
        components: {
            Money
        },
        props: {
            value: {
                type: [Number, String],
                default: 0
            },
            type: {
                type: String,
                default: "currency" // Could be currency, percent, number
            },
            max: {
                type: Number,
                default: null
            },
            min: {
                type: Number,
                default: null
            },
            precision: {
                type: Number,
                default: 0
            },
            prefix: {
                type: String,
                default: "$"
            },
            suffix: {
                type: String,
                default: ""
            },
            hideControls: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                money: {
                    decimal: ".",
                    thousands: this.$props.type === "number" ? "" : ",",
                    prefix: this.$props.prefix,
                    suffix: this.$props.suffix,
                    precision: this.$props.precision,
                    masked: false
                }
            }
        },
        computed: {
            inputVal: {
                get() {
                    return this.value;
                },
                set(val) {
                    if (this.type === "percent" && this.inputVal > 100) {
                        this.$emit("input", 100);
                    } else if (this.type === "number" && this.max !== null && this.inputVal > this.max) {
                        this.$emit("input", this.max);
                    } else if(this.min !== null && this.inputVal < this.min) {
                        this.$emit("input", this.min);
                    }else {
                        this.$emit("input", val);
                    }
                }
            }
        },
        methods: {
            increment() {
                if (this.type === "percent" && this.inputVal === 100) return;
                if (this.type === "number" && this.max !== null && this.inputVal === this.max) return;
                this.inputVal += 1;
            },
            decrement() {
                if (this.min !== null && this.inputVal === this.min) return;
                this.inputVal -= 1;
            }
        }
    }
</script>

<style scoped lang="scss" src="./style.scss"></style>
