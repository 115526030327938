<template>
    <div class="project-details">
        <div class="details-title">
            DETAILS
        </div>
        <div class="details-content">
            <div class="in-line">
                <div class="item">Request from: <span class="marked">{{ project.user }}</span></div>
                <div class="item" v-if="isManager || isAdmin">Designer: <span class="marked">{{ project.designer_name }}</span></div>
                <div class="item">Due Date: <span class="marked">{{ $moment(project.estimated_date).format("MMM. DD, YYYY") }}</span></div>
            </div>
            <div class="in-line">
                <div class="item">Model Price: <span class="marked">${{ project.client_amount || project.overall_designer_amount }}</span></div>
                <div class="item">Project ID: <span class="marked">{{ project.id || "none" }}</span></div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";

    export default {
        props: {
            project: {
                type: Object,
                default:()=>({})
            }
        },
        computed: {
            ...mapGetters([
                "isAdmin",
                "isManager"
            ])
        }
    }
</script>

<style scoped lang="scss">
  .project-details {
    border: 1px solid #BCBCBC;
    box-shadow: 4px 4px 4px rgba(70, 70, 70, 0.15);
    border-radius: 5px;
    padding: 14px 20px 16px 20px;

    .details-title {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #464646;
      padding-bottom: 11px;
      border-bottom: 1px solid #BCBCBC;
      margin-bottom: 2px;
    }

    .details-content {
        display: flex;
        justify-content: space-between;
      .item {
        margin-top: 10px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #464646;

        .marked {
          font-weight: 700;
        }
      }
    }
  }
</style>
