<template>
    <div class="project-card-item px-6 py-5 pb-4">
        <div class="pci-labels" v-if="!isCancelled">
            <div v-if="!isNew" class="pci-label pci-new-lbl">New</div>
            <div :class="[getPaymentClass(project.payment_status)]" v-if="getPaymentTitle(project.payment_status) && !isDesigner" class="pci-label pci-paid-lbl">
                {{ getPaymentTitle(project.payment_status) }}
            </div>
        </div>

        <div class="pci-lc">
            <div class="pci-gal-wrap">
                <div class="pci-gal">
                    <div v-if="singleImage" class="pci-img">
                        <img :src="singleImage" alt="">
                    </div>
                    <div v-else-if="filteredFiles.length" class="pci-swiper-wrap">
                        <swiper
                            ref="pciSwiper"
                            :options="swiperOption"
                            class="pci-swiper">
                            <swiper-slide v-for="(item, index) in filteredFiles" :key="index">
                                <div class="pci-img">
                                    <img :src="item.url" alt="">
                                </div>
                            </swiper-slide>
                        </swiper>
                        <div :class="`pci-prev-btn-${project.id}`" class="pci-swiper-btn pci-prev-btn">
                            <svg width="7" height="11" viewBox="0 0 7 11" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.62793 0.8125L0.869731 5.5707L5.62793 10.3289" stroke="#464646" stroke-linecap="round"/>
                            </svg>
                        </div>
                        <div :class="`pci-next-btn-${project.id}`" class="pci-swiper-btn pci-next-btn">
                            <svg width="7" height="12" viewBox="0 0 7 12" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.09863 10.7275L5.85683 5.96934L1.09863 1.21114" stroke="#464646" stroke-linecap="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="pci-rc">
            <div class="pci-rc-cont">
                <div class="pci-head-sect align-items-center">
                    <div class="pci-hs-lc ">
                        <span class="pci-type" @click.prevent.stop="setFilterType">{{ project.type }}</span>

                        <router-link
                            v-if="project.status_id !== $config.project.status.canceled"
                            :title="project.title"
                            :to="go"
                            class="pci-ttl">
                            {{ project.title }}
                        </router-link>
                        <div v-else class="pci-ttl">
                            {{ project.title }}
                        </div>
                    </div>
                    <div class="pci-hs-rc pci-status-wrapper align-items-center">
                        <div :class="$store.getters['getClassProjectStatus'](projectStatusLabel)" class="pci-status">{{ projectStatusLabel }}</div>
                        <v-menu
                            v-if="!availProjects && !isCancelled && ((allowedCancel(project.status_id) && isClient ) || isManager || isAdmin)"
                            bottom
                            class="pci-status-menu"
                            left
                            nudge-bottom="11"
                            nudge-left="11"
                            origin="bottom left">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    class="pci-status-menu__action"
                                    icon>
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item class="pci-status-menu__item is-cancel" dense>
                                    <v-list-item-title @click.stop="cancelDialog = true">Cancel this project</v-list-item-title>

                                    <template v-if="isClient">
                                        <dialog-cancel-project v-model="cancelDialog"
                                                               :project="project"
                                                               @update-projects="$emit('update-projects')"/>
                                    </template>
                                    <template v-if="isManager || isAdmin">
                                        <dialog-cancel-project-manager v-model="cancelDialog"
                                                                       :project="project"
                                                                       @update-projects="$emit('update-projects')"/>
                                    </template>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </div>
                <div v-if="$store.getters.isDesigner && availProjects" class="pci-date-sect mt-2">
                    <div class="pci-ds-col">
                        <span class="pci-ds-lbl">Created: </span>
                        <span class="pci-ds-val">{{ project.created_at | moment("created_at") }} ({{ getProjectOwner }})</span>
                    </div>
                    <div class="pci-ds-col">
                        <span class="pci-ds-lbl">Model price: </span>
                        <span class="pci-ds-val">${{ project.overall_designer_amount }}</span>
                    </div>
                </div>
                <div v-else-if="$store.getters.isDesigner && !availProjects" class="pci-date-sect three-cols mt-2">
                    <div class="pci-ds-col">
                        <span class="pci-ds-lbl">Created: </span>
                        <span class="pci-ds-val">{{ project.created_at | moment("created_at") }} ({{ getProjectOwner }})</span>
                    </div>
                    <div class="pci-ds-col">
                        <span class="pci-ds-lbl">Model price: </span>
                        <span class="pci-ds-val">${{ project.overall_designer_amount }}</span>
                    </div>
                    <div class="pci-ds-col">
                        <span class="pci-ds-lbl">Due date: </span>
                        <span v-if="project.estimated_date" class="pci-ds-val">{{ project.estimated_date | moment("created_at") }}</span>
                    </div>
                </div>
                <div v-else class="pci-date-sect  mt-5 ">
                    <div class="pci-ds-col">
                        <span class="pci-ds-lbl">Created: </span>
                        <span class="pci-ds-val">{{ project.created_at | moment("created_at") }} ({{ project.user }})</span>
                    </div>
                    <div class="pci-ds-col ">
                        <span class="pci-ds-lbl">Last edited: </span>
                        <span class="pci-ds-val">{{ project.updated_at | moment("updated_at") }}</span>
                    </div>
                </div>
                <div class="pci-options-sect">
                    <div class="pci-os-lc">
                        <div v-if="project.metals && project.metals.length" class="pci-os-item">
                            <span class="pci-os-lbl">Metal Types: </span>
                            <span class="pci-os-val">{{ project.metals.join(", ") }}</span>
                        </div>
                        <div v-if="project.stones && project.stones.length" class="pci-os-item">
                            <span class="pci-os-lbl">Stone Type: </span>
                            <span class="pci-os-val">{{ project.stones.join(", ") }}</span>
                        </div>
                        <div
                            v-if="availProjects && (project.payment_status === $config.project.paymentStatus.success || project.payment_status === $config.project.paymentStatus.refund)"
                            class="pci-btns-sect mt-4">
                            <v-tooltip top content-class="tooltip-information" :disabled="!isDesigner || isDesigner && profile.approved">
                                <template #activator="{on, attrs}">
                                    <div v-on="on" v-bind="attrs" class="d-inline-flex">
                                        <v-btn
                                            max-height="23"
                                            min-width="148"
                                            rounded
                                            outlined
                                            small
                                            elevation="0"
                                            color="#02810F"
                                            :disabled="isDesigner && !profile.approved"
                                            @click="actionProjectData">
                                            {{ actionProjectDataText }}
                                        </v-btn>
                                    </div>
                                </template>

                                <div class="text-center font-weight-regular">Contact our support to get <br> approved</div>
                            </v-tooltip>
                        </div>
                        <div class="d-flex align-center mt-1" v-if="(isManager || isAdmin) && project.designer_id">
                            <div class="body-2">
                                <span class="font-weight-bold">Modeler:</span> {{ project.designer_name }}
                            </div>
                            <v-dialog v-model="reassignModelerDialog" width="650">
                                <template v-slot:activator="{ attrs, on }">
                                    <v-btn class="ml-3 btn-assign-modeler" height="24" color="#464646" outlined
                                           rounded v-bind="attrs" v-on="on" @click.prevent.stop>
                                        Assign a Different Modeler
                                    </v-btn>
                                </template>
                                <div class="dialog-overflow-wrap">
                                    <v-btn class="pci-ad-cl-btn" icon>
                                        <v-img
                                            :src="require('@/assets/icons/ic-close-modal.svg')"
                                            contain
                                            height="36"
                                            max-width="36"
                                            @click="reassignModelerDialog = false"/>
                                    </v-btn>
                                    <reassign-view
                                        :on-going-project-id="project.id"
                                        :full-name="project.designer_name"
                                        :current-modeler-i-d="project.designer_id"
                                        @closeDialog="closeDialog">
                                    </reassign-view>
                                </div>
                            </v-dialog>
                        </div>
                    </div>
                    <div class="pci-os-rc">
                        <div class="pci-os-desc">
                            <truncate :text="project.description" type="html" :length="140" class="show-more-wrapper"
                                      v-if="project.description"></truncate>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from "moment";
    import { mapGetters } from "vuex";
    import truncate from "vue-truncate-collapsed";


    import "swiper/swiper-bundle.css";
    import { Autoplay, Mousewheel, Navigation, Pagination, Swiper as SwiperClass } from "swiper/core";
    import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";
    import DialogCancelProject from "@/components/ui/dialog-cancel-project";
    import DialogCancelProjectManager from "../ui/dialog-cancel-project-manager";
    import ReassignView from "../../views/manager/modelers-view/ReassignView.vue";

    SwiperClass.use([Pagination, Mousewheel, Navigation, Autoplay]);
    const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass);

    export default {
        name: "project-card",
        components: {
            DialogCancelProjectManager,
            DialogCancelProject,
            truncate,
            Swiper,
            SwiperSlide,
            ReassignView
        },
        filters: {
            moment: function (date, type) {
                if (type === "created_at") {
                    return moment(date).format("M/D/YY");
                }
                if (type === "updated_at") {
                    return moment(date).calendar(null, {
                        nextWeek: "M/D/YYYY [at] h:mmA",
                        lastWeek: "M/D/YYYY [at] h:mmA",
                        sameElse: "M/D/YYYY [at] h:mmA"
                    })
                }
            }
        },
        props: {
            project: {
                type: Object,
                default () {
                    return {};
                }
            },
            availProjects: {
                type: Number,
                default: 0
            }
        },
        data () {
            return {
                reassignModelerDialog: false,
                cancelDialog: false,
                swiperOption: {
                    navigation: {
                        nextEl: `.pci-next-btn-${this.project.id}`,
                        prevEl: `.pci-prev-btn-${this.project.id}`
                    },
                    pagination: {
                        el: ".pci-pagination",
                        clickable: true
                    },
                    //spaceBetween: 20,
                    loop: true,
                    slidesPerView: 1
                }
            };
        },
        computed: {
            ...mapGetters([
                "isClient",
                "isDesigner",
                "isManager",
                "isAdmin",
                "allowedCancel",
                "getValueProjectStatus",
                "profile"
            ]),
            isOnHold() {
                return this.project.extra_charge && this.project.extra_charge.status === this.$config.extraChargeStatuses.awaiting;
            },
            isCancelled() {
                return !this.project.status_id || this.project.status_id === this.$config.project.status.canceled
            },
            filteredFiles() {
                return this.project.files.filter(file => { return file.type !== this.$config.fileTypes.models &&  file.type !== this.$config.fileTypes.others})
            },
            isFinalAssets() {
                return this.project.status_id === this.$config.project.status.final_assets_prep && this.project.designer_id || this.project.status_id === this.$config.project.status.completed
            },
            isRenderProgress() {
                const { in_progress_rendering, in_cqa, ready_for_review_renders } = this.$config.project.status;
                return [in_progress_rendering, in_cqa, ready_for_review_renders].includes(this.project.status_id) && this.project.designer_id
            },
            projectStatusLabel() {
                if (this.isDesigner && this.project.status_id !== this.$config.project.status.completed && this.isOnHold) {
                    return this.$config.project.statusLabels[this.$config.project.status.on_hold];
                }
                if (!this.isDesigner && this.isOnHold) {
                    return this.$config.project.statusLabels[this.$config.project.status.on_hold_action_needed];
                }
                return this.project.status;
            },
            projectTypeFullInfo () {
                let allTypes = this.$store.getters.getProjectTypesFullInfo;
                return allTypes.find(item => item.title === this.project.type);
            },
            dueDate () {
                return moment().add(this.projectTypeFullInfo.due_date, "days").format("M/D/YY");
            },
            isNew () {
                let today = moment().format("M/D/YYYY");
                let createdAt = moment(this.project.created_at).format("M/D/YYYY");
                return today === createdAt;
            },
            singleImage () {
                let result = null;
                let images = this.filteredFiles.length;
                if (!images) {
                    result = "/img/no-image.jpg";
                }
                if (images && images === 1) {
                    result = this.filteredFiles[0].url;
                }
                return result;
            },
            dialogType () {
                return this.isManager || this.isAdmin ? "manager" : "main";
            },
            actionProjectDataText () {
                return this.isManager || this.isAdmin ? "Assign a Modeler" : "Accept Project";
            },
            getProjectOwner() {
                return !this.isDesigner ? this.project.user : this.project.user.split(" ")[0];
            },
            go() {
                if (this.isRenderProgress) {
                    return { name: "render-review", params: { id: this.project.id } };
                }
                if (this.isFinalAssets) {
                    return this.isDesigner ? { name: "project", params: { id: this.project.id } } : { name: "project-assets", params: { id: this.project.id } };
                }
                return { name: "project", params: { id: this.project.id } };
            }
        },
        methods: {
            closeDialog () {
                this.$emit("update-projects")
                this.reassignModelerDialog = false
            },
            getPaymentClass (payment_status) {
                switch (payment_status) {
                    case 0:
                        return "paid-await";
                    case 1:
                        return "paid-success";
                    case 2:
                        return "paid-error";
                }
            },
            getPaymentTitle (payment_status) {
                return this.$config.project.paymentStatusLabels[payment_status];
            },
            actionProjectData () {
                this.$emit("onOpenDialog", Object.assign(this.project, { dialogType: this.dialogType }));
            },
            setFilterType () {
                if (this.$store.getters.getProjectTypes) {
                    let typeIndex;
                    for (let i in this.$store.getters.getProjectTypes) {
                        if (this.$store.getters.getProjectTypes[i] === this.project.type) {
                            typeIndex = i;
                            break;
                        }
                    }
                    this.$emit("onFilter", typeIndex);
                }
            },
            showMore (status) {

            }
        }
    };
</script>

<style lang="scss" scoped>
    .pci-status {
        display: inline-block;
        vertical-align: top;
        white-space: nowrap;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        text-transform: uppercase;
        letter-spacing: .4px;
        padding: 4px 10px;
        border: 1px solid #464646;
        border-radius: 4px;
        color: #464646;
        &-menu {
            &__action {
                // margin-left: -5px;
                height: 22px;
                width: 22px;
                font-size: 13px;
            }
            &__item {
                min-height: auto;
                font-size: 13px;
                cursor: pointer;
                &.is-cancel {
                    .v-list-item__title {
                        color: #D00E0E !important;
                    }
                }
            }
        }
    }
    .pci-status-wrapper {
        display: flex;
        flex-wrap: nowrap;
        & .pci-status-menu__action {
        }
    }
    .project-card-item {
        display: flex;
        border: 1px solid #BCBCBC;
        border-radius: 5px;
        position: relative;
        text-decoration: none;
        &:hover{
          background: #f5fef6;
        }
      @media screen and (max-width: 767px) {
        flex-wrap: wrap;
      }
    }
    .pci-lc {
        flex: 0 0 220px;
        max-width: 220px;
        width: 100%;
        margin-right: 27px;
        @media screen and (max-width: 767px) {
            flex: 0 0 100%;
            max-width: 100%;
            margin-bottom: 15px;
        }
    }
    .pci-rc {
        position: relative;
        flex:1 1 0;
        overflow: hidden;
        @media screen and (max-width: 767px) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .pci-head-sect {
        display: flex;
        @media screen and (max-width: 575px) {
          flex-direction: column;
        }
    }
    .pci-hs-lc {
        max-width: 100%;
        width: 100%;
        padding-right: 15px;
        line-height: 24px;
        display: flex;
        align-items: center;
        flex: 1 1 auto;
        overflow: hidden;
        white-space: nowrap;
        @media screen and (max-width: 575px) {
            flex: 0 0 100%;
            padding-right: 0;
        }
    }
    .pci-hs-rc {
        @media screen and (max-width: 575px) {
            flex: 0 0 100%;
            order: -1;
            margin-bottom: 7px;
        }
    }
    .pci-ttl {
        margin-right: 15px;
        font-size: 24px;
        line-height: 1.2;
        color: #464646;
        text-decoration: none;
        transition: all 250ms ease;
        word-break: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        &:hover {
            opacity: 0.7;
        }
    }

    .pci-type {
        display: inline-block;
        background: #d9fbdd;
        border: 1px solid #464646;
        padding: 3px 10px;
        border-radius: 5px;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.2;
        text-transform: uppercase;
        color: #464646;
        cursor: pointer;
        transition: all 250ms ease;
        margin-right: 11px;
        &:hover {
            opacity: 0.7;
        }
    }

    .pci-date-sect {
        display: flex;
        background: #F4F4F4;
        border-radius: 5px;
      @media screen and (max-width: 575px) {
        flex-wrap: wrap;
      }
    }
    .pci-ds-col {
        line-height: 16px;
        width: 100%;
        padding: 4px 10px;
        .three-cols & {
            @media screen and (min-width: 576px) {
                &:nth-child(1) {
                    flex: 0 0 44%;
                    max-width: 44%;
                }
                &:nth-child(2) {
                    flex: 0 0 26%;
                    max-width: 26%;
                }
                &:nth-child(3) {
                    max-width: 30%;
                    margin-left: auto;
                    width: auto;
                    flex: 0 0 auto;
                }
            }
        }
        .four-cols & {
            @media screen and (min-width: 576px) {
                &:nth-child(1) {
                    flex: 0 0 39%;
                    max-width: 39%;
                }
                &:nth-child(2) {
                    flex: 0 0 21%;
                    max-width: 21%;
                }
                &:nth-child(3) {
                    flex: 0 0 20%;
                    max-width: 20%;
                }
                &:nth-child(4) {
                    max-width: 20%;
                    margin-left: auto;
                    width: auto;
                    flex: 0 0 auto;
                }
            }
        }

      &:last-child{

        @media screen and (min-width: 576px) {
          text-align: right;
        }
      }
        @media screen and (max-width: 575px) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .pci-ds-lbl {
        display: inline-block;
        vertical-align: top;
        font-size: 14px;
        line-height: 16px;
        color: #464646;
        text-transform: uppercase;
        margin-right: 5px;
    }
    .pci-ds-val {
        display: inline-block;
        vertical-align: top;
        font-size: 14px;
        line-height: 16px;
        color: #464646;
        font-weight: 700;
    }
    .pci-options-sect {
        display: flex;
        flex-wrap: wrap;
    }
    .pci-os-lc {
        padding: 17px 5px 0;
        flex-grow: 1;
        color: #464646;
        flex-basis: 0;
        max-width: 100%;
        width: 100%;
        @media screen and (max-width: 575px) {
            flex: 0 0 100%;
        }
    }
    .pci-os-rc {
        flex: 0 0 40%;
        max-width: 40%;
        width: 100%;
        padding: 15px 10px 0;
      @media screen and (max-width: 575px) {
            flex: 0 0 100%;
            max-width: 100%;
             padding: 15px 0;
        }
    }
    .pci-os-item {
        line-height: 16px;
        margin-bottom: 5px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .pci-os-lbl {
        font-size: 14px;
        color: #464646;
    }
    .pci-os-val {
        font-size: 14px;
        color: #464646;
        font-weight: 700;
    }
    .pci-img {
        height: 150px;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .pci-gal-wrap {
        position: relative;
        @media screen and (max-width: 767px) {
            max-width: 255px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .pci-gal {
      background: #ffffff;
        border: 1px solid #BCBCBC;
        border-radius: 5px;
    }

    .pci-swiper-wrap {
        position: relative;

    }
    .pci-swiper-btn {
        z-index: 1;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        width: 22px;
        height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: #FFFFFF;
        border: 1px solid #DCDCDC;
        cursor: pointer;
        transition: opacity 250ms ease;
        &:hover {
            opacity: 0.7;
        }
        &.pci-prev-btn {
            left: -10px;
          >svg {
            margin-left: -1px;
          }
        }
        &.pci-next-btn {
            right: -10px;
        }
       > svg {
         width: 9px;
       }
    }
    .pci-labels{
      position: absolute;
      top: -12px;
      left: -2px;
      z-index: 1;
      display: flex;
      .pci-label{
        font-size: 14px;
        line-height: 16px;
        color: #FFFFFF;
        padding: 4px 8px;
        text-align: center;
        font-weight: bold;
        border-radius: 2px;
        & + .pci-label{
          margin-left: 10px;
        }
      }
    }
    .pci-new-lbl {
        background: #3F7359;
    }
    .pci-paid-lbl {
        background: green;
    }
    .paid-await {
        background: rgb(255, 111, 0);
        // color: #79750D;
    }
    .paid-success {
        background: rgba(0, 146, 32, 1);
        // color: #006616;
    }
    .paid-error {
        background: rgba(208, 14, 14, 1);
        // color: #D00E0E;
    }

    @media screen and (min-width: 576px) {
        .three-cols .pci-ds-col:first-child {
            flex: 0 0 44%;
            max-width: 44%;
        }
    }
</style>

<style lang="scss">

.show-more-wrapper{
    overflow: hidden;
    width: auto;
    max-width: none;
    margin: 0;

    * {
        display: inline;
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        color: #888888;
        margin: 0;
        padding: 0 !important;
    }

    u {
        text-decoration: none;
    }

    font-size: 14px;
    line-height: 18px;
    color: #888888;
    padding: 0;

    &.text-truncated, &.text-original{
        display: inline-block;
    }

    button{
        margin-left: 7px;
        font-size: 14px;
        line-height: 16px;
        text-align: left;
        padding: 0;
        color: #3F7359;
        text-decoration: underline;
        cursor: pointer;
        transition: all 250ms ease;
        &:hover {
            opacity: 0.7;
        }
    }
}
    .pci-os-desc-show-more {
        overflow: hidden;
        width: auto;
        max-width: none;
        margin: 0;
        padding: 0 !important;
        .default, .expanded {
            u {
                text-decoration: none;
            }
            h1 {
                font-size: 16px;
                line-height: 18px;
                color: #888888;
                display: inline;
            }
            h2 {
                font-size: 15px;
                line-height: 18px;
                color: #888888;
                display: inline;
            }
            h3 {
                font-size: 14px;
                line-height: 18px;
                color: #888888;
                display: inline;
            }
            font-size: 14px;
            line-height: 18px;
            color: #888888;
        }
        .anchor {
            display: block;
            float: right;
            font-size: 14px;
            line-height: 16px;
            text-align: left;
            padding: 0;
            margin: 5px 0 0;
            color: #3F7359;
            text-decoration: underline;
            cursor: pointer;
            transition: all 250ms ease;
            &:hover {
                opacity: 0.7;
            }
        }
    }
    .pci-pagination {
        line-height: 9px;
        bottom: 15px !important;
        .swiper-pagination-bullet {
            vertical-align: top;
            width: 9px;
            height: 9px;
            border: 1px solid #656565;
            background: #FFFFFF;
            opacity: 1;
            &.swiper-pagination-bullet-active {
                background: #656565;
            }
        }
    }

    .v-btn.btn-assign-modeler{
      .v-btn__content{
        font-size: 14px;
      }
    }
</style>
