<template>
    <v-card class="pa-0 py-12 px-15">
        <div class="d-flex justify-space-between align-center">
            <v-card-title class="px-0 assign-modeler-title">
                Assign new modeler?
            </v-card-title>
            <v-card-subtitle class="pa-0 ma-0">
                <span class="font-weight-bold current-modeler">
                    Current Modeler:
                </span>
                {{ fullName }}
            </v-card-subtitle>
        </div>

        <v-card-text
            class="px-0 pb-9 grey-lightend body-1"
            style="font-size: 14px; line-height: 18px; color: #4e4e4e">
            Assigning a modeler will notify the modeler. The current modeler
            will be notified that they have been moved off the project.
        </v-card-text>

        <v-row class="d-flex align-center">
            <v-col cols="4">
                <div
                    class="grey-lightend font-weight-bold"
                    style="font-size: 14px; line-height: 18px; color: #4e4e4e">
                    Assign Modeler:
                </div>
            </v-col>
            <v-col cols="8">
                <v-select
                    v-model="modeler"
                    :items="modelers"
                    :menu-props="{ button: true, offsetY: true }"
                    dense
                    hide-details
                    item-value="id"
                    outlined>
                    <template v-slot:selection="{ item }">
                        {{ item.first_name }} {{ item.last_name }}
                    </template>
                    <template v-slot:item="{ item }">
                        {{ item.first_name }} {{ item.last_name }}
                    </template>
                </v-select>
            </v-col>
            <v-col></v-col>
        </v-row>

        <div v-if="onGoingProject">
            <div class="d-flex align-center justify-space-between mt-8">
                <div>
                    <div class="text-h6 font-weight-regular">
                        {{ onGoingProject.title }}
                    </div>
                    <v-chip
                        :class="{
                            'in-progress':
                                onGoingProject.status === 'In Progress',
                            'input-needed':
                                onGoingProject.status === 'Input Needed',
                            'pending-approval':
                                onGoingProject.status === 'Pending Approval',
                            'open-for-work':
                                onGoingProject.status === 'Open For Work',
                            'ready-for-review':
                                onGoingProject.status === 'Ready For Review',
                        }"
                        :style="chipStyles"
                        class="pf-chip mr-4 mb-4">
                        {{ onGoingProject.status }}
                    </v-chip>
                </div>
                <div class="pci-ad-price">
                    ${{ onGoingProject.overall_designer_amount }}
                </div>
            </div>

            <div class="pci-ad-options mt-6">
                <div class="pci-ad-opt-item">
                    Due date: TODO
                    <!-- {{ this.$moment().add(onGoingProject.due_date, "days").format("D/M/YY") }} ({{ onGoingProject.due_date }} Days) -->
                </div>
                <div class="pci-ad-opt-item mt-4">
                    Created:
                    <span class="pci-ad-oi-val">
                        {{
                            this.$moment(onGoingProject.created_at).format(
                                "MM/DD/YY"
                            )
                        }}
                        ({{ onGoingProject.user }})
                    </span>
                </div>
                <div class="pci-ad-opt-item mt-4">
                    Project type:
                    <span class="pci-ad-oi-val">{{ onGoingProject.type }}</span>
                </div>
                <div class="pci-ad-opt-item mt-4">
                    Project id:
                    <span class="pci-ad-oi-val">{{ onGoingProject.id }}</span>
                </div>
            </div>

            <div>
                <template
                    v-if="
                        onGoingProject.metals ||
                            onGoingProject.stones ||
                            onGoingProject.description
                    ">
                    <p class="mt-7 cursor-pointer green-link" @click="showInfo">
                        Show more information
                        <v-icon
                            v-if="!isInfoVisible"
                            color="#3F7359"
                            style="text-decoration: none">
                            mdi-chevron-right
                        </v-icon>
                        <v-icon
                            v-if="isInfoVisible"
                            color="#3F7359"
                            style="text-decoration: none">
                            mdi-chevron-down
                        </v-icon>
                    </p>

                    <template v-if="isInfoVisible">
                        <v-divider
                            v-if="
                                onGoingProject.metals || onGoingProject.stones
                            "/>
                        <p
                            v-if="onGoingProject.metals"
                            class="bright-grey mt-4">
                            <span>Metal Types:</span>
                            <span class="font-weight-bold">
                                {{ onGoingProject.metals.join(", ") }}
                            </span>
                        </p>
                        <p
                            v-if="onGoingProject.stones"
                            class="bright-grey mb-4">
                            <span>Stone Types:</span>
                            <span class="font-weight-bold">
                                {{ onGoingProject.stones.join(", ") }}
                            </span>
                        </p>
                        <v-divider/>
                        <p
                            v-if="onGoingProject.description"
                            class="bright-grey mt-4">
                            DESCRIPTION: {{ onGoingProject.description }}
                        </p>
                    </template>
                </template>

                <div class="d-flex mt-7 justify-space-between align-center">
                    <p
                        v-if="$route.path !== `/project/${onGoingProjectId}`"
                        class="pa-0 ma-0 cursor-pointer green-link"
                        @click="openProject">
                        View Full Project
                    </p>
                    <v-btn
                        :loading="processing"
                        :disabled="processing || !modeler"
                        :class="{ disabledBtn: processing || !modeler }"
                        class="green-btn ml-auto"
                        large
                        min-width="200"
                        outlined
                        rounded
                        @click="reassignProject">
                        Reassign Project
                    </v-btn>
                </div>
            </div>
        </div>
    </v-card>
</template>

<script>
    import http from "@/api/httpClient.api";

    export default {
        name: "add-new-modeler-dialog",
        props: {
            onGoingProjectId: {
                type: Number,
                required: true
            },
            fullName: {
                type: String,
                required: true
            },
            currentModelerID: {
                type: Number,
                required: true
            }
        },
        data: () => ({
            onGoingProject: null,
            processing: false,
            modeler: "",
            chipStyles: {
                borderRadius: "4px",
                height: "21px"
            },
            isInfoVisible: false
        }),
        methods: {
            fetchOngoingProject(id) {
                http.get(`/projects/${id}`).then(({ data }) => {
                    this.onGoingProject = data.data;
                    console.log(data.data, "test");
                });
            },
            reassignProject() {
                if (this.modeler) {
                    this.processing = true;

                    const MODELER_ID = this.modeler;
                    const PROJECT_ID = this.onGoingProject.id;
                    const ENDPOINT = `/manager/modelers/${MODELER_ID}/projects/${PROJECT_ID}/assign`;

                    http.put(ENDPOINT)
                        .then(() => {
                            this.$store.dispatch("manager/getModelerList");
                            this.$emit("closeDialog");
                            this.processing = false;
                        })
                        .catch((e) => {
                            console.log(e);
                            this.processing = false;
                        });
                }
            },
            showInfo() {
                this.isInfoVisible = !this.isInfoVisible;
            },
            openProject() {
                this.$router.push(`/project/${this.onGoingProjectId}`);
            }
        },
        computed: {
            modelers() {
                return this.$store.state.manager.modelerList.filter(
                    (modeler) => modeler.id !== this.currentModelerID
                );
            }
        },
        watch: {
            onGoingProjectId(val) {
                if (this.onGoingProjectId !== 0) {
                    this.fetchOngoingProject(val);
                }
            }
        },
        created() {
            if (!this.modelers.length) {
                this.$store.dispatch("manager/getModelerList");
            }
            this.fetchOngoingProject(this.onGoingProjectId);
        }
    };
</script>

<style lang="scss" scoped>
// .bright-blue {
//   color: #0046AF;
// }
// .bright-green {
//   background-color: #02810F !important;
// }
.reassign-modeler__chip {
    height: 20px !important;
}

.assign-modeler-title,
.current-modeler {
    color: #464646;
}

.green-link {
    color: #3f7359;
    background-color: transparent;
}

.green-btn {
    height: 47px;
    background-color: #9ff4a9;
    border: 1px solid #464646;
    box-sizing: border-box;
    border-radius: 50px;
    font-size: 14px;
    line-height: 18px;
    color: #464646 !important;
}
</style>
