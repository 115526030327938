<template>
    <div>
        <v-dialog
            v-bind="$attrs"
            v-on="$listeners"
            :persistent="loading"
            :max-width="480"
            content-class="client-extra-charge-popup">
            <div class="dialog-overflow-wrap">
                <v-btn
                    class="pci-ad-cl-btn"
                    icon>
                    <v-img
                        :src="require('@/assets/icons/ic-close-modal.svg')"
                        contain
                        height="36"
                        max-width="36"
                        @click="$emit('input', false)"/>
                </v-btn>

                <div class="white pa-8 main-text--text">
                    <h4 class="mb-5 text-h5 font-weight-semibold main-text--text">Extra charges</h4>

                    <h6 class="mb-1 font-weight-semibold">Reason</h6>
                    <strong class="d-block font-weight-semibold">{{ $config.extraChargeReasons[getProjectData.extra_charge.reason] }}</strong>

                    <p v-if="getProjectData.extra_charge.reason_details" class="mb-0">
                        {{ getProjectData.extra_charge.reason_details }}
                    </p>

                    <div class="amount-row d-flex justify-space-between my-5 py-3">
                        Amount to be charged
                        <span class="green-100--text">${{ getProjectData.extra_charge.amount }}</span>
                    </div>

                    <v-radio-group v-model="userDecision" hide-details>
                        <simple-radio-checkbox
                            :value="userDecisionOptions.acceptAndPay"
                            label="Pay extra charge & accept changes"/>
                        <simple-radio-checkbox
                            v-if="!getProjectData.extra_charge.without_skipping"
                            :value="userDecisionOptions.proceedWithoutChanges"
                            label="Proceed without changes"/>
                        <simple-radio-checkbox
                            :value="userDecisionOptions.cancelProject"
                            label="Cancel whole project"/>
                    </v-radio-group>

                    <div v-if="userDecision === userDecisionOptions.cancelProject" class="d-flex align-center mt-6">
                        <img :src="require('@/assets/icons/ic-info.svg')" alt="">
                        <span class="ml-2">You will receive ${{ getProjectData.extra_charge.refund_amount }} refund if you cancel.</span>
                    </div>

                    <div v-if="userDecision === userDecisionOptions.cancelProject && false" class="d-flex align-start mt-6">
                        <img :src="require('@/assets/icons/ic-info-red.svg')" alt="" style="margin-top: 2px;">
                        <span class="delete-warning ml-2 red-100--text">
                            You will cancel this project without a refund. <br> This action is undoable
                        </span>
                    </div>


                    <div class="d-flex justify-end mt-6">
                        <v-btn
                            width="180"
                            height="38"
                            outlined
                            rounded
                            :loading="loading"
                            color="main-text"
                            class="bg-primary"
                            @click="buttonHandler">
                            <template v-if="userDecision === userDecisionOptions.acceptAndPay">Pay ${{ getProjectData.extra_charge.amount }}</template>
                            <template v-else-if="userDecision === userDecisionOptions.proceedWithoutChanges">Continue</template>
                            <template v-else>
                                {{ true ? 'Cancel & Receive refund' : 'Cancel project' }}
                            </template>
                        </v-btn>
                    </div>
                </div>
            </div>
        </v-dialog>

        <dialog-payment
            v-model="paymentDialog"
            eager
            :autocloseable="false"
            type="extraCharge"
            :amount="+getProjectData.extra_charge.amount"/>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import SimpleRadioCheckbox from "@/components/ui/SimpleRadioCheckbox";
    import DialogPayment from "@/components/ui/DialogPayment";
    import { projectApi } from "@/api";

    export default {
        name: "client-extra-charge-dialog",
        components: {
            SimpleRadioCheckbox,
            DialogPayment
        },
        data() {
            return {
                loading: false,
                userDecisionOptions: {
                    acceptAndPay: 1,
                    proceedWithoutChanges: 2,
                    cancelProject: 3
                },
                userDecision: 1,
                paymentDialog: false
            }
        },
        computed: {
            ...mapGetters([
                "getProjectData",
                "getValueProjectStatus"
            ])
        },
        methods: {
            buttonHandler() {
                if (this.userDecision === this.userDecisionOptions.acceptAndPay) {
                    this.acceptAndPay();
                } else if (this.userDecision === this.userDecisionOptions.proceedWithoutChanges) {
                    this.proceedWithoutChanges();
                } else {
                    this.cancelProject();
                }
            },
            async acceptAndPay() {
                this.$emit("input", false);
                this.paymentDialog = true;
            },
            async proceedWithoutChanges() {
                this.loading = true;
                try {
                    await projectApi.skipExtraCharge(this.getProjectData.id);
                    await this.$store.dispatch("updateProjectData", {
                        extra_charge: {
                            ...this.getProjectData.extra_charge,
                            status: this.$config.extraChargeStatuses.skipped
                        }
                    });

                    this.loading = false;
                    this.$emit("input", false);
                } catch (error) {
                    console.error(error);
                }
            },
            async cancelProject() {
                this.loading = true;
                try {
                    await projectApi.cancelExtraChargeProject(this.getProjectData.id);
                    this.$emit("input", false);
                    await this.$router.push({ name: "dashboard" });

                } catch (error) {
                    console.error(error)
                }
            }
        }
    }
</script>

<style scoped lang="scss">
::v-deep .client-extra-charge-popup {
    font-size: 14px;
    line-height: 18px;

    h6 {
        font-size: 18px;
        line-height: 20px;
    }

    .amount-row {
        border-top: 1px solid #E8E8E8;
        border-bottom: 1px solid #E8E8E8;
        span {
            font-size: 18px;
            line-height: 20px;
        }
    }

    .delete-warning {
        font-size: 18px;
        line-height: 23px;
    }
}
</style>
