<template>
    <div>
        <v-app-bar
            app
            :class="['unselect app-header', {'is-warning': fallbackTokenData && $vuetify.breakpoint.xsOnly}]"
            :height="fallbackTokenData && $vuetify.breakpoint.xsOnly ? '90' : undefined"
            color="white">
            <v-app-bar-nav-icon
                v-show="$vuetify.breakpoint.smAndDown"
                color="gray"
                @click.stop="$store.dispatch('toggleSidebarMenu')"/>
            <div class="d-flex align-center ml-2 mr-4 ml-md-16 mr-md-8">
                <router-link :to="{ name: 'dashboard' }">
                    <v-img
                        :src="appLogo"
                        alt="Unco Logo"
                        class="shrink mr-2"
                        contain
                        transition="scale-transition"
                        width="100"/>
                </router-link>
            </div>

            <div
                v-if="isManagerOrAdmin && $vuetify.breakpoint.smAndUp"
                class="d-flex ml-15">
                <router-link
                    v-for="(route, i) in routes"
                    :key="i"
                    :to="route.path"
                    class="mr-12 manager-link"
                    style="cursor: pointer">
                    <img src="@/assets/_redesigne/icons/ic-sun.svg" v-if="$route.path === route.path">
                    {{ route.title }}
                </router-link>
            </div>

            <div v-if="isAdmin && $vuetify.breakpoint.smAndUp">
                <v-menu
                    open-on-hover
                    bottom
                    right
                    offset-y
                    content-class="app-header-sub-menu">
                    <template v-slot:activator="{ on, attrs }">
                        <div
                            v-on="on"
                            v-bind="attrs"
                            class="mr-12 manager-link"
                            style="cursor: pointer">
                            Management
                        </div>
                    </template>

                    <v-list>
                        <v-list-item
                            v-for="(link, idx) in managementLinks"
                            :key="idx"
                            :to="link.path"
                            class="manager-link">
                            <v-list-item-title class="manager-link">{{ link.title }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>

            <v-tooltip
                v-if="fallbackTokenData && $vuetify.breakpoint.xsOnly"
                bottom
                nudge-top="6"
                max-width="290"
                min-width="290"
                content-class="header-account-warning-tooltip">
                <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on" class="warning-tooltip-text d-flex align-center justify-center mx-auto">
                        <div class="text-uppercase">Attention!</div>
                        <span class="tooltip-question-mark d-inline-block ml-1 text-center">?</span>
                    </div>
                </template>
                <login-as-user-indicator v-if="fallbackTokenData"/>
            </v-tooltip>

            <login-as-user-indicator v-if="fallbackTokenData && $vuetify.breakpoint.smAndUp" class="mx-auto"/>

            <v-spacer v-if="!fallbackTokenData || fallbackTokenData && $vuetify.breakpoint.xsOnly"/>

            <v-btn v-if="isManagerOrAdmin && ($route.path.includes('project') && $route.name !== 'project-start')"
                   v-show="$route.name !== 'project-changelog'"
                   class="new-project-btn px-4 mr-4"
                   color="#9FF4A9"
                   depressed
                   rounded
                   height="30"
                   small
                   :to="{name: 'project-changelog', params: {id: $route.params.id}}">
                Changelog
            </v-btn>

            <v-btn v-if="isAdmin && ($route.path.includes('project') && $route.name !== 'project-start') "
                   class="new-project-btn px-4 mr-4"
                   color="#9FF4A9"
                   depressed
                   rounded
                   height="30"
                   small
                   @click="archiveDialog = true">
                Archive
            </v-btn>


            <v-btn v-if="isManagerOrAdmin && ['project', 'project-assets', 'render-review', 'project-changelog'].includes($route.name)"
                   class="new-project-btn px-4 mr-4"
                   color="#9FF4A9"
                   depressed
                   rounded
                   height="30"
                   :to="{name : 'ManagerEditProject', params: {id: $route.params.id}}"
                   small>
                Edit
            </v-btn>

            <v-btn
                v-if="!isDesigner && isClient || (isManagerOrAdmin && $route.name === 'dashboard')"
                class="new-project-btn pl-4 pr-4 mr-sm-4"
                color="#9FF4A9"
                depressed
                rounded
                height="30"
                width="137"
                small
                @click="createNewProject">
                New Project
            </v-btn>

            <v-btn
                icon
                outlined
                width="30"
                height="30"
                small
                link
                color="#464646"
                class="support-btn mr-4"
                :to="{name: 'support'}">
                <img src="@/assets/_redesigne/icons/ic-question-mark.svg">
            </v-btn>

            <notifications-dropdown btn-class="mr-4"/>

            <user-dropdown
                v-show="!$vuetify.breakpoint.smAndDown" btn-class="header-user-menu mr-2 mr-md-16"/>
        </v-app-bar>
        <dialog-confirm-archive-project v-model="archiveDialog" :project-id="$route.params.id"/>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import appLogo from "@/assets/_redesigne/title-logo.svg";
    import LoginAsUserIndicator from "@/components/ui/LoginAsUserIndicator";
    import NotificationsDropdown from "@/components/Dropdowns/NotificationsDropdown";
    import UserDropdown from "@/components/Dropdowns/UserDropdown";
    import DialogConfirmArchiveProject from "@/components/ui/DialogConfirmArchiveProject";

    export default {
        name: "the-header",
        components: {
            DialogConfirmArchiveProject,
            LoginAsUserIndicator,
            NotificationsDropdown,
            UserDropdown
        },
        data: () => ({
            appLogo,
            items: [
                { title: "Dashboard", href: { name: "dashboard" }, icon: "mdi-home-outline" },
                { title: "Your Projects", href: "/inbox", icon: "mdi-mailbox" }
            ],
            routes: [
                { title: "Projects", path: "/dashboard" },
                { title: "Modelers", path: "/modelers" },
                { title: "Clients", path: "/clients" }
            ],
            managementLinks: [
                { title: "Price Config", path: "/price-config" },
                { title: "Configurator Demos", path: "/configurator-demos" },
                { title: "BLOG CMS", path: "/blog-cms" }
            ],
            archiveDialog: false
        }),
        methods: {
            createNewProject () {
                this.$store.dispatch("resetProject");
                this.$store.dispatch("updateStep", 1);
                this.$router.push({ name: "project-start" }).catch(() => {
                });
            }
        },
        computed: {
            ...mapGetters([
                "isAdmin",
                "isClient",
                "isDesigner",
                "fallbackTokenData",
                "isManagerOrAdmin"
            ])
        }
    };
</script>

<style lang="scss">
    .app-header {
        box-shadow: none !important;

        .v-toolbar__content {
            border-bottom: 1px solid #464646;
        }
    }

    .manager-link {
        display: flex;
        align-items: center;
        font-family: "Futura PT", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px!important;
        color: #707070 !important;
        text-decoration: none;
        text-transform: uppercase;

        img {
            margin-right: 2px;
        }

        &.router-link-active,
        &.v-list-item--active {
            text-decoration: underline;
        }

        &.v-list-item {
            min-height: 34px;
        }

        &:not(.v-list-item__title, .v-list-item) {
            padding: 12px 0;
        }
    }

    .app-header-sub-menu.v-menu__content {
        border-radius: 5px!important;
        border: 1px solid #BCBCBC;
        box-shadow: 4px 4px 4px rgba(70, 70, 70, 0.15);
    }

    .separation-line {
        width: 100%;
        height: 1px;
        background-color:#E7E7E7;
        margin: 2px 0;
    }

    a, button {
        .v-btn__content {
            font-family: "Futura PT", sans-serif !important;
            font-style: normal !important;
            font-weight: normal !important;
            text-transform: none !important;
            letter-spacing: 0px;
        }
    }
    a.v-size--small, button.v-size--small {
        .v-btn__content {
            font-size: 12px;
            line-height: 200%;
        }
    }
    a.v-size--default, button.v-size--default {
        .v-btn__content {
            font-size: 14px;
            line-height: 18px;
        }
    }

    .v-menu__content {
        border-radius: 6px !important;
    }

    .user-menu-list {
        padding: 25px 18px 18px 24px;
        background: #FEFEFE;
        border: 0.8px solid #D9D9D9 !important;
        box-sizing: border-box;
        box-shadow: 4px 4px 4px rgba(70, 70, 70, 0.15) !important;
        border-radius: 6px !important;
        width: 220px;

        .user-name {
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            color: #464646;
            margin-bottom: 11px;
        }

        .v-list-item {
            padding: 0;
            min-height: 32px;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #464646;

            a {
                text-decoration: none;
                transition: opacity 250ms ease;
                &:hover {
                    opacity: 0.7;
                }
            }

            .icon {
                width: 16px;
                height: 16px;
                margin-right: 8px;
            }
        }

        .logout-btn {
            margin-right: 8px;
            display: flex !important;
            align-items: flex-start;
            margin-top: 12px;

            div {
                margin-left: auto;
                margin-right: 8px;
            }
            img {
                width: 18px;
                height: 18px;
            }
        }
    }

.new-project-btn {
    font-size: 14px;
    line-height: 18px;
    color: #464646;
    font-weight: 400;
    border: 1px solid #464646 !important;
    box-shadow: none;
}

    .warning-tooltip-text {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 4px 0;
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        border-top: 1px solid #464646;
        background-color: #D00E0E;
        color: #fff;

        .tooltip-question-mark {
            background-color: #fff;
            color: #D00E0E;
        }
    }

    .header-account-warning-tooltip.v-tooltip__content {
        padding: 0;
        pointer-events: auto;
    }


    @media screen and (max-width: 768px) {
        .app-header.is-warning {
            .v-toolbar__content {
                padding-bottom: 30px;
                border-bottom: none;
            }
        }

        .support-btn,
        .header-user-menu {
            display: none;
        }
    }
</style>
