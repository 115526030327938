<template>
    <div v-if="$vuetify.breakpoint.smAndDown">
        <v-navigation-drawer
            v-model="$store.state.sidebar.menu"
            :dark="$store.getters.isDark"
            :temporary="$vuetify.breakpoint.mdAndDown"
            app
            class="light"
            floating
            max-width="260"
            mobile-breakpoint="500">
            <v-list
                v-show="$vuetify.breakpoint.smAndDown"
                class="v-navigation-drawer__header pt-0"
                dense
                nav>
                <v-list-item class="v-list--darker">
                    <v-list-item-content class=" pt-3">
                        <div class="d-flex align-center ml-8 mr-4 ml-md-16 mr-md-8">
                            <router-link to="/">
                                <v-img
                                    :src="appLogo"
                                    alt="Unco Logo"
                                    class="shrink mr-2"
                                    contain
                                    width="64"/>
                            </router-link>
                        </div>
                    </v-list-item-content>

                    <v-app-bar-nav-icon
                        style="position: absolute; right:0; 'z-index': 10002"
                        @click.stop="$store.dispatch('toggleSidebarMenu')"/>
                </v-list-item>
            </v-list>

            <div class="v-navigation-drawer__content">
                <v-divider/>

                <v-list-item-group v-model="group">
                    <v-list-item
                        v-for="link in menuLinks"
                        :key="link.title"
                        :to="{name: link.routeName}"
                        link>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ link.title }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <template v-if="isManager || isAdmin">
                        <v-list-item
                            v-for="link in managementLinks"
                            :key="link.title"
                            :to="{name: link.routeName}"
                            link>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ link.title }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>

                    <template v-if="isAdmin">
                        <v-list-item
                            v-for="link in adminLinks"
                            :key="link.title"
                            :to="{name: link.routeName}"
                            link>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ link.title }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-list-item-group>

                <div class="d-flex align-center justify-center mt-4">
                    <!-- Copy-pasted from TheHeader.vue -->
                    <v-btn
                        fab
                        width="30"
                        height="30"
                        outlined
                        link
                        :to="{name: 'support'}">
                        <img src="@/assets/_redesigne/icons/ic-question-mark.svg">
                    </v-btn>
                    <notifications-dropdown btn-class="mx-4"/>
                    <user-dropdown/>
                </div>
            </div>
        </v-navigation-drawer>
    </div>
</template>

<script>
    import appLogo from "@/assets/_redesigne/title-logo.svg";
    import { mapGetters } from "vuex";
    import NotificationsDropdown from "@/components/Dropdowns/NotificationsDropdown";
    import UserDropdown from "@/components/Dropdowns/UserDropdown";

    export default {
        name: "the-side-bar",
        components: { NotificationsDropdown, UserDropdown },

        data: () => ({
            appLogo,
            group: null,
            sidebarMenu: true,
            hover: false,
            //toggleMini: false,
            menuLinks: [
                { title: "Dashboard", routeName: "dashboard" }
            ],
            managementLinks: [
                { title: "Modelers", routeName: "modelers" },
                { title: "Clients", routeName: "clients" }
            ],
            adminLinks: [
                { title: "Price Config", routeName: "priceConfig" }
            ]
        }),
        created () {
            const commit = this.$store.commit;

            function breaker (point) {
                switch (point) {
                    case "xs":
                        commit("SET_MENU_STATUS", false);
                        break;
                    case "sm":
                        commit("SET_MENU_STATUS", false);
                        break;
                    case "md":
                        commit("SET_MENU_STATUS", false);
                        break;
                    case "lg":
                        commit("SET_MENU_STATUS", true);
                        break;
                    case "xl":
                        commit("SET_MENU_STATUS", true);
                        break;
                }
            }

            breaker(this.$vuetify.breakpoint.name);
            this.$watch(() => {
                return this.$vuetify.breakpoint.name;
            }, breaker);
        },
        computed: {
            ...mapGetters([
                "isManager",
                "isAdmin"
            ])
        }
    };
</script>

<style lang="scss">
    .v-navigation-drawer {
        .v-list-item__title {
            align-self: center;
            font-size: 0.875rem;
        }

        .v-list-item--active.v-list-item--link:before {
            background-color: #00ff20;
        }
    }

    // Fix scroll only for content
    .v-navigation-drawer > .v-navigation-drawer__content {
        overflow: initial !important;
    }

    .v-navigation-drawer {
        overflow: initial !important;
        //fix click on the arrow
        &__border {
            display: none;
        }
    }
</style>
