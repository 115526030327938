import httpClientApi from "@/api/httpClient.api";

const END_POINT = "/authorizations";

const apiSetUserToken = async userData => {
    const url = ""; //process.env.VUE_APP_EMBEDDED == 1 ? '/internal':
    try {
        const { data } = await httpClientApi.post(END_POINT + url, userData, {
            skipAuth: true
        });
        return data;
    } catch (error) {
        console.error(`Set user jwt token failed with error: ${error.message}`);
        return error.response;
    }
};

const apiRefreshToken = async () => {
    const tokenName = "jwt_token";
    const token = JSON.parse(localStorage.getItem(tokenName)).token;
    try {
        const { data } = await httpClientApi.put(`${END_POINT}/current`, null, {
            headers: {
                "Authorization": `Bearer ${token}`
            },
            skipAuth: true
        });
        return data;
    } catch (error) {
        console.error(`Refresh user jwt token failed with error: ${error.message}`);
        return error.response;
    }
};

const apiDeleteCurrentToken = async () => {
    const tokenName = "jwt_token";
    const token = JSON.parse(localStorage.getItem(tokenName)).token;
    try {

        await httpClientApi.delete(`${END_POINT}/current`, {
            headers: {
                "Authorization": `Bearer ${token}`
            },
            skipAuth: true
        });
    } catch (error) {
        console.error(`Deleting current jwt token failed with error: ${error.message}`);
    }
};

const recoverEmailRequest = async email => {
    try {
        const { data } = await httpClientApi.post(`${END_POINT}/recover-request`, email, {
            skipAuth: true
        });
        return data;
    } catch (error) {
        console.error(`Recover password request failed with error: ${error.message}`);
        return error.response;
    }
};

const recoverPasswordRequest = async passwordsData => {
    try {
        const { data } = await httpClientApi.post(`${END_POINT}/recover-set`, passwordsData, {
            skipAuth: true
        });
        return data;
    } catch (error) {
        console.error(`Recover password request failed with error: ${error.message}`);
        return error.response;
    }
};

const apiRegisterClient = async userData => {
    try {
        const { data } = await httpClientApi.post(`${END_POINT}/sign-up`, userData, {
            skipAuth: true
        });
        return data;
    } catch (error) {
        console.error(`Register Client error: ${error.message}`);
        return error.response;
    }
};

const apiRegisterWithGoogle = async () => {
    try {
        const { data } = await httpClientApi.post(`${END_POINT}/google`, null, {
            skipAuth: true
        });
        return data;
    } catch (error) {
        console.error(`Register Client With Google error: ${error.message}`);
        return error.response;
    }
};

export {
    apiSetUserToken,
    apiRefreshToken,
    apiDeleteCurrentToken,
    apiRegisterClient,
    apiRegisterWithGoogle,
    recoverEmailRequest,
    recoverPasswordRequest
};
