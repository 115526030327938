import axios from "axios";
import http, { getData, post } from "@/api/httpClient.api";
import { transformFiles } from "@/api/uploadFilesService.api";
import camelcaseKey from "camelcase-keys";

const getSilentOpusProduct = async sku => {
    return await axios.get(`./fine-configurator/${sku}.json`);
};

const errors = {
    "0": "no errors",
    "1": "Params error",
    "2": "Incorrect token",
    "3": "Incorrect authkey",
    "4": "Incorrect data",
    "5": "Can not logout",
    "6": "Incorrect Role",
    "7": "Failed to save data. Try later",
    "8": "Incorrect Email format",
    "9": "Incorrect Password format",
    "10": "Phone is required",
    "11": "Email is required",
    "12": "First Name is required",
    "13": "Last Name is required",
    "14": "Country is required",
    "15": "City is required",
    "16": "Password is required",
    "17": "Role required",
    "20": "User is waiting to approve",
    "21": "User is disable",
    "22": "User not deleted",
    "23": "User not found",
    "24": "This email address has been registered",
    "25": "User email is not verified"
};
export const userApi = {
    getProfile: async () => {
        const data = await getData({ path: "/users/profile" });
        return camelcaseKey(data?.data);
    },
    deleteProfile: async () => {
        return await http.delete(`/users/profile`);
    },
    contactUs: async data => {
        return await http.post(`/contact-form`, data, {
            skipAuth: true
        });
    },
    loginAsUser: async data => {
        return await http.post(`/authorizations/login-as`, data);
    },
    getNotifications: async params => {
        return await http.get(`/users/notification`, { params });
    },
    readNotifications: async (uuid = "") => {
        return await http.put(`users/notification${uuid ? "/" + uuid : ""}`);
    },
    getOrderHistory: async params => {
        return http.get("/users/profile/history", { params });
    },
    getReceipts: async params => {
        return http.get("/users/profile/receipts", { params });
    },
    downloadReceipts: (receipts_ids = []) => {
        return http.get(`/users/profile/receipts/download`, { params: { receipts: receipts_ids }, responseType:"arraybuffer" })
    },
    getNotificationSetting: async () =>{
        return http.get("/users/notification-setting")
    },
    updateNotificationSetting: async (activeCategory) =>{
        return http.patch("/users/notification-setting", {
            categories: activeCategory
        })
    },
    acceptAgreement: async () => {
        return http.patch("/users/profile/nda-agreement/accept")
    }
};

export const projectApi = {
    create: async (data) => {
        const { status = 0 } = data;
        return post({ path: "/projects", formData: { ...data, status } });
    },
    edit: async (id, forData) => {
        return http.patch(`/projects/${id}`, forData);
    },
    editSpecificField: async (id, field) => {
        return http.patch(`/projects/${id}/fields`, field);
    },
    getFiles: async (id) => {

        const data = await getData({ path: `/projects/${id}/files` });

        return transformFiles(data?.data);
    },
    get: async (id) => {
        const data = await getData({ path: `/projects/${id}` });
        return data?.data;
    },
    getProjectTypes: async (projectType) => { // This is completely unacceptable. In the future, we should definitely refactor this shit
        const data = await getData({ path: `/projects/types` });
        if (projectType) {
            const map = {
                "Bracelet": 2,
                "Earrings": 4,
                "Cuffs": 5,
                "Ring": 3,
                "Necklace": 1,
                "Band": 6
            };
            const type = map[projectType] || projectType;
            const array = data?.data || data;
            return array.find((item) => (item.id === type || item.title === projectType)) || {};
        } else {
            return data?.data || data;
        }
    },
    bindFile (id, files) {
        return http.patch(`/projects/${id}/files-bind`, files).then(res => {
            if (res.status === 200) {
				return true
            }
        }).catch(err => {
			return false
        });
    },
    bindFileToRound: async(project_id, data) => {
        return await http.patch(`/projects/${project_id}/files-bind-by-round`, data);
    },
    submitToClient: async (project_id, data) => {
        return await http.put(`/projects/${project_id}/review`, data);
    },
    requestModification: async (project_id, data) => {
        return await http.put(`/projects/${project_id}/request-modification`, data);
    },
    requestRendersModification: async (project_id, data) => {
        return await http.put(`/projects/${project_id}/request-renders-modification`, data);
    },
    approveDesign: async (project_id, data) => {
        return await http.put(`/projects/${project_id}/approve-design`, data);
    },
    approveRenders: async (project_id, data) => {
        return await http.patch(`/projects/${project_id}/approve-renders`, data);
    },
    getAssets: (project_id) => {
        return http.get(`/projects/${project_id}/assets`)
    },
    getAssetsReference: (project_id) => {
        return http.get(`/projects/${project_id}/assets/reference`)
    },
    getAssetsComments: (project_id) => {
        return http.get(`/projects/${project_id}/assets/comments`)
    },
    deleteAssetsRender: async (project_id, file_ids= []) => {
        return await http.delete(`/projects/${project_id}/assets`, { params: { files: file_ids } })
    },
    completeProject: async (project_id) => {
        return await http.put(`/projects/${project_id}/complete`)
    },
    projectToFinalAssetsPrep: async (project_id) => {
        return await http.patch(`/projects/${project_id}/final-assets-prep`)
    },
    downloadFinalAssetsRender: (project_id, file_ids = []) => {
        return http.get(`projects/${project_id}/assets/download/renders`, { params: { files: file_ids }, responseType:"arraybuffer" })
    },
    downloadApproved3dModel: async (project_id) => {
        return http.get(`/projects/${project_id}/assets/download/models/approved`, { responseType:"arraybuffer" })
    },
    submitSpec: async (data) => {
        return await http.put(`/projects/${data.id}/render-specs`, data);
    },
    // projectToRenderSpecsDraft: async project_id => {
    //     return await http.patch(`/projects/${project_id}/draft`);
    // },
    // projectRepublish: async project_id => {
    //     return await http.patch(`/projects/${project_id}/republish`);
    // },
    projectArchive: async project_id => {
        return await http.patch(`/projects/${project_id}/archive`);
    },
    requestCustomTypeProjectPrice: async project_id => {
        return await http.put(`/projects/${project_id}/request-custom-pricing`);
    },
    setCustomTypeProjectPrice: async (project_id, data) => {
        return await http.put(`/projects/${project_id}/set-custom-price`, data);
    },
    editProjectByAdmin: async (project_id, data) => {
        return await http.put(`/projects/${project_id}/manager`, data)
    },
    paymentRefund: async (project_id, data) => {
        return await http.put(`/manager/${project_id}/payment-refund`, data)
    },
    createExtraCharge: async (project_id, data) => {
        return await http.post(`/projects/${project_id}/extra-charges`, data);
    },
    deleteExtraCharge: async (project_id, extra_charge_id) => {
        return await http.delete(`/projects/${project_id}/extra-charges/${extra_charge_id}`);
    },
    skipExtraCharge: async project_id => {
        return await http.patch(`/projects/${project_id}/extra-charges/skip-awaiting`);
    },
    cancelExtraChargeProject: async project_id => {
        return await http.patch(`/projects/${project_id}/extra-charges/cancel-awaiting`);
    },
    getProjectsCsv: async (params) => {
        return http.get("/projects/export", { params: params })
    },
    projectChangelogEvents: async (project_id, params) => {
        return await http.get(`/projects/${project_id}/changelog`, { params })
    },
    projectChangelogCurrent: async (project_id) => {
        return await http.get(`/projects/${project_id}/changelog/current`)
    }
};

export const commentApi = {
    create: async (formData) => {
        return await post({ path: "/project-comments", formData: formData });
    },
    reply: async (parentID, formData) => {
        return await post({ path: "/project-comments", formData: { parentID, ...formData } });
    },
    update: async (commentID, formData) => {
        // {
        //     "project_id": 49,
        //     "comment": "wefwefwefwefwe!!!~!"
        // }
        const res = await http.patch(`/project-comments/${commentID}`, formData);
        return res?.data?.data;
    },
    updateFull: async (commentID, formData) => {
        // {
        //     "project_id": 49,
        //     "comment": "wefwefwefwefwe!!!~!"
        // }
        const res = await http.put(`/project-comments/${commentID}`, formData);
        return res?.data?.data;
    },
    remove: async (commentID) => {
        return await http.delete(`/project-comments/${commentID}`);
    },
    removeBulk: async (projectId, comments_ids=[]) => {
        return await http.delete(`/projects/${projectId}/comments`, { params: { comments: comments_ids } });
    }
    // getComments: async () => {
    //     const data = await getData({ path: `/project-comments` });
    //     return data;
    // },
    // getCommentsByProject: async (id) => {
    //     const data = await getData({ path: `/projects/${id}/comments` });
    //     data?.data.sort((a, b) => (a.id - b.id));
    //     return data;
    // }
};

export const paymentApi = {
    create: async data => {
        return http.post("/users/create-payment", data);
    },
    // gateway: async (id) => {
    //     const data = await getData({path: `/payment-gateways/${id}`});
    //     return data?.data;
    // },
    gateways: async () => {
        const data = await getData({ path: `/users/payment-gateways` });
        return data?.data;
    },
    //For secret key
    /*
     *   req: { "payment_gateway_id": 1},
     *   res:  { "secret": "sk_0123456789abcdef"}
     */
    createMethod: async (payment_gateway_id) => {
        const res = await post({ path: "/users/payment-methods", formData: { "payment_gateway_id": payment_gateway_id } });
        if (res && res.secret) {
            return res.secret;
        }
        new Error("paymentApi.createMethod secret is not found in response");
    },
    gateMethods: async (payment_gateway_id) => {
        const data = await getData({ path: `/users/payment-methods?payment_gateway_id=${payment_gateway_id}` });
        return data?.data || data || [];
    },
    applyDiscount: async (project_id, code) => {
        return await http.patch(`/projects/${project_id}/apply-discount/${code}`);
    },
    paidAdminProject: async (project_id) => {
        return await http.patch(`/projects/${project_id}/paid`)
    },
    manualUpdatePayment: async (project_id) => {
        return await http.post(`/projects/${project_id}/payout`)
    }
};

export const managerApi = {
    getClientsList: async params => {
        return await http.get("/manager/clients", { params });
    },
    getClientOrderHistory: async (client_id, params) => {
        return http.get(`/manager/clients/${client_id}/orders-history`, {
            params
        });
    },
    getClientProjectsCompleted: async client_id => {
        return await http.get(`/manager/clients/${client_id}/projects/completed`);
    },
    getModelerList: async params => {
        return await http.get("/manager/modelers", { params });
    },
    approveModeler: async modeler_id => {
        return await http.patch(`/manager/modelers/${modeler_id}/approve`);
    },
    rejectModeler: async modeler_id => {
        return await http.patch(`/manager/modelers/${modeler_id}/reject`);
    },
    createArticle: async (data) => {
        return http.post(`/manager/blogs`, data)
    },
    editArticle: async (data, id) => {
        return http.put(`/manager/blogs/${id}`, data)
    },
    getCategoryList: async () => {
        return http.get("/manager/blog-categories")
    },
    getBlogList: async (params = {}) => {
        return http.get("/manager/blogs", { params })
    },
    getArticle: async (id) => {
        return http.get(`/manager/blogs/${id}`)
    },
    deleteArticle: async (id) => {
        return http.delete(`/manager/blogs/${id}`)
    }
}

export const adminApi = {
    getPriceConfig: async () => {
        return await getData({ path: "/admin/price-configuration" });
    },
    updatePriceConfig: async data => {
        return await http.put("/admin/price-configuration", data );
    },

    getDemoList: async (params = {}) => {
        return http.get("/admin/demos", { params })
    },

    createDemo: async (data) => {
        return http.post("/admin/demos", data)
    },

    updateDemo: async (id, data) => {
        return http.patch(`/admin/demos/${id}`, data)
    },
    // delete demo
    deleteDemo: async (id) => {
        return http.delete(`/admin/demos/${id}`)
    },

    getDemoById: async (id) => {
        return http.get(`/admin/demos/${id}`)
    }
}

const api = {
    errors,
    getSilentOpusProduct
};

export default api;
