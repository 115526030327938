<template>
    <v-dialog
        v-bind="$attrs"
        v-on="$listeners"
        :persistent="loading"
        :max-width="480"
        content-class="dismiss-extra-charge-popup">
        <div class="dialog-overflow-wrap">
            <v-btn
                class="pci-ad-cl-btn"
                icon>
                <v-img
                    :src="require('@/assets/icons/ic-close-modal.svg')"
                    contain
                    height="36"
                    max-width="36"
                    @click="$emit('input', false)"/>
            </v-btn>

            <div class="white pa-8 main-text--text">
                <h4 class="mb-5 text-h5 font-weight-semibold main-text--text">Extra charges</h4>

                <h6 class="mb-1 font-weight-semibold">Reason</h6>
                <strong class="d-block font-weight-semibold">{{ $config.extraChargeReasons[getProjectData.extra_charge.reason] }}</strong>

                <p v-if="getProjectData.extra_charge.reason_details" class="mb-0">
                    {{ getProjectData.extra_charge.reason_details }}
                </p>

                <div class="amount-row d-flex justify-space-between my-5 py-3">
                    Amount to be charged
                    <span class="green-100--text">${{ getProjectData.extra_charge.amount }}</span>
                </div>

                <div class="amount-row d-flex justify-space-between mb-5 py-3">
                    Amount to be refunded
                    <span class="red-100--text">${{ getProjectData.extra_charge.refund_amount }}</span>
                </div>

                <div class="d-flex justify-end">
                    <v-btn
                        width="180"
                        height="38"
                        outlined
                        rounded
                        :loading="loading"
                        color="main-text"
                        class="bg-primary"
                        @click="dismissExtraCharge">
                        Dismiss Extra Charge
                    </v-btn>
                </div>
            </div>
        </div>
    </v-dialog>
</template>

<script>
    import { mapGetters } from "vuex";
    import { projectApi } from "@/api";
    export default {
        name: "designer-extra-charge-dialog",
        data() {
            return {
                loading: false
            }
        },
        computed: {
            ...mapGetters([
                "getProjectData",
                "getValueProjectStatus"
            ])
        },
        methods: {
            async dismissExtraCharge() {
                this.loading = true;
                await projectApi.deleteExtraCharge(this.getProjectData.id, this.getProjectData.extra_charge.id);

                const res = await projectApi.get(this.getProjectData.id);

                await this.$store.dispatch("updateProjectData", {
                    status: this.getValueProjectStatus(res.status_id),
                    status_id: res.status_id,
                    extra_charge: null
                });

                this.loading = false;
                this.$emit("input", false);
            }
        }
    }
</script>

<style scoped lang="scss">
::v-deep .dismiss-extra-charge-popup {
    font-size: 14px;
    line-height: 18px;

    h6 {
        font-size: 18px;
        line-height: 20px;
    }

    .amount-row {
        border-top: 1px solid #E8E8E8;
        border-bottom: 1px solid #E8E8E8;
        span {
            font-size: 18px;
            line-height: 20px;
        }
    }
}
</style>
