<template>
    <div class="drawable-image">
        <div v-if="imageLoading" class="page-preloader"/>
        <div
            class="draw-wrapper"
            :id="name" :ref="name"/>
        <v-fade-transition>
            <div v-show="!isZooming">
                <comment-window
                    v-if="commentFormVisible"
                    style="position: absolute"
                    :position="commentWindowPosition"
                    :direction="commentOpensDirection"
                    @cancel="cancelComment"
                    @post="addComment"/>

                <div v-if="hasComments && !commentsHidden">
                    <div
                        v-for="(comment, index) of commentsNodes"
                        :key="index"
                        class="floating-marker"
                        :class="{'pointer-events-none' : paintInProgress}"
                        :style="`position: absolute; left: ${markerAbsolutePosition(comment).x}%; top: ${markerAbsolutePosition(comment).y}%;`">
                        <comments-thread
                            :replies="getCommentReplies(comment.id)"
                            :id="index + 1"
                            :comment="comment"
                            :position="{ x: comment.x, y: comment.y }"
                            :expanded="expandedCommentsThreadId === comment.id"
                            :scene-width="stage ? stage.width(): defaultCanvasWidth"
                            :scene-height="stage ? stage.height(): defaultCanvasHeight"
                            :allow-replay-comment="allowReplayComment"
                            :allow-edit-comment="allowEditComment"
                            @addReply="addReply(comment.id, $event)"
                            @expand="expandedCommentsThreadId = comment.id"
                            @close="expandedCommentsThreadId = null"
                            @hover="onCommentHover(comment.id)"
                            @leave="onCommentLeave(comment.id)"
                            @deleteComment="deleteComment"
                            @updateComment="$emit('updateComment', $event)"/>
                    </div>
                </div>

                <div v-if="stones && stones.length > 0">
                    <attachments-plate
                        :class="{'pointer-events-none' : paintInProgress}"
                        v-for="(stone, index) of stones"
                        :key="index"
                        :group="stone" :position="markerAbsolutePosition(stone)" :allow-edit="profile.id === stone.user_id && allowChangeStone"
                        @hover="onCommentHover(stone.id)"
                        @leave="onCommentLeave(stone.id)" @delete="deleteComment" @updateComment="$emit('updateComment', $event)"/>
                </div>

                <div v-if="metals && metals.length > 0 && !isDesigner">
                    <attachments-plate
                        :class="{'pointer-events-none' : paintInProgress}"
                        v-for="(metal, index) of metals"
                        :key="index"
                        :group="metal" :position="markerAbsolutePosition(metal)" :allow-edit="profile.id === metal.user_id && allowChangeMetal"
                        @hover="onCommentHover(metal.id)"
                        @leave="onCommentLeave(metal.id)" @delete="deleteComment" @updateComment="$emit('updateComment', $event)"/>
                </div>
            </div>
        </v-fade-transition>
    </div>
</template>

<script>
    import Konva from "konva";
    import { mapGetters } from "vuex";

    export default {
        props: {
            initiallyOpenedComment: {
                type: [String, Boolean],
                default: null
            },
            allowReplayComment: {
                type: Boolean,
                default: true
            },
            allowEditComment: {
                type: Boolean,
                default: true
            },
            name: {
                type: String,
                default: "draw-wrapper"
            },
            atachments: {
                type: Array,
                default: null
            },
            backgroundImage: {
                type: String,
                default: null
            },
            mode: {
                type: String,
                default: "draw"
            },
            comments: {
                type: Array,
                default: () => []
            },
            allowChangeStone: {
                type: Boolean,
                default: true
            },
            allowChangeMetal: {
                type: Boolean,
                default: true
            },
            file: {
                type: Object,
                default: null
            }
        },
        components: {
            CommentWindow: () => import("@/components/CommentWindow"),
            CommentsThread: () => import("@/components/CommentsThread"),
            AttachmentsPlate: () => import("@/components/AttachmentsPlate")
        },
        mounted() {
            this.$nextTick(() => {
                this.setUp();

                if(this.initiallyOpenedComment) {
                    this.expandedCommentsThreadId = this.initiallyOpenedComment
                }
            });
        },
        data() {
            return {
                imageLoading: true,
                isDrawing: false,
                isZooming: false,
                stage: null,
                layer: null,
                paintInProgress: false,
                lastLine: null,
                defaultBrushWidth: 10,
                defaultCanvasWidth: 615,
                defaultCanvasHeight: 461,
                brushColor: "#9ff4a980",

                commentFormVisible: false,
                history: this.$props.comments,
                currentHistoryStep: 0,
                historySteps: [],
                defaultHistoryStep: [],

                commentWindowPosition: {
                    x: 0,
                    y: 0
                },
                lastPointerPosition: {
                    x: 0,
                    y: 0
                },
                commentOpensDirection: "top right",
                commentsHidden: false,
                expandedCommentsThreadId: null
            }
        },
        methods: {
            increaseHistoryStep(){
                if (this.currentHistoryStep + 1 > this.historySteps.length) return;

                this.currentHistoryStep += 1;
                let historyStep;

                if (this.currentHistoryStep === this.historySteps.length) {
                    historyStep = JSON.parse(this.defaultHistoryStep);
                } else {
                    historyStep = JSON.parse(this.historySteps[this.currentHistoryStep]);
                }

                if (this.history.length > historyStep.length) {
                    const lastComment = this.history[this.history.length-1];
                    this.deleteComment(lastComment.id, false);
                } else {
                    const lastComment = historyStep[historyStep.length-1];
                    this.$emit("redoComment", {
                        ...lastComment,
                        fileId: lastComment.file_id,
                        points: lastComment.canvas_data[0].points,
                        brush_width: lastComment.canvas_data[0].brush_width,
                        canvas_width: lastComment.canvas_data[0].canvas_width,
                        canvas_height: lastComment.canvas_data[0].canvas_height,
                        position: {
                            x: lastComment.x,
                            y: lastComment.y
                        }
                    });
                    this.drawLine(lastComment)
                    this.hideAllLines();
                }
            },

            reduceHistoryStep(){
                if (this.currentHistoryStep === 0) return;

                if (this.currentHistoryStep === this.historySteps.length) {
                    this.defaultHistoryStep = JSON.stringify(this.history);
                }

                this.currentHistoryStep -= 1;
                const historyStep = JSON.parse(this.historySteps[this.currentHistoryStep]);

                if (this.history.length > historyStep.length) {
                    const lastComment = this.history[this.history.length-1];
                    this.deleteComment(lastComment.id, false);
                } else {
                    const lastComment = historyStep[historyStep.length-1];
                    this.$emit("redoComment", {
                        ...lastComment,
                        fileId: lastComment.file_id,
                        points: lastComment.canvas_data[0].points,
                        brush_width: lastComment.canvas_data[0].brush_width,
                        canvas_width: lastComment.canvas_data[0].canvas_width,
                        canvas_height: lastComment.canvas_data[0].canvas_height,
                        position: {
                            x: lastComment.x,
                            y: lastComment.y
                        }
                    });
                    this.drawLine(lastComment)
                    this.hideAllLines();
                }
            },

            updateHistory() {
                if (this.currentHistoryStep < this.historySteps.length) {
                    this.historySteps.slice(0, this.currentHistoryStep);
                }

                this.historySteps.push(JSON.stringify(this.history));
                if (this.historySteps.length > 20) {
                    this.historySteps.shift();
                }

                this.currentHistoryStep = this.historySteps.length;
            },

            setUp() {
                let stageConfig = {
                    width: this.$refs[this.name].offsetWidth,
                    height: this.$refs[this.name].offsetHeight,
                    container: this.name
                };

                this.stage = new Konva.Stage(stageConfig);

                this.layer = new Konva.Layer();
                this.stage.add(this.layer);

                this.setBackgroundImage(this.backgroundImage);

                this.stage.on("mousedown touchstart", this.onMouseDown);
                this.stage.on("touchend mouseleave", () => {this.paintInProgress = false;});
                this.stage.on("touchend mouseup", this.onMouseUp);
                this.stage.on("mousemove touchmove", this.onMouseMove);

                if(!this.mode) {
                    this.turnOnZoom()
                }
            },

            drawCommentsLines() {
                this.layer.find("Line").forEach((line) => line.destroy());
                if (this.comments && this.comments.length > 0) {
                    const lines = this.comments.filter(
                        (comment) =>
                            comment.canvas_data &&
                            comment.canvas_data[0] &&
                            comment.canvas_data[0].points &&
                            comment.canvas_data[0].points.length > 1
                    );
                    if (lines) {
                        lines.forEach((line) => {
                            this.drawLine(line)
                        });
                    }

                    this.hideAllLines();
                }
            },

            drawLine(line) {
                const drawedLine = new Konva.Line({
                    fill: "#464646b3",
                    stroke: this.brushColor,
                    strokeWidth: line.canvas_data[0].brush_width,
                    globalCompositeOperation: "brush",
                    lineCap: "round",
                    lineJoin: "round",
                    points: line.canvas_data[0].points
                });
                this.layer.add(drawedLine);

                const scaleX = this.stage.width() * 100 / line.canvas_data[0].canvas_width || this.defaultCanvasWidth
                const scaleY = this.stage.height() * 100 / line.canvas_data[0].canvas_height || this.defaultCanvasHeight

                drawedLine.scale({ x: scaleX / 100, y:scaleY/100 })
                this.layer.draw();
            },

            turnOnZoom() {
                this.stage.on("wheel", this.handlerMouseWheel);
                this.stage.on("dblclick", this.handlerDblClick);
            },

            turnOffZoom() {
                this.stage.off("wheel", this.handlerMouseWheel);
                this.stage.off("dblclick", this.handlerDblClick);
            },

            handlerMouseWheel(e) {
                const scaleBy = 1.1;
                e.evt.preventDefault();

                const oldScale = this.stage.scaleX();
                const pointer = this.stage.getPointerPosition();

                const mousePointTo = {
                    x: (pointer.x - this.stage.x()) / oldScale,
                    y: (pointer.y - this.stage.y()) / oldScale
                };

                // how to scale? Zoom in? Or zoom out?
                let direction = e.evt.deltaY > 0 ? -1 : 1;

                // when we zoom on trackpad, e.evt.ctrlKey is true
                // in that case lets revert direction
                // if (e.evt.ctrlKey) {
                //     direction = -direction;
                // }

                const newScale = Math.max(1, Math.min(direction > 0 ? oldScale * scaleBy : oldScale / scaleBy, 10));

                const newPos = {
                    x: pointer.x - mousePointTo.x * newScale,
                    y: pointer.y - mousePointTo.y * newScale
                };

                this.handlerZoom(newScale, newPos);
            },

            handlerDblClick(e) {
                // stop default scrolling
                e.evt.preventDefault();
                const oldScale = this.stage.scaleX();
                const pointer = this.stage.getPointerPosition();

                const mousePointTo = {
                    x: (pointer.x - this.stage.x()) / oldScale,
                    y: (pointer.y - this.stage.y()) / oldScale
                };

                const newScale = oldScale >= 10 ? 1 : 10;

                const newPos = {
                    x: pointer.x - mousePointTo.x * newScale,
                    y: pointer.y - mousePointTo.y * newScale
                };

                this.handlerZoom(newScale, newPos);
            },

            handlerZoom( scale, position ) {
                this.stage.scale({ x: scale, y: scale });

                this.stage.position(position);

                if(scale > 1) {
                    this.isZooming = true;
                    this.stage.draggable(true);
                } else {
                    this.isZooming = false;
                    this.resetLayer();
                }

                this.stage.draw()
            },

            resetLayer() {
                this.stage.draggable(false)
                this.stage.position({
                    x: 0,
                    y: 0
                });
                this.stage.scale({
                    x: 1,
                    y: 1
                });
            },

            setBackgroundImage(val) {
                if (this.file.url !== val) return;

                const imageObj = new Image();
                const canvas = {
                    width: this.stage.width(),
                    height: this.stage.height()
                };

                imageObj.onload = () => {
                    const backgroundImage = new Konva.Image({
                        image: imageObj,
                        ...this.cropImage(imageObj, canvas)
                    });

                    this.layer.find("Image").destroy();
                    this.layer.find("Line").forEach((line) => line.destroy());

                    this.layer.add(backgroundImage);
                    this.layer.draw();
                    this.drawCommentsLines();

                    this.imageLoading = false;
                };

                imageObj.src = val;
            },

            cropImage(img, canvas) {
                // get the scale
                const scale = Math.min(canvas.width / img.width, canvas.height / img.height);
                // get the top left position of the image
                const x = (canvas.width / 2) - (img.width / 2) * scale;
                const y = (canvas.height / 2) - (img.height / 2) * scale;

                return {
                    x,
                    y,
                    width: img.width * scale,
                    height: img.height * scale
                };
            },

            onMouseDown() {
                const pointerPosition = this.stage.getPointerPosition();
                this.expandedCommentsThreadId = null;

                if (this.mode === "draw" && !this.commentFormVisible) {
                    this.paintInProgress = true;
                    this.lastLine = new Konva.Line({
                        fill: "#464646b3",
                        stroke: this.brushColor,
                        strokeWidth: this.computedBrushWidth,
                        globalCompositeOperation: "brush",
                        lineCap: "round",
                        lineJoin: "round",
                        points: [
                            pointerPosition.x,
                            pointerPosition.y,
                            pointerPosition.x,
                            pointerPosition.y
                        ]
                    });
                    this.layer.add(this.lastLine);
                }
            },

            onMouseMove(e) {
                if (this.mode === "draw") {
                    if (!this.paintInProgress) {
                        return;
                    }
                    // prevent scrolling on touch devices
                    e.evt.preventDefault();
                    const pointerPosition = this.stage.getPointerPosition();
                    const newPoints = this.lastLine
                        .points()
                        .concat([pointerPosition.x, pointerPosition.y]);
                    this.lastLine.points(newPoints);
                    this.layer.draw();
                }
            },

            onMouseUp() {
                const pointerPosition = this.stage.getPointerPosition();
                this.paintInProgress = false;
                this.lastPointerPosition = pointerPosition;

                if (!this.atachments && this.mode === "draw") {
                    console.log("open comment form", pointerPosition);
                    this.defineFormOpeningPosition(pointerPosition)
                } else if (this.atachments && this.atachments.length) {
                    console.log("Attachment pl: ",  this.atachments);
                    this.placeAtachmentsGroup(pointerPosition.x, pointerPosition.y);
                }

                if (this.mode === "comment") {
                    this.defineFormOpeningPosition(pointerPosition)
                }
            },

            defineFormOpeningPosition({ x, y }){
                const formWidth = 284;
                const formHeight = 124;

                const rightCorner = x + formWidth
                const leftCorner = x - formWidth
                const topCorner = y + formHeight

                let horizonatal = "right"
                let vertical = "bottom"

                if (rightCorner <= this.stage.width()) {
                    horizonatal = "right";
                    this.$set(this.commentWindowPosition, "x" , x)
                } else {
                    horizonatal = "left";
                    this.$set(this.commentWindowPosition, "x" , leftCorner)
                }
                if(topCorner > this.stage.height()) {
                    this.$set(this.commentWindowPosition, "y" , y-formHeight)
                } else {
                    this.$set(this.commentWindowPosition, "y" , y)
                }

                this.openCommentFormAt(this.commentWindowPosition.x, this.commentWindowPosition.y)
                this.commentOpensDirection = {
                    horizonatal,
                    vertical
                }
            },

            openCommentFormAt(x, y) {
                console.log("open comment form at", x,y);
                this.commentFormVisible = true;
                this.commentWindowPosition = { x, y };
            },

            addComment(text) {
                const lastLineAtLayer = this.layer.find("Line")[this.layer.find("Line").length - 1];

                const comment = {
                    id: this.comments.length + 1,
                    text,
                    position: this.lastPointerPosition,
                    lineIndex: this.mode === "draw" ? this.layer.find("Line").length - 1 : null,
                    points: this.mode === "draw" ? lastLineAtLayer.points() : null,
                    brush_width: this.computedBrushWidth,
                    canvas_width: this.stage.width(),
                    canvas_height: this.stage.height()
                };
                console.log("Add comment");

                if(this.mode==="draw") {
                    this.hideAllLines()
                }
                this.$emit("addComment", comment);
                this.commentFormVisible = false;
                this.updateHistory();
            },

            showLine(index) {
                const lineShapes = this.layer.find("Line");

                if (lineShapes[index]) {
                    lineShapes[index].show();
                    this.layer.draw();
                }
            },

            hideLine(index) {
                const lineShapes = this.layer.find("Line");
                if (lineShapes[index]) {
                    lineShapes[index].hide();
                    this.layer.draw();
                }
            },

            removeAll() {
                this.layer.clear();
                this.setUp()
            },

            placeAtachmentsGroup(x, y) {
                const attachment = {
                    attachments: this.atachments,
                    position: { x, y },
                    points: this.lastLine.points(),
                    brush_width: this.computedBrushWidth,
                    canvas_width: this.stage.width(),
                    canvas_height: this.stage.height()
                };


                if (this.atachments.every(attach => attach.itemType === this.$config.commentType.stone)) {
                    this.$emit("addStone", attachment);
                } else if (this.atachments.every(attach => attach.itemType ===this.$config.commentType.metal)) {
                    this.$emit("addMetall", attachment);
                }

                this.updateHistory();
            },

            cancelComment() {
                this.commentFormVisible = false;
                if (this.mode === "draw") {
                    this.deleteLastLine();
                }
            },

            hideAllLines() {
                this.layer.find("Line").forEach((line) => {
                    line.hide();
                });
                this.layer.draw();
            },

            deleteLastLine() {
                if (this.mode === "draw") {
                    const lines = this.layer.find("Line");
                    if(lines && lines[lines.length-1]) {
                        lines[lines.length-1].destroy()
                        this.layer.draw()
                    }
                }
            },

            getCanvasData() {
                return this.stage.toJSON();
            },

            addReply(parentId, replyText) {
                const reply = {
                    parent_id: parentId,
                    comment: replyText
                };
                this.$emit("addReply", reply);
            },

            getCommentReplies(commentId) {
                return this.comments.filter(
                    (comment) => comment.parent_id && comment.parent_id === commentId
                );
            },

            onCommentHover(commentID) {
                const lineIndex = this.findLineIndexForComment(commentID);
                if (lineIndex >= 0) {
                    this.showLine(lineIndex);
                }
            },

            onCommentLeave(commentID) {
                const lineIndex = this.findLineIndexForComment(commentID);
                if (lineIndex >= 0) {
                    this.hideLine(lineIndex);
                }
            },

            findLineIndexForComment(id) {
                // The line index coresponding to comments order;
                const commentsWithLines = this.comments.filter(
                    (comment) =>
                        comment.canvas_data &&
                        comment.canvas_data[0] &&
                        comment.canvas_data[0].points &&
                        comment.canvas_data[0].points.length > 1
                );
                return commentsWithLines.findIndex(
                    (comment) => id === comment.id
                );
            },

            setCommentsVisibility(isVisible){
                this.commentsHidden = isVisible
                if(!isVisible) {
                    this.hideAllLines()
                }
            },

            deleteComment(commentId, shouldHistoryUpdate = true){
                const commentsLineIndex = this.findLineIndexForComment(commentId)
                if(commentsLineIndex>=0){
                    this.layer.find("Line")[commentsLineIndex]?.destroy()
                    this.layer.draw()
                }
                this.$emit("deleteComment",  commentId);

                if (shouldHistoryUpdate) {
                    this.updateHistory();
                }
            }
        },
        computed: {
            ...mapGetters(["profile", "isDesigner"]),

            computedBrushWidth() {
                return this.stage ? Math.ceil(this.stage.width() * 0.016) : this.defaultBrushWidth
            },

            markerAbsolutePosition() {
                return (marker) => {
                    const width = marker.canvas_data[0].canvas_width || this.defaultCanvasWidth
                    const height = marker.canvas_data[0].canvas_height || this.defaultCanvasHeight
                    return {
                        x: marker.x * 100 / width,
                        y: marker.y * 100 / height
                    }
                }
            },

            hasComments() {
                return this.comments && this.comments.length > 0;
            },

            commentsNodes() {
                if (this.history && this.history.length > 0) {
                    return this.history.filter(
                        (comment) => comment.type === 1 && !comment.parent_id
                    );
                } else {
                    return [];
                }
            },

            metals() {
                if (this.history && this.history.length > 0) {
                    return this.history.filter((comment) => comment.type === this.$config.commentType.metal);
                } else {
                    return [];
                }
            },

            stones() {
                if (this.history && this.history.length > 0) {
                    return this.history.filter((comment) => comment.type === this.$config.commentType.stone);
                } else {
                    return [];
                }
            }
        },
        watch: {
            mode(value) {
                if(!this.stage) return;
                if (value) {
                    this.turnOffZoom()
                } else {
                    this.turnOnZoom();
                }
                this.isZooming = false
                this.resetLayer();
                this.stage.draw();
            },
            backgroundImage() {
                this.layer.find("Image").destroy();
                this.layer.find("Line").forEach((line) => line.destroy());

                this.setUp()
            },
            comments: {
                deep: true,
                handler(nv) {
                    this.history = nv;
                    this.drawCommentsLines()
                }
            }
        }
    };
</script>

<style scoped lang="scss" src="./style.scss"></style>
