<template>
    <!--  <a role="button" class="drop-item-menu__btn drop-item-menu__btn&#45;&#45;close" v-if="!isReplying" @click="$emit('hideComment', $event)">Close</a>-->
    <button
        class="btn--link v-size--default"
        style="color: rgb(119, 119, 119); caret-color: rgb(119, 119, 119);"
        type="button"
        @click="$emit('click', $event)">
        <span class="v-btn__content">
            <slot/>
        </span>
    </button>
</template>

<script>
    export default {
        name: "vlink",
        props: {}
    };
</script>

<style lang="scss" scoped>
    .btn--link {
        .v-ripple-container {
            display: none;
        }
        padding: 0 !important;
        &:hover {
            border: 0 !important;
            text-decoration: none;
        }
        &:active {
            border: 0 !important;
            outline: none !important;
        }
        &:focus {
            border: 0 !important;
            outline: none !important;
        }
    }
</style>
